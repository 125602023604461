import React from 'react'
import { Row, Col , Carousel} from 'antd'
import classes from "./css/Slider.module.css"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'

import { settingsCarouselHomeSlider } from '../../../components/ArrowsForCarousel/Categories'

const Slider: React.FC = () => {

  const { t } = useTranslation()
 
  return (
    <>
     <div className={classes.containerBig}>
        <div className={classes.container}>
          <Row justify="center">

          <Col span={24}>
            
              <div className={classes.title}>
                {t('Slider_Title')}
              </div>
              <div className={classes.subTitle}>
                {t('Slider_SubTitle')}
              </div>

              <div className={classes.sliderContainer}>
                <Carousel autoplay {...settingsCarouselHomeSlider} >
                  <div>
                    <img className={classes.image} alt="Chania image 1" src="./img/headerSlider/01.jpg" />
                  </div>
                  <div>
                    <img className={classes.image} alt="Chania image 2" src="./img/headerSlider/02.jpg" />
                  </div>
                  <div>
                    <img className={classes.image} alt="Chania image 3" src="./img/headerSlider/03.jpg" />
                  </div>
                  <div>
                    <img className={classes.image} alt="Chania image 4" src="./img/headerSlider/04.jpg" />
                  </div>
                </Carousel>
              </div>

            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Slider
