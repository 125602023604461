
import React, {useState, useEffect} from "react"
import { Row, Col, Button, Spin, Rate, message, Popconfirm, Tooltip } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import { usePois } from '../../../hooks/useExploration'
import { useParams, useNavigate } from 'react-router-dom'
import { HeartOutlined, ExclamationOutlined } from '@ant-design/icons'

import { useUserFavoriteAddOrRemovePoint } from '../../../hooks/useUser'
import { useAuth } from "../../../utils/auth"

import GoToPoiSvg from '../../../img/svg/goToPoi'


import HelmetComponent from '../../../components/Helmet'
import LiefletComponent from './Lieflet'
import SliderComponent from './Slider'
import Slider3DComponent from './Slider3d'
import SliderVideoComponent from './SliderVideo'


import PlayComponent from './Play'
import ThreePoisesComponent from './ThreePoises'


import i18n from '../../../translation'



import ImageSvg from '../../../img/svg/ImageSVG'
import ImageThreeSvg from '../../../img/svg/ImageThreeSVG'
import ImageVideoSvg from '../../../img/svg/ImageVideoSVG'
import {ChangeMunicipality} from '../../../components/Box/ChangeMunicipality'

const ExplorationView: React.FC = () => {

  const { t } = useTranslation()
  const auth = useAuth()


  const { id } = useParams()
  const { isLoading, isFetching, PoisData, refe } = usePois(id || '')
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage()

  const [pois, setPois] = useState<any>({})
  const [poisId, setPoisId] = useState<string>('')
  const { refetch } = useUserFavoriteAddOrRemovePoint(auth.userData.id, poisId, 1)
  const [showImages, setShowImages] = useState<boolean>(true)
  const [showImages360, setShowImages360] = useState<boolean>(false)
  const [showVideo, setShowVideo] = useState<boolean>(false)



  const changeView = (view1: boolean, view2: boolean, view3: boolean) => {

    setShowImages(view1)
    setShowImages360(view2)
    setShowVideo(view3)
  }

  const onClickAdd = (poisId: string) => {

    setPoisId(poisId)

    messageApi.open({
          type: 'success',
          content: t('Σημείο Ενδιαφέροντος προστέθηκε με επιτυχία'),
          style: {
            marginTop: '20vh'
          },
        })
  }

  useEffect(() => {
    if (poisId !== '')
    refetch()
 }, [poisId]);


  useEffect(() => {
    if(PoisData)
      setPois(PoisData)
  }, [PoisData])

  
  useEffect(() => {
    refe()
  }, [i18n.language])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  const showInMapClicked = () => {
    window.open(`https://www.google.com/maps/dir//${pois.lat},+${pois.lng}/@${pois.lat},${pois.lng},13z/data=!4m6!4m5!1m0!1m3!2m2!1d22.3801386!2d38.3825321`)
  };


  if (isLoading || Object.keys(pois).length < 0) {
    return (
      <Row justify="center" >
        <Col span={12} offset={6}>
          <div className={classes.loadingSpinner}> <Spin size="large" /> {t('Παρακαλώ περιμένετε')} ... </div>
        </Col>
      </Row>
    )
  }


  return (
    <>
      {contextHolder}

      <HelmetComponent
        title={pois.title}
        descrition={pois.title+', ' + ChangeMunicipality(t(pois.municipality))}
        keywords='Εξερεύνηση'
      />

      {isFetching && <div className={classes.allScreen}>
          <div className={classes.loader}></div>
      </div>}
      

      
      <div className={classes.containerBackgroundImage}>
        <div className={classes.container}>
          <Row justify="center">
            <Col span={14}>
              <div className={classes.title}>
                {pois.title}
              </div>
              <div className={classes.subTitle}>
                
                { id === '1_48'  ? t('Δήμος') + ' ' + t(pois.municipality) + ',  ' + t('Δήμος') + ' ' + t('Πλατανιά')
                  : id === '1_290' ? t('Δήμος') + ' ' + t(pois.municipality) + ',  ' + t('Δήμος') + ' ' + t('Κισσάμου')
                    : t('Δήμος') + ' ' + t(pois.municipality)
                }

              </div>
              <div className={classes.rate}>
                <Rate disabled allowHalf value={pois.rating}  />
              </div>
            </Col>
          </Row>

          <Row justify="center">
            <Col span={18}>
              <Button
                size='large'
                className={classes.BackBtn}
                onClick={() => navigate(-1)}>&nbsp;{t('Επιστροφή')}</Button>
            </Col>
          </Row>

        </div>
      </div>
      
      {pois.images && pois.images.length !== 0 && showImages &&
        <Row justify="center">  
          <Col xs={16} sm={16} md={{ span: 16 }} lg={14}>
            <div className={classes.helper}>
              <img src={pois.images[0].url} alt="Image from Pois" />
            </div>
          </Col>
        </Row> 
      }


      <br/><br/>

      <Row justify="center">

        <Col xs={16} sm={14} md={{ span: 6 }} lg={{ span: 6 }}>
          <div className={classes.PointFavoriteBtnRight}>
            <Button
              size='large'
              icon={<GoToPoiSvg width={18} height={18} fill='' />}
              className={classes.PointFavoriteBtn}
              onClick={showInMapClicked}
            >
              &nbsp;{t('Μετάβαση στο σημείο')}
            </Button>
          </div>  
        </Col>

        <Col xs={16} sm={14} md={{ span: 6 }} lg={{ span: 6 }}>
          <div className={classes.PointFavoriteBtnleft}>
            {(auth.userData.id == '' || auth.userData.id == undefined)
              ?
              <Popconfirm
                title= {<div style={{ width: '200px', textAlign: 'left', fontSize: '14px', color: '#05324c', lineHeight: '1.2' }}>
                        {t('Για να προσθέσεις σημείο ενδιαφέροντος στα Αγαπημένα σου, κάνε σύνδεση ή εγγραφή')}
                      </div>}
                //  description="Πρέπει να είστε συνδεδεμένοι για να προσθέσετε σημεία ενδιαφέροντος στη λίστα με τα Αγαπημένα σας."
                onConfirm={() => navigate('/login')}
                icon={<ExclamationOutlined style={{ color: '#275674', fontWeight: '600' }} />}
                okText={t('Σύνδεση')}
                showCancel={false}
              >
                <Button
                  size='large'
                  icon={< HeartOutlined />}
                  className={classes.PointFavoriteBtn}
                >
                  {t('Προσθήκη στα Αγαπημένα')}
                </Button>
              </Popconfirm>
              :
              <Button
                size='large'
                icon={< HeartOutlined />}
                className={classes.PointFavoriteBtn}
                onClick={() => auth.userData.id !== '' && onClickAdd(pois.id)}
              >
                {t('Προσθήκη στα Αγαπημένα')}
              </Button>
            }
          </div>
        </Col>
      </Row>

      
      <PlayComponent title={pois.title } mp3={pois.mp3 } />
      
      <Row justify="center">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 14 }} lg={{ span: 14 }} >
          <hr className={classes.hr2} />
        </Col>
      </Row>

      <Row justify="center">
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14 }} lg={{ span: 14 }} >
          <div className={classes.description}>
            <br/>
            <div className={classes.eventTitle}>{t('Περιγραφή')}</div>

            <div className={classes.eventDescription} dangerouslySetInnerHTML={{ __html: pois.description }} />

            <div className={classes.eventPhoto}>{t('Πολυμεσικό Υλικό')}</div>

            {pois.images && pois.images.length !== 0 && showImages &&
              
              <SliderComponent images={pois.images} />
            }

            {pois.images3d && pois.images3d.length !== 0 && showImages360 &&
             
             <Slider3DComponent images={pois.images3d} />
            }

            {pois.video && pois.video.length !== 0 && showVideo &&
             
             <SliderVideoComponent url={pois.video[0].url} />
            }

            <div className={classes.demoImg}>

              {pois.images && pois.images.length !== 0 && pois.images3d.length !== 0 &&
                <Tooltip placement="topLeft" title={t('Προβολή φωτογραφιών')} >
                  
                  <span onClick={() => changeView(true, false, false)}>
                    <img src={pois.images[0].url} alt='preview image' className={classes.demoImgImg} />
                    <span><ImageSvg /></span>
                    <span className={classes.textDemo}>Image</span>
                  </span>
                  
                </Tooltip>
              }  

              {pois.images3d && pois.images3d.length !== 0 &&
                <Tooltip placement="topLeft" title={t('Εικονική περιήγηση')} >
                  <span onClick={() => changeView(false, true, false)}>
                    <img src={pois.images3d[0].url} alt='preview image' className={classes.demoImgImg} />
                    <span><ImageThreeSvg /></span>
                    <span className={classes.textDemo}>360&#176;</span>
                  </span>
                </Tooltip>
              }  

              {pois.video && pois.video.length !== 0 &&
                <Tooltip placement="topLeft" title={t('Αναπαραγωγή Βίντεο')} >
                  <span onClick={() => changeView(false, false, true)}>
                    <img src={pois.images[0].url} alt='preview Video' className={classes.demoImgImg} />
                    <span><ImageVideoSvg /></span>
                    <span className={classes.textDemo}>Video</span>
                  </span>
                </Tooltip>
              } 
              
            </div>
         </div>
        </Col>
      </Row>
      

      <br />
      
        {pois.lat &&
          <Row justify="center">
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14 }} lg={{ span: 14 }} >
            <div className={classes.description}>
           
              <div className={classes.eventTitle}>{t('Χάρτης')}</div> 
              
              <LiefletComponent
                image={pois.image}
                title={pois.title}
                latitude={pois.lat}
                longitude={pois.lng}
              />
            </div> 
            </Col>
          </Row>
        }
     

      <Row justify="center">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 14 }} lg={{ span: 14 }} >
          <br/><br/>
          <div className={classes.eventTitle}>{t('Κατηγορίες')}</div>
          {/* <div>{ pois?.category && pois.category.forEach((cat: string) => <div> {cat}</div> )}</div>*/}
          <div className={classes.eventPaddingBottom}>{pois?.category && pois.category.map((cat: string, index: number) => <span key={ index } className={classes.Categories}>{cat}</span>) }</div>
        </Col>
      </Row>

      <br/><br/>

      <div style={{ backgroundColor: '#f3f8fd' }}>
        <Row justify="center" className={classes.DestinationContainer}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} >
            <ThreePoisesComponent />
          </Col>
        </Row>
      </div>

    </>
  )
}

export default ExplorationView
