
import React, {useState, useEffect} from "react"
import { Row, Col, Button, Spin, Drawer, Space  } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import i18n from '../../translation'
import { useAreas } from '../../hooks/useAreas'
import {  Link } from 'react-router-dom'
import HelmetComponent from '../../components/Helmet'
import Spinner from '../../components/PageComponents/Spinner'
import TitleAndSubtitle from '../../components/PageComponents/TitleAndSubtitle'
import CheckBoxComponent from './checkBoxGroup'
import ViewRoutesComponent from './viewRoutes'
import LiefletComponent from './Lieflet'
import { CloseOutlined } from '@ant-design/icons'
import MapSvg from '../../img/svg/map'
import { NodeIndexOutlined } from  "@ant-design/icons"
import GlobusSvg from '../../img/svg/globus'

import type { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'

import {RoutesTypeData} from './routesType'

import { useRoutes } from '../../hooks/useRoute'
import utils from '../../utils'

const Routes: React.FC = () => {

  const { t } = useTranslation()

  const { isLoading, RoutesData, refetch } =  useRoutes()

  const { areas } = useAreas()


  const ReduxRoutes: any[] = useSelector((state: RootState) => state.Routes.Routes)
  const ReduxMunicipalities: any[] = useSelector((state: RootState) => state.Routes.Municipality)

  const [municipalities, setMunicipalities] = useState<any[]>([]);

  const [routes, setRoutesData] = useState<any[]>([]);
  const [polyline, setPolyline] = useState<[number, number][][]>([]);
  const [pois, setPois] = useState<[number, number][][]>([]);

  const [setPoisesMarkerExploration, PoisesMarkerExploration ] = useState<any[]>([]);

  const [showMap, setShowMap] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {

    setMunicipalities(areas)
    if(RoutesData)
    setRoutesData(RoutesData)
   
  }, [RoutesData])


  useEffect(() => {

    let polyline: any[] = []
    let addPois: any[] = []
    let pois: any[] = []

    if (RoutesData) {
      let result = utils.filterRoutesArray(RoutesData, ReduxRoutes, ReduxMunicipalities)

      for (let i = 0; i < result.length; i++) {

        pois.push([])
        polyline.push([])
        addPois.push([])

        result[i].route_coordinates.forEach((popyl: any, j: any, row: any): void => {
          if (j + 1 === row.length) {
            addPois[i].push({ id: result[i].pois.length + 1, latitude: parseFloat(popyl.latitude), longitude: parseFloat(popyl.longitude), name: 'Finish' })
          }
          polyline[i].push([parseFloat(popyl.latitude), parseFloat(popyl.longitude)])
        })

        result[i].pois.forEach((poi: any): void => {
          pois[i].push({
            main_id: result[i].id,
            id: poi.id,
            latitude: parseFloat(poi.latitude),
            longitude: parseFloat(poi.longitude),
            image: poi.main_image,
            title: poi.name,
            description: poi.portal_main_info,
            category: poi.categories[0].name,
            
          })
        })

      }

      result.length >= 1 ? setRoutesData(result) : setRoutesData([])
      setPolyline(polyline)
      setPois(pois)
    }

  },[ RoutesData, ReduxRoutes, ReduxMunicipalities ])



  const showDrawer = () => { setOpen(true) }

  const onClose = () => { setOpen(false) }


  
  useEffect(() => {
    refetch()
  }, [i18n.language])

  if ( !RoutesData ) {
    return (<Spinner />)
  }

  return (
    <>
      <HelmetComponent
        title={t('routes_title') as string}
        descrition={t('routes_description') as string}
        keywords={t('routes_keywords') as string}
      />
     
      <TitleAndSubtitle title={t('Ζήσε την περιπέτεια ακολουθώντας διαδρομές στα Χανιά')} subTitle={t('Βρες τα σημεία που σε ενδιαφέρουν στο Χάρτη')}/>

      {showMap &&
        <div className={classes.MainContainer}>
          <div className={classes.container}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <div className={[classes.btn, classes.btnPadding].join(" ")}>
                  <Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff" />} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp; {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <LiefletComponent
                  pois={pois}
                  polyline={polyline}
                  iconImg={<NodeIndexOutlined width={25} height={25} color="#275674" />}
                  setPoisesMarkerExploration={setPoisesMarkerExploration} />
              </Col>
            </Row>
          </div>
        </div>
      } 
      <div className={classes.MainContainer}>
        <div className={classes.checkBoxesContainerPoint}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
             
              <div className={classes.btnFiltersAndMap}>
                <div>
                  <Button
                    type="primary"
                    size='large'
                    className={classes.btnFilters}
                    onClick={showDrawer}> {t('Φίλτρα')}
                  </Button>
                </div>
                {
                  !showMap &&
                  <div className={classes.btn}>
                    <Button
                      type="primary"
                      icon={<MapSvg width={20} height={20} fill="#fff" />}
                      size='large'
                      className={classes.btnMap}
                      onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp; {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')}
                    </Button>
                  </div>
                } 
              </div>

            </Col>
          </Row>
        </div>
      </div>
      <div className={classes.MainContainer}>


      <Drawer
        bodyStyle={{padding:'0px'}}
        autoFocus={false}
        mask={false}
        title={t('Φίλτρα')}
        placement={'left'}
        closable={false}
        onClose={onClose}
        open={open}
        // getContainer={false}
        width={350}
        extra={
          <Space>
          <Button
            icon={<CloseOutlined />}
            onClick={onClose}
            type="text"
            style={{ position: 'absolute', top: 8, right: 0 }}
          />
        </Space>
        }
      >
        <div className={classes.checkBoxesContainer}>
          <p className={classes.filters}>{t('Φίλτρα αναζήτησης')}</p>
            <CheckBoxComponent header={'Διαδρομές'} array={RoutesTypeData} icon={<NodeIndexOutlined style={{ color: "#275674", fontSize: "30px"}} />} type={'routes'} />
          <hr />
            <CheckBoxComponent header={t("Δήμος") || ''} array={municipalities} icon={<GlobusSvg width={30} height={30} fill="#275674" />} type={'municipality'} /> 
        </div>
      </Drawer>

      <Row justify="center">
          <Col xs={24} sm={24} md={5} lg={5}>
            <span className={classes.hideIsMobile}>
              <div className={classes.checkBoxesContainer}>
                <p className={classes.filters}>{t('Φίλτρα αναζήτησης')}</p>
                  <CheckBoxComponent header={'Διαδρομές'} array={RoutesTypeData} icon={<NodeIndexOutlined style={{ color: "#275674", fontSize: "30px"}} />} type={'routes'} />
                <hr />
                <CheckBoxComponent header={t("Δήμος") || ''} array={municipalities} icon={<GlobusSvg width={30} height={30} fill="#275674" />} type={'municipality'} /> 
              </div>
            </span>
        </Col>

        <Col xs={24} sm={24} md={19} lg={19}>
          <Row justify="center">
            <Col xs={24} sm={24} md={24} lg={24}>
           
                {routes.length !== 0
                  
                  ?
                  <ViewRoutesComponent
                    array={routes}
                    total={routes.length}
                    icon={<NodeIndexOutlined style={{ color: "#275674", fontSize: "26px" }} />}
                    setEventsMarkerExploration={PoisesMarkerExploration} />
                  :

                  <div className={classes.dontHave}>
                    { t('Δεν υπάρχουν διαδρομές με τα συγκεκριμένα κριτήρια')}
                  </div>
                } 
                
            </Col>
          </Row>
        </Col>

      </Row>
      </div>
    </>
  )
}

export default Routes
