export const CategoriesData = [{
  id: 1,
  src: '../../img/Categories/1.png',
  title: 'Παραλίες',
  type:'env',
  typeId: 21, 
},
{
  id: 2,
  src: '../../img/Categories/2.png',
  title: 'Φαράγγια',
  type:'env',
  typeId: 24,
},
{
  id: 3,
  src: '../../img/Categories/3.png',
  title: 'Μουσεία',
  type:'his',
  typeId: 4,
},
{
  id: 4,
  src: '../../img/Categories/4.png',
  title: 'Αρχαιολογικά',
  type:'his',
  typeId: 34,
},
{
  id: 5,
  src: '../../img/Categories/5.png',
  title: 'Μοναστήρια',
  type:'his',
  typeId: 6,
},
{
  id: 6,
  src: '../../img/Categories/6.png',
  title: 'Σπήλαια',
  type:'env',
  typeId: 26,
},
{
  id: 7,
  src: '../../img/Categories/7.png',
  title: 'Μονοπάτια',
  type:'env',
  typeId: 27,
},
{
  id: 8,
  src: '../../img/Categories/8.png',
  title: 'Περισσότερα',
  type:'env',
  typeId: 0,
},
// {
//   id: 3,
//   date: '03.03.2023',
//   event: 'Συναντήσεις με πρεσβείες στη Βουλγαρία',
//   city: 'ALEXANDROUPOLI 3'
// },
// {
//   id: 4,
//   date: '04.04.2023',
//   event: 'Συναντήσεις με πρεσβείες στην Ελλάδα',
//   city: 'ALEXANDROUPOLI 4'
// },
// {
//   id: 5,
//   date: '05.05.2023',
//   event: 'Συνάντηση καθοδήγησης των εταίρων του έργου στη Θεσσαλονίκη',
//   city: 'ALEXANDROUPOLI 5'
// },
// {
//   id: 6,
//   date: '06.06.2023',
//   event: 'Συμμετοχή σε αποστολή και έκθεση στο Ισραήλ',
//   city: 'ALEXANDROUPOLI 6'
// },
// {
//   id: 7,
//   date: '07.07.2023',
//   event: 'Αποστολή στο Βιετνάμ',
//   city: 'ALEXANDROUPOLI 7'
// },
// {
//   id: 8,
//   date: '07.07.2023',
//   event: 'Αποστολή στο Βιετνάμ',
//   city: 'ALEXANDROUPOLI 8'
// }
]


