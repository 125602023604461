
import React from "react"
import Props from './svgProps'

const LoginCreateAccountPersonSvg = ({width, height, fill}:Props) => {
    return (
      <svg 
        width={width}
        height={height} 
        viewBox="0 0 45 40" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
        <path id="Icon" d="M22.5 27H13.5C10.7089 27 9.31331 27 8.17772 27.3445C5.62091 28.1201 3.62008 30.1209 2.84448 32.6777C2.5 33.8133 2.5 35.2089 2.5 38M30.5 32L34.5 36L42.5 28M27.5 11C27.5 15.9706 23.4706 20 18.5 20C13.5294 20 9.5 15.9706 9.5 11C9.5 6.02944 13.5294 2 18.5 2C23.4706 2 27.5 6.02944 27.5 11Z" stroke="#275674" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
}

export default LoginCreateAccountPersonSvg
