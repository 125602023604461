import React, { useState, useEffect } from 'react';
import { Modal, notification } from 'antd';
import { useAuth } from "../../utils/auth"
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import classes from "./Pois.module.css"
import { HeartOutlined, CloseCircleOutlined } from '@ant-design/icons'
import LoginCreateAccountPersonSvg from '../../img/svg/loginCreateAccountPerson'
import FavoritePoiAddedSvg from '../../img/svg/favoritePoiAdded'
import ModalRegisterFormForPoiComponent from "../../pages/Secure/Login/ModalRegisterFormForPoiComponent"
import ModalLoginFormForPoiComponent from "../../pages/Secure/Login/ModalLoginFormForPoiComponent"
import type { NotificationPlacement } from 'antd/es/notification/interface'

interface Props{
  id: string
  isAuth?: boolean
  isAddOrDelete?: boolean
  onClickAddOrRemovePois: (text: string, idPois: string, AddOrDelete: number) => void,
  onClickAddRemovePois: (idPois : string) => void,
}
const config = {
  maxCount: 1,
  duration: 120,
};

const LoginOrRegisterModal: React.FC<Props> = ({ id, isAuth, isAddOrDelete, onClickAddOrRemovePois, onClickAddRemovePois }: Props) => {
  
  const { t } = useTranslation()

  const [api, contextHolder] = notification.useNotification(config)
  const [modalLoginOrRegisterOpen, setModalLoginOrRegisterOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const auth = useAuth()
  
  const Myfunc = () => {

    onClickAddRemovePois(id)
    
    if (isAddOrDelete) {
      onClickAddOrRemovePois(t('Σημείο Ενδιαφέροντος προστέθηκε με επιτυχία'), id, 1)
      openNotification('bottom')
    }
    
    else
      onClickAddOrRemovePois(t('Σημείο Ενδιαφέροντος διαγράφηκε επιτυχώς'), id, 0)
  }
  

  useEffect(() => {
    
  }, [])

  
  const destroyNotification = () => {
    api.destroy()
  }


  const openNotification = (placement: NotificationPlacement) => {
    
    api.open({
      key: 'ser',
      className: 'FavoritePoiAddedBg',
      message: <span className={classes.FavoritePoiAddedSvgTitle}>{t('Το Σημείο Ενδιαφέροντος προστέθηκε στα Αγαπημένα σου!')}</span>,
      description: <><p className={classes.FavoritePoiAddedSvgText}>{t('Μπορείς να δεις όλα τα αγαπημένα σημεία σου στη σελίδα του Λογαριασμού σου')}</p>
        <span className={classes.destroyNotification} onClick={destroyNotification}>{t('Παράλειψη')}</span>
        <Link to={'/favorites' } className={classes.goToMyAccount} >{t('Μετάβαση στο Λογαριασμό μου')}</Link></>,
      icon: <FavoritePoiAddedSvg width={38} height={38} fill="#75E0A7" />,
      placement  
    });

    notification.destroy()
  };


  return (
    <>
      {contextHolder}
      <Modal
        title={<span className={classes.LoginCreateAccountPersonSvg}><LoginCreateAccountPersonSvg width={25} height={25} fill="#275674"/></span>}
        centered
        open={modalLoginOrRegisterOpen}
        footer={null}
        onCancel={() => setModalLoginOrRegisterOpen(false)}
      >
        <p className={classes.LoginCreateAccountPersonSvgTitle}>{t('Σύνδεση ή δημιουργία Λογαριασμού')}</p>
        <p className={classes.LoginCreateAccountPersonSvgText}>{t('Για να προσθέσεις σημείο ενδιαφέροντος στα Αγαπημένα σου, κάνε σύνδεση ή εγγραφή')}</p>

        <div className={classes.wrapper}>
          <div><ModalLoginFormForPoiComponent activeIndex={ activeIndex}  clickModal={()=>setActiveIndex(0)} /> </div>
          <div><ModalRegisterFormForPoiComponent clickModal={(e)=>setActiveIndex(1)} /> </div>
        </div>
      </Modal>

      {(auth.userData.id == '' || auth.userData.id == undefined)
        ?
        <div className={!isAuth ? classes.PointFavoriteBtn2 : classes.PointRemoveFavoriteBtn} onClick={() => setModalLoginOrRegisterOpen(true)} >
          {!isAuth ? < HeartOutlined /> : <CloseCircleOutlined />}&nbsp;
          {!isAuth ? t('Προσθήκη στα Αγαπημένα') : t('Αφαίρεση απο αγαπημένα')}
        </div>

        :
        <div
          className={!isAuth ? classes.PointFavoriteBtn2 : classes.PointFavoriteBtnRemove}
          // style={{color: 'red'}}
         // onClick={() => auth.userData.id !== '' && [onClickAddRemovePois(id), isAddOrDelete ? onClickAddOrRemovePois(t('Σημείο Ενδιαφέροντος προστέθηκε με επιτυχία'), id, 1) : onClickAddOrRemovePois(t('Σημείο Ενδιαφέροντος διαγράφηκε επιτυχώς'), id, 0)]}
          onClick={Myfunc}
        >
          {!isAuth ? < HeartOutlined /> : <CloseCircleOutlined />}&nbsp;
          {!isAuth ? t('Προσθήκη στα Αγαπημένα') : t('Αφαίρεση απο αγαπημένα')}
        </div>

      }
    </>
  )
}

export default LoginOrRegisterModal