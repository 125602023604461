import React from "react"
import classes from "./Footer.module.css"


import Find from './parts/Find'
import Mobile from './parts/Mobile'
import Download from './parts/Download'


function Footer() {

  return (
    <>
      <Find /> 
      <Mobile />
      <Download />
    </>
  )
}

export default Footer