import React from "react"
import { Row, Col } from 'antd'
import classes from './styles.module.css'


interface Props{
  title: string
  subTitle: string
}

const TitleAndSubtitle: React.FC<Props> = ({ title, subTitle }: Props) => {

  return (
      <div className={classes.container}>
        <Row justify="center">
          <Col span={20}>
            <div className={classes.title}>
            { title }
            </div>
            <div className={classes.subTitle}>
            { subTitle }
            </div>
          </Col>
        </Row>
      </div>
  )
}

export default TitleAndSubtitle
