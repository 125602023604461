
import React from "react"
import Props from './svgProps'
    
const MapSvg = ({width, height, fill}:Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 -2 18 18"
      xmlns="https://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
          <g transform="translate(-1380.000000, -156.000000)" fill={fill}>
              <g transform="translate(1380.000000, 156.000000)">  
                <path d="M11.018396,15.2078094 L11.018396,5.12771606 L7.07639066,2.97028632 L7.07639066,13.0503797 L11.018396,15.2078094 Z M12.518396,14.9978121 L15.75,12.7870215 L15.75,2.92187045 L12.518396,5.13266107 L12.518396,14.9978121 Z M5.57639066,12.99786 L5.57639066,3.1211519 L2.25,5.37401449 L2.25,15.2507226 L5.57639066,12.99786 Z M1.07942772,4.3551613 L5.90788977,1.08499173 C6.14062397,0.927367981 6.44195758,0.913112572 6.68853271,1.04806128 L11.76847,3.82827262 L16.0765271,0.881063692 C16.4183957,0.647186068 16.8851302,0.734730043 17.1190078,1.07659863 C17.2043413,1.20133409 17.25,1.34894001 17.25,1.50007153 L17.25,13.1826471 C17.25,13.4302302 17.1278141,13.6618619 16.9234729,13.8016549 L12.1483054,17.0684213 C11.9152692,17.227845 11.6124444,17.242881 11.3647618,17.1073262 L6.28693999,14.3282726 L1.92057228,17.2854798 C1.57761325,17.5177555 1.1112934,17.4280289 0.879017745,17.0850699 C0.794939515,16.9609269 0.75,16.814433 0.75,16.6644976 L0.75,4.97614356 C0.75,4.72731873 0.873406655,4.49469307 1.07942772,4.3551613 Z" ></path> 
              </g>
          </g>
      </g>
    </svg>
  )
}

export default MapSvg  