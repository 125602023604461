export const RoutesTypeData = [
{
    id: "walking",
    name: "Περίπατος",
},
{
    id: "hiking",
    name: "Πεζοπορία",
},
{
    id: "excursion",
     name: "Εκδρομή",
}
]