import React, { useState, useEffect } from 'react'
import { CalendarOutlined, NodeIndexOutlined, SearchOutlined, MenuOutlined, CaretDownOutlined, DownOutlined } from "@ant-design/icons"
import { Button } from 'antd';
import { Link, NavLink, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next";
import classes from "./Navbar.module.css";
import { UsefulSvg } from './svg'
import { ArrowSvg } from './arrow'
import { HashLink } from 'react-router-hash-link'

import CompassSvg from '../../img/svg/compass'

const Navbar = () => {

  const [toggleMenu, setToggleMenu] = useState(false)

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }


  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', changeWidth)
    return () => {
      window.removeEventListener('resize', changeWidth)
    }
  }, [])

    return (
      <div>
        {(toggleMenu || screenWidth > 1160) && (
          <ul className={ [classes.list, classes.items].join(" ")}>
            {
              screenWidth < 700 ?
                <NavLink
                  to="/explorations"
                  onClick={toggleNav}
                  // style={{position: 'relative',  top: '-5px'}}
                  className={ pathname.split('/').some(url => ['explorations','exploration'].includes(url)) ? classes.bottomLine : ''}
                  state={{ page: 'exploration' }}>
                  <CompassSvg width={25} height={23} fill="#275674" /><span className={pathname.split('/').some(url => ['explorations','exploration'].includes(url)) ? classes.compass : classes.compassTop}>{t('Εξερεύνηση').length <= 12 ? t('Εξερεύνηση') : t('Εξερεύνηση').slice(0, 12) + ' ...'} </span>
                </NavLink>
              :
                <NavLink
                  to="/explorations"
                  onClick={toggleNav}
                  className={ pathname.split('/').some(url => ['explorations','exploration'].includes(url)) ? classes.bottomLine : ''}
                  state={{ page: 'exploration' }}>
                  <CompassSvg width={25} height={23} fill="#275674" /><span className={pathname.split('/').some(url => ['explorations','exploration'].includes(url)) ? classes.compass : classes.compassTop}>{t('Εξερεύνηση')}</span>
                </NavLink>
            }
           
            <NavLink
              to="/routes"
              onClick={toggleNav}
              className={pathname.split('/').some(url => ['routes', 'route'].includes(url)) ? classes.bottomLine : ''}>
              <span className={ pathname.split('/').some(url => ['routes', 'route'].includes(url)) ? classes.compass2 : ''}><NodeIndexOutlined /> {t('Διαδρομές')}</span>
              {/* { ({ isActive }) => <span className={ isActive ? classes.compass2 : ''}><NodeIndexOutlined /> {t('Διαδρομές')}</span> } */}

            </NavLink>

            <NavLink
              to="/events"
              onClick={toggleNav}
              className={ pathname.split('/').some(url => ['events','event'].includes(url)) ? classes.bottomLine : ''}>
              <span className={pathname.split('/').some(url => ['events','event'].includes(url)) ? classes.compass2 : ''}><CalendarOutlined /> {t('Εκδηλώσεις')}</span>
            </NavLink>
            {/* <NavLink
              to="/events/chania"
              onClick={toggleNav}
              className={ pathname.split('/').some(url => ['events','event'].includes(url)) ? classes.bottomLine : ''}>
              <span className={pathname.split('/').some(url => ['events','event'].includes(url)) ? classes.compass2 : ''}><CalendarOutlined /> {t('Εκδηλώσεις')}</span>
            </NavLink> */}
            <div className={ classes.dropdown} >
              <NavLink to="/useful" className={({ isActive }) => isActive ? classes.bottomLine : ''} onClick={toggleNav} >
                { ({ isActive }) => <span className={ isActive ? classes.compass2 : ''}><UsefulSvg /> {t('Χρήσιμα')}&nbsp;<ArrowSvg /></span> }
              </NavLink>

                <div className={classes.dropdownContent}>
                  <span className={classes.useful}><HashLink smooth to="/useful#TouristMaps">{t('Τουριστικοί Χάρτες')}</HashLink></span>
                  <span className={classes.useful}><HashLink smooth to="/useful#UsefulInfo">{t('Χρήσιμες Πληροφορίες')}</HashLink></span>
                  <span className={classes.useful}><HashLink smooth to="/useful#WeatherForecast">{t('Πρόγνωση Καιρού')}</HashLink></span> 
                  <span className={classes.useful}><HashLink smooth to="/useful#LiveCams">{t('Live Κάμερες')}</HashLink></span> 
                  <span className={classes.useful}><HashLink smooth to="/useful#TravelAndHealth">{t('Ταξίδι και Υγεία')}</HashLink></span>
                  <span className={classes.useful}><HashLink smooth to="/useful#ProjectPublicityMaterial">{t('Υλικό προβολής explorechania')}</HashLink></span>
                </div>

            </div>
           {/* <Link to="/useful" state={{ page:'events' }} onClick={toggleNav} > 
            <Select
              size='large'
              showArrow={false}
              defaultValue={t('Χρήσιμα')}
              style={{ width: 290, marginLeft: '-16px', textAlign: 'left'}}
              bordered={false}
              onChange={handleChange}
              options={[
                {
                  value: t('Χρήσιμα'),
                  label: <span className={classes.useful}>{t('Χρήσιμα')}</span>
                },
                {
                  value: t('Τουριστικοί Χάρτες'),
                  label: <span className={classes.useful}><NavHashLink smooth to="/useful#TouristMaps">{t('Τουριστικοί Χάρτες')}</NavHashLink></span> 
                },
                {
                  value: t('Χρήσιμες Πληροφορίες'),
                  label: <span className={classes.useful}><NavHashLink smooth to="/useful#UsefulInfo">{t('Χρήσιμες Πληροφορίες')}</NavHashLink></span>,
                },
                {
                  value: t('Πρόγνωση Καιρού'),
                  label: <span className={classes.useful}><NavHashLink to="/useful#WeatherForecast">{t('Πρόγνωση Καιρού')}</NavHashLink></span>,
                },
                {
                  value: t('Live Κάμερες'),
                  label:(<> <span className={classes.useful}><NavHashLink to="/useful#LiveCams">{t('Live Κάμερες')}</NavHashLink></span></>),
                },
                {
                  value: 'COVID 19',
                  label: <span className={classes.useful}><NavHashLink to="/useful#Covid19">COVID 19</NavHashLink></span>,
                }
              ]}
            />
            </Link>  */}
            {/* </span> */}
            <span className={classes.searchButton} ><Link to="/search" style={{ paddingRight: '0px', paddingLeft: '0px' }}><Button size="large" icon={<SearchOutlined />} style={{ color: '#275674', width: '180px' }}><span></span>{ t('Αναζήτηση')}</Button></Link></span>
         
          </ul>
        )}
        <button onClick={toggleNav} className={classes.btn}><MenuOutlined /></button>
      </div>
    )

}
export default Navbar
