import icon01 from "./pointers/01.svg"
import icon02 from "./pointers/02.svg"
import icon03 from "./pointers/03.svg"
import icon04 from "./pointers/04.svg"
import icon05 from "./pointers/05.svg"
import icon06 from "./pointers/06.svg"
import icon07 from "./pointers/07.svg"
import icon08 from "./pointers/08.svg"
import icon09 from "./pointers/09.svg"
import icon10 from "./pointers/10.svg"
import icon11 from "./pointers/11.svg"
import icon12 from "./pointers/12.svg"
import icon13 from "./pointers/13.svg"
import icon14 from "./pointers/14.svg"
import icon15 from "./pointers/15.svg"
import icon16 from "./pointers/16.svg"
import icon17 from "./pointers/17.svg"
import icon18 from "./pointers/18.svg"
import icon19 from "./pointers/19.svg"
import icon20 from "./pointers/20.svg"
import icon21 from "./pointers/21.svg"
import icon22 from "./pointers/22.svg"
import icon23 from "./pointers/23.svg"
import icon24 from "./pointers/24.svg"
import icon25 from "./pointers/25.svg"
import icon26 from "./pointers/26.svg"
import icon27 from "./pointers/27.svg"
import icon28 from "./pointers/28.svg"
import icon29 from "./pointers/29.svg"
import icon30 from "./pointers/30.svg"
import icon31 from "./pointers/31.svg"
import icon32 from "./pointers/32.svg"
import icon33 from "./pointers/33.svg"
import icon34 from "./pointers/34.svg"
import icon35 from "./pointers/35.svg"
import icon36 from "./pointers/36.svg"
import icon37 from "./pointers/37.svg"
import icon38 from "./pointers/38.svg"
import icon39 from "./pointers/39.svg"
import icon40 from "./pointers/40.svg"

import finish from "./pointers/finish.svg"
import iconUrl from "./pointers/map_marker.svg"

const SvgPointer = [iconUrl, finish, icon01, icon02, icon03, icon04, icon05, icon06, icon07, icon08, icon09, icon10, icon11, icon12, icon13,
  icon14, icon15, icon16, icon17, icon18, icon19, icon20, icon21, icon22, icon23, icon24, icon25, icon26, icon27, icon28, icon29, icon30,
  icon31, icon32, icon33, icon34, icon35, icon36, icon37, icon38, icon39, icon40]

export default SvgPointer;
