import ky from 'ky'
import {API, Header} from './configuration'

export const axiosPoints = {

  async getTopDestinations(lang:string) {
    const data: any = await ky.get(`${API}/pois?is_highlight=1&lang=${lang === 'gr' ? 'el' : lang }`, {headers: Header}).json()
    return data.success
  }

}