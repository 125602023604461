import React from 'react'
import { Row, Col } from 'antd'
import classes from "./styles.module.css"
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../components/Helmet'
import TitleAndSubtitleAndBtn from '../../components/PageComponents/TitleAndSubtitleAndBtn'
import ReturnAndHomeBtn from '../../components/PageComponents/ReturnAndHomeBtn'
import ComponentBtn from './ComponentBtn'


const MunicipalSocialNetworks: React.FC = () => {

  const { t } = useTranslation()

  return (
    <>
      <HelmetComponent title='Κοινωνικά Δίκτυα Δήμων' descrition='Κοινωνικά Δίκτυα Δήμων' />
      
      <TitleAndSubtitleAndBtn title={t('Κοινωνικά Δίκτυα Δήμων')} subTitle={''}/>

      <Row justify="center">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 8 }} lg={{ span: 8 }}>
          <div className={classes.subTitle}>
            {t('Οι επίσημες σελίδες στα κοινωνικά δίκτυα των Δήμων που συμμετέχουν στο Explore Chania')}
          </div>
        </Col>
      </Row>
      
      <Row justify="center">
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 17 }} lg={{ span: 7 }}>
          <div className={classes.MunicipalityContainerLeft}>
            <ComponentBtn
              image='img/Municipality/apokoronou.jpg'
              Title={t('Δήμος Αποκορώνου')}
              FacebookLink="https://el-gr.facebook.com/dimos.apokoronou/"
              TwitterLink="https://twitter.com/apokoronou"
            />
          </div>
          <div className={classes.MunicipalityContainerLeft}>
            <ComponentBtn
              image='img/Municipality/kantanou_selinou.jpg'
              Title={t('Δήμος Καντάνου & Σελίνου')}
              FacebookLink="https://www.facebook.com/profile.php?id=100013350366720"
              InstagramLink="https://www.instagram.com/kantanouselinou/?hl=en"
              YoutubeLink="https://www.youtube.com/@user-xy3sy1hu7c/"
            />
          </div>

          <div className={classes.MunicipalityContainerLeft}>
            <ComponentBtn
              image='img/Municipality/sfakiwn.jpg'
              Title={t('Δήμος Σφακίων')}
              FacebookLink="https://www.facebook.com/sfakia.gr"
              InstagramLink="https://www.instagram.com/sfakiagr/"
              TwitterLink="https://twitter.com/SfakiaGr"
              YoutubeLink="https://www.youtube.com/@sfakiagr"
              TikTokLink="https://www.tiktok.com/@sfakia.gr"
            />
          </div>
        </Col>
        
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 17 }} lg={{ span: 7 }}>
          <div className={classes.MunicipalityContainerRight}>
            <ComponentBtn
              image='img/Municipality/gaudou.jpg'
              Title={t('Δήμος Γαύδου')}
              FacebookLink="https://www.facebook.com/people/%CE%94%CE%97%CE%9C%CE%9F%CE%A3-%CE%93%CE%B1%CF%8D%CE%B4%CE%BF%CF%85/100021421340100/"
            />
          </div>
          <div className={classes.MunicipalityContainerRight}>
            <ComponentBtn
              image='img/Municipality/kissamou.jpg'
              Title={t('Δήμος Κισσάμου')}
              FacebookLink="https://www.facebook.com/discoverkissamos "
              InstagramLink="https://www.instagram.com/discoverkissamos/"
            />
          </div>
          <div className={classes.MunicipalityContainerRight}>
            <ComponentBtn
              image='img/Municipality/platania.jpg'
              Title={t('Δήμος Πλατανιά')}
              FacebookLink="https://www.facebook.com/MunicipalityOfPlatanias/"
              InstagramLink="https://www.instagram.com/visitplatanias/"
              TwitterLink="https://twitter.com/dimosplatania"
              YoutubeLink="https://www.youtube.com/@-municipalityofplatanias2963"
            />
          </div>
          <div className={classes.MunicipalityContainerRight}>
            <ComponentBtn
              image='img/Municipality/chaniwn.jpg'
              Title={t('Δήμος Χανίων')}
              FacebookLink="https://www.facebook.com/Chania.tourism"
              InstagramLink="https://www.instagram.com/chaniatourism/"
              YoutubeLink="https://youtube.com/playlist?list=PLrxLhQmW8aqFbSKoPvf1e5BeS1B5pFsi_"
            />
          </div>
        </Col>
      </Row>

      <ReturnAndHomeBtn />

    </>
  )
}

export default MunicipalSocialNetworks
