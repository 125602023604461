import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface HistoryArray{
  value: number;
}
interface EnvironmentArray{
  value: number;
}
interface MunicipalityArray{
  value: string;
}

interface CategoryState {
  History: HistoryArray[]
  Environment: EnvironmentArray[]
  Municipality: MunicipalityArray[]
  ExplorationPageNumberRedux: 0
}

const initialState: CategoryState = {
  History:  [],
  Environment: [],
  Municipality: [], 
  ExplorationPageNumberRedux: 0
};

export const CategorySlice = createSlice({
  name: 'Categories',
  initialState,
  reducers: {

    ExplorationPageNumberRedux: (state, action) => {
      state.ExplorationPageNumberRedux=action.payload 
    },

    History: (state, action) => {
      // state.History.splice(0, state.History.length);
      // state.History.push(action.payload)
      state.History = [];
      action.payload == '' ? state.History = [] : state.History.push(action.payload)
    },
    
    Environment: (state, action) => {
      state.Environment = [];
      action.payload == '' ? state.Environment = [] : state.Environment.push(action.payload)
    },

    Municipality: (state, action) => {
      state.Municipality = [];
      action.payload == '' ? state.Municipality = [] : state.Municipality.push(action.payload)
    }
  },
})

export const { ExplorationPageNumberRedux, History, Environment, Municipality } = CategorySlice.actions
export default CategorySlice.reducer 