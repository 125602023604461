import { useState } from "react"
import { useQuery } from '@tanstack/react-query'
import { EventProps, EventsProps } from "./props/event"
import { axiosSearch } from "../services/search"


import { PoisProps, RoutesProps, SearchProps } from "./props/search"

export const useSearch = (searchString: string, lang: string) => {

  let searchArray: SearchProps[] = []

  const [checkIfArrayEmpty, setCheckIfArrayEmpty] = useState<boolean>(false);
  const [SearchResultData, setSearchResultData] = useState<SearchProps[]>([]);

  const { isLoading, isFetching, error, refetch } = useQuery(['search', searchString, lang], () => axiosSearch.getSearch(searchString, lang), {

    enabled: false,
    staleTime: Infinity,
    cacheTime: Infinity,

    onSuccess: (data) => {

      // console.log(data);
      // console.log('Pois : ', data[0])
      // console.log('Routes : ', data[1])
      // console.log('Events : ', data[2])


      data[0].map((poi: PoisProps) => {

        let municipalityName = setMunicipality(poi.extras._municipalityName.value)

        poi.featured == 0
          ?
          searchArray.push({
            id: poi.id,
            type: 'Poi',
            image: poi.main_image,
            municipality: municipalityName,
            title: poi.name,
            category_id: poi.category_path_ids[0][1],
            category: poi.category_path[0][1],
            categoryName: '',
            text: poi.portal_main_info,
          })
          :
          searchArray.unshift({
            id: poi.id,
            type: 'Poi',
            image: poi.main_image,
            municipality: municipalityName,
            title: poi.name,  
            category_id: poi.category_path_ids[0][1],
            category: poi.category_path[0][1],
            categoryName: '',
            text: poi.portal_main_info,
          })
      })


      data[1].map((route: RoutesProps) => {

        let categoryName = setRouteCategoryName(route.extras._route_type.value[0])
        let municipalityName = setMunicipality(route.area_path[0][0])
          searchArray.push({
            id: route.id,
            type: 'Route',
            image: route.main_image,
            municipality: municipalityName,
            title: route.name,
            category_id: route.category_id,
            category: route.extras._route_type.value[0],
            categoryName: categoryName,
            text: route.description,
          })
         
      })

      data[2].map((event: any) => {
        

        let array: EventProps;
        let timePeriodsArray: any[] = []
        let timePeriodsArrayTwo: any[] = []
  
        let hours = ''
        let start_date = ''
        let end_date = ''
        let start_dateTwo = ''
        let end_dateTwo = ''
        const optionsForDate = { hour12: false, hour: '2-digit', minute: '2-digit', day: 'numeric', month: '2-digit', year: 'numeric' } as const;
        const optionsForDateTwo = { hour12: false, hour: '2-digit', minute: '2-digit', day: 'numeric', month: 'string', year: 'numeric' } as const;
  
        if (event.time_periods.length == 0) {
            
          hours = '12:00 - 12:00'
          start_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(434800800 * 1000)
          end_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(434800800 * 1000)
          timePeriodsArray.push({ starting_date: start_date, ending_date: end_date })
        
        }
        else {
          hours = `${new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).format((event.time_periods[0].starting_date * 1000))} - ${new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).format((event.time_periods[0].ending_date * 1000))}`
        
          for (let i = 0; i < event.time_periods.length; i++) {
  
            start_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(event.time_periods[i].starting_date * 1000)
            end_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(event.time_periods[i].ending_date * 1000)
        
            start_dateTwo = new Intl.DateTimeFormat('el-GR', { dateStyle: 'medium', timeStyle: 'medium', timeZone: 'Europe/Athens' }).format(event.time_periods[i].starting_date * 1000);
            end_dateTwo = new Intl.DateTimeFormat('el-GR', { dateStyle: 'medium', timeStyle: 'medium', timeZone: 'Europe/Athens' }).format(event.time_periods[i].ending_date * 1000);
  
            timePeriodsArray.push({ starting_date: start_date, ending_date: end_date })
            timePeriodsArrayTwo.push({ starting_date: start_dateTwo, ending_date: end_dateTwo })
          }
        }

        let municipalityName = setMunicipalityWithDimos(event.client.title)
        
        searchArray.push({
          id: event.id,
          type: 'Event',
          image: event.image.url,
          municipality: municipalityName,
          title: event.title,
          category_id: event.category.id,
          category: event.category.title,
          categoryName: event.category.title,
          text: event.description,
          timePeriods: timePeriodsArray
        })
       
      })


    //  console.log('Array', searchArray)
     searchArray.length === 0 ? setCheckIfArrayEmpty(true) : setCheckIfArrayEmpty(false)

     setSearchResultData(searchArray)  
    },

    onError: (error:any) => {
      // alert(error.message)
    }
  })

  return { isLoading, isFetching, error, SearchResultData, checkIfArrayEmpty, refetch }
}







function setRouteCategoryName(checkName: string) {

  let categoryName = ''

  switch (checkName) {
    case "walking": {
      categoryName = "Περίπατος";
      break;
    }
    case "hiking": {
      categoryName = "Πεζοπορία";
      break;
    }
    case "excursion": {
      categoryName = "Εκδρομή";
      break;
    }
    default: {
      break;
    }
  }

  return categoryName
}


function setMunicipality(municipality: string) {

  let Mun = municipality

  switch (municipality) {
    case 'Αποκορώνου': Mun = 'Αποκόρωνας'; break;
    case 'Γαύδου': Mun = 'Γαύδος'; break;
    case 'Καντάνου-Σελίνου': Mun = 'Σέλινο'; break;
    case 'Καντάνου – Σελίνου': Mun = 'Σέλινο'; break;
    case 'Κισσάμου': Mun = 'Κίσσαμος'; break;
    case 'Πλατανιά': Mun = 'Πλατανιάς'; break;
    case 'Σφακιών': Mun = 'Σφακιά'; break;
    case 'Σφακίων': Mun = 'Σφακιά'; break;
    case 'Χανίων': Mun = 'Χανιά'; break;
    default:  break;
  }
  return Mun
}


function setMunicipalityWithDimos(checkMunicipality: string) {

  let municipality = ''

  switch (checkMunicipality) {
    case "Δήμος Πλατανιά": {
      municipality = "Πλατανιάς";
      break;
    }
    case "Δήμος Γαύδου": {
      municipality = "Γαύδος";
      break;
    }
    case "Δήμος Κισσάμου": {
      municipality = "Κίσσαμος";
      break;
    }
    case "Δήμος Σφακίων": {
      municipality = "Σφακιά";
      break;
    }
    case "Δήμος Καντάνου-Σελίνου": {
      municipality = "Κάντανος-Σέλινος";
      break;
    }
    case "Δήμος Χανίων": {
      municipality = "Χανιά";
      break;
    }
    case "Δήμος Αποκορώνου": {
      municipality = "Αποκόρωνας";
      break;
    }
    default: {
      municipality = 'Σέλινο'
      break;
    }
  }

  return municipality
}
