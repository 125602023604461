import React, {useState, useEffect} from "react"
import { useNavigate } from "react-router-dom"
import { Row, Col, Button } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../../components/Helmet'

import ModalRegisterForm from "./ModalRegisterForm"
import ModalLoginForm from "./ModalLoginForm"


import { useAuth } from "../../../utils/auth"

const Login: React.FC = () => {

    const { t } = useTranslation()

    const navigate = useNavigate()
    const auth = useAuth()
    const [activeIndex, setActiveIndex] = useState<number>(0);



    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      }, [])

    return (
        <>
            <HelmetComponent title="Ο Λογαριασμός μου" descrition="Login page" />
            <div className={classes.container} style={{ marginBottom: 8 }}>
               
                <Row>
                    <Col xs={24} sm={24} md={7} lg={7}>
                        <div className={classes.BackBtnContainer}>
                            <Row justify="center">
                                <Button size='large' className={classes.BackBtn} onClick={() => navigate(-1)}>{t('Επιστροφή')}</Button>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={10} lg={10}>
                        <div className={classes.title}>
                            {t('Ο Λογαριασμός μου')}
                        </div>
                        <div className={classes.subTitle}>
                            {t('Καλώς ορίσατε!')} { auth.userData.name }
                        </div>
                    </Col>
                </Row>
            </div>
            <div style={{ marginBottom: 58 }}>
                <Row justify="center">
                    <div id="FavouritePoints" className={classes.FavouritesSection}>
                        <div className={classes.pointTitle}>{t('Εξερευνήστε τα Χανιά')}</div>
                        <div className={classes.sectionTitle}>
                            {t('Εγγραφείτε για να έχετε πρόσβαση στα αγαπημένα σας σημεία και από το explorechania.gr και από το Explore Chania App')}
                        </div>

                        <ModalRegisterForm clickModal={(e)=>setActiveIndex(1)} />
                        <ModalLoginForm activeIndex={ activeIndex}  clickModal={()=>setActiveIndex(0)} />
 
                        {/* <Modal centered title={t('Είσοδος στο Λογαριασμό')} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                            <br/>
                            <Form
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 16 }}
                                form={form}
                                name="control-hooks"
                                onFinish={onFinish}
                                style={{ maxWidth: 600 }}
                            >
                                <Form.Item name="user" label="Username" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                    <Button htmlType="button" onClick={onReset}>
                                        Reset
                                    </Button>
                                    <Button type="link" htmlType="button" onClick={onFill}>
                                        Fill form
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Modal> */}

                    </div>
                </Row>
            </div>
        </>
    )
}

export default Login
