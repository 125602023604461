import React from 'react'
import { Row, Col } from 'antd'
import classes from "./styles.module.css"
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../components/Helmet'
import TitleAndSubtitleAndBtn from '../../components/PageComponents/TitleAndSubtitleAndBtn'
import ReturnAndHomeBtn from '../../components/PageComponents/ReturnAndHomeBtn'
import { Link } from 'react-router-dom'
import { HashLink, NavHashLink  } from 'react-router-hash-link'

const Sitemap: React.FC = () => {

  const { t } = useTranslation()

  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

  return (
    <>
      <HelmetComponent title='Δομή πληροφορίας ιστοτόπου' descrition='Δομή πληροφορίας ιστοτόπου' />
      
      <TitleAndSubtitleAndBtn title={t('Δομή πληροφορίας ιστοτόπου')} subTitle={'Sitemap'}/>

      <Row justify="center">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 7 }} lg={{ span: 7 }}>
          <div className={classes.subTitle}>

            <p className={classes.spanLeft}><Link to='/'><strong>{t('Αρχική')}</strong></Link></p>
            <p className={classes.spanLeft}><Link to='/search'><strong>{t('Αναζήτηση')}</strong></Link></p>
            <p className={classes.spanLeft}><Link to='/login'><strong>{t('Ο Λογαριασμός μου')}</strong></Link></p>

            <p className={classes.spanLeft}><Link to='/explorations'><strong>{t('Εξερεύνηση')}</strong></Link></p>
            <p className={[classes.spanLeft, classes.mL30].join(" ")}>{t('Σημείο ενδιαφέροντος')}</p>
                        
            <p className={classes.spanLeft}><Link to='/routes'><strong>{t('Διαδρομές')}</strong></Link></p>
            <p className={[classes.spanLeft, classes.mL30].join(" ")}>{t('Διαδρομή')}</p>
            <p className={[classes.spanLeft, classes.mL60].join(" ")}>{t('Σημείο ενδιαφέροντος')}</p>
            <p className={classes.spanLeft}><Link to='/events'><strong>{t('Εκδηλώσεις')}</strong></Link></p>


            <p className={classes.spanLeft}><Link to='/events/apokoronas'><strong>{t('Εκδηλώσεις Δήμου Αποκορώνου')}</strong></Link></p>
            <p className={classes.spanLeft}><Link to='/events/gavdos'><strong>{t('Εκδηλώσεις Δήμου Γαύδου')}</strong></Link></p>
            <p className={classes.spanLeft}><Link to='/events/kantanouselinou'><strong>{t('Εκδηλώσεις Δήμου Καντάνου-Σελίνου')}</strong></Link></p>
            <p className={classes.spanLeft}><Link to='/events/kissamos'><strong>{t('Εκδηλώσεις Δήμου Κισσάμου')}</strong></Link></p>
            <p className={classes.spanLeft}><Link to='/events/platanias'><strong>{t('Εκδηλώσεις Δήμου Πλατανιά')}</strong></Link></p>
            <p className={classes.spanLeft}><Link to='/events/sfakia'><strong>{t('Εκδηλώσεις Δήμου Σφακίων')}</strong></Link></p>
            <p className={classes.spanLeft}><Link to='/events/chania'><strong>{t('Εκδηλώσεις Δήμου Χανίων')}</strong></Link></p>

            <p className={[classes.spanLeft, classes.mL30].join(" ")}>{t('Εκδήλωση')}</p>
                        
            <br/><br/>       
            <p className={classes.spanLeft}><Link to='/useful'><strong>{t('Χρήσιμα')}</strong></Link></p>
            <p className={[classes.spanLeft, classes.mL30].join(" ")}><HashLink to="/useful#TouristMaps">{t('Τουριστικοί Χάρτες')}</HashLink></p>
            <p className={[classes.spanLeft, classes.mL30].join(" ")}><HashLink to="/useful#UsefulInfo">{t('Χρήσιμες Πληροφορίες')}</HashLink></p>
            <p className={[classes.spanLeft, classes.mL30].join(" ")}><HashLink to="/useful#WeatherForecast">{t('Πρόγνωση Καιρού')}</HashLink></p>
            <p className={[classes.spanLeft, classes.mL30].join(" ")}><HashLink to="/useful#LiveCams">{t('Live Κάμερες')}</HashLink></p>
            <p className={[classes.spanLeft, classes.mL30].join(" ")}><HashLink to="/useful#TravelAndHealth">{t('Ταξίδι και Υγεία')}</HashLink></p>
            <p className={[classes.spanLeft, classes.mL30].join(" ")}><HashLink to={'/useful#ProjectPublicityMaterial'} >{t('Υλικό προβολής explorechania')}</HashLink></p>
            <br/><br/>


            <br/><br/><br/>
            <p className={classes.spanLeft}><Link to='/municipal_social_networks'><strong>{t('Κοινωνικά Δίκτυα Δήμων')}</strong></Link></p>
            <p className={classes.spanLeft}><Link to='/page/about_us'><strong>{t('Σχετικά με το Έργο')}</strong></Link></p>
            <p className={classes.spanLeft}><Link to='/page/accessibility_statement'><strong>{t('Δήλωση Προσβασιμότητας')}</strong></Link></p>
            <p className={classes.spanLeft}><Link to='/page/terms'><strong>{t('Όροι και προϋποθέσεις')}</strong></Link></p>
            <p className={classes.spanLeft}><Link to='/page/policy'><strong>{t('Πολιτική Απορρήτου')}</strong></Link></p>
            <p className={classes.spanLeft}><Link to='/commentForm'><strong>{t('Φόρμα Σχολιασμού Περιεχομένου')}</strong></Link></p>

          </div>
        </Col>
      </Row>
    
    <ReturnAndHomeBtn />
    </>
  )
}

export default Sitemap
