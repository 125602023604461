
import React from "react"

const ImageThreeSvg = () => {

    return (

        <svg 
            width="28" 
            height="28" 
            viewBox="0 0 17 17" 
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.3125 15.5624L8.41723 16.1762C8.62993 16.2943 8.73628 16.3534 8.84891 16.3766C8.9486 16.3971 9.05141 16.3971 9.15109 16.3766C9.26372 16.3534 9.37007 16.2943 9.58277 16.1762L10.6875 15.5624M3.9375 13.6874L2.86723 13.0928C2.64259 12.968 2.53026 12.9056 2.44847 12.8169C2.37612 12.7384 2.32136 12.6453 2.28786 12.5439C2.25 12.4293 2.25 12.3008 2.25 12.0439V10.8749M2.25 7.12494V5.95603C2.25 5.69904 2.25 5.57055 2.28786 5.45595C2.32136 5.35457 2.37612 5.26151 2.44847 5.18299C2.53026 5.09424 2.64259 5.03184 2.86723 4.90704L3.9375 4.31244M7.3125 2.43744L8.41723 1.8237C8.62993 1.70553 8.73628 1.64645 8.84891 1.62329C8.9486 1.60278 9.05141 1.60278 9.15109 1.62329C9.26372 1.64645 9.37007 1.70553 9.58277 1.8237L10.6875 2.43744M14.0625 4.31244L15.1328 4.90704C15.3574 5.03184 15.4697 5.09424 15.5515 5.18299C15.6239 5.26151 15.6786 5.35457 15.7121 5.45595C15.75 5.57055 15.75 5.69904 15.75 5.95603V7.12494M15.75 10.8749V12.0439C15.75 12.3008 15.75 12.4293 15.7121 12.5439C15.6786 12.6453 15.6239 12.7384 15.5515 12.8169C15.4697 12.9056 15.3574 12.968 15.1328 13.0928L14.0625 13.6874M7.3125 8.06244L9 8.99994M9 8.99994L10.6875 8.06244M9 8.99994V10.8749M2.25 5.24994L3.9375 6.18744M14.0625 6.18744L15.75 5.24994M9 14.6249V16.4999" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default ImageThreeSvg