export const DatesData = [
{
    id: "today",
    item: "Σήμερα",
},
{
    id: "week",
    item: "Αυτή την εβδομάδα",
},
{
    id: "month",
    item: "Αυτό το μήνα",
},
{
    id: "nextMonth",
    item: "Τον επόμενο μήνα",
}
// {
//     id: "range",
//     name: "Έυρος ημερομηνιών",
// }
]