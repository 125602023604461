import React, { useState } from "react"
import { useQuery } from '@tanstack/react-query'
import { axiosAreas } from "../services/areas"
import { AreasProps } from "./props/areas"


export const useAreas = () => {
  
  const [areas, setAreas] = useState<AreasProps[]>([]); 
  let arrayAreas: AreasProps[] = []


  const { isLoading, error } = useQuery(['areas'], () => axiosAreas.getAreas(), {
   
    onSuccess: (data) => {

      data.map((category: any, index:number) => {

        arrayAreas.push({
          id: category.id,
          name: category.name
        })
      })
      
      setAreas(arrayAreas)
      
    },
    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { areas }
}