import React from "react"
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import classes from "./Find.module.css"
import { HeartOutlined, ArrowRightOutlined } from '@ant-design/icons'

function Find() {

  const { t } = useTranslation()

  return (
    <>
    <div className={classes.footerContainerBg}>
      <div className={classes.footerContainer}>
        <Row justify="center">
          <Col span={2}>
          <div className={classes.icon}><HeartOutlined /></div>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
            <div className={classes.find}>{t('Βρες τα Αγαπημένα σου σημεία!')}</div>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
            <div className={classes.add}>{t('Προσθεσε προορισμους στα αγαπημενα σου και φτιαξε το δικο σου ταξιδι')}</div>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
            <div className={classes.hr}><hr/></div>
          </Col>
        </Row>
       

        <Row justify="space-around">
          <Col xs={24} sm={24} md={8} lg={8} >
            <div className={classes.footerDiv}>
              <div className={classes.rounded}><span className={classes.number}>1</span></div>
              <div className={classes.title}>{t('Κάνε είσοδο - εγγραφή')}</div>
              <div className={classes.body}>{t('Εγγράψου ώστε να μπορείς να αποθηκεύσεις τα αγαπημένα σου')}</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} >
          <div className={classes.footerDiv}>
              <div className={classes.rounded}><span className={classes.number}>2</span></div>
              <div className={classes.title}>{t('Πρόσθεσε στα Αγαπημένα')}</div>
              <div className={classes.body}>{t('Επέλεξε τα σημεία που σε ενδιαφέρουν και πάτα “Προσθήκη στα αγαπημένα”')}</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} >
          <div className={classes.footerDiv}>
              <div className={classes.rounded}><span className={classes.number}>3</span></div>
              <div className={classes.title}>{t('Απόλαυσε την εξερεύνηση')}</div>
              <div className={classes.body}>{t('Δες μαζεμένα τα αγαπημένα σου σημεία και οργάνωσε την εξερεύνηση σου καλύτερα')}</div>
            </div>
          </Col>
        </Row>

      </div>
    </div>
    </>
  )
}

export default Find