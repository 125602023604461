
import React from "react"
import Props from './svgProps'
    
const MovieSvg = ({width, height, fill}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="https://www.w3.org/2000/svg"
        viewBox="0 0 19.96 20">
        
        <path fill={fill} d="M5.37,1.37A2.75,2.75,0,1,1,2.62,4.12,2.76,2.76,0,0,1,5.37,1.37Zm6.87,0A2.75,2.75,0,1,1,9.49,4.12,2.75,2.75,0,0,1,12.24,1.37ZM5.37,8.25A4.12,4.12,0,0,0,8.8,6.4,4.13,4.13,0,1,0,12.24,0,4.15,4.15,0,0,0,8.8,1.85a4.12,4.12,0,1,0-3.43,6.4Zm13.75,4.81h-.46l-2.29-1.8v-2l2.29-1.72h.46ZM18.2,6.19,15.45,8.25H15v4.81a.69.69,0,0,1-.69.69h-11a.69.69,0,0,1-.69-.69V8.25H1.24v4.81a2.07,2.07,0,0,0,2.07,2.06H5.86L4.7,19.77,6,20.1l1.25-5h.84v4.82H9.49V15.12h.84l1.24,5,1.34-.33-1.16-4.65H14.3a2.07,2.07,0,0,0,2.07-2.06V13l1.82,1.43h2.3V6.19Z"/>
      </svg>
    )
}

export default MovieSvg