import React, {useState, useEffect } from 'react'
import { Row, Col, Input, Button, AutoComplete  } from 'antd'
import classes from "./css/Search.module.css"
import { useTranslation } from "react-i18next"
import { SearchOutlined, HeartOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom"
import SearchItemComponentMain from '../../../components/Box/SearchItemComponentMain'
import Spinner from '../../../components/PageComponents/Spinner'


// Redux
import { useSelector, useDispatch } from 'react-redux'
import type { RootState } from '../../../redux/store'
import { KeySearchRedux } from '../../../redux/mapPage/search'
import { useSearch } from '../../../hooks/useSearch'


import { SearchPoisTitles } from '../SearchPoisTitles'

const Search: React.FC = () => {

  const { t, i18n } = useTranslation()
  const [stringTemp, setStringTemp] = useState<string>("")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [inputValue, setInputValue] = useState<string>('');
  
  const ReduxKey: string = useSelector((state: RootState) => state.Searches.Key)
  const { isLoading, isFetching, SearchResultData, checkIfArrayEmpty, refetch } = useSearch(stringTemp, i18n.language)

  // console.log('SearchResultData', SearchResultData)
  // console.log('ReduxKey', ReduxKey)


  // useEffect(() => {
  //   if ( stringTemp !== '' ) {
  //     refetch() 
  //   }
  //   // console.log('SearchResultData', SearchResultData)
  //   // console.log('stringTemp', stringTemp)
  // }, [stringTemp, i18n.language])



  const handleKeypress = (e : React.KeyboardEvent<HTMLInputElement>) => {
  
    if (e.key === 'Enter') {
      // console.log('stringTemp', stringTemp)
      dispatch(KeySearchRedux(stringTemp))
      navigate(`/search`, { replace: false })
    }
  };


  // const SearchInputs = () => {
  //   return (
  //     <Row justify="center">
  //       <Col xs={{ span:22 }} lg={{ span:16 }}>
  //         <div className={classes.line}>
  //           <Input
  //             placeholder={t("Εισάγετε ένα όρο αναζήτησης") || ''}
  //             prefix={<SearchOutlined />}
  //             className={classes.input}
  //             onChange={(e)=> setStringTemp(e.target.value) }
  //           />
  //           <Button
  //             type="primary"
  //             className={classes.searchBtn}
  //             onClick={() => [dispatch(KeySearchRedux(stringTemp)), navigate(`/search`, { replace: false })]}>
  //             {t("Αναζήτηση")}
  //           </Button>
  //         </div>
  //       </Col>
  //     </Row>
  //   )
  // }


  const onSelect = (data: string) => { setStringTemp(data); console.log('onSelect') }
  const onChange = (data: string) => { setStringTemp(data); console.log('onChange')}

  return (
    <>
      <div className={classes.containerBg}>
      <div className={classes.container}>
        
        <Row justify="center">
          <Col xs={24} sm={24} md={6} lg={{ span: 6 }} >
            <div className={classes.center} >
              <hr className={classes.hr} />
            </div>
          </Col>
          <Col xs={24} sm={24} md={16} lg={16}>
            <div className={classes.title}>
              {t('Search_Title')}
            </div>
          </Col>
          <Col lg={4}> </Col>
        </Row>

        {/* <SearchInputs /> */}

        <Row justify="center">
          <Col xs={{ span:22 }} md={{ span:22 }} lg={{ span:16 }}>
            <div className={classes.line}>

            <AutoComplete
              dropdownMatchSelectWidth={252}
              // value={inputValue}
              className={classes.input}
              options={stringTemp.length >=2 ? SearchPoisTitles : []}
              onSelect={onSelect}
              onChange={onChange}
              filterOption={(inputValue, option) =>
               
                inputValue.length >= 2 &&
                
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 }
              
            > 
              <Input
                placeholder={t("Εισάγετε ένα όρο αναζήτησης") || ''}
                prefix={<SearchOutlined />}
                className={classes.inputMain}
                onChange={(e) => setStringTemp(e.target.value)}
                onKeyPress={ handleKeypress }
              />
            </AutoComplete>
                
            <Button
              type="primary"
              className={classes.searchBtn}
              onClick={() => [dispatch(KeySearchRedux(stringTemp)), navigate(`/search`, { replace: false })]}>
              {t("Αναζήτηση")}
            </Button>
                
            </div>
          </Col>
        </Row>

          
         {isFetching &&
   
            <div className={classes.containerSpiner}>
              <div className={classes.centeredSpiner}>
                <Spinner />
              </div>
            </div>
              
          }

        {SearchResultData.length >= 1 &&
            
          <Row justify="center">
            <Col xs={{ span:24 }} lg={{ span:19 }}>
              <div className={classes.line}>
                <div className={classes.inputResults}>
                
                    {SearchResultData.map((pois: any) => {
                      let textShort = `${pois.text ? pois.text.substring(0, 170) : ''}`
                      textShort = textShort.slice(0, textShort.lastIndexOf("&"))
                      return(
                      <SearchItemComponentMain 
                        id={pois.id}
                        type={ pois.type}
                        image = {pois.image !== '' ? pois.image : '../img/defaultPoisImage.jpg'}
                        title={pois.title}
                        category_id={pois.category_id}
                        text={textShort}
                        municipality={pois.municipality}
                        categoryName={pois.category}
                        timePeriods = {pois.timePeriods}
                      />
                    
                      )
                    })}   
   
                    

                </div>
              </div>
            </Col>
          </Row>

        }  
          







        <Row justify="center">
          <Col xs={{ span:22 }} lg={{ span:16 }}>
            <div className={classes.subTitle}>
            <HeartOutlined /> {t('Search_SubTitle')}
            </div>
          </Col>
        </Row>  


        <Row justify="center">
          <Col xs={{ span:22 }} lg={{ span:16 }}>
            <Button type="primary" className={classes.popularBtn}><Link to="/exploration/1_48">{ t('Σαμαριά') }</Link></Button>
            <Button type="primary" className={classes.popularBtn}><Link to="/exploration/1_430">{ t('Φάρος Χανίων') }</Link></Button>
            <Button type="primary" className={classes.popularBtn}><Link to="/exploration/1_278">{ t('Λουτρό') }</Link></Button>
            <Button type="primary" className={classes.popularBtn}><Link to="/exploration/1_444">{ t('Σαρακίνικο Γαύδου') }</Link></Button>
          </Col>
          <Col xs={{ span:22 }} lg={{ span:16 }}>
            <Button type="primary" className={classes.popularBtn}><Link to="/exploration/1_358">{ t('Μπάλος') }</Link></Button>
            <Button type="primary" className={classes.popularBtn}><Link to="/exploration/1_470">{ t('Αρχαιολογικό Μουσείο Χανίων') }</Link></Button>
            <Button type="primary" className={classes.popularBtn}><Link to="/exploration/1_1">{ t('Λίμνη Κουρνά') }</Link></Button>
            <Button type="primary" className={classes.popularBtn}><Link to="/exploration/1_240">{ t('Παλαιοχώρα') }</Link></Button>
          </Col>
        </Row>
        
        </div>
        </div>
    </>
  )
}

export default Search