import React, {useState, useEffect} from "react"
import { Checkbox, Col, Row } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'

import { useTranslation } from "react-i18next"
import classes from "./styles.module.css"

import {MunicipalitiesData} from './municipalitiesForHome'
import GlobusSvg from '../../img/svg/globus'


// Redux
import { useDispatch } from 'react-redux'
import { MunicipalityRedux }  from '../../redux/mapPage/event'

import { useParams } from 'react-router-dom'

interface ListData {
  id: string
  value: string
  item: string
}

interface Props{
  checkedId?: any[]
}

const CheckBoxMunicipalityComponent: React.FC<Props> = ({ ...props } ) => {

  const { t } = useTranslation()
  const { municipality } = useParams()

  const dispatch = useDispatch()


  useEffect(() => {
   
    switch (municipality) {
      case 'apokoronas': dispatch(MunicipalityRedux(['Δήμος Αποκορώνου'])); break;
      case 'gavdos': dispatch(MunicipalityRedux(['Δήμος Γαύδου'])); break;
      case 'kantanouselinou': dispatch(MunicipalityRedux(['Δήμος Καντάνου - Σελίνου'])); break;
      case 'kissamos': dispatch(MunicipalityRedux(['Δήμος Κισσάμου'])); break;
      case 'platanias': dispatch(MunicipalityRedux(['Δήμος Πλατανιά'])); break;
      case 'sfakia': dispatch(MunicipalityRedux(['Δήμος Σφακίων'])); break;
      case 'chania': dispatch(MunicipalityRedux(['Δήμος Χανίων'])); break;
           
    }
  }, [])

  const onChange = (list: CheckboxValueType[]) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    dispatch(MunicipalityRedux(list))
  }
  
  return (
    <>
      {/* <div className={classes.divider}><GlobusSvg width={30} height={30} fill="#275674" />&nbsp;&nbsp;{t("Δήμος")}</div> */}
      <div className={classes.dividerMunicipality}><br/><GlobusSvg width={30} height={30} fill="#275674" /><span className={classes.dividerSpanMunicipality}>{t("Δήμος")}</span></div>
      <Checkbox.Group style={{ width: '100%' }} onChange={onChange} defaultValue={
        municipality === 'apokoronas' ? ['Δήμος Αποκορώνου'] :
          municipality === 'gavdos' ? ['Δήμος Γαύδου'] :
            municipality === 'kantanouselinou' ? ['Δήμος Καντάνου - Σελίνου'] :
              municipality === 'kissamos' ? ['Δήμος Κισσάμου'] :
                municipality === 'platanias' ? ['Δήμος Πλατανιά'] :
                  municipality === 'sfakia' ? ['Δήμος Σφακίων'] : ['Δήμος Χανίων']
      }>
        <Row>
          {MunicipalitiesData.map((data: ListData) => {
            return (
              <Col span={24} key={data.id} className={classes.checkboxGroup}>
                <Checkbox value={t( data.value)}>{t( data.item )}</Checkbox>
              </Col>
            )
          })}
        </Row>
      </Checkbox.Group>
    </>
  )
}

export default CheckBoxMunicipalityComponent