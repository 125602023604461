
import ky from 'ky'
import {API, Header} from './configuration'
import i18n from '../../src/translation'


export const axiosRoutes = {

  async getRoute(id: string) {
    const data: any = await ky.get(`${API}/route?id=${id}&lang=${i18n.language == 'gr' ? 'el' : i18n.language }`, {headers: Header}).json()
    return data.success
  },

  async getRoutes() {
    const data:any = await ky.get(`${API}/routes?lang=${i18n.language == 'gr' ? 'el' : i18n.language }`, {headers: Header}).json()
    return data.success
  }

}