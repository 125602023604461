export const RoutesData = [
  { id: '1', item: "Περίπατος", value: 'walking' },
  { id: '2', item: "Πεζοπορία", value: 'hiking' },
  { id: '3', item: "Εκδρομή", value: 'excursion' }
]


export const RoutesSearchBoxData = [
  { id: '1', item: "walking", value:"Περίπατος"  },
  { id: '2', item: "hiking", value: "Πεζοπορία" },
  { id: '3', item: "excursion", value: "Εκδρομή" }
]