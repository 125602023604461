import { useState, useEffect } from "react"
import { useQuery } from '@tanstack/react-query'
import { axiosPoisJson } from "../services/createPoisJson"
import { PoisProps } from "./props/pois"
import { GeneralProps } from "./props/general"

export const useCreatePoisJson = (lang: string) => {
  
  const [PoisJsonData, setPoisJsonData] = useState<GeneralProps[]>([]);
  const [arrayPois, setArrayPois] = useState<GeneralProps[]>([]);

  let poisesArray: GeneralProps[] = []

  const { isLoading, isFetching, error, refetch } = useQuery(['event', lang], () => axiosPoisJson.getPoiJson(lang), {
  
    refetchOnWindowFocus: false,
    enabled: false,
    
    onSuccess: (data) => {

      data.map((array: PoisProps) => {

          array.featured == 0
          ?
            poisesArray.push({
              id: array.id,
              image: array.main_image,
              municipality: array.extras._municipalityName.value,
              title: array.name,
              lat: array.coordinates[0].latitude,
              lng: array.coordinates[0].longitude,
              rate: array.rating !== null ? array.rating : 0,
              vote: array.votesNum !== null ? array.votesNum : 0,
              category_id: array.category_path_ids[0][1],
              category: array.category_path[0][1],
              text: array.portal_main_info,
              featured: array.featured,
              google_place: array.google_place
      
            })
           :
            poisesArray.unshift({
              id: array.id,
              image: array.main_image,
              municipality: array.extras._municipalityName.value,
              title: array.name,
              lat: array.coordinates[0].latitude,
              lng: array.coordinates[0].longitude,
              rate: array.rating !== null ? array.rating : 0,
              vote: array.votesNum !== null ? array.votesNum : 0,
              category_id: array.category_path_ids[0][1],
              category: array.category_path[0][1],
              text: array.portal_main_info,
              featured: array.featured,
              google_place: array.google_place
          
            })

      })

      setPoisJsonData(poisesArray)
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })


  useEffect(() => {

    const datas = GetServerSideProps(PoisJsonData)
    Promise.all([datas]).then(results => { setArrayPois(results[0]) })

  }, [PoisJsonData])


  return { isLoading, isFetching, error, arrayPois, refetch }
}


const GetServerSideProps = async(poisesArray: GeneralProps[]) => {

  let poisesArrayFinal: any[] = []
  let poisesArrayFinal2: any[] = []
  let google_vote: any
  
  for (const array of poisesArray) {

    if (array.google_place.length !== 0) {

      const response = await fetch(`https://maps.googleapis.com/maps/api/place/details/json?place_id=${array.google_place}&fields=name%2Crating&key=AIzaSyDB3Wu5LUt5Hcah934FZHr-JM9M5T48hXE`);
    
      poisesArrayFinal.push({
        id: array.id,
        image: array.image,
        municipality: array.municipality,
        title: array.title,
        lat: array.lat,
        lng: array.lng,
        rate: array.rate,
        vote: array.vote,
        category_id: array.category_id,
        category: array.category,
        text: array.text,
        featured: array.featured,
        google_place: array.google_place,
        google_vote: await response.json(),
      })
    }

    else {
      poisesArrayFinal.push({
        id: array.id,
        image: array.image,
        municipality: array.municipality,
        title: array.title,
        lat: array.lat,
        lng: array.lng,
        rate: array.rate,
        vote: array.vote,
        category_id: array.category_id,
        category: array.category,
        text: array.text,
        featured: array.featured,
        google_place: array.google_place,
        google_vote: '',
      })
    }
  }

  for (const array of poisesArrayFinal) {

    // if (array.google_place !== '' && array.google_vote.status !== 'NOT_FOUND' && array.google_vote.status !== 'INVALID_REQUEST' && array.google_vote.status.rating !== null) {
    if (array.google_vote.status === 'OK' && array.google_vote.result.rating !== undefined) {

      google_vote = array.google_vote.result.rating 
    }
    
    else 
      google_vote = 0
    
    poisesArrayFinal2.push({
      id: array.id,
      image: array.image,
      municipality: array.municipality,
      title: array.title,
      lat: array.lat,
      lng: array.lng,
      rate: google_vote,
      vote: array.vote,
      category_id: array.category_id,
      category: array.category,
      text: array.text,
      featured: array.featured,
    })
  }

  return poisesArrayFinal2
}