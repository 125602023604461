import React,{useState, useEffect, useMemo} from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Row, Col, Button, Radio, Divider, Anchor } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../components/Helmet'
import TitleAndSubtitleAndBtn from '../../components/PageComponents/TitleAndSubtitleAndBtn'


const Useful: React.FC = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { hash } = useLocation()
    // navigate(location.hash)
    const navigateHome = () => {
        navigate('/');
    };

    console.log(hash)

    const [sticky, setSticky] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
          if (window.scrollY >= 5000) {
            setSticky(false)
          } else {
            setSticky(true)
          }
        });
      }, []);


    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
           window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    const isMobile = width <= 768;

    return (
        <>
            <HelmetComponent title="Χρήσιμα" descrition="Χρήσιμα page" />

            <TitleAndSubtitleAndBtn title={t('Χρήσιμα')} subTitle={''} />

            <div className={ sticky ? classes.sticky : classes.sticky2}>
                <div className={classes.RadioGroupContainer}>
                    <Row justify="center">
                        <Radio.Group>

                            <Radio.Button className={classes.RadioButtonMenu}>
                                <Anchor
                                    className={classes.anchor}
                                    affix={false}
                                    offsetTop={isMobile ? 270 : 220}
                                    items={[
                                        {
                                            key: '1',
                                            href: '#TouristMaps',
                                            title: t('Τουριστικοί Χάρτες'),
                                        }
                                    ]}
                                />
                            </Radio.Button>
                            <Radio.Button className={classes.RadioButtonMenu}>
                                <Anchor
                                    className={classes.anchor}
                                    affix={false}
                                    offsetTop={isMobile ? 250 : 220}
                                    items={[
                                        {
                                            key: '2',
                                            href: '#UsefulInfo',
                                            title: t('Χρήσιμες Πληροφορίες'),
                                        }
                                    ]}
                                />
                            </Radio.Button>
                            <Radio.Button className={classes.RadioButtonMenu}>
                                <Anchor
                                    className={classes.anchor}
                                    affix={false}
                                    offsetTop={isMobile ? 250 : 220}
                                    items={[
                                        {
                                            key: '3',
                                            href: '#WeatherForecast',
                                            title: t('Πρόγνωση Καιρού'),
                                        }
                                    ]}
                                />
                            </Radio.Button>
                        </Radio.Group>
                    </Row>
                    <Row justify="center">
                        <Radio.Group>
                            <Radio.Button className={classes.RadioButtonMenu}>
                                <Anchor
                                    className={classes.anchor}
                                    affix={false}
                                    offsetTop={isMobile ? 250 : 220}
                                    items={[
                                        {
                                            key: '4',
                                            href: '#LiveCams',
                                            title: t('Live Κάμερες'),
                                        }
                                    ]}
                                />
                            </Radio.Button>
                            <Radio.Button className={classes.RadioButtonMenu}>
                                <Anchor
                                    className={classes.anchor}
                                    affix={false}
                                    offsetTop={isMobile ? 260 : 200}
                                    items={[
                                        {
                                            key: '5',
                                            href: '#TravelAndHealth',
                                            title: t('Ταξίδι και Υγεία'),
                                        }
                                    ]}
                                />
                            </Radio.Button>
                            <Radio.Button className={classes.RadioButtonMenu}>
                                <Anchor
                                    className={classes.anchor}
                                    affix={false}
                                    offsetTop={isMobile ? 260 : 200}
                                    items={[
                                        {
                                            key: '6',
                                            href: '#ProjectPublicityMaterial',
                                            title: t('Υλικό προβολής explorechania'),
                                        }
                                    ]}
                                />
                            </Radio.Button>
                        </Radio.Group>
                    </Row>
                </div>
            </div>


            <div id="TouristMaps" className={classes.Scroll}>
                <Row justify="center" style={{zIndex:'1'}} >
                    <div className={classes.UsefulSection}>
                        <div style={{ marginBottom: 10 }} className={classes.pointTitle}>{t('Τουριστικοί Χάρτες')}</div>
                        <div className={classes.subTitleSection}> {t('Δείτε τη συλλογή χαρτών που θα σας φανούν χρήσιμοι σε διάφορες περιπτώσεις στην επίσκεψή σας στο Νομό Χανίων')}:</div>
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χάρτης Νομού Χανίων')} (GR, EN)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/useful/01.Chania_area_map_50_x_70-02_LowRes_GR_EN.jpg' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <Divider />

                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χάρτης Πόλης Χανίων')} (EN)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/useful/02_Chania_city_map_35_x_50-01_LowRes_EN.jpg' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}</Button>
                                </div>

                            </Col>
                        </Row>

                        <Divider />

                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χάρτης Αγίας Μαρίνας')} (EN)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/useful/04_Agia_Marina_Stalos_map_35_x_ 50-02_low_res_EN.jpg' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}</Button>
                                </div>
                            </Col>
                        </Row>

                        <Divider />

                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χάρτης Ακρωτηρίου')} (EN)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/useful/03_Akrotiri_map_35_x_50-01_LowRes_EN.jpg' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}</Button>
                                </div>
                            </Col>
                        </Row>


                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χάρτης Δήμου Πλατανιά')} (GR)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/useful/Platanias_map_GR.pdf' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}</Button>
                                </div>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χάρτης Δήμου Πλατανιά')} (EN)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/useful/Platanias_map_EN.pdf' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}</Button>
                                </div>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χάρτης κωμόπολης Κισσάμου')} (EN)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/useful/Kissamos_map_EN.jpg' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}</Button>
                                </div>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χάρτης Δήμου Κισσάμου')} (EN)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/useful/Kissamos_dimos_map_EN.jpg' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}</Button>
                                </div>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χάρτης Δήμου Σφακίων')} (GR, EN)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/useful/Sfakion_dimos_map_EN_GR.jpg' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}</Button>
                                </div>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χάρτης Δήμου Γαύδου')} (GR, EN)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/useful/Gaudou_dimos_map_EN_GR.pdf' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}</Button>
                                </div>
                            </Col>
                        </Row>
                   
               

                    </div>
                </Row>
            </div>

            <div id="UsefulInfo" className={classes.Scroll}>
                <Row justify="center">
                    <div className={classes.UsefulSection}>
                        <div style={{ marginBottom: 10 }} className={classes.pointTitle}>{t('Χρήσιμες Πληροφορίες')}</div>
                        <div className={classes.subTitleSection}> {t('Συλλογή τηλεφώνων και διευθύνσεων για Νοσοκομεία, Τουριστικές και Ταχυδρομικές Υπηρεσίες, Ταξί, Λιμεναρχεία, Λιμάνια και Προξενεία')}:</div>
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χρήσιμα τηλέφωνα - Δήμος Χανίων')} (GR)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://www.chania.gr/ta-chania-mou/xrhsthlefwna/useful-info.html'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χρήσιμα τηλέφωνα - Δήμος Χανίων')} (EN)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://www.chaniatourism.gr/useful-info/'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χρήσιμα τηλέφωνα - Δήμος Πλατανιά')} (GR)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://www.platanias.gr/infotelephones/'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χρήσιμα τηλέφωνα - Δήμος Πλατανιά')} (EN)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://www.platanias.gr/en/useful-telephone-numbers/'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
   
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χρήσιμα τηλέφωνα - Δήμος Κισσάμου')} (GR)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='/img/useful/Kissamos_useful_Infos_GR.jpg'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χρήσιμα τηλέφωνα - Δήμος Κισσάμου')} (EN)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='/img/useful/Kissamos_useful_Infos_GR.jpg'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χρήσιμα τηλέφωνα - Δήμος Καντάνου Σελίνου')} (GR)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='/img/useful/Kantanou_Selinou_useful_Infos_GR.jpg'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χρήσιμα τηλέφωνα - Δήμος Σφακίων')} (GR)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='/img/useful/Sfakiwn_useful_Infos_GR.jpg'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χρήσιμα τηλέφωνα - Δήμος Αποκορώνου')} (GR)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='/img/useful/Apokoronou_useful_Infos_GR.jpg'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Χρήσιμα τηλέφωνα - Δήμος Γαύδου')} (GR)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='/img/useful/Gavdos_useful_Infos_GR.pdf'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </Row>
            </div>

            <div id="WeatherForecast" className={classes.Scroll}>
                <Row justify="center">
                    <div className={[classes.UsefulSection,classes.Scroll].join(' ')}>
                        <div style={{ marginBottom: 10 }} className={classes.pointTitle}>{t('Πρόγνωση Καιρού')}</div>
                        <div className={classes.subTitleSection}> {t('Η πρόγνωση του καιρού από την Επίσημη ιστοσελίδα της Εθνικής Μετερεωλογικής Υπηρεσίας (Ε.Μ.Υ)')}:</div>
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Ο καιρός στα Χανιά')}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://www.chania.gr/ta-chania-mou/kairos/weather.html'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Row>
            </div>

            <div id="LiveCams"  className={classes.Scroll}>
                <Row justify="center">
                    <div id="LiveCams" className={classes.UsefulSection}>
                        <div style={{ marginBottom: 10 }} className={classes.pointTitle}>{t('Live Κάμερες')}</div>
                        <div className={classes.subTitleSection}> {t('Ζωντανή μετάδοση από διάφορα σημεία του Νομού')}:</div>
                        
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}> {t('Χανιά - Live κάμερα')} </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://www.chania.gr/ta-chania-mou/taXaniaLive/deite-live.html'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}> {t('Πλατανιάς - Live κάμερα')} </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://penteli.meteo.gr/stations/platanias/'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}> {t('Ζυμβραγού - Live κάμερα')} </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://penteli.meteo.gr/stations/zymbragou/'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}> {t('Γαύδος - Live κάμερες')} </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://cretaweather.gr/gavdos/'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}> {t('Ελαφονήσι - Live κάμερα')} </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://cretaweather.gr/elafonisilive/'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>


                    </div>
                </Row>
            </div>    

            <div id="TravelAndHealth"  className={classes.Scroll}>
                <Row justify="center">
                    <div className={classes.UsefulSection}>
                        <div style={{ marginBottom: 10 }} className={classes.pointTitle}>{t('Ταξίδι και Υγεία')}</div>
                        {/* <div className={classes.subTitleSection}> {t('Ταξιδιωτικές πληροφορίες για τον COVID-19')}:</div> */}
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Ταξιδιωτικές πληροφορίες (Υπουργείο Τουρισμού)')}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://mintour.gov.gr/covid-19/'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>

                            </Col>
                        </Row>
                    </div>
                </Row>
            </div>  


            <div id="ProjectPublicityMaterial"  className={classes.Scroll}>
                <Row justify="center">
                    <div className={classes.UsefulSection}>
                        <div style={{ marginBottom: 10 }} className={classes.pointTitle}>{t('Υλικό προβολής')}</div>
                        {/* <div className={classes.subTitleSection}> {t('Ταξιδιωτικές πληροφορίες για τον COVID-19')}:</div> */}
                        <Divider />

                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Βίντεο προβολής του explorechania')} (GR)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://youtu.be/d0UcsB8kLcE'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>

                            </Col>
                        </Row>
                        <Divider /> 
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Βίντεο προβολής του explorechania')} (EN)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://youtu.be/Ylr8qZbsedM'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>

                            </Col>
                        </Row>


                        
                        <Divider /> 
                        <Row>
                            <Col xs={24} sm={24} md={15} lg={15}>
                                <div className={classes.sectionTitle}>
                                    {t('Βίντεο εκδήλωσης παρουσίασης')} (GR)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={9} lg={9}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://www.youtube.com/watch?v=1-u9a7x92g8'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>

                            </Col>
                        </Row>


                        <Divider /> 
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Φυλλάδιο explorechania A4')} (GR, EN)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/useful/ExploreChania-Filladio_A4_montage.pdf' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <Divider /> 
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Αυτοκόλλητο explorechania Α6')} (GR, EN)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/useful/ExploreChania-Aftokollita.jpg' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>


                    </div>
                </Row>
            </div> 



            <div style={{ marginBottom: 100 }}>
                <Row justify="center">
                    <Col span={11} xs={24} sm={24} md={11} lg={11}>
                        <div className={classes.sectionTitle}>
                            <Button
                                size='large'
                                icon={<ArrowLeftOutlined />}
                                onClick={() => {
                                    if (!window.location.hash)
                                        navigate(-1)
                                    else
                                        navigate(-2)
                                }
                                }
                                className={classes.ReturnBtn}>&nbsp;&nbsp;{t('Επιστροφή')}
                            </Button>

                        </div>
                    </Col>
                    <div className={classes.space}></div>
                    <Col span={11} xs={24} sm={24} md={11} lg={11}>
                        <div className={classes.sectionTitle}>
                            <Button
                                size='large'
                                className={classes.HomeBtn}
                                onClick={navigateHome}>
                                {t('Αρχική Σελίδα')}&nbsp;&nbsp;<ArrowRightOutlined />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Useful
