
import React from "react"
import Props from './svgProps'
    
const ConferencesSvg = ({width, height, fill}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="https://www.w3.org/2000/svg"
        viewBox="0 0 19.96 20">   
        
        <path fill={fill} d="M3.5,13h5a.51.51,0,0,0,.5-.5,2.49,2.49,0,0,0-1.44-2.26,2,2,0,1,0-3.12,0A2.49,2.49,0,0,0,3,12.5a.51.51,0,0,0,.5.5ZM6,8a1,1,0,1,1-.71.29A1,1,0,0,1,6,8Zm-.5,3h1a1.53,1.53,0,0,1,.87.28,1.44,1.44,0,0,1,.54.72H4.09a1.44,1.44,0,0,1,.54-.72A1.53,1.53,0,0,1,5.5,11Z"/>
        <path fill={fill} d="M12,9a2,2,0,0,0,.44,1.24A2.49,2.49,0,0,0,11,12.5a.51.51,0,0,0,.5.5h5a.51.51,0,0,0,.5-.5,2.49,2.49,0,0,0-1.44-2.26A2,2,0,1,0,12,9Zm3.91,3H12.09a1.44,1.44,0,0,1,.54-.72A1.53,1.53,0,0,1,13.5,11h1a1.53,1.53,0,0,1,.87.28A1.44,1.44,0,0,1,15.91,12ZM14,8a1,1,0,1,1-.71.29A1,1,0,0,1,14,8Z"/>
        <path fill={fill} d="M4.56,17.24a2,2,0,1,0-3.12,0,2.52,2.52,0,0,0-1,.92A2.55,2.55,0,0,0,0,19.5a.47.47,0,0,0,.15.35A.47.47,0,0,0,.5,20h5a.51.51,0,0,0,.5-.5,2.55,2.55,0,0,0-.39-1.34,2.52,2.52,0,0,0-1.05-.92ZM3,15a1,1,0,1,1-.71,1.71A1,1,0,0,1,3,15ZM1.09,19a1.44,1.44,0,0,1,.54-.72A1.53,1.53,0,0,1,2.5,18h1a1.53,1.53,0,0,1,.87.28,1.44,1.44,0,0,1,.54.72Z"/>
        <path fill={fill} d="M11.56,17.24a2,2,0,1,0-3.12,0A2.49,2.49,0,0,0,7,19.5a.51.51,0,0,0,.5.5h5a.51.51,0,0,0,.5-.5,2.49,2.49,0,0,0-1.44-2.26ZM10,15a1,1,0,1,1-.71.29A1,1,0,0,1,10,15ZM8.09,19a1.44,1.44,0,0,1,.54-.72A1.53,1.53,0,0,1,9.5,18h1a1.53,1.53,0,0,1,.87.28,1.44,1.44,0,0,1,.54.72Z"/>
        <path fill={fill} d="M18.56,17.24a2,2,0,1,0-3.12,0A2.49,2.49,0,0,0,14,19.5a.51.51,0,0,0,.5.5h5a.51.51,0,0,0,.5-.5,2.49,2.49,0,0,0-1.44-2.26ZM17,15a1,1,0,0,1,.71,1.71A1.05,1.05,0,0,1,17,17a1,1,0,1,1,0-2Zm-1.91,4a1.44,1.44,0,0,1,.54-.72A1.53,1.53,0,0,1,16.5,18h1a1.53,1.53,0,0,1,.87.28,1.44,1.44,0,0,1,.54.72Z"/>
        <path fill={fill} d="M7.5,6h5a.51.51,0,0,0,.5-.5,2.49,2.49,0,0,0-1.44-2.26,2,2,0,1,0-3.12,0A2.49,2.49,0,0,0,7,5.5a.51.51,0,0,0,.5.5ZM10,1a1,1,0,0,1,.71.29,1,1,0,0,1,0,1.42,1,1,0,0,1-1.42,0,1,1,0,0,1,0-1.42A1,1,0,0,1,10,1ZM9.5,4h1a1.53,1.53,0,0,1,.87.28,1.44,1.44,0,0,1,.54.72H8.09a1.44,1.44,0,0,1,.54-.72A1.53,1.53,0,0,1,9.5,4Z"/>
      </svg>
    )
}

export default ConferencesSvg 