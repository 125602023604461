import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { I18nextProvider } from "react-i18next"
import i18n from "./translation"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// Redux
import { Provider } from 'react-redux'
import { store } from './redux/store'

import Header from "./components/Header/Header"
import Footer from "./components/Footer"

import AutoScrollToTop from './components/AutoScrollToTop'
import ScrollToTop from '../src/components/ScrollToTop'                     

// const Home = lazy(() => import('./pages/Home/Home'))
import Home from './pages/Home/Home'

import Myfavorites from './pages/MyFavorites/MyFavorites'
import MyAccount from './pages/MyAccount/MyAccount'
import Login from './pages/Secure/Login/Login'
import Search from './pages/Search/Search'
import Explorations from './pages/Exploration/Exploration'
import ExplorationView from './pages/Exploration/ExplorationView/ExplorationView'
import ExplorationViewOmeka from './pages/ExplorationOmeka/ExplorationViewOmeka/ExplorationViewOmeka'
import Routess from './pages/Routes/Routes' 
import RouteView from './pages/RouteView/RouteView'
import Events from './pages/Events/Events'
import EventsByMunicipality from './pages/Events/EventsByMunicipality'
import EventView from './pages/Events/EventView/EventView'
import CommonPage from './pages/CommonPage/CommonPage'
import Contacts from './pages/Contacts/Contacts'
import Useful from './pages/Useful/Useful'
import CommentForm from './pages/CommentForm/CommentForm'
import MunicipalSocialNetworks from './pages/MunicipalSocialNetworks/MunicipalSocialNetworks'
import Sitemap from './pages/Sitemap/Sitemap'

import JsonPage from './pages/JsonPage/JsonPage'


import PageNotFound from './pages/404/404'
import { AuthProvider } from './utils/auth'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    }
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)


root.render(
  <>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <AutoScrollToTop/>
            <AuthProvider>
            <Header />
            
            <Routes>
              <Route index path="/" element={<Home />} />
              <Route path="search" element={<Search />} />
              <Route path="explorations" element={<Explorations />} />
              <Route path="exploration/:id" element={<ExplorationView />} />
              <Route path="routes" element={<Routess />} />
              <Route path="route/:id" element={<RouteView />} />
              <Route path="events" element={<Events />} />
              <Route path="events/:municipality" element={<EventsByMunicipality />} />
              <Route path="event/:id" element={<EventView />} />
             
              <Route path="page/:page" element={<CommonPage />} />
              <Route path="contacts" element={<Contacts />} />
              <Route path="useful" element={<Useful />} />
              <Route path="commentForm" element={<CommentForm />} />  
              <Route path="municipal_social_networks" element={<MunicipalSocialNetworks />} />  
              <Route path="sitemap" element={<Sitemap />} />  
              <Route path=":id" element={<ExplorationViewOmeka />} /> 
              <Route path="json" element={<JsonPage />} />
              <Route path="*" element={<PageNotFound />} />

              {/* <Route element={<PrivateRoutes />}> */}
                <Route path="favorites" element={<Myfavorites />} />
                <Route path="account" element={<MyAccount />} />
              {/* </Route> */}

                <Route path="login" element={<Login />} />
              
              {/* <Route path="*" element={<Navigate to="/" />} /> */}
            </Routes>

            <Footer />
            <ScrollToTop />
          </AuthProvider>
          </I18nextProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>  
  </>
)