export const configClusterGroup = {
  'maxClusterRadius': '80',
  'spiderfyOnMaxZoom': 'true',
  'showCoverageOnHover': 'true',
}


export const polygonOptions = {
  'fillColor': '#ffffff',
  'color': '#6cbece',
  'weight': '2',
  'opacity': '1',
  'fillOpacity': '0.4'
}