export const SearchPoisTitles = [
  
    { value: "Aπολιθωμένος σκελετός σειρηνίου στα βόρεια του οικισμού Κοτσιανά"},
    { value: "Αγία Ειρήνη"},
    { value: "Αγία Μονή Σαρακήνα (μετόχι Μονής Μεγίστης Λαύρας)"},
    { value: "Άγιοι Πάντες"},
    { value: "Άγιος Γεώργιος"},
    { value: "Άγιος Δίκαιος"},
    { value: "Άγιος Ιωάννης Ελεήμονας"},
    { value: "Άγιος Νέστορας – Άγιος Δημήτριος στο Κατοχώρι Κεραμειών"},
    { value: "Αγίου Ονουφρίου"},
    { value: "Αγίων Αποστόλων"},
    { value: "Αγροικία με εγκατάσταση ληνού"},
    { value: "Αγροικίες με εγκατάσταση ελαιοπιεστηρίου"},
    { value: "Αεροδρόμιο Μάλεμε"},
    { value: "Αι Γιάννης"},
    { value: "Αιωνόβια ελιά Μαθέ"},
    { value: "Αιωνόβια ελιά Σαμωνά"},
    { value: "Άμπελος"},
    { value: "Ανασκαφή «Βακαλουνάκη»"},
    { value: "Ανασκαφή «Λιονάκη-Βλαμάκη»"},
    { value: "Ανασκαφή «Μαθιουδάκη»"},
    { value: "Ανασκαφή «Νότια του Τείχους»"},
    { value: "Ανασκαφή «Παπαδόπουλου»"},
    { value: "Ανώπολις"},
    { value: "Απολιθώματα ανατολικά του Αγ. Γεωργίου του Κεδρέ"},
    { value: "Απολιθώματα στο εκκλησάκι του Αγίου Παντελεήμονα"},
    { value: "Απολιθωμένος σκελετός σειρηνίου"},
    { value: "Αποτυπώματα πελμάτων που ενδέχεται να προήλθαν από ανθρωπίδες του Μειόκαινου (6 εκ. χρόνων) στην παραλία Τράχηλος Γραμβούσας"},
    { value: "Αραδήν"},
    { value: "Αρχαία Άπτερα"},
    { value: "Αρχαία Κίσαμος"},
    { value: "Αρχαία Φαλάσαρνα"},
    { value: "Αρχαιολογικό Μουσείο Κισάμου"},
    { value: "Αρχαιολογικό Μουσείο Χανίων"},
    { value: "Αρχαιολογικός Χώρος «Αζοϊρέ» ή «Αζωγυρέ» Στύλου"},
    { value: "Αρχαιολογικός χώρος Μαραθίου"},
    { value: "Αρχαιολογικός Χώρος Νοπήγεια - Δραπανιά - Κολένι"},
    { value: "Αρχαίο νεκροταφείο"},
    { value: "Ασβεστοκάμινο, ανατολικά του Φάρου"},
    { value: "Ασβεστοκάμινο στο μονοπάτι Καστρί-Άμπελος"},
    { value: "«Αστράτηγος» / Ναός Αρχαγγέλου Μιχαήλ"},
    { value: "Αυστριακό Προξενείο επί Κρητικής Πολιτείας"},
    { value: "Βαβουλέδο - Παλαιά Ρούματα"},
    { value: "Βατσιανά"},
    { value: "Βενετικός Νερόμυλος Καλυβών"},
    { value: "Βενιζέλειο Ωδείο"},
    { value: "Βίεννος"},
    { value: "Βίλα Ρετόντα"},
    { value: "Βίλλα Κούνδουρου"},
    { value: "Βιοτεχνικό Πάρκο – Καλλιτεχνικό Χωριό Βερέκυνθος"},
    { value: "Βλητέ"},
    { value: "Βοτανικό Πάρκο και Κήποι Κρήτης"},
    { value: "Βρύση"},
    { value: "Βυζαντινή & Μεταβυζαντινή Συλλογή Χανίων"},
    { value: "Γαλλική Σχολή"},
    { value: "Γαλλικό Προξενείο επί Κρητικής Πολιτείας"},
    { value: "Γαυδοπούλα"},
    { value: "Γενοβέζικος (ή Γενουάτικος) πύργος"},
    { value: "Γερμανικό Προξενείο επί Κρητικής Πολιτείας"},
    { value: "Γερμανικό Στρατιωτικό Νεκροταφείο"},
    { value: "Γιαλισκάρι"},
    { value: "Γιαλί Τζαμί"},
    { value: "Γιορτή καλιτσουνιού - Καντάνου"},
    { value: "Γιορτή ντομάτας"},
    { value: "Γλυκά νερά"},
    { value: "Γολγοθάς, Σημείο θέας στο Σκινέ"},
    { value: "Γουβερνέτο – Σταυρός"},
    { value: "Γραμβούσια-Κρητικός Γάμος"},
    { value: "Γραμμένο"},
    { value: "Γραμμένο Κεδρόδασος"},
    { value: "Γρε Λέσκα"},
    { value: "Δάσος Αζίλακα"},
    { value: "Δάσος Ρουπακιά (Ορπακιά)"},
    { value: "Δεσποτικό Χανίων"},
    { value: "Δευκαλίωνος"},
    { value: "Δημοτική Αγορά"},
    { value: "Δημοτική βιβλιοθήκη "},
    { value: "Δημοτική Πινακοθήκη"},
    { value: "Δημοτικός Κήπος"},
    { value: "Δημοτικός κινηματογράφος «Κήπος»"},
    { value: "Διαβατικό Ενετικού Μεγάρου Renier, Παρεκκλήσι Οικογένειας Renier"},
    { value: "Διεθνές Σκακιστικό Τουρνουά Παλαιόχωρας"},
    { value: "Διεθνής Αερολιμένας Χανίων «Δασκαλογιάννης»"},
    { value: "Δικαστικό Διοικητικό Μέγαρο"},
    { value: "Δικτυνναίο Ιερό"},
    { value: "Διοικητήριο επί Κρητικής Πολιτείας («Παλιά Μεραρχία»)"},
    { value: "Δρακώνα - Ψυχρό Πηγάδι"},
    { value: "Εβραϊκή Συναγωγή Ετζ Χαγίμ"},
    { value: "Εθνικό Ίδρυμα Ερευνών & Μελετών “Ελευθέριος Βενιζέλος”"},
    { value: "Εθνικό Σκοπευτήριο Χανίων"},
    { value: "Εκβολές ποταμού Μορώνη"},
    { value: "Εκβολή Κλαδισού ποταμού"},
    { value: "Εκβολή ποταμού Βλυχάδα"},
    { value: "Ελαφονήσι"},
    { value: "Ελληνική καμάρα"},
    { value: "Ελληνικό Προξενείο επί Κρητικής Πολιτείας"},
    { value: "Ελληνοσουηδική ανασκαφή"},
    { value: "Έλος"},
    { value: "Έλυρος"},
    { value: "Εμπορικό κέντρο"},
    { value: "Εμπορικό Λιμάνι Σούδας"},
    { value: "Ενετικά Νεώρια"},
    { value: "Ενετική έπαυλη με ελαιοτριβείο"},
    { value: "Ενετικό κτήριο"},
    { value: "Ενετικό κτήριο (Fagotto)"},
    { value: "Ενετικό κτήριο (ξενοδοχείο Alcanea)"},
    { value: "Ενετικό κτήριο (πρώην οικία Σταυρουλάκη)"},
    { value: "Ενετικό κτήριο (σημερινό ξενοδοχείο OFF)"},
    { value: "Επιπρομαχώνας Priuli"},
    { value: "Επιπρομαχώνας Αγίας Αικατερίνης"},
    { value: "Ηλιακό ρολόι Φωτακάδο"},
    { value: "Ήμερη"},
    { value: "Ηρώο"},
    { value: "Θέατρο Ανατολικής Τάφρου"},
    { value: "Θέατρο “Δημήτρης Βλησίδης”"},
    { value: "Θολωτός τάφος «Αζωγυρέ» ή «Αζοϊρέ» Στύλου"},
    { value: "Θολωτός τάφος Μάλεμε"},
    { value: "Θολωτός τάφος Φυλακής"},
    { value: "Ιερά Μονή Αγίας Κυριακής"},
    { value: "Ιερά Μονή Αγίας Κυριακής - Σπηλαιώδες παρεκκλήσι των Αγίων Επτά Παίδων"},
    { value: "Ιερά Μονή Αγίας Τριάδας (Μετόχι της Αγίας Αικατερίνης Σινά)"},
    { value: "Ιερά Μονή Αγίας Τριάδας των Τζαγκαρόλων"},
    { value: "Ιερά Μονή Ζωοδόχου Πηγής (Χρυσοπηγή)"},
    { value: "Ιερά Μονή Κυρίας των Αγγέλων Γουβερνέτου"},
    { value: "Ιερά Μονή Μεταμόρφωσης του Σωτήρος"},
    { value: "Ιερά Μονή Τιμίου Προδρόμου Κορακιών"},
    { value: "Ιερός Ναός Αγίου Παντελεήμονα"},
    { value: "Ιερός Ναός Αγίου Φραγκίσκου"},
    { value: "Ιερός Ναός Μιχαήλ Αρχαγγέλου"},
    { value: "Ιερός Ναός Προφήτη Ηλία"},
    { value: "Ίλιγκας"},
    { value: "Ιστορική, Λαογραφική & Αρχαιολογική Εταιρεία Κρήτης (Κτίριο ΙΛΑΕΚ)"},
    { value: "Ιστορικό Αρχείο Κρήτης"},
    { value: "Ιστορικό - Λαογραφικό μουσείο Γαβαλοχωρίου"},
    { value: "Ιστορικό μονοπάτι Πρασέ"},
    { value: "Ιταλικός Στρατώνας"},
    { value: "Κάδρος"},
    { value: "Καθεδρικός Ναός Εισοδίων ή Τριμάρτυρη)"},
    { value: "Καθολική Εκκλησία"},
    { value: "Καθολικό Μονής Γωνιάς"},
    { value: "Καλαθά"},
    { value: "Καλαμάκι"},
    { value: "Καλαμιού"},
    { value: "Καλλεργιανά"},
    { value: "Καλλικράτης"},
    { value: "Καλυβιανή"},
    { value: "Καμάρα του Κουτσού"},
    { value: "Κάμποι – Καταφύγιο Βόλικα"},
    { value: "Καρναβάλι Παλαιόχωρας"},
    { value: "Κάστελλος (Βυζαντινό Φρούριο)"},
    { value: "Καστράκι στα Πευκάκια"},
    { value: "Καστρί"},
    { value: "Κάστρο Γραμβούσας"},
    { value: "Καταδυτικό πάρκο Ομπρόσγιαλου"},
    { value: "Καταφύγιο Βόλικα"},
    { value: "Καταφύγιο γυπαετός"},
    { value: "Καταφύγιο Καλλέργη (Ομαλός)"},
    { value: "Καταφύγιο, Μουσείο Β΄ Παγκοσμίου Πολέμου"},
    { value: "Κατοικία Κυριάκου Κων/νου Μητσοτάκη"},
    { value: "Κατοικία του εμπόρου Ναξάκη"},
    { value: "Καψοδάσος"},
    { value: "Κεδρόδασος"},
    { value: "Κέντρο Αρχιτεκτονικής της Μεσογείου (Μεγάλο Αρσενάλι) "},
    { value: "Κέντρο Περιβαλλοντικής Εκπαίδευσης (Κ.Π.Ε.)"},
    { value: "Κισαμίτικο καρναβάλι"},
    { value: "Κόρφος"},
    { value: "Κουλέδες Ασκύφου"},
    { value: "Κουλές Κάστελλου"},
    { value: "Κουλές Νιο Χωριό"},
    { value: "Κούτελος"},
    { value: "Κρινάκια στις παραλίες της Νέας Κυδωνίας, Χρυσή Ακτή έως Αγιά Μαρίνα (προστατευόμενο είδος)"},
    { value: "Κριός"},
    { value: "Κτηνοτρόφοι, τυροκόμοι, καζανιέρηδες τσικουδιάς"},
    { value: "Κτήριο Εμμ. Παπαγιαννάκη"},
    { value: "Κτήριο πρώην Αγροτικής Τράπεζας"},
    { value: "Κτίριο Αγγέλας Ξηρουχάκη"},
    { value: "Κτίριο Αικατερίνης Σαριδάκη"},
    { value: "Κτίριο Γεωργίου Πανηγυράκη"},
    { value: "Κτίριο Γεωργίου Παπαδόπετρου"},
    { value: "Κτίριο Ιδιοκτησίας Μπολάκη Ελένης & Παναγιώτη"},
    { value: "Κτίριο Κωνσταντίνου Κατζουράκη"},
    { value: "Κτίριο Μπικουβάρη"},
    { value: "Κτίριο Παναγιώτη Μουζουράκη"},
    { value: "Κτίριο πολιτικών εξορίστων («σπίτι Άρη Βελουχιώτη»)"},
    { value: "Κτίριο Τράπεζας της Ελλάδος"},
    { value: "Κτίριο Χατζηδάκη & Παπαδονικολάκη"},
    { value: "Κυδώνι - Μποριανών - Καράνου"},
    { value: "Κωμόλιθοι"},
    { value: "Λαγκούφα"},
    { value: "Λακούδι"},
    { value: "Λαξευτός θαλαμοειδής τάφος"},
    { value: "Λαογραφικό Μουσείο Κεφαλά"},
    { value: "Λαογραφικό Μουσείο Παλαιών Ρουμάτων"},
    { value: "Λαυρακάς"},
    { value: "Λαφονήσιος Δρόμος"},
    { value: "Λιμανάκι Νέας Χώρας"},
    { value: "Λιμάνι Γαύδου"},
    { value: "Λιμάνι και παραλία Γραμβούσας"},
    { value: "Λίμνη Κουρνά"},
    { value: "Λίμνη Κριού"},
    { value: "Λίμνη της Αγιάς (περιοχή Natura)"},
    { value: "Λισός"},
    { value: "Λουτράκι"},
    { value: "Λουτρό"},
    { value: "Λυγένια πηγάδια"},
    { value: "Λύκος"},
    { value: "Μαλοτήρα, ματζουράνα, φασκομηλιά, βολβοί, ασκολίμπροι, ραδίκια κ.λ.π."},
    { value: "Μαράθι"},
    { value: "Μάρμαρα"},
    { value: "Μαχαιρίδα"},
    { value: "Μέγαρο Θεοδώρου Μανούσακα"},
    { value: "Μεταλλική γέφυρα Ταυρωνίτη"},
    { value: "Μετόχι Αγίου Γεωργίου Χαρωδιάς"},
    { value: "Μετόχι Παναγίας στην Περβολίτσα"},
    { value: "Μετόχι Φραγγεδιανών"},
    { value: "Μηλιά"},
    { value: "Μιναρές Αχμέτ Αγά"},
    { value: "Μινωική έπαυλη στα Νεροκούρου Χανίων"},
    { value: "Μινωικό κτηριακό συγκρότημα"},
    { value: "Μινωικό οικοδόμημα"},
    { value: "Μιτάτα – Κούμοι στα Κεραμειά"},
    { value: "Μνημείο δέκα εκτελεσθέντων"},
    { value: "Μνημείο εκτελεσθέντων - Γολγοθάς"},
    { value: "Μνημείο Κρητικών Επαναστάσεων (τέως Δημαρχείο Βάμου)"},
    { value: "Μνημείο Μάχης Κρήτης"},
    { value: "Μνημείο Μάχης της Παναγιάς"},
    { value: "Μνημείο Μεταπολιτευτικής Επιτροπής (Βρύσες)"},
    { value: "Μνημείο Ολοκαυτώματος"},
    { value: "Μνημείο Πεσόντων 1941 στην πλατεία του χωριού. (Πλατεία Ηρώων 1941)"},
    { value: "Μνημείο πεσόντων Νεοχωριτών"},
    { value: "Μνημείο Πεσόντων στο Αρώνι"},
    { value: "Μνημείο της «Μάχης της 42ης οδού»"},
    { value: "Μονή Santa Maria dei Miracolli"},
    { value: "Μονή Αγίου Ελευθερίου"},
    { value: "Μονή Αγίου Ιωάννη Γκιώνα"},
    { value: "Μονή Αγίου Ιωάννου Προδρόμου στην θέση Σαμόλι"},
    { value: "Μονή Αγίου Νικολάου των Δομηνικανών"},
    { value: "Μονή Αγίου Παύλου"},
    { value: "Μονή Αγίων Πατέρων"},
    { value: "Μονή Ζωοδόχου Πηγής"},
    { value: "Μονή Παναγίας Θυμιανής"},
    { value: "Μονή Παναγίας Οδηγήτριας Γωνιάς "},
    { value: "Μονή Παναγίας (Τύλιφος)"},
    { value: "Μονή Παναγίας Χρυσοσκαλίτισσας"},
    { value: "Μονή της Santa Maria della Misericordia των Αυγουστιανών  "},
    { value: "Μονή του San Salvatore των Φραγκισκανών"},
    { value: "Μονοπάτι Βαρύπετρο – Θέρισο"},
    { value: "Μονοπάτι Δασκάλου Βρύση"},
    { value: "Μονοπάτι Ζούρβα-Τρομάρισσα"},
    { value: "Μουσείο Ακριτών της Ευρώπης"},
    { value: "Μουσείο Εθνικής Αντίστασης Θερίσου"},
    { value: "Μουσείο Ελιάς Βουβών "},
    { value: "Μουσείο Ελιάς & Λαδιού - Λαογραφική Συλλογή Δερμιτζάκη"},
    { value: "Μουσείο Κρητικών βοτάνων ΟΑΚ Κολυμβαρίου"},
    { value: "Μουσείο – Στρατηγείο της επανάστασης του Θερίσου (1905)"},
    { value: "Μουσείο Σύγχρονης Τέχνης “Ελαιουργείον”, Δρομόνερο"},
    { value: "Μουσείο Σχολικής Ζωής"},
    { value: "Μουσείο Τυπογραφίας"},
    { value: "Μπουτσουνάρια"},
    { value: "Ναός Αγίας Αικατερίνης"},
    { value: "Ναός Αγίας Αικατερίνης (Σπλάντζια)"},
    { value: "Ναός Αγίας Άννας"},
    { value: "Ναός Αγίας Βαρβάρας Λατζιανών"},
    { value: "Ναός Αγίας Ειρήνης"},
    { value: "Ναός Αγίας Ζώνης "},
    { value: "Ναός Αγίας Κυριακής"},
    { value: "Ναός Αγίας Μαγδαληνής"},
    { value: "Ναός Αγίου Αθανασίου"},
    { value: "Ναός Αγίου Βασιλείου"},
    { value: "Ναός Αγίου Γεωργίου"},
    { value: "Ναός Αγίου Γεωργίου (Αλικιανός)"},
    { value: "Ναός Αγίου Γεωργίου (Κεδρές)"},
    { value: "Ναός Αγίου Γεωργίου Κουμπελή"},
    { value: "Ναός Αγίου Γεωργίου (Λάκκοι)"},
    { value: "Ναός Αγίου Ελευθερίου"},
    { value: "Ναός Αγίου Ευτυχίου"},
    { value: "Ναός Αγίου Ιωάννη"},
    { value: "Ναός Αγίου  Ιωάννη Θεολόγου"},
    { value: "Ναός Αγίου Ιωάννη Θεολόγου"},
    { value: "Ναός Αγίου Ιωάννη Θεολόγου και Αγίου Νικολάου"},
    { value: "Ναός Αγίου Νικολάου"},
    { value: "Ναός Αγίου Νικολάου (Κυριακοσέλια)"},
    { value: "Ναός Αγίου Νικολάου (Μάζα)"},
    { value: "Ναός Αγίου Νικολάου στο Μουρί"},
    { value: "Ναός Αγίου Παντελεήμονα"},
    { value: "Ναός Αγίου Παύλου"},
    { value: "Ναός Αγίου Ρόκκου"},
    { value: "Ναός Αγίου Σπυρίδωνα"},
    { value: "Ναός Αγίου Στεφάνου"},
    { value: "Ναός Αγίου Φωτίου και Ανίκητου"},
    { value: "Ναός Αγίου Χαραλάμπους"},
    { value: "Ναός Αγίων Αναργύρων"},
    { value: "Ναός Αγίων Ασωμάτων"},
    { value: "Ναός Αγίων Πάντων"},
    { value: "Ναός Αγίων Πατέρων"},
    { value: "Ναός Άη Γιώργη Μεθυστή"},
    { value: "Ναός Αρχαγγέλου Μιχαήλ (Σαρακήνα)"},
    { value: "Ναός Γεννεσίου Της Θεοτόκου"},
    { value: "Ναός Ευαγγελισμού της Θεοτόκου (Μητρόπολη)"},
    { value: "Ναός Ζωοδόχου Πηγής/ Άη Κυρ Γιάννης"},
    { value: "Ναός Κοιμήσεως της Θεοτόκου"},
    { value: "Ναός Κοιμήσεως της Θεοτόκου (Αλίκαμπος)"},
    { value: "Ναός Κοιμήσεως της Θεοτόκου (Κεφαλάς)"},
    { value: "Ναός Μεταμόρφωσης του Σωτήρα (Πλεμενιανά)"},
    { value: "Ναός Μεταμόρφωσης του Σωτήρα (Τεμένια)"},
    { value: "Ναός Μιχαήλ Αρχαγγέλου (Καβαλαριανά)"},
    { value: "Ναός Μιχαήλ Αρχαγγέλου (Ροτόντα)"},
    { value: "Ναός Οσίας Μαρίας της Αιγυπτίας"},
    { value: "Ναός Οσίου Ιωάννη του Ξένου"},
    { value: "Ναός Παναγίας (Ανισαράκι)"},
    { value: "Ναός Παναγίας Μυρτιδιώτισσας"},
    { value: "Ναός Παναγίας Σκαφιδιανής"},
    { value: "Ναός Παναγίας (Σκλαβοπούλα)"},
    { value: "Ναός Παναγίας στο Κάδρος"},
    { value: "Ναός Παναγίας της Ζερβιώτισσας (ή Μοναστήρα)"},
    { value: "Ναός Προφήτη Ηλία"},
    { value: "Ναός Προφήτη Ηλία στο Μελιδόνι"},
    { value: "Ναός Σωτήρα Χριστού"},
    { value: "Ναός της Παναγίας των Δύο Βράχων (ή Παναγία στο Χαράκι)"},
    { value: "Ναός Τιμίου Σταυρού"},
    { value: "Ναυτικό Μουσείο Κρήτης"},
    { value: "Νεώρια Moro"},
    { value: "Νησίδα Μαράθι"},
    { value: "Νησίδα Σούδας (Ενετικό φρούριο)"},
    { value: "Νύχτα των ευχών"},
    { value: "Ξυλόσκαλο Σαμαριάς"},
    { value: "Οικία Βαλέριου Καλούτση"},
    { value: "Οικία Γέννησης Ελευθερίου Βενιζέλου, Μουρνιές"},
    { value: "Οικία-Μουσείο Βενιζέλου"},
    { value: "Οικία Πέτρου Μαρκαντωνάκη (Εριέττας Χορτάτζη-Μαρκαντωνάκη)"},
    { value: "Οικία του χρυσοχόου Ανίκητου Γαροφαλάκη"},
    { value: "Οικία Φαιναρέτης Μιτσοτάκη - Βρετανικό Προξενείο επί Κρητικής Πολιτείας"},
    { value: "Οικισμός Αράδαινας, χαρακτηρισμένος ιστορικός τόπος, Μονοπάτι φαραγγιού Αράδαινας, χαρακτηρισμένο μνημείο"},
    { value: "Οινοποιεία στα Κεραμεία"},
    { value: "Ολόκληρο το νησί"},
    { value: "Ορθή Άμμος"},
    { value: "Οροπέδιο Ομαλού"},
    { value: "Ούγια, σημείο θέας στη Ραβδούχα"},
    { value: "Οχυρωματικός Πύργος (Ανύδροι)"},
    { value: "Οχυρωματικός Πύργος (Βλιθιάς)"},
    { value: "Οχυρωματικός Πύργος (Σπανιακός)"},
    { value: "Οχυρωματικό τείχος"},
    { value: "Οχυρώσεις"},
    { value: "Παζάρι Μεγάλης Παρασκευής - οδός Σκαλίδη"},
    { value: "Παλαιός επαρχιακός δρόμος Καντάνου - Τμήμα Ανάβου-Καντάνου"},
    { value: "Παλαιοχριστιανική βασιλική Αγίας Ρουμέλης"},
    { value: "Παλαιοχριστιανική βασιλική Αλμυρίδας"},
    { value: "Παλαιοχριστιανική βασιλική του Αγίου Νικήτα"},
    { value: "Παλάτι Πρίγκιπα Γεωργίου"},
    { value: "Παλιά γειτονιά"},
    { value: "Παλιό Επισκοπείο"},
    { value: "Παναγιά στην Επισκοπή Αγιάς"},
    { value: "Παναγία στο Θέρισο"},
    { value: "Παραδοσιακός υποδηματοποιός-στιβάνια"},
    { value: "Παραλία Αγίας Μαρίνας"},
    { value: "Παραλία Αφράτων"},
    { value: "Παραλία Κολυμβαρίου"},
    { value: "Παραλία «Κουμ Καπί»"},
    { value: "Παραλία Μάλεμε"},
    { value: "Παραλία Πλατανιά"},
    { value: "Παραλία Πλατανιά - Λιμανάκι"},
    { value: "Παραλία Ποταμός"},
    { value: "Παραλία Ραβδούχας"},
    { value: "Παραλία στο παλιό λιμάνι"},
    { value: "Παραλία Ταυρωνίτη"},
    { value: "Παρθεναγωγείο"},
    { value: "Πάρκο Αγίου Γερασίμου, Μόδι"},
    { value: "Πάρκο Αγίων Αποστόλων"},
    { value: "Πάρκο Διάσωσης Χλωρίδας και Πανίδας του Πολυτεχνείου Κρήτης"},
    { value: "Παχιά Άμμος"},
    { value: "Πελεκανιώτης"},
    { value: "Πέτρινο θέατρο"},
    { value: "Πλαζ Ναυστάθμου Κρήτης"},
    { value: "Πλατεία 1866"},
    { value: "Πλατεία Τάλω"},
    { value: "Πνευματικό Κέντρο Χανίων"},
    { value: "Ποδηλατικοί Αγώνες Παλαιόχωρας"},
    { value: "Πολυρρήνια"},
    { value: "Ποτάμι Αγίας Ειρήνης"},
    { value: "Ποταμός Ταυρωνίτης"},
    { value: "Πρασονήσια"},
    { value: "Προμαχώνας Lando (ή Αγίου Δημητρίου)"},
    { value: "Προμαχώνας Mocenigo"},
    { value: "Προμαχώνας Piatta Forma"},
    { value: "Προμαχώνας Sabbionara"},
    { value: "Προμαχώνας San Salvatore"},
    { value: "Προμαχώνας Santa Lucia"},
    { value: "Προμαχώνας Αγίου Νικολάου του Μώλου"},
    { value: "Πρώην γερμανικό στρατηγείο (στοά)"},
    { value: "Πρώην κατοικία βουλευτή Αντώνη Κατζουράκη (Κτιρίο Ιδιοκτ. Κλέαρχου Μαρκαντωνάκη)"},
    { value: "Πρώην Κατοικία Βουλευτή Κωνσταντίνου Φούμη"},
    { value: "Πρώην κατοικία Βουλευτή Χαράλαμπου Πωλογιώργη"},
    { value: "Πρώην κατοικία γερουσιαστή Μπιστολάκη (Κτίριο ΤΕΕ, Τμήμα Δυτικής Κρήτης)"},
    { value: "Πρώην Κατοικία Διοικητή Τράπεζας Φίλιππου Ντόκου "},
    { value: "Πρώην κατοικία εμπόρου Νικολάου Κουφάκη"},
    { value: "Πρώην κατοικία Λαντράκη"},
    { value: "Πρώην μονή Αγίου Ιωάννη του Θεολόγου (Μετόχι Πάτμου), εντός του αρχαιολογικού χώρου Απτέρας"},
    { value: "Πρώην Οικία Άγγλου Εμποροπλοιάρχου Σώου"},
    { value: "Πρωτομινωικός Τάφος στα Νέα Ρούματα"},
    { value: "Πύλη Sabbionara"},
    { value: "Πύργος"},
    { value: "Πύργος Αληδάκη"},
    { value: "Πύργος Βουκολιών"},
    { value: "Πύργος Καλλεργών"},
    { value: "Ρέμα Κεδρές"},
    { value: "Ρέμα Φετιφέ"},
    { value: "Ριζίτικα"},
    { value: "Ρόκκα"},
    { value: "Ρολόι Δημοτικού Κήπου"},
    { value: "Ρώσικος Στρατώνας (Κέντρο Αναβίωσης Παραδοσιακών Επαγγελμάτων)"},
    { value: "Σαμαριά"},
    { value: "Σαρακίνικο"},
    { value: "Σάσαλο"},
    { value: "Σεϊτάν (Στεφάνου) Λιμάνι"},
    { value: "Σήραγγα Τοπολίων"},
    { value: "Σηρικάρι Μύλοι στην είσοδο του φαραγγιού"},
    { value: "Σκαλίδης"},
    { value: "Σούγια"},
    { value: "Σπήλαιο Αγίου Ιωάννη Ερημίτη"},
    { value: "Σπήλαιο Ασφένδου, θέση Σκορδαλάκια"},
    { value: "Σπήλαιο  Δρακόλακκοι ή Δρακολάκι"},
    { value: "Σπήλαιο Κουμαρόσπηλιος"},
    { value: "Σπήλαιο Κρυονερίδας"},
    { value: "Σπήλαιο Ξωτικόσπηλιος"},
    { value: "Σπήλαιο Πολύφημου"},
    { value: "Σπήλαιο Τζανή"},
    { value: "Σπήλαιο της του Θεού Σοφίας (Αγία Σοφία)"},
    { value: "Σπήλαιο των Ελεφάντων"},
    { value: "Σταλού"},
    { value: "Σταρβολίμνη ή Στραβολίμνη"},
    { value: "Σταυρός"},
    { value: "Στέρνες Καπαρέ"},
    { value: "Στιβανάδικα/Οδός Σκρίδλωφ"},
    { value: "Στόμιο"},
    { value: "Συγκρότημα Καθολικού, Αρκουδιώτισσα, Ναός Αγίου Ιωάννη Ερημίτου"},
    { value: "Συία"},
    { value: "Συμμαχικό Νεκροταφείο"},
    { value: "Σχολείο Βουτά"},
    { value: "Σχολείο Κουστογέρακου"},
    { value: "Σχολείο Σκλαβοπούλας"},
    { value: "Σχολικά κτίρια Κοραή"},
    { value: "Ταμπακαριά"},
    { value: "Ταμπακαριά (οδού Βιβιλάκη)"},
    { value: "Τάρρα"},
    { value: "Τάφοι Βενιζέλων"},
    { value: "Τερσανά"},
    { value: "Τηγάνι - Λιμνοθάλασσα Μπάλου"},
    { value: "Τοπολιανό"},
    { value: "Τρυπητή"},
    { value: "Υδραγωγείο"},
    { value: "Υπαίθριο αγροτικό ιερό"},
    { value: "Υπόγεια κρήνη Σπλάντζιας"},
    { value: "Υρτακίνα"},
    { value: "Ύψωμα «Άγιος Ελισσαίος»"},
    { value: "Ύψωμα Βάρδιες στον Σταυρό"},
    { value: "Φαλάσσαρνα"},
    { value: "Φαράγγι Αγίας Ειρήνης"},
    { value: "Φαράγγι Άη Γιώργη (Κυκλάμινου)"},
    { value: "Φαράγγι Ανύδρων"},
    { value: "Φαράγγι Αράδαινας"},
    { value: "Φαράγγι Δικτάμου"},
    { value: "Φαράγγι Θερίσου"},
    { value: "Φαράγγι Ίμπρου"},
    { value: "Φαράγγι Κάμπου"},
    { value: "Φαράγγι Κανδάνου"},
    { value: "Φαράγγι Λισσού"},
    { value: "Φαράγγι Μεσαυλίων - Δελιανών"},
    { value: "Φαράγγι Ποταμός"},
    { value: "Φαράγγι Ρόκκας"},
    { value: "Φαράγγι Σαμαριάς"},
    { value: "Φαράγγι Σαρακήνας"},
    { value: "Φαράγγι Σαρακίνας, Μεσκλά"},
    { value: "Φαράγγι Σηρικαρίου"},
    { value: "Φάρος Ακρωτηρίου Δρέπανο"},
    { value: "Φάρος Γαύδου"},
    { value: "Φάρος λιμένα Χανίων"},
    { value: "Φεστιβάλ Κισαμίτικης Κουλτούρας"},
    { value: "Φιλολογικός Σύλλογος “Ο Χρυσόστομος”"},
    { value: "Φοίνικας"},
    { value: "Φράγκικο Νεκροταφείο Χανίων"},
    { value: "Φραγκοκάστελο"},
    { value: "Φράγμα Βαλσαμιώτη στο Βατόλακκο"},
    { value: "Φρούριο Αγίων Θεοδώρων"},
    { value: "Φρούριο Ιτζεδίν"},
    { value: "Φρούριο Κισάμου"},
    { value: "Φρούριο Κούλες"},
    { value: "Φρούριο Κυριακοσελίων"},
    { value: "Φρούριο λιμένος Λουτρού και κουλές Λουτρού"},
    { value: "Φρούριο Μαλάθυρου"},
    { value: "Φρούριο Παλαιοχώρας (Castel Selino)"},
    { value: "Φρούριο Φιρκά"},
    { value: "Φωκόσπηλιο"},
    { value: "Χαμάμ στην οδό Ζαμπελίου"},
    { value: "Χαμάμ στην οδό Κατρέ"},
    { value: "Χαμάμ στην οδό Χάληδων"},
    { value: "Χελώνα Caretta Caretta με φωλιές κατά μήκος της παραλίας του Σταλού"},
    { value: "Χιονότρυπα της Μηλέ"},
    { value: "Χριστιανικές κατακόμβες Στέρνες (Ευαγγελίστρια)"},
    { value: "Χρυσή Ακτή"},
    { value: "Χρυσοσκαλίτισσα – Ελαφονήσι – Κεδρόδασος – Κριός"},
    { value: "Χώρος Πολλαπλών Δραστηριοτήτων - Θέατρο Μίκης Θεοδωράκης"},
    










  
    { value: "1866 Square"},
    { value: "1941 War Memorial in the village square. (1941 Heroes square)"},
    { value: "Afrata Beach"},
    { value: "Agia Ekaterini Rampart"},
    { value: "Agia Irini"},
    { value: "Agia Irini Gorge"},
    { value: "Agia Irini river"},
    { value: "Agia Kiriaki Monastery – Agion Epta Pedon (Holy Seven Sleepers’) Cave Chapel"},
    { value: "Agia Marina Beach"},
    { value: "Agia Triada Monastery (Dependency of Sinai St. Catherine Monastery)"},
    { value: "Agia Zoni Church (Cincture of the Theotokos)"},
    { value: "Agii Pantes Church (All Saints)"},
    { value: "Agioi Apostoli Park"},
    { value: "Agioi Apostoloi"},
    { value: "Agioi Pantes church"},
    { value: "Agioi Pantes Church"},
    { value: "Agioi Theodoroi Fortress"},
    { value: "Agios Dikaios"},
    { value: "Agios Eleftherios Monastery"},
    { value: "Agios Georgios (St. George)"},
    { value: "Agios Ioannis Eleimon (St. John the Merciful)"},
    { value: "Agios Nestor – Agios Demetrius in Katochori"},
    { value: "Agios Onoufrios"},
    { value: "Agios (Saint) Nikolaos (Maza)"},
    { value: "Ahmet Aga Minaret"},
    { value: "Ai Giannis"},
    { value: "Ai Giorgis (Kyklamino) Gorge"},
    { value: "Allied War Cemetery"},
    { value: "Ampelos"},
    { value: "Ancient Aptera"},
    { value: "Ancient cemetery"},
    { value: "Ancient Falassarna"},
    { value: "Ancient Kissamos"},
    { value: "Anidri Gorge"},
    { value: "Anopolis"},
    { value: "Aqua Creta Limnoupolis – Pista Park"},
    { value: "Aqueduct"},
    { value: "Aradena Gorge"},
    { value: "Aradena settlement, designated historical site, Aradena gorge path, designated monument"},
    { value: "Aradin"},
    { value: "Archaeological Museum of Chania"},
    { value: "Archaeological Museum of Kissamos"},
    { value: "Archaeological Site Nopigia-Drapanias-Koleni"},
    { value: "Augustinian Monastery of Santa Maria della Misericordia"},
    { value: "Austrian Consulate during the Cretan State"},
    { value: "Azilakas Forest"},
    { value: "Bank of Greece building"},
    { value: "Bastion of Agios Nikolaos Molos"},
    { value: "Bastion Piatta Forma"},
    { value: "Bastion Santa Lucia"},
    { value: "“Battle of 42nd street” Memorial"},
    { value: "Battle of Crete Memorial"},
    { value: "Beach at the old harbor"},
    { value: "Bikouvari building"},
    { value: "Botanical Park and Gardens of Crete"},
    { value: "Boutsounaria"},
    { value: "Bridge of Koutsos"},
    { value: "Building of Angela Xirouhaki"},
    { value: "Building of Ekaterini Saridaki"},
    { value: "Building of Eleni & Panagiotis Mpolaki"},
    { value: "Building of Emmanuel Papagiannakis"},
    { value: "Building of Georgios Papadopetros"},
    { value: "Building of Konstantinos Katzourakis"},
    { value: "Building of Panagiotis Mouzourakis"},
    { value: "Byzantine & Post-Byzantine Collection of Chania"},
    { value: "Caretta Caretta Sea Turtle and its nests on Stalos beach"},
    { value: "Carnival of Paleochora"},
    { value: "Carved chamber tomb"},
    { value: "Cathedral of the Presentation of the Virgin Mary (or Trimartyri)"},
    { value: "Catholic Church"},
    { value: "Cave of Asfendos, site of Skordalakia"},
    { value: "Cave of God’s Wisdom (Agia Sofia)"},
    { value: "Cave of Saint John the Hermit"},
    { value: "Cave of the elephants"},
    { value: "Center of Mediterranean Architecture (Great Shipyard)"},
    { value: "Chania Catholic Cemetery (French Cemetery)"},
    { value: "Chania harbor lighthouse"},
    { value: "Chania International Airport “Daskalogiannis”"},
    { value: "Chania National Shooting Range"},
    { value: "Chionotrypa of Mile"},
    { value: " Chrissi Akti beach"},
    { value: "Christian Catacombs of Sternes (Evaggelistria)"},
    { value: "Chrysoskalitissa – Elafonisi – Kedrodasos – Krios"},
    { value: "Church of Agii (Saints) Asomati"},
    { value: "Church of Agioi Anargyroi"},
    { value: "Church of Agioi Pateres (the Holy Fathers)"},
    { value: "Church of Agios Eftychios"},
    { value: "Church of Agios Georgios (Saint George) (Kedres)"},
    { value: "Church of Agios Ioannis (Saint John)"},
    { value: "Church of Agios Nikolaos"},
    { value: "Church of Agios Nikolaos (Saint Nicolas)"},
    { value: "Church of Agios Panteleimon"},
    { value: "Church of Agios Rokkos"},
    { value: "Church of Agios (Saint) Ioannis Theologos and Agios (Saint) Nikolaos"},
    { value: "Church of Agios (Saint) Nikolaos (Kyriakoselia)"},
    { value: "Church of Agios Spyridon"},
    { value: "Church of Archangel Michael"},
    { value: "Church of Christ the Savior"},
    { value: "Church of Michael the Archangel (Kavalariana)"},
    { value: "Church of Michael the Archangel (Rotunda)"},
    { value: "Church of Michael the Archangel (Sarakina)"},
    { value: "Church of of Saint Francis"},
    { value: "Church of Panagia in Kadros"},
    { value: "Church of Panagia (Mother of God) (Anisaraki)"},
    { value: "Church of Panagia Myrtidiotissa"},
    { value: "Church of Panagia Skafidiani"},
    { value: "Church of Panagia (Sklavopoula)"},
    { value: "Church of Panagia Zerviotissa (or Monastira)"},
    { value: "Church of Profitis Elias (Prophet Elijah)"},
    { value: "Church of Prophet Elias (Elijah)"},
    { value: "Church of Prophet Elias (Elijah) in Melidoni"},
    { value: "Church of Saint Anna"},
    { value: "Church of Saint Athanasios"},
    { value: "Church of Saint Barbara in Latziana"},
    { value: "Church of Saint Charalambos"},
    { value: "Church of Saint Ekaterini (Splantzia)"},
    { value: "Church of Saint Eleftherios"},
    { value: "Church of Saint George"},
    { value: "Church of Saint George (Alikianos)"},
    { value: "Church of Saint George (Lakki)"},
    { value: "Church of Saint George Methystis"},
    { value: "Church of Saint Georgios Koumpelis"},
    { value: "Church of Saint Irene"},
    { value: "Church of Saint John"},
    { value: "Church of Saint John Theologos"},
    { value: "Church of Saint John the Theologian"},
    { value: "Church of Saint John Xenos"},
    { value: "Church of Saint Kyriaki"},
    { value: "Church of Saint Magdalene"},
    { value: "Church of Saint Mary of Egypt"},
    { value: "Church of Saint Nicholas in Mouri"},
    { value: "Church of Saint Panteleimon"},
    { value: "Church of Saint Paul"},
    { value: "Church of Saints Fotios and Anikitos"},
    { value: "Church of Saint Stefanos"},
    { value: "Church of Saint Vassilios"},
    { value: "Church of Sotiras Christos (Christ the Savior)"},
    { value: "Church of St. Catherine"},
    { value: "Church of the Annunciation (Diocese)"},
    { value: "Church of the Dormition of the Mother of God"},
    { value: "Church of the Dormition of the Mother of God (Alikampos)"},
    { value: "Church of the Dormition of the Mother of God (Kefalas)"},
    { value: "Church of the Nativity of the Virgin"},
    { value: "Church of the Savior Christ"},
    { value: "Church of the Timios Stavros (Holy Cross)"},
    { value: "Church of the Transfiguration of the Savior (Plemeniana)"},
    { value: "Church of the Transfiguration of the Saviour (Temenia)"},
    { value: "Cisterns in Kapare"},
    { value: "Commercial Harbour of Souda"},
    { value: "Crete Naval Base Beach"},
    { value: "Cultural Center of Chania"},
    { value: "Cycling Races of Paleochora"},
    { value: "Defkalionos"},
    { value: "Dependency of Agios Georgios in Charodia"},
    { value: "Despotiko of Chania"},
    { value: "Diktamos Gorge"},
    { value: "Diktynaio Sanctuary"},
    { value: "Dominican Monastery of Saint Nicholas"},
    { value: "Drakolakki or Drakolaki Cave"},
    { value: "Drakona – Psychro Pigadi"},
    { value: "Early Christian basilica of Agia Roumeli"},
    { value: "Early Christian basilica of Almyrida"},
    { value: "Early Christian basilica of Saint Nikitas"},
    { value: "Early Minoan Tomb in Nea Roumata"},
    { value: "East Moat Theatre"},
    { value: "Elafonisi"},
    { value: "Elliniki Kamara"},
    { value: "Elos"},
    { value: "Elyros"},
    { value: "Environmental Education Center (K.P.E.)"},
    { value: "Etz Hayyim Synagogue"},
    { value: "Falassarna"},
    { value: "Farmhouses with olive press facility"},
    { value: "Farmhouse with winemaking installation"},
    { value: "Finikas"},
    { value: "Firkas Fortress "},
    { value: "Fokospilio"},
    { value: "Folklore Museum of Kefalas"},
    { value: "Folklore Museum of Palea Roumata"},
    { value: "Former Agricultural Bank Building"},
    { value: "Former German bunker (tunnels)"},
    { value: "Former monastery of Agios Ioannis Theologos [Saint John the Theologian] (Dependency of Patmos monastery), inside the archaeological site of Aptera"},
    { value: "Former residence of Lantrakis"},
    { value: "Former Residence of Member of Parliament Charalambos Pologiorgis"},
    { value: "Former residence of merchant Nikolaos Koufakis"},
    { value: "Former residence of MP Antonios Katzourakis (Building of Klearchos Markantonakis)"},
    { value: "Former Residence of MP Konstantinos Foumis"},
    { value: "Former residence of Senator Mpistolakis (Building of the Technical Chamber of Greece / TEE, Department of West Crete)"},
    { value: "Former residence of the Bank Manager Filippos Ntokos"},
    { value: "Fortifications"},
    { value: "Fortification Tower (Anidri)"},
    { value: "Fortification Tower (Spaniakos)"},
    { value: "Fortification Tower (Vlithias)"},
    { value: "Fortified wall"},
    { value: "Fort of Paleochora (Castel Selino)"},
    { value: "Fortress of Kyriakoselia"},
    { value: "Fortress of the Loutro port and koules of the Loutro"},
    { value: "Fossilized Sirenian skeleton north of Kotsiana settlement"},
    { value: "Fossils at the small church of Saint Panteleimon"},
    { value: "Fossils east of Saint George in Kedres"},
    { value: "Franciscan Monastery of San Salvatore"},
    { value: "Frangediana Metochion"},
    { value: "Frangokastelo"},
    { value: "French Consulate during Cretan State"},
    { value: "French School"},
    { value: "Gavdopoula"},
    { value: "Gavdos Lighthouse"},
    { value: "Genoese tower"},
    { value: "Georgios Panigyrakis' Building"},
    { value: "German Consulate during the Cretan State"},
    { value: "German Military Cemetery"},
    { value: "Gialiskari"},
    { value: "Glebe of Panagia in Pervolitsa"},
    { value: "Glyka Nera"},
    { value: "Golgothas View point in Skines"},
    { value: "Good Friday Bazaar - Skalidi Street"},
    { value: "Gouverneto – Stavros"},
    { value: "Grammeno"},
    { value: "Grammeno Cedar Forest"},
    { value: "Gramvousa Castle"},
    { value: "Gramvousa port and beach"},
    { value: "Gramvousia-Cretan Wedding"},
    { value: "Greek Consulate during the Cretan State"},
    { value: "Greek-Swedish excavation"},
    { value: "Gre Leska"},
    { value: "Hammam on Halidon Street"},
    { value: "Hammam on Katre Street"},
    { value: "Hammam on Zambeliou Street"},
    { value: "Hatzidakis & Papadonikolakis building"},
    { value: "Heroon"},
    { value: "Historical and Folklore Museum of Gavalochori"},
    { value: "Historical Archives of Crete"},
    { value: "Historical, Folklore & Archaeological Society of Crete (ILAEK)"},
    { value: "Historic trail in Prasses"},
    { value: "House of Fainareti Mitsotaki - British Consulate during the Cretan State"},
    { value: "House of goldsmith Anikitos Garofalakis"},
    { value: "House of Petros Markantonakis (Erietta Hortatzi-Markantonaki)"},
    { value: "House of Valerios Kaloutsis"},
    { value: "Iligas"},
    { value: "Imbros Gorge"},
    { value: "Imeri"},
    { value: "Industry Park – Art Village Verekynthos"},
    { value: "Intzedin Fortress"},
    { value: "Ironwort, marjoram, sage, bulbs, askolymproi, chicory etc."},
    { value: "Islet Marathi"},
    { value: "Italian Barracks"},
    { value: "Judicial – Administrative Palace"},
    { value: "Kadros"},
    { value: "Kalamaki"},
    { value: "Kalami"},
    { value: "Kalathas"},
    { value: "Kalitsounia Festival of Kantanos"},
    { value: "Kaliviani"},
    { value: "Kallergiana"},
    { value: "Kallergi Refuge, Omalos"},
    { value: "Kallikratis"},
    { value: "Kampoi – Volikas Refuge"},
    { value: "Kampos gorge"},
    { value: "Kandanos Gorge"},
    { value: "Kapsodasos"},
    { value: "Kastellos (Byzantine Fortress)"},
    { value: "Kastraki in Pefkakia"},
    { value: "Kastri"},
    { value: "Katholikon complex - Arkoudiotissa - Church of St. John the Hermit (Agiou Ioanni tou Erimiti)"},
    { value: "Katholikon of Gonia Monastery"},
    { value: "Kedrodasos"},
    { value: "Keramia Wineries"},
    { value: "Kissamos carnival"},
    { value: "Kissamos Culture Festival"},
    { value: "Kissamos Fortress"},
    { value: "Kladisos river estuary"},
    { value: "Kolymbari Beach"},
    { value: "Komolithi"},
    { value: "Korfos"},
    { value: "Kouledes of Askifos"},
    { value: "Koules Fortress"},
    { value: "Koules Nio Chorio"},
    { value: "Koules of Kastellos"},
    { value: "Koumarospilios Cave"},
    { value: "Kournas Lake"},
    { value: "Koutelos"},
    { value: "Krios"},
    { value: "Krios Lake"},
    { value: "Kryonerida Cave"},
    { value: "Kydoni-Boriana  Gorge"},
    { value: "Lafonisios Dromos running race"},
    { value: "Lagoufa"},
    { value: "Lake Agia (Natura Site)"},
    { value: "Lakoudi"},
    { value: "Lando (or Agios Dimitrios) Bastion"},
    { value: "Lavrakas"},
    { value: "Lighthouse of Drepano in Akrotiri"},
    { value: "Lime kiln, east of the Lighthouse"},
    { value: "Lime kiln on the Kastri-Ampelos path"},
    { value: "Lisos"},
    { value: "Lissos Gorge"},
    { value: "Loutraki"},
    { value: "Loutro"},
    { value: "Lygenia pigadia (wells) "},
    { value: "Lykos"},
    { value: "Macherida"},
    { value: "Malathyros Fortress"},
    { value: "Maleme Airport"},
    { value: "Maleme Beach"},
    { value: "Mansion of Theodoros Manousakas"},
    { value: "Marathi"},
    { value: "Marathi Archaeological Site"},
    { value: "Maritime Museum of Crete"},
    { value: "Marmara"},
    { value: "Memorial of the Ten Executed"},
    { value: "Memorial to the Executed - Golgotha"},
    { value: "Mesavlia-Deliana gorge"},
    { value: "Milia"},
    { value: "Mills of Sirikari at the gorge's entrance"},
    { value: "Minoan building"},
    { value: "Minoan building complex"},
    { value: "Minoan villa in Nerokouros, Chania"},
    { value: "Mitata – Koumoi at Keramia"},
    { value: "Mocenigo Bastion"},
    { value: "Monastery of Agia Kyriaki"},
    { value: "Monastery of Agia Triada Tzagarolon"},
    { value: "Monastery of Panagia Chrysoskalitissa"},
    { value: "Monastery of Panagia Odigitria Gonia"},
    { value: "Monastery of Panagia Thymiani"},
    { value: "Monastery of Panagia (Tylifos)"},
    { value: "Monastery of Saint John of Giona"},
    { value: "Monastery of Saint Paul"},
    { value: "Monastery of Santa Maria dei Miracolli"},
    { value: "Monastery of St. John the Baptist in Korakies"},
    { value: "Monastery of the Holy Fathers"},
    { value: "Monastery of Zoodochos Pigi"},
    { value: "Monument of Cretan Revolts (former Town Hall of Vamos)"},
    { value: "Monument of the Razing"},
    { value: "Monument of the Transitional Committee (Vryses)"},
    { value: "Monument to fallen residents of Nea Chora"},
    { value: "Mound Vardies in Stavros"},
    { value: "Mouth of the Vlychada river"},
    { value: "Municipal Art Gallery"},
    { value: "Municipal Garden"},
    { value: "Municipal Garden Clock tower"},
    { value: "Municipal Library"},
    { value: "Municipal Market"},
    { value: "Museum - Headquarters of the revolution of Theriso (1905)"},
    { value: "Museum of Cretan Herbs OAC of Kolymbari"},
    { value: "Museum of Typography"},
    { value: "Nea Chora Harbour"},
    { value: "Neoria Moro"},
    { value: "Night of wishes"},
    { value: "Obrosgialos Diving Park"},
    { value: "Old Bishopric"},
    { value: "Old neighbourhood"},
    { value: "Old provincial road of Kandanos - Anavos-Kandanos section"},
    { value: "Olive Museum in Vouves"},
    { value: "Olive & Oil Museum - Dermitzaki Folklore Collection"},
    { value: "Omalos Plateau"},
    { value: "Open-air rural sanctuary"},
    { value: "Orthi Ammos"},
    { value: "Ougia, view point in Ravdoucha"},
    { value: "Our Lady of the Angels Monastery of Gouverneto"},
    { value: "Pachia Ammos"},
    { value: "Paleochora International Chess Tournament "},
    { value: "Panagia Battle Monument"},
    { value: "Panagia of Agia in Episkopi"},
    { value: "Panagia (St. Mary) in Therisos"},
    { value: "Panagia ton Dyo Vrachon  (or Panagia on Charaki)"},
    { value: "Park for the Preservation of Flora and Fauna of the Technical University of Crete"},
    { value: "Park of Agios Gerasimos, Modi"},
    { value: "Parthenagogio (Maiden’s School)"},
    { value: "Passage of Renier Venetian Mansion, Chapel of Renier Family"},
    { value: "Path of the fountain of Daskalos"},
    { value: "Pelekaniotis"},
    { value: "Phoenix"},
    { value: "Platanias Beach"},
    { value: "Platanias Beach - Limanaki"},
    { value: "Polyphemus Cave"},
    { value: "Polyrrinia"},
    { value: "Port of Gavdos"},
    { value: "Possible hominin footprints from the late Miocene (6 million years old) at Trachilas beach in Gramvousa"},
    { value: "Potamos Beach"},
    { value: "Potamos Gorge"},
    { value: "Prasonisia"},
    { value: "Prince George's Palace"},
    { value: "Priuli Rampart"},
    { value: "Pyrgos"},
    { value: "Ravdoucha Beach"},
    { value: "Residence of Kyriakos, son of Konstantinos Mitsotakis"},
    { value: "Residence of the English merchant Soos"},
    { value: "Residence of the merchant Naxakis"},
    { value: "River Moronis estuary"},
    { value: "Rizitika"},
    { value: "Rokka"},
    { value: "Rokka gorge"},
    { value: "Roupakias Forest"},
    { value: "Russian barracks"},
    { value: "Sabbionara Bastion"},
    { value: "Sabbionara Gate"},
    { value: "Samaria"},
    { value: "Samaria Gorge"},
    { value: "Sand lilies on the beaches of Nea Kydonia, Golden Beach to Agia Marina (protected species)"},
    { value: "San Salvatore Bastion"},
    { value: "Sarakina Gorge"},
    { value: "Sarakina Gorge, Meskla"},
    { value: "Sarakina Monastery (Dependency of Megisti Lavra Monastery)"},
    { value: "Sarakiniko"},
    { value: "Sasalo"},
    { value: "School buildings on Korai Street"},
    { value: "School Life Museum"},
    { value: "School of Koustogerako"},
    { value: "School of Sklavopoula"},
    { value: "School of Voutas"},
    { value: "Seitan (Stefanou) Limani"},
    { value: "Shelter, World War II Museum"},
    { value: "Shopping Center"},
    { value: "Sirikari gorge"},
    { value: "Skalidis"},
    { value: "Skeleton of Sirenian fossil"},
    { value: "Souda Islet (Venetian fortress)"},
    { value: "Sougia"},
    { value: "Stalos"},
    { value: "Starvolimni or Stravolimni"},
    { value: "Stavros"},
    { value: "Stivanadika/Scridlof Street"},
    { value: "St. John the Baptist Monastery in Samoli"},
    { value: "Stockbreeders, cheesemakers, tsikoudia distillers"},
    { value: "Stomio"},
    { value: "Stone Theater"},
    { value: "Sundial in Fotakado"},
    { value: "Syia"},
    { value: "Talo Square"},
    { value: "Tampakaria"},
    { value: "Tampakaria (Vivilakis Street)"},
    { value: "Tarra"},
    { value: "Tavronitis Beach"},
    { value: "Tavronitis metal bridge"},
    { value: "Tavronitis river"},
    { value: "Temple of Zoodochos Pigis / Ai Kyr Giannis"},
    { value: "Tersanas "},
    { value: "The Agios (Saint) Georgios church"},
    { value: "The centuries-old olive tree of Mathes"},
    { value: "The centuries-old olive tree of Samonas"},
    { value: "Therisos Gorge"},
    { value: "Therisos Museum of National Resistance"},
    { value: "The stream of Fetifes"},
    { value: "The stream of Kedres"},
    { value: "The whole island"},
    { value: "Tigani - Balos Lagoon"},
    { value: "Tomato Festival"},
    { value: "Topolia gorge"},
    { value: "Topolia Tunnel"},
    { value: "Tower of Alidakis"},
    { value: "Tower of Kallergi"},
    { value: "Tower of Voukolies"},
    { value: "Traditional shoemaker-stivania (traditional Cretan leather boots)"},
    { value: "Transfiguration of Christ Monastery"},
    { value: "Trypiti"},
    { value: "Tzanis Cave"},
    { value: "Underground fountain of Splantzia"},
    { value: "Valsamiotis dam in Vatolakkos"},
    { value: "Varipetro – Therisos Trail"},
    { value: "Vatsiana"},
    { value: "Vaulted tomb in Maleme"},
    { value: "Vaulted tomb of “Azogyre” or “Azoire” in Stylos"},
    { value: "Vaulted tomb of Fylaki"},
    { value: "Vavouledo, Palea Roumata"},
    { value: "Venetian building"},
    { value: "Venetian building (Alcanea Hotel)"},
    { value: "Venetian building (current OFF hotel)"},
    { value: "Venetian building (Fagotto)"},
    { value: "Venetian building (former residence of Stavroulakis)"},
    { value: "Venetian Shipsheds"},
    { value: "Venetian villa with an olive mill"},
    { value: "Venetian Watermill of Kalyves"},
    { value: "Venizelio Conservatory"},
    { value: "Venizelos’ Residence-Museum "},
    { value: "Venizelos Tombs"},
    { value: "Viennos"},
    { value: "Villa Koundouros"},
    { value: "Villa Retonda"},
    { value: "Vlites"},
    { value: "Volikas Refuge"},
    { value: "Vrysi"},
    { value: "Vulture shelter"},
    { value: "War Memorial in Aroni"},
    { value: "Xotikospilios Cave"},
    { value: "Xyloskalo of Samaria"},
    { value: "Yali Tzamisi"},
    { value: "Yrtakina"},
    { value: "Zoodochos Pigi (Chrysopigi) Monastery"},
    { value: "Zourva-Tromarissa path"},
    { value: "Βirth Ηouse of Eleftherios Venizelos, Mournies"},














      { value: "Abri, Musée de la Seconde Guerre mondiale"},
      { value: "Aéroport de Maleme"},
      { value: "Aéroport International de Chania “Daskalogiannis »"},
      { value: "Agia Irini"},
      { value: "Agioi Pantes"},
      { value: "Agion Apostolon"},
      { value: "Agios Dikaios"},
      { value: "Agios Ioannis Eleimonas"},
      { value: "Agios Nestoras – Agios Dimitrios à Katochori Keramion"},
      { value: "Agios Onoufrios"},
      { value: "Aï Giannis"},
      { value: "Ampelos"},
      { value: "Ancien cimetière"},
      { value: "Ancien Episkopeio (résidence de l’évêque)"},
      { value: "Ancien hameau"},
      { value: "Ancien monastère de saint Jean le théologien (Metohi Patmos), dans le site archéologique d'Aptera"},
      { value: "Ancienne Falasarna"},
      { value: "Ancienne Kissamos"},
      { value: "Ancienne résidence du capitaine anglais de la marine marchande Show"},
      { value: "Ancienne résidence du député Antonis Katzourakis (actuellement propriété de Klearchos Markantonakis)"},
      { value: "Ancienne résidence du député Charalampos Pologiorgis"},
      { value: "Ancienne résidence du député Konstantinos Foumis"},
      { value: "Ancienne résidence du directeur de banque Filippos Ntokos"},
      { value: "Ancienne résidence du négociant Nikolaos Koufakis"},
      { value: "Ancienne résidence du sénateur Mpistolakis (Bâtiment TEE, Section de Crète occidentale)"},
      { value: "Ancienne résidence Lantraki"},
      { value: "Ancienne route départementale Kandanos - Section Anavos-Kandanos"},
      { value: "Ancien quartier général allemand (galerie)"},
      { value: "Anopolis"},
      { value: "Aptera Antique"},
      { value: "Aqueduc"},
      { value: "Aradin"},
      { value: "Archives historiques de Crète"},
      { value: "Astratigos / Eglise d’Archangelos Michaïl (Archange Michel)"},
      { value: "Barrage de Valsamiotis à Vatolakkos"},
      { value: "Basilique paléochrétienne d’Agia Roumeli"},
      { value: "Basilique paléochrétienne d’Agios Nikitas"},
      { value: "Basilique paléochrétienne d’Almyrida"},
      { value: "Bastion de Piatta Forma"},
      { value: "Bastion Lando (ou bastion d’Agios Dimitrios)"},
      { value: "Bastion Sabbionara"},
      { value: "Bastion San Salvatore"},
      { value: "Bastion Santa Lucia"},
      { value: "Bâtiment de la Banque de Grèce"},
      { value: "Bâtiment de l’ancienne Banque Agricole (Agrotiki Trapeza)"},
      { value: "Bâtiment des exilés politiques (« maison d’Aris Velouchiotis »)"},
      { value: "Bâtiments scolaires Koraïs"},
      { value: "Bibliothèque municipale"},
      { value: "Bottier traditionnel - stivania"},
      { value: "Boule de neige de Mile"},
      { value: "Boutsounaria"},
      { value: "Cadran solaire de Fotakado"},
      { value: "Carnaval de Kissamos"},
      { value: "Carnaval de Palaiochora"},
      { value: "Caserne russe"},
      { value: "Catacombes chrétiennes Sternes (Evangelistria)"},
      { value: "Cathédrale Eisodion tis Theotokou (Présentation de la Mère de Dieu) (ou Trimartyri)"},
      { value: "Centre commercial"},
      { value: "Centre culturel de Chania"},
      { value: "Centre d’architecture de la Méditerranée (Grand arsenal)"},
      { value: "Centre d’éducation à l’environnement (K.P.E.)"},
      { value: "Centre polyvalent - Théâtre « Mikis Théodorakis »"},
      { value: "Champ national de tir de Chania"},
      { value: "Chryssoskalitissa – Elafonissi – Kedrodassos – Krios"},
      { value: "Cimetière allié"},
      { value: "Cimetière catholique de Chania (cimetière franc)"},
      { value: "Cimetière militaire allemand"},
      { value: "Cinéma municipal « Kipos »"},
      { value: "Collection byzantine et post-byzantine de Chania"},
      { value: "Colline d’Agios Elissaios"},
      { value: "Colline Vardies à Stavros"},
      { value: "Compétitions cyclistes de Palaiochora"},
      { value: "Complexe de bâtiments minoens "},
      { value: "Complexe de Katholikon, Arkoudiotissa, église de Saint Jean l’Ermite"},
      { value: "Conservatoire Venizelio"},
      { value: "Consulat d’Allemagne en Crète Autonome"},
      { value: "Consulat d’Autriche en Crète Autonome"},
      { value: "Consulat de France en Crète Autonome"},
      { value: "Consulat de Grèce en Crète Autonome"},
      { value: "Côte Doré"},
      { value: "Côte Kum-Kapisi"},
      { value: "Cours d’eau d’Agia Irini"},
      { value: "Cours d’eau de Tavronitis"},
      { value: "Couvent de Santa Maria dei Miracolli"},
      { value: "Couvent de Santa Maria della Misericordia des Augustines"},
      { value: "Defkalionos"},
      { value: "Despotiko de Chania"},
      { value: "Drakona – Puits Froid"},
      { value: "École de Koustogerakos"},
      { value: "École de Sklavopoula"},
      { value: "École de Voutas"},
      { value: "École française"},
      { value: "Édifice Angela Xirouchaki"},
      { value: "Édifice Bikouvari"},
      { value: "Edifice du député et sénateur Manoussos Koundouros"},
      { value: "Édifice minoen"},
      { value: "Édifice vénitien"},
      { value: "Édifice vénitien (actuel hôtel OFF)"},
      { value: "Édifice vénitien (ex-résidence Stavroulakis)"},
      { value: "Édifice vénitien (Fagotto)"},
      { value: "Édifice vénitien (hôtel Alcanea)"},
      { value: "Église Agion Pateron (saints Pères)"},
      { value: "Église Agios Nikolaos (Saint-Nicolas)"},
      { value: "Église Agios Nikolaos (Saint-Nicolas) (Kyriakoselia)"},
      { value: "Église Agios Nikolaos (Saint-Nicolas) (Maza)"},
      { value: "Eglise Catholique"},
      { value: "Église d’Agia Aikaterini"},
      { value: "Église d’Agia Aikaterini (Splantzia)"},
      { value: "Église d’Agia Anna"},
      { value: "Église d’Agia Irini"},
      { value: "Église d’Agia Kyriaki"},
      { value: "Église d’Agia Magdalini (Sainte-Madeleine)"},
      { value: "Église d’Agia Triada (Métropole de Sainte Catherine de Sina)"},
      { value: "Église d’Agia Varvara de Latziana"},
      { value: "Église d’Agia Zoni (Sainte Ceinture)"},
      { value: "Église d’Agioi Anargyroi"},
      { value: "Église d’Agios Athanasios"},
      { value: "Église d’Agios Charalampos"},
      { value: "Église d’Agios Eftychios"},
      { value: "Église d’Agios Eleftherios"},
      { value: "Église d’Agios Fotios et Anikitos"},
      { value: "Église d’Agios Frangiskos (saint François)"},
      { value: "Église d’Agios Georgios Koumpelis"},
      { value: "Église d’Agios Georgios (Saint-Georges) (Alikianos)"},
      { value: "Église d’Agios Georgios (Saint-Georges) (Kedrès)"},
      { value: "Église d’Agios Georgios (Saint-Georges) (Lakkoi)"},
      { value: "Église d’Agios Ioannis"},
      { value: "Église d’Agios Ioannis Théologos"},
      { value: "Église d’Agios Ioannis Théologos et d’Agios Nikolaos"},
      { value: "Église d’Agios Nikolaos à Mouri"},
      { value: "Église d’Agios Pantéléimon"},
      { value: "Église d’Agios Pavlos"},
      { value: "Église d’Agios Rokkos"},
      { value: "Église d’Agios Spyridon"},
      { value: "Église d’Agios Stefanos"},
      { value: "Église d’Agios Vasilios"},
      { value: "Église d’Aï Giorgis Methystis"},
      { value: "Église de l’Annonciation de la Mère de Dieu (Evangelismou) (Métropole)"},
      { value: "Église de la Toussaint"},
      { value: "Église de la Toussaint (Agion Panton)"},
      { value: "Église de la Transfiguration du Sauveur (Metamorfosi)"},
      { value: "Église de la Transfiguration du Sauveur (Metamorfosi) (Temenia)"},
      { value: "Église de Michail Archangelos (Archange Michel) (Kavalariana)"},
      { value: "Église de Michaïl Archangelos (Archange Michel) (Sarakina)"},
      { value: "Église de Michaïl Archangelos (Rotonde)"},
      { value: "Église de Panagia à Kadros"},
      { value: "Église de Panagia Myrtidiotissa"},
      { value: "Église de Panagia Skafidiani"},
      { value: "Église de Panagia ton Dyo Vrachon (Notre Dame des deux rochers) (ou Panagia sto Charaki)"},
      { value: "Église de Panagia Zerviotissa (ou Monastira)"},
      { value: "Église de Profitis Ilias (Prophète Élie)"},
      { value: "Église de Profitis Ilias (Prophète Élie) à Mélidoni"},
      { value: "Église de Prophète Elias"},
      { value: "Église de Sainte Marie l’Égyptienne"},
      { value: "Église des Saints-Asomatoi (Agioi Asomatoi)"},
      { value: "Église d’Osios Ioannis Xenos"},
      { value: "Église du Christ Sauveur (Sotira Christou)"},
      { value: "Église Gennesiou tis Theotokou (Naissance de la Mère de Dieu)"},
      { value: "Église Koimiseos tis Theotokou (de la Dormition)"},
      { value: "Église Koimiseos tis Theotokou (de la Dormition) (Alikampos)"},
      { value: "Église Koimiseos tis Theotokou (de la Dormition) (Kefalas)"},
      { value: "Église Panagias (Anissaraki)"},
      { value: "Église Panagias (Sklavopoula)"},
      { value: "Église Timiou Stavrou (Sainte-Croix)"},
      { value: "Église Zoodochou Pigis (Source de Vie) / Aï Kyr-Giannis"},
      { value: "Elafonissi"},
      { value: "Éleveurs, fromagers, fabricants de « coppers » de tsikoudias"},
      { value: "Elliniki Kamara (L’arc grec)"},
      { value: "Elos"},
      { value: "Elyros"},
      { value: "Embouchures de la rivière Moroni"},
      { value: "Empreintes de pas susceptibles de provenir d’hominidés du Miocène (6 millions d’années) sur la plage de Trachilas, à Gramvousa"},
      { value: "Enceinte fortifiée"},
      { value: "Estuaire du cours d’eau de Kladissos"},
      { value: "Estuaire du fleuve Vlychadas"},
      { value: "Falassarna"},
      { value: "Ferme avec installation de pressoir"},
      { value: "Fermes avec moulin à huile"},
      { value: "Festival de la Culture de Kissamos"},
      { value: "Fête de la tomate"},
      { value: "Fête du kalitsouni - Kantanos"},
      { value: "Finikas"},
      { value: "Fokospilio"},
      { value: "Fontaine souterraine de Splantzia"},
      { value: "Forêt d’Azilakas"},
      { value: "Forêt de Roupakias (Orpakia)"},
      { value: "Fort de Firkas"},
      { value: "Fort de Gramvousa"},
      { value: "Fort de Kissamos"},
      { value: "Fort de Malathyros"},
      { value: "Fort de Palaiochora (Castel Selino)"},
      { value: "Forteresse d'Agioi Theodoroi"},
      { value: "Forteresse de Koules"},
      { value: "Forteresse de Kyriakoselia"},
      { value: "Forteresse Itzedin"},
      { value: "Fortifications"},
      { value: "Fort sur le port de Loutro et koulès de Loutro"},
      { value: "Fossiles à la chapelle d’Agios Pantéléimon"},
      { value: "Fossiles à l’est d’Agios Georgios de Kedrès"},
      { value: "Fouilles au «Sud des Remparts»"},
      { value: "Fouilles gréco-suédoises"},
      { value: "Fouilles « Lionaki-Vlamaki »"},
      { value: "Fouilles « Papadopoulos »"},
      { value: "Fouilles «Vakalounakis»"},
      { value: "Four à chaux, à l’est du Phare"},
      { value: "Four à chaux sur le sentier Kastri-Ampelos"},
      { value: "Frangokastelo"},
      { value: "Gavdopoula"},
      { value: "Gialiskari"},
      { value: "Giali Tzami"},
      { value: "Glyka Nera"},
      { value: "Golgothas, Point de vue à Skinès"},
      { value: "Gorge d’Agia Irini"},
      { value: "Gorge d’Anydroi"},
      { value: "Gorge d’Aradaina"},
      { value: "Gorge de Kampos"},
      { value: "Gorge de Kantanos"},
      { value: "Gorge de Lissos"},
      { value: "Gorge de Mesavlia - Deliana"},
      { value: "Gorge de Rokka"},
      { value: "Gorge de Sarakina"},
      { value: "Gorge de Sarakina, Meskla"},
      { value: "Gorge de Sirikari"},
      { value: "Gorge de Therisou"},
      { value: "Gorge d’Imbros"},
      { value: "Gorge Potamos"},
      { value: "Gorges de Diktamou"},
      { value: "Gorges de Samaria"},
      { value: "Gorges du Saint Georges (Cyclaminou)"},
      { value: "Gouverneto – Stavros"},
      { value: "Gouvernorat de la Crète Autonome (« ancienne Division »)"},
      { value: "Grammeno"},
      { value: "Grammeno Forêt de Genévriers"},
      { value: "Gramvousia - Mariage crétois"},
      { value: "Grè de Leska"},
      { value: "Grotte d’Agios Ioannis Erimitis (saint Jean l’ermite) "},
      { value: "Grotte d’Asfendos, lieu-dit Skordalakia"},
      { value: "Grotte de Drakolakkoi ou Drakolaki"},
      { value: "Grotte de Koumarospilios"},
      { value: "Grotte de Kryonerida"},
      { value: "Grotte de la Sagesse de Dieu (Agia Sofia)"},
      { value: "Grotte des Éléphants (Spilaio ton elefanton)"},
      { value: "Grotte de Tzani"},
      { value: "Grotte de Xotikospilios"},
      { value: "Hameau d’Aradena, site historique classé, Sentier de la gorge d’Aradena, monument classé"},
      { value: "Hammam rue Katrè"},
      { value: "Ilingas"},
      { value: "Ilôt de Souda (forteresse vénitienne)"},
      { value: "Îlot Marathi"},
      { value: "Imeri"},
      { value: "Institut national pour la recherche et les études “Eleftherios Venizélos”"},
      { value: "Iroon (Monument aux héros)"},
      { value: "Kadros"},
      { value: "Kalamaki"},
      { value: "Kalami"},
      { value: "Kalatha"},
      { value: "Kallergiana"},
      { value: "Kallikratis"},
      { value: "Kalyviani"},
      { value: "Kamara tou Koutsou"},
      { value: "Kamoi – Refuge de Volika"},
      { value: "Kapsodasos"},
      { value: "Kastellos (forteresse Byzantine)"},
      { value: "Kastri"},
      { value: "Kedrodasos (Forêt de Genévriers)"},
      { value: "Komolithoi"},
      { value: "Korfos"},
      { value: "Koulès d’Askyfos"},
      { value: "Koulès de Castellos"},
      { value: "Koulès Nio Chorio"},
      { value: "Koutelos"},
      { value: "Krios"},
      { value: "Kydoni - Borianon - Karanou"},
      { value: "La caserne italienne"},
      { value: "Lac d’Agias (Zone Natura)"},
      { value: "Lac de Kournas"},
      { value: "Lac de Krios"},
      { value: "Lafonisios Dromos (Course de Lafonisos)"},
      { value: "La galerie voûtée (diavatiko) du palais vénitien Renier, la chapelle de la famille Renier"},
      { value: "La Grotte de Polyphimos (Polyphème)"},
      { value: "Lakoudi"},
      { value: "Langoufa"},
      { value: "La nuit des vœux (Nichta ton efchon)"},
      { value: "Lavrakas"},
      { value: "Le bastion d’Agios Nikolaos de Molos"},
      { value: "Le bastion de Mocenigo"},
      { value: "Le catholicon du Monastère de Gonia"},
      { value: "Le château Callergi"},
      { value: "L’église d’Agios Georgios (Saint-Georges)"},
      { value: "Le hammam de la rue Chalidon"},
      { value: "Le hammam rue Zampeliou"},
      { value: "Le Jardin municipal"},
      { value: "Le marché (pazari) de Vendredi Saint - rue Skalidi"},
      { value: "Le minaret Ahmet aga"},
      { value: "Le monastère d’Agios Pavlos (Saint-Paul)"},
      { value: "Le Monument au Comité du nouveau régime politique (Vrysses)"},
      { value: "Le palais du prince Georgios"},
      { value: "Le Phare au port de Chania"},
      { value: "Le Phare de Gavdos"},
      { value: "Le Phare du cap Drepano"},
      { value: "Les fouilles « Mathioudakis »"},
      { value: "Les tombes des Venizélos"},
      { value: "Le « surbastion » d’Agia Aikaterini"},
      { value: "L’horloge du Jardin municipal"},
      { value: "Lieu - Siège de la révolution Therissou (1905)"},
      { value: "Lissos"},
      { value: "Loutraki"},
      { value: "Loutro"},
      { value: "Lygenia pigadia (puits)"},
      { value: "Lykos"},
      { value: "Lys aux plages de Nea Kidonia, de la plage d’Or jusqu’à Agia Marina (espèce protégée)"},
      { value: "Machairida"},
      { value: "Malotira, marjolaine, sauge, Bulbes, Myrtilles, Radis, etc."},
      { value: "Manoir minoen à Nerokourou, Chania"},
      { value: "Marathi"},
      { value: "Marché municipal"},
      { value: "Marmara"},
      { value: "Métairie de Fraggediana"},
      { value: "Métropole de Saint Georges Charodias"},
      { value: "Milia"},
      { value: "Mitata – Koumoi à Kerameia"},
      { value: "Monastère Agion Pateron"},
      { value: "Monastère d'Agios Eleftherios"},
      { value: "Monastère d’Agios Ioannis de Giona"},
      { value: "Monastère d'Agios Ioannis le Prodrome à Samoli"},
      { value: "Monastère d’Agios Nikolaos des dominicains"},
      { value: "Monastère d’Agiou Panteleimona"},
      { value: "Monastère de la Dame des Anges Gouvernetou"},
      { value: "Monastère de Panagia à Pervolitsa"},
      { value: "Monastère de Panagia Chrysoskalitissa"},
      { value: "Monastère de Panagia Odigitria (Mère de Dieu qui guide) de Gonia"},
      { value: "Monastère de Panagia Thymiani"},
      { value: "Monastère de San Salvatore des Franciscains"},
      { value: "Monastère de Timios Saint Prodromos Korakion"},
      { value: "Monastère de Zoodochou Pigi (Chrysopigi)"},
      { value: "Monastère Panagias (Mère de Dieu) (Tylifos)"},
      { value: "Monastère Zoodochou Pigis (de la Source de vie)"},
      { value: "Monument aux exécutés de 1941 à la place du village. (Place des héros 1941)"},
      { value: "Monument aux morts à Aroni"},
      { value: "Monument aux morts de Nea Chora"},
      { value: "Monument aux révoltes crétoises (ancienne Mairie)"},
      { value: "Monument de dix exécutés"},
      { value: "Monument de la bataille de Crète"},
      { value: "Monument de la «Bataille de la 42ème rue»"},
      { value: "Monument d’exécutés – Calvaire"},
      { value: "Monument du Massacre"},
      { value: "Moulin à eau vénitien de Kalyves"},
      { value: "Musée archéologique de Chania"},
      { value: "Musée archéologique de Kissamos"},
      { value: "Musée d’art populaire de Kefalas"},
      { value: "Musée de la flore et de la végétation crétoises OAK de Kolymvari"},
      { value: "Musée de la résistance nationale"},
      { value: "Musée de la typographie"},
      { value: "Musée de la vie scolaire"},
      { value: "Musée de l'olive et de l'huile d'olive - Collection folklorique Dermitzakis"},
      { value: "Musée de l'olivier de Vouvès"},
      { value: "Musée des Acrites d’Europe"},
      { value: "Musée d’histoire et d’art populaire de Gavalochori"},
      { value: "Musée folklorique de Palaia Roumata"},
      { value: "Musée naval de Crète"},
      { value: "Neoria (bassins de carénage)"},
      { value: "Néoria (bassins de carénage) de Moro"},
      { value: "Olivier séculaire de Mathès"},
      { value: "Olivier séculaire de Samonas"},
      { value: "Orthi Ammos"},
      { value: "Ougia, point de vue à Ravdoucha"},
      { value: "Pachia Ammos"},
      { value: "Palais de justice"},
      { value: "Panagia à Theriso"},
      { value: "Panagia dans le diocèse d'Agia"},
      { value: "Panagia Monument de Bataille"},
      { value: "Parc botanique et Jardins de Crète"},
      { value: "Parc d’Agios Gerasimos, Modi"},
      { value: "Parc de plongée sous-marine d’Omprosgialos"},
      { value: "Parc de sauvetage de la flore et de la faune de l'Université technique de Crète"},
      { value: "Parc des Saints Apôtres"},
      { value: "Parc industriel– Village Artistique Verekinthos"},
      { value: "Parthenagogeio"},
      { value: "Pelekaniotis"},
      { value: "Petit fort à Pefkakia"},
      { value: "Petit port de Nea Chora"},
      { value: "Pinacothèque municipale"},
      { value: "Place Talo"},
      { value: "Plage d’Afrata"},
      { value: "Plage d’Agia Marina"},
      { value: "Plage de Kolymvari"},
      { value: "Plage de la gare maritime de Crète"},
      { value: "Plage de Màleme"},
      { value: "Plage de Platanias"},
      { value: "Plage de Platanias - Limanaki (petit port)"},
      { value: "Plage de Ravdoucha"},
      { value: "Plage de Tavronitis"},
      { value: "Plage Potamos"},
      { value: "Plage sur le vieux port"},
      { value: "Plateau d’Omalos"},
      { value: "Plateia 1866"},
      { value: "Polyrrinia"},
      { value: "Pont métallique de Tavronitis"},
      { value: "Port Commercial de Souda"},
      { value: "Port de Gavdos"},
      { value: "Port de Seitan (Stefanou)"},
      { value: "Porte Sabbionara"},
      { value: "Port et plage de Gramvoussa"},
      { value: "Prasonissia"},
      { value: "Propriété Eleni & Panagiotis Mpolakis"},
      { value: "Pyrgos"},
      { value: "Refuge de Volika"},
      { value: "Refuge du gypaète"},
      { value: "Refuge Kallergis, Omalos"},
      { value: "Rema (cours d’eau) Fetifè"},
      { value: "Rema (cours d’eau) Kedrès"},
      { value: "Résidence Aikaterini Saridaki"},
      { value: "Résidence de Kyriakos Konstantinos Mitsotakis"},
      { value: "Résidence de l’orfèvre Anikitos Garofalakis"},
      { value: "Résidence de naissance d’Eleftherios Venizélos, Mourniès"},
      { value: "Résidence de Petros Markantonakis (Henriette Chortatzi-Markantonaki)"},
      { value: "Résidence du négociant Naxakis"},
      { value: "Résidence Emmanouil Papagiannakis"},
      { value: "Résidence Fainareti Mitsotakis - Consulat britannique en Crète Autonome"},
      { value: "Résidence Georgios Panigyrakis"},
      { value: "Résidence Georgios Papadopetrou"},
      { value: "Résidence Konstaninos Katzourakis"},
      { value: "Résidence Panagiotis Mouzourakis"},
      { value: "Résidences Chatzidaki & Papadonikolaki"},
      { value: "Résidence Théodoros Manoussakas"},
      { value: "Résidence Valerios Kaloutsis"},
      { value: "Rizitika"},
      { value: "Rokka"},
      { value: "Saint Georges"},
      { value: "Saint monastère d'Agia Kyriaki"},
      { value: "Saint Monastère d'Agia Kyriaki - Chapelle des Saints de Sept Enfants"},
      { value: "Saint Monastère d’Agia Triada de Tzagarolon"},
      { value: "Saint Monastère d’Archange Michael"},
      { value: "Saint Monastère de la Transfiguration du Sauveur"},
      { value: "Saint monastère de Sarakina (Métropole du monastère de Megistis Lavras)"},
      { value: "Samaria"},
      { value: "Sanctuaire de Diktynna"},
      { value: "Sanctuaire rural en plein air"},
      { value: "Sarakiniko"},
      { value: "Sassalo"},
      { value: "Sentier Daskalou Vryssi"},
      { value: "Sentier historique de Prassés"},
      { value: "Sentier Varipetro – Theriso"},
      { value: "Sentier Zourva-Tromarissa"},
      { value: "Sirikari Moulins à l’entrée de la gorge"},
      { value: "Site archéologique « Azoïrè » ou « Azogirè » de Stylos"},
      { value: "Site archéologique de Marathi"},
      { value: "Site archéologique de Nopigia-Drapania-Kolèni"},
      { value: "Skalidis"},
      { value: "Société historique, folklorique et archéologique de Crète (ILAEK)"},
      { value: "Société philologique « O Chrysostomos »"},
      { value: "Sougia"},
      { value: "Squelette fossile de sirénien"},
      { value: "Squelette fossile de sirénien, au nord du hameau de Kotsiana"},
      { value: "Stalos"},
      { value: "Starvolimni ou Stravolimni"},
      { value: "Stavros"},
      { value: "Sternes Kaparè (les citernes de Kaparè)"},
      { value: "Stivanadika"},
      { value: "Stomio"},
      { value: "Surbastion de Priuli"},
      { value: "Syia"},
      { value: "Synagogue Etz Hayyim"},
      { value: "Tabakaria"},
      { value: "Tabakaria (rue Vivilaki)"},
      { value: "Tarra"},
      { value: "Tersana"},
      { value: "Théâtre de pierre"},
      { value: "Théâtre « Dimitris Vlissidis »"},
      { value: "Théâtre du Fossé oriental"},
      { value: "Tigani - Lagune de Balos"},
      { value: "Tombe à voûte « Azogyrè » ou « Azoïrè » de Stylos"},
      { value: "Tombe à voûte de Fylaki"},
      { value: "Tombe à voûte de Maleme"},
      { value: "Tombe en forme de chambre, taillée dans la roche"},
      { value: "Tombe protominoenne à Nea Roumata"},
      { value: "Topoliano"},
      { value: "Tortue Caretta Caretta avec des nids tout au long de la plage Stalou"},
      { value: "Tour Alidaki"},
      { value: "Tour de Voukoliès"},
      { value: "Tour forte (Anydroi)"},
      { value: "Tour forte (Spaniakos)"},
      { value: "Tour forte (Vlithias)"},
      { value: "Tour génoise"},
      { value: "Tournoi international d’échecs de Palaiochora"},
      { value: "Toute l’île"},
      { value: "Trypiti"},
      { value: "Tunnel de Topolia"},
      { value: "Vatsiana"},
      { value: "Vavouledo - Anciens Roumata"},
      { value: "Viennos"},
      { value: "Vignobles à Keramia"},
      { value: "Villa Retonda"},
      { value: "Villa vénitienne avec moulin à huile d’olive"},
      { value: "Vlites"},
      { value: "Vryssi"},
      { value: "Xyloskalo de Samaria"},
      { value: "Yrtakina"},
  















        { value: "Agia Irini"},
        { value: "Agia Irini Schlucht"},
        { value: "Agioi Apostoloi"},
        { value: "Agioi Pantes"},
        { value: "Agios Dikaios"},
        { value: "Agios Georgios"},
        { value: "Agios Gerasimos Park, Modi"},
        { value: "Agios Ioannis Eleimonas"},
        { value: "Agios Nestoras –Agios Dimitrios in Katochori – Kerameia"},
        { value: "Agios Onoufrios"},
        { value: "Ai Giannis"},
        { value: "Alliierter Soldatenfriedhof"},
        { value: "Alte Landstraße von Kantanos - Teil Anavos-Kantanos"},
        { value: "Alte Nachbarschaft"},
        { value: "Ampelos"},
        { value: "Anopolis"},
        { value: "Antike Falassarna"},
        { value: "Antike Kissamos"},
        { value: "Antiker Friedhofen"},
        { value: "Antikes Aptera"},
        { value: "Anydroi Schlucht"},
        { value: "Aquädukt"},
        { value: "Aradena-Siedlung, ausgewiesene historische Stätte, Pfad der Schlucht Aradena, ausgewiesenes Denkmal"},
        { value: "Aradin"},
        { value: "Archälogische Stätte von Marathi"},
        { value: "Archäologisches Museum von Kissamos"},
        { value: "Archäologische Stätte von „Azogyre“ oder „Azoire“ in Stylos"},
        { value: "Archäologische Stätte von Nopigeia-Drapania-Koleni"},
        { value: "Asfendos-Höhle, Stelle Skordalakia"},
        { value: "„Astratigos“/Kirche Archangelou Michail (Erzengel Michail)"},
        { value: "Augustinenkloster von Santa Maria della Misericordia"},
        { value: "Ausgrabung „Lionakis-Vlamakis“"},
        { value: "Ausgrabung „Mathioudaki“"},
        { value: "Ausgrabung „Papadopoulos“"},
        { value: "Ausläufer des Flusses Moroni"},
        { value: "Ausstellungsort - Hauptquartier des Aufstandes in Theriso (1905)"},
        { value: "Azilakas-Wald"},
        { value: "Bach Fetife"},
        { value: "Bach Kedres"},
        { value: "Bastion des Agios Nikolaos tou Molou (des Piers)"},
        { value: "Bastion Piatta Forma"},
        { value: "Bastion San Salvatore"},
        { value: "Bastion Santa Luccia"},
        { value: "Bauernhaus mit Weinkelter"},
        { value: "Berghütte Kallergi, Omalos"},
        { value: "Berghütte Volika"},
        { value: "Berg Stavros"},
        { value: "Botanischer Park und Gärten von Kreta"},
        { value: "Boutsounaria"},
        { value: "Buchdruckmuseum"},
        { value: "Bunker, Museum des 2.Weltkriegs"},
        { value: "Burg Alidakis"},
        { value: "Burg von Gramvousa"},
        { value: "Burg von Kyriakoselia"},
        { value: "Byzantinische&Postbyzantinische Sammlung von Chania"},
        { value: "Christliche Katakomben Sternes (Evangelistria)"},
        { value: "Chrysi Akti"},
        { value: "Chrysoskalitissa – Elafonisi – Kedrodasos – Krios"},
        { value: "Das alte Episkopat"},
        { value: "Das archäologische Museum von Chania"},
        { value: "Das Hauptquartier des kretischen Staates (alte Division)"},
        { value: "Das Maritime Museum von Kreta"},
        { value: "Das Mediterrane Architekturzentrum (Großes Arsenal)"},
        { value: "Defkalionos"},
        { value: "Denkmal der Gefallenen"},
        { value: "Denkmal der gefallenen Neohoriten"},
        { value: "Denkmal der Hingerichteten 1941 am Hauptplatz des Dorfes (Platia Iroon 1941)"},
        { value: "Denkmal der Hingerichteten – Golgotha"},
        { value: "Denkmal der kretischen Revolutionen (ehemaliges Rathaus von Vamos)"},
        { value: "Denkmal der „Schlacht der 42. Straße“"},
        { value: "Denkmal der Schlacht um Kreta"},
        { value: "Denkmal der zehn Hingerichteten"},
        { value: "Denkmal des Postregierungskomitees (Brunnen)"},
        { value: "Denkmal „Machi tis Panagias“"},
        { value: "Der katholische Friedhof (Fränkischer Friedhof)"},
        { value: "Der Städtische Markt"},
        { value: "Der Turm in Voukolia"},
        { value: "Der Turm von Kallergi"},
        { value: "Despotiko von Chania"},
        { value: "Deutscher Soldatenfriedhof in Maleme"},
        { value: "Deutsches Konsulat während des kretischen Staates"},
        { value: "Die Burg von Kissamos"},
        { value: "Die Burg von Malathyros"},
        { value: "Die Burg von Paleochora (Castel Selino)"},
        { value: "Die Höhle der Elefanten"},
        { value: "Die Kirche des Propheten Elias in Melidoni"},
        { value: "Die Nacht der Wünsche"},
        { value: "Die Stadtbibliothek"},
        { value: "Diktynnaio Heiligtum"},
        { value: "Drakona – Psychro Pigadi"},
        { value: "Ehemalige Residenz des Abgeordneten Antonis Katzourakis (Gebäude im Besitz des Klearhos Markantonakis)"},
        { value: "Ehemalige Residenz des Abgeordneten Bistolakis (Gebäude der Technischen Kammer Griechenlands, Filiale von West-Kreta)"},
        { value: "Ehemalige Residenz des Abgeordneten Konstantinos Foumis"},
        { value: "Ehemalige Residenz des Bankdirektors Filippos Dokos"},
        { value: "Ehemalige Residenz Lantrakis"},
        { value: "Ehemaliger Wohnsitz des Abgeordneten Charalambos Pologiorgis"},
        { value: "Ehemaliger Wohnsitz des Kaufmanns Nikolaos Koufakis"},
        { value: "Ehemaliges deutsches Hauptquartier (Galerie)"},
        { value: "Ehemaliges Kloster Agios Ioannis des Theologen (Klostergut Patmos), innerhalb der archäologischen Stätte Apteras"},
        { value: "Einkaufszentrum"},
        { value: "Elafonisi"},
        { value: "Elos"},
        { value: "Elyros"},
        { value: "Falasarna"},
        { value: "Felsinsel von Souda (Venezianische Festung)"},
        { value: "Festival der Kissamos-Kultur"},
        { value: "Festung des Hafens von Loutro und Koules von Loutro"},
        { value: "Festungen"},
        { value: "Festung Firka"},
        { value: "Festung Itzedin"},
        { value: "Festung Koules"},
        { value: "Festungsmauer"},
        { value: "Festung von Agioi Theodoroi"},
        { value: "Festung von Koules"},
        { value: "Finikas"},
        { value: "Flughafen Maleme"},
        { value: "Fluss Agia Irini"},
        { value: "Fluss Tavronitis"},
        { value: "Fokospilio"},
        { value: "Fossiles  Sirenenskelett"},
        { value: "Fossilien in der Kapelle von Agios Panteleimonas"},
        { value: "Fossilien östlich von Agios Georgios Kedre"},
        { value: "Fragediana Metochi"},
        { value: "Frangokastelo"},
        { value: "Französische Schule"},
        { value: "Französisches Konsulat während des kretischen Staates"},
        { value: "Frühchristliche Basilika von Agia Roumeli"},
        { value: "Frühchristliche Basilika von Agios Nikitas"},
        { value: "Frühchristliche Basilika von Almyrida"},
        { value: "Frühminoisches Grab in Nea Roumata"},
        { value: "Fußabdrücke, die möglicherweise von Menschen aus dem Miozän stammen (6 Millionen Jahre alt) am Strand von Trachilos Gramvousa"},
        { value: "Gavdopoula"},
        { value: "Gebäude Bikouvari"},
        { value: "Gebäude der Angela Xirouhaki"},
        { value: "Gebäude der Bank von Griechenland"},
        { value: "Gebäude der ehemaligen Landwirtschaftsbank von Griechenland"},
        { value: "Gebäude des Abgeordneten und Senators Manoussos Koundouros"},
        { value: "Gebaude des Georgios Papadopetros"},
        { value: "Gebäude des Konstantinos Katzourakis"},
        { value: "Gebäude des Panagiotis Mouzourakis"},
        { value: "Gebäude Emm.Papagiannakis"},
        { value: "Gebäude Georgios Panigyrakis"},
        { value: "Gebäude Hatzidaki&Papadonikolaki"},
        { value: "Gebäude im Besitz von Bolaki Eleni&Panagiotis"},
        { value: "Gebäude von Ekaterini Saridaki"},
        { value: "Geistliches Zentrum  von Chania"},
        { value: "Genuesischer Turm"},
        { value: "Geschichte-Volkskundemuseum von Gavalochori"},
        { value: "Geschnitztes Kammergrab"},
        { value: "Gesellschaft für Geschichte, Folklore und Archäologie Kretas (ILAEK)"},
        { value: "Gewerbepark – Kunsthandwerksdorf Verekynthos"},
        { value: "Gewolbtes Grab in Maleme"},
        { value: "Gewölbtes Grab von „Azogyre“ oder „Azoire“ in Stylos"},
        { value: "Gewölbtes Grab von Fylaki"},
        { value: "Gialiskari"},
        { value: "Glyka Nera"},
        { value: "Golgothas, Aussichtspunkt bei Skines"},
        { value: "Gouverneto - Stavros"},
        { value: "Gräber der Venizelos Familie"},
        { value: "Grammeno"},
        { value: "Grammeno Zedernwald"},
        { value: "Gramvousia-Kretische Hochzeit"},
        { value: "Gre Leska"},
        { value: "Griechischer Bogen (Kamara)"},
        { value: "Griechisches Konsulat während des kretischen Staates"},
        { value: "Griechisch-schwedische Ausgrabung"},
        { value: "Hafen und Strände von Gramvousa"},
        { value: "Hafen von Gavdos"},
        { value: "Hafen von Nea Chora"},
        { value: "Hamam in der Katre Straße"},
        { value: "Hamam in der Zambeliou Straße"},
        { value: "Hammam in der Halidon Straße"},
        { value: "Handelshafen Souda"},
        { value: "Haus der Geburt des Eleftherios Venizelos, Mournies"},
        { value: "Haus des Goldschmieds Anikitos Garofalakis"},
        { value: "Haus des Kaufmanns Naxakis"},
        { value: "Haus des Petros Markantonakis (Erietta Hortatzi-Markantonaki)"},
        { value: "Haus von Fenareti Mitsotaki-Britisches Konsulat während des kretischen Staates"},
        { value: "Haus von Valerios Kaloutsis"},
        { value: "Hebrew Synagoge Etz Hayyim"},
        { value: "Heiliges Kloster Agia Kyriaki"},
        { value: "Heiliges Kloster Agia Triada (Klostergut von Agia Ekaterini Sina)"},
        { value: "Heiliges Kloster Metamorphosis tou Sotiros"},
        { value: "Heiliges Kloster Sarakina (Klostergut des Klosters Megisti Lavra)"},
        { value: "Heiliges Kloster Timiou Prodromou von Korakies"},
        { value: "Heroon"},
        { value: "Historischer Pfad Prasses"},
        { value: "Historisches Archiv von Kreta"},
        { value: "Hochebene von Omalos"},
        { value: "Hohle des Heiligen Ioannis Erimitis (Einsiedler)"},
        { value: "Höhle Drakolakkoi oder Drakolaki"},
        { value: "Höhle  „Tis tou Theou Sofias“ (Agia Sofia)"},
        { value: "Höhle von Polyphem"},
        { value: "Höhle Xotikospilias (Elfenhöhle)"},
        { value: "Holocaust Denkmal"},
        { value: "Hügel Agios Elisseos"},
        { value: "Ilingas"},
        { value: "Imeri"},
        { value: "Inselchen Marathi"},
        { value: "Inselkomplex"},
        { value: "Internationaler Flughafen Chania „Daskalogiannis“"},
        { value: "Internationales Schachturnier Paleochora"},
        { value: "Italienische Kaserne"},
        { value: "Justizverwaltungsgebäude"},
        { value: "Kadros"},
        { value: "Kalamaki"},
        { value: "Kalami"},
        { value: "Kalatha"},
        { value: "Kalitsouni Festival Kantanos"},
        { value: "Kalkofen auf dem Weg Kastri-Ampelos"},
        { value: "Kalkofen, östlich des Leuchtturms"},
        { value: "Kallergiana"},
        { value: "Kallikratis"},
        { value: "Kalyviani"},
        { value: "Kamara tou Koutsou (Bogen des Lahmen)"},
        { value: "Kambi – Berghütte Volika"},
        { value: "Kampos Schlucht"},
        { value: "Kandanos Schlucht"},
        { value: "Kapsodasos"},
        { value: "Karfreitagsbasar - Skalidi-Straße"},
        { value: "Karneval von Kissamos"},
        { value: "Kastellos (Byzantinische Festung)"},
        { value: "Kastraki (kleine Burg) in Pefkakia"},
        { value: "Kastri"},
        { value: "Kathedrale der Darstellung der Jungfrau Maria (oder Trimartyri)"},
        { value: "Katholikon des Klosters Gonia"},
        { value: "Katholische Kirche"},
        { value: "Kavalier Priuli"},
        { value: "Kavalier von Agia Ekaterini"},
        { value: "Kedrodasos"},
        { value: "Kirche Agia Ekaterini (Splantzia)"},
        { value: "Kirche Agia Irini"},
        { value: "Kirche Agia Varvara in Latziana"},
        { value: "Kirche Agia Zoni"},
        { value: "Kirche Agioi Anargyroi"},
        { value: "Kirche Agioi Asomatoi"},
        { value: "Kirche Agioi Pantes"},
        { value: "Kirche Agios Athanassios"},
        { value: "Kirche Agios Charalambos"},
        { value: "Kirche Agios Eftychios"},
        { value: "Kirche Agios Eleftherios"},
        { value: "Kirche Agios Fotios und Anikitos (Unbesiegte)"},
        { value: "Kirche Agios Georgios"},
        { value: "Kirche Agios Georgios (Alikianos)"},
        { value: "Kirche Agios Georgios (Kedres)"},
        { value: "Kirche Agios Georgios (Lakkoi)"},
        { value: "Kirche Agios Georgios Methystis"},
        { value: "Kirche Agios Ioannis"},
        { value: "Kirche Agios Ioannis Theologos"},
        { value: "Kirche Agios Ioannis Theologos und Agios Nikolaos"},
        { value: "Kirche Agios Nikolaos"},
        { value: "Kirche Agios Nikolaos in Mouri"},
        { value: "Kirche Agios Nikolaos (Kyriakoselia)"},
        { value: "Kirche Agios Nikolaos (Maza)"},
        { value: "Kirche Agios Panteleimon"},
        { value: "Kirche Agios Panteleimonas"},
        { value: "Kirche Agios Pavlos"},
        { value: "Kirche Agios Roccos"},
        { value: "Kirche Agios Spyridon"},
        { value: "Kirche Agios Stefanos"},
        { value: "Kirche Agios Vassilios"},
        { value: "Kirche Archangelos Michail (Erzengel Michail) (Sarakina)"},
        { value: "Kirche der Agia Anna"},
        { value: "Kirche der Agia Ekaterini"},
        { value: "Kirche der Agia Irini"},
        { value: "Kirche der Agia Kyriaki"},
        { value: "Kirche der Agia Magdalini"},
        { value: "Kirche der Geburt der Jungfrau Maria"},
        { value: "Kirche der Heiligen Maria von Ägypten"},
        { value: "Kirche der Heiligen Väter"},
        { value: "Kirche der Panagia (Jungfrau) in Kadros"},
        { value: "Kirche der Panagia (Jungfrau) (Sklavopoula)"},
        { value: "Kirche der Panagia (Jungfrau) Zerviotissa (oder Monastira)"},
        { value: "Kirche der Panagia Myrtidiotissa"},
        { value: "Kirche des Agios Georgios Koubelis"},
        { value: "Kirche des Agios Ilias"},
        { value: "Kirche des Agios Ioannis Theologos"},
        { value: "Kirche des Agios Nikolaos"},
        { value: "Kirche des Agios Panteleimon"},
        { value: "Kirche des Sotiras Christos"},
        { value: "Kirche Evangelismos tis Theotokou (Kathedrale) (Verkündigung der Jungfrau)"},
        { value: "Kirche Kimiseos tis Theotokou (Himmelfahrt der Jungfrau Maria) (Alikampos)"},
        { value: "Kirche Metamorfosis tou Sotira (Temenia)"},
        { value: "Kirche Metamorfosis tou Sotira (Verklärung des Erlösers) (Plemeniana)"},
        { value: "Kirche Michail Archagelos (Rotunde)"},
        { value: "Kirche Michail Archangelos"},
        { value: "Kirche Michail Archangelos (Erzengel Michail)  (Kavalariana)"},
        { value: "Kirche Osios(Heiliger) Ioannis o Xenos"},
        { value: "Kirche Panagia (Jungfrau) (Anisaraki)"},
        { value: "Kirche Panagia Skafidiani"},
        { value: "Kirche Panagia ton dyo Vrachon (der Jungfrau der zwei Felsen) (oder Panagia in Haraki)"},
        { value: "Kirche Profitis Ilias"},
        { value: "Kirche Sotiras Christos  (Christus Erlöser)"},
        { value: "Kirche Zoodochou Pigis/Ai-Kyr Giannis"},
        { value: "Kloster Agia Kyriaki – Höhlenkapelle Agion Epta Paidon (Sieben Heilige)"},
        { value: "Kloster Agia Triada ton Tsagarolon"},
        { value: "Kloster Agios Eleftherios"},
        { value: "Kloster Agios Ioannis Giona"},
        { value: "Kloster Agios Ioannis Prodromos im Gebiet Samoli"},
        { value: "Kloster Agios Pavlos"},
        { value: "Kloster der Santa Maria die Miracolli"},
        { value: "Kloster des Agios Nikolaos der Dominikanermönche"},
        { value: "Kloster des Heiligen Franziskus der Franziskaner"},
        { value: "Kloster Gouverneto – „die Königin der Engel“"},
        { value: "Klostergut Agios Georgios Charodias"},
        { value: "Klosterkomplex Katholikon, Arkoudiotissa, Kirche des Heiligen Johannes des Einsiedlers"},
        { value: "Kloster Panagia Chrysoskalitissa"},
        { value: "Kloster Panagia Odigitria in Gonia"},
        { value: "Kloster Panagia Thymiani"},
        { value: "Kloster Panagia (Tylifos)"},
        { value: "Kloster San Salvatore der Franziskaner"},
        { value: "Kloster Zoodochou Pigis"},
        { value: "Kloster Zoodochou Pigis (Chrysopigi)"},
        { value: "Komolithoi"},
        { value: "Korfos"},
        { value: "Kouledes (Burgen) von Askifou"},
        { value: "Koules Nio Chorio"},
        { value: "Koumarospilios-Höhle"},
        { value: "Kournas-See"},
        { value: "Koutelos"},
        { value: "Krios"},
        { value: "Krios See"},
        { value: "Kryonerida-Höhle"},
        { value: "Kydoni - Mporiana - Karanos"},
        { value: "Lafonissi- Straßenrennen"},
        { value: "Lagoufa"},
        { value: "Lakoudi"},
        { value: "Ländliches Heiligtum im Freien"},
        { value: "Lando (oder Agios Dimitrios) Bastion"},
        { value: "Lavrakas"},
        { value: "Leuchtturm des Hafens Chania"},
        { value: "Leuchtturm von Gavdos"},
        { value: "Leuchtturm von Kap Drepano"},
        { value: "Lissos"},
        { value: "Lissos Schlucht"},
        { value: "Loutraki"},
        { value: "Loutro"},
        { value: "Lygenia Brunnen"},
        { value: "Lykos"},
        { value: "Machairida"},
        { value: "Mädchenschule"},
        { value: "Maleme Strand"},
        { value: "Marathi"},
        { value: "Marmara"},
        { value: "Mesavlia - Deliana Schlucht"},
        { value: "Metallbrücke Tavronitis"},
        { value: "Milia"},
        { value: "Minarett Ahmed Aga"},
        { value: "Minoisches Gebäude"},
        { value: "Minoisches Gebäudekomplex"},
        { value: "Minoische Villa in Nerokourou Chania"},
        { value: "Mitata – Koumi – in Kerameia"},
        { value: "Mocenigo Bastion"},
        { value: "Moro Werfte"},
        { value: "Mündung des Flusses Kladisos"},
        { value: "Mündung des Flusses Vlychada"},
        { value: "Museum der Akriten von Europa"},
        { value: "Museum der kretischen Kräuter Orthodoxe Akademie von Kreta"},
        { value: "Museum des Nationalen Widerstandes Theriso"},
        { value: "Museum für Schulleben"},
        { value: "Nationale Forschungsstiftung „Eleftherios Venizelos“"},
        { value: "Naturpark für Flora und Fauna des Polytechnikums von Kreta"},
        { value: "Naturreservat Bartgeier"},
        { value: "Olivenmuseum in Vouves"},
        { value: "Oliven- und Ölmuseum - Dermitzaki Folklore-Sammlung"},
        { value: "Orthi Ammos"},
        { value: "Österreichisches Konsulat während des kretischen Staates"},
        { value: "Ougia, Aussichtspunkt bei Ravdoucha"},
        { value: "Pachia Ammos"},
        { value: "Palast von Prinz Georgios"},
        { value: "Paleochora Karneval"},
        { value: "Panagia in Theriso"},
        { value: "Panagia Pervolitsa"},
        { value: "Panagia stin Episkopi Agias"},
        { value: "Park Agioi Apostoloi"},
        { value: "Pelekanoitis"},
        { value: "Pfad Daskalou Vryssi"},
        { value: "Pfad Zourva-Tromarissa"},
        { value: "Philologische Vereinigung „Chrysostomos“"},
        { value: "Platz 1866"},
        { value: "Platz Talo"},
        { value: "Polyrrinia"},
        { value: "Prasonisia"},
        { value: "Pyrgos"},
        { value: "Radrennen Paleochora"},
        { value: "Residenz des englischen Handelsmarine-Kapitäns Soos"},
        { value: "Rizitika-Lieder"},
        { value: "Rokka"},
        { value: "Rokka Schlucht"},
        { value: "Roupakias Wald (Orpakia)"},
        { value: "Russische Kaserne"},
        { value: "Sabbionara Bastion"},
        { value: "Samaria"},
        { value: "Sarakina Schlucht"},
        { value: "Sarakina Schlucht, Meskla"},
        { value: "Sarakiniko"},
        { value: "Sasalo"},
        { value: "Schießstand KABANI Akrotiri"},
        { value: "Schildkröte Caretta Caretta mit Nestern entlang der Küste von Stalos"},
        { value: "Schlucht Ai Giorgi (Kyklamino)"},
        { value: "Schlucht Diktamo"},
        { value: "Schlucht Potamos"},
        { value: "Schlucht von Aradena"},
        { value: "Schlucht von Imbros"},
        { value: "Schlucht von Samaria"},
        { value: "Schlucht von Theriso"},
        { value: "Schneeloch von Mile"},
        { value: "Schule Koutsogerako"},
        { value: "Schule Sklavopoula"},
        { value: "Schule Vouta"},
        { value: "Schulgebäude Korais"},
        { value: "See von Agia (Gebiet Natura)"},
        { value: "Seitan (Stefanou) Limania"},
        { value: "Sirikari Mühlen am Eingang der Schlucht"},
        { value: "Sirikari Schlucht"},
        { value: "Skalidis"},
        { value: "Sonnenuhr Fotakado"},
        { value: "Sougia"},
        { value: "Stadtgarten von Chania"},
        { value: "Stadtische Kunstgalerie"},
        { value: "Städtisches Kino „Kipos“ (Garten)"},
        { value: "Stalos"},
        { value: "Starvolimni oder Stravolimni"},
        { value: "Stavros"},
        { value: "Steintheater"},
        { value: "Stivanadika"},
        { value: "Stockwerkerweiterung (Diavatiko) des venezianischen Herrenhauses Renier, Kapelle der Familie Renier"},
        { value: "Stomio"},
        { value: "Strand am alten Hafen"},
        { value: "Strand der Marinewerft Kretas"},
        { value: "Strand in Afrata"},
        { value: "Strand in Kolymvari"},
        { value: "Strand in Platanias"},
        { value: "Strand in Platanias - Hafen"},
        { value: "Strand in Ravdoucha"},
        { value: "Strand „Koum Kapi“"},
        { value: "Strandlilien an der Küste von Nea Kydonia, an den Stränden Chrisi Akti bis Agia Marina ( unter Naturschutz stehende Art)"},
        { value: "Strand Potamos"},
        { value: "Strand von Agia Marina"},
        { value: "Syia"},
        { value: "Syrisches Gliedkraut, Majoran, Salbei, Spanische Golddistel, Löwenzahn usw."},
        { value: "Tabakaria"},
        { value: "Tabakaria (in Vivilaki Strasse)"},
        { value: "Tarra"},
        { value: "Tauchpark Obrosgialos"},
        { value: "Tavronitis Strand"},
        { value: "Tersana"},
        { value: "Theater „Dimitris Vlisidis“"},
        { value: "Tigani-Lagune Balos"},
        { value: "Tomatenfest"},
        { value: "Topoliano"},
        { value: "Tor Sabbionara"},
        { value: "Traditioneller Schuhmacher-Stivania"},
        { value: "Trypiti"},
        { value: "Tzani Höhle"},
        { value: "Uhr des Stadtgartens"},
        { value: "Umwelterziehungszentrum"},
        { value: "Unterirdische Brunnen von Splantzia"},
        { value: "Uralter Olivenbaum Mathes"},
        { value: "Uralter Olivenbaum von Samonas"},
        { value: "Valsamioti-Damm in Vatolakkos"},
        { value: "Varipetro – Theriso"},
        { value: "Vatsiana"},
        { value: "Vavouledo - Palea Roumata"},
        { value: "Venezianisches Gebäude"},
        { value: "Venezianisches Gebäude (ehemalige Residenz Stavroulakis)"},
        { value: "Venezianisches Gebäude (Fagotto)"},
        { value: "Venezianisches Gebäude (heute Hotel OFF)"},
        { value: "Venezianisches Gebäude (Hotel Alcanea)"},
        { value: "Venezianische Villa mit Olivenmühle"},
        { value: "Venezianische Wassermühle in Kalyves"},
        { value: "Venizelio-Konservatorium"},
        { value: "Versteinerter Sirenenskelett im Norden der Siedlung Kotsiana"},
        { value: "Viehzüchter, Käser, Schnapsbrenner"},
        { value: "Viennos"},
        { value: "Villa Retonda"},
        { value: "Vlites"},
        { value: "Volkskundemuseum von Kefalas"},
        { value: "Volkskundemuseum von Palea Roumata"},
        { value: "Vryssi"},
        { value: "Wehrturm (Anydroi)"},
        { value: "Wehrturm (Spaniakos)"},
        { value: "Wehrturm (Vlithias)"},
        { value: "Weingute in Kerameia"},
        { value: "Werfte"},
        { value: "Xyloskalo in Samaria"},
        { value: "Yali Jamisi"},
        { value: "Yrtakina"},
        { value: "Zentrum für vielfältige Aktivitäten - Theater „Mikis Theodorakis“"},
        { value: "Zisternen Kapare"},
        { value: "Αusgrabung “südlich der Mauern”"},
        { value: "Αusgrabung “Vakalunaki”"},
        { value: "Ηaus des Kyriakos Mitsotakis, Sohn des Konstantinos"},
        { value: "Ηerrenhaus Theodoros Manousakas"},
        { value: "Κirche Kimiseos tis Theotokou (Kirche Mariä Himmelfahrt)"},
        { value: "Κirche Kimiseos tis Theotokou (Kirche Mariä Himmelfahrt) (Kefalas)"},
        { value: "Κirche Sotira Christou (des Erlösers Christus)"},
        { value: "Κirche Timiou Stavrou (Heiligen Kreuzes)"},
        { value: "Κloster der Heiligen Väter"},
        { value: "Τheater des östlichen Grabens"},
        { value: "Τopolia Tunnel"},
        













    { value: "Αрадин"},
    { value: "Βатсиана"},
    { value: "Κастри"},
    { value: "Августинский Монастырь Санта Мария делла Мизерикордиа"},
    { value: "Австрийское консульство в Критском государстве"},
    { value: "Агиа Ирина"},
    { value: "Агии Апостоли"},
    { value: "Агиос Дикеос"},
    { value: "Агиос Онуфриос"},
    { value: "Администрация Критского государства («Старая дивизия»)"},
    { value: "Ай Гианнис"},
    { value: "Акведук"},
    { value: "Ампелос"},
    { value: "Анополис"},
    { value: "Ансамбль кафоликона, Аркудиотиссы, собора Св. Иоанна Пустынника (Агиос Иоаннис Эримитис)"},
    { value: "Арочный мост Хромого («Кутсу»)"},
    { value: "Археологический музей Кисамоса"},
    { value: "Археологический музей Ханьи"},
    { value: "Археологический объект «Азоире», или «Азогире» в Стилосе"},
    { value: "Археологический объект в Марафи"},
    { value: "Археологический объект Нопигиа-Драпаниа-Колени"},
    { value: "«Астратигос»/Собор Архангела Михаила"},
    { value: "Аэродром Малеме"},
    { value: "Бастион Ландо (или Святого Димитрия)"},
    { value: "Бастион Мочениго"},
    { value: "Бастион Пьятта Форма"},
    { value: "Бастион Саббьонара"},
    { value: "Бастион Сан Сальваторе"},
    { value: "Бастион Санта Лючия"},
    { value: "Бастион Святого Николая у мола"},
    { value: "Башня Алидаки"},
    { value: "Башня Вуколиес"},
    { value: "Башня Каллергидов"},
    { value: "Бивуак в Воликасе"},
    { value: "Бивуак им. Каллергиса, Омалос"},
    { value: "Биотехнический парк - Ремесленная деревня Верекинфос"},
    { value: "Ботанический парк и Сады Крита"},
    { value: "Бутсунариа"},
    { value: "Бывший дом депутата Антониоса Катзуракиса (здание, принадлежащее Клеархосу Маркантонакису)"},
    { value: "Бывший дом депутата Константиноса Фумиса"},
    { value: "Бывший дом депутата Хараламбоса Пологиоргиса"},
    { value: "Бывший дом Директора Банка Филип Докоса"},
    { value: "Бывший дом купца Николаоса Куфакиса"},
    { value: "Бывший дом Лантракиса"},
    { value: "Бывший дом сенатора Бистолакиса (нынешнее здание Технической Палаты Греции, Отделение Западного Крита)"},
    { value: "Бывший монастырь Св. Иоанна Богослова (Патмосское Подворье) на территории археологического места Аптера"},
    { value: "Бывший немецкий штаб (галерея)"},
    { value: "Вавуледо - Старые Румата"},
    { value: "Вековая олива в Мафес"},
    { value: "Вековая олива в Самонасе "},
    { value: "Велогонки в Палеохоре"},
    { value: "Венецианская вилла с давильней для оливкового масла"},
    { value: "Венецианская водяная мельница в Каливес"},
    { value: "Венецианское здание"},
    { value: "Венецианское здание («Fagotto»)"},
    { value: "Венецианское здание (бывший дом Ставрулакиса)"},
    { value: "Венецианское здание (гостиница «Alcanea»)"},
    { value: "Венецианское здание (ныне гостиница «OFF»)"},
    { value: "Верфи Моро"},
    { value: "Верфи (Неория)"},
    { value: "Весь остров"},
    { value: "Виеннос"},
    { value: "Византийская и Поствизантийская коллекция Ханьи"},
    { value: "Вила Ретонда"},
    { value: "Винодельни в Керамиа"},
    { value: "Влитес"},
    { value: "Военно-морской музей Крита"},
    { value: "Возвышенность Вардиес в Ставросе"},
    { value: "Ворота Саббьонара"},
    { value: "Вриси"},
    { value: "Гавань в Неа Хора"},
    { value: "Гавдопула"},
    { value: "Галерея венецианского дворца Ренье, часовня семьи Ренье"},
    { value: "Генуэзская башня (Генуатикос)"},
    { value: "Героон"},
    { value: "Гиалискари"},
    { value: "Глика нера"},
    { value: "Голгофа, панорамный вид Скинес"},
    { value: "Грамвусиа – Критская свадьба"},
    { value: "Граммено"},
    { value: "Граммено Кедродасос"},
    { value: "Гре Ласка"},
    { value: "Греческое консульство в Критском государстве"},
    { value: "Греческо-шведские раскопки"},
    { value: "Гувернето – Ставрос"},
    { value: "Дворец Принца Георга"},
    { value: "Дворец Теодороса Манусакаса"},
    { value: "Девкалион"},
    { value: "Диктиннейский храм"},
    { value: "Дом английского судоторговца Шоу"},
    { value: "Дом Валериоса Калутсиса"},
    { value: "Дом, в котором родился Элефтериос Венизелос, Мурниес"},
    { value: "Дом Георгиоса Панигиракиса"},
    { value: "Доминиканский монастырь Св. Николая"},
    { value: "Дом Кирьякоса Константиновича Митсотакиса"},
    { value: "Дом купца Наксакиса"},
    { value: "Дом Петроса Маркантонакиса (Генриетты Хортатзи-Маркантонаки)"},
    { value: "Дом Фенарети Митсотаки – Британское консульство в Критском государстве"},
    { value: "Дом Хатзидакиса и Папаниколакиса"},
    { value: "Дом ювелира Аникитоса Гарофилакиса"},
    { value: "Дракона - Психро Пигади (Ледяной Источник)"},
    { value: "Древнее кладбище"},
    { value: "Древний Кисамос"},
    { value: "Древняя Аптера"},
    { value: "Древняя Фаласарна"},
    { value: "Духовный центр Ханьи"},
    { value: "Еврейская синагога Эдж Хагим"},
    { value: "Железница сирийская, майоран, шалфей, дикий чеснок, золотой чертополох, цикорий и т.д."},
    { value: "Животноводы, сыровары, производители тсикудьи"},
    { value: "Заповедник ястребов-бородачей"},
    { value: "Здание Банка Греции"},
    { value: "Здание Бикувариса"},
    { value: "Здание бывшего Сельскохозяйственного Банка"},
    { value: "Здание Георгиоса Пападопетроса"},
    { value: "Здание депутата и сенатора Манусоса Кундуроса"},
    { value: "Здание Екатерины Саридаки"},
    { value: "Здание Константиноса Катзуракиса"},
    { value: "Здание Панагиотиса Музуракиса"},
    { value: "Здание политических ссыльных («дом Ариса Велухиотиса»)"},
    { value: "Здание Элени и Панагиотиса Болаки"},
    { value: "Здание Эммануила Папагианнакиса"},
    { value: "Здние Ангелы Ксирухаки"},
    { value: "Известковая печь, к востоку от Маяка"},
    { value: "Известковая печь на тропе Кастри - Ампелос"},
    { value: "Илингас"},
    { value: "Имери"},
    { value: "Иртакина"},
    { value: "Историко-фольклорный музей в Гавалохори"},
    { value: "Историческая тропа в Прасес"},
    { value: "Исторический архив Крита"},
    { value: "Историческое, фольклорное и археологическое общество Крита (ИЛАЕК)"},
    { value: "Источники Лигениа"},
    { value: "Итальянская казарма"},
    { value: "Кадрос"},
    { value: "Каламаки"},
    { value: "Калами"},
    { value: "Калафас"},
    { value: "Каливиани"},
    { value: "Каллергиана"},
    { value: "Калликратис"},
    { value: "Каменная камерная гробница"},
    { value: "Каменный театр"},
    { value: "Кампи – Бивуак в Воликасе"},
    { value: "Капсодасос"},
    { value: "Карнавал в Палеохоре"},
    { value: "Кастеллос (Византийский форт)"},
    { value: "Кастраки в Певкакиа"},
    { value: "Католическая церковь"},
    { value: "Католическое кладбище Ханьи (Франкское кладбище)"},
    { value: "Кафедральный собор Введения во храм Богородицы (или Троемученицы)"},
    { value: "Кедродасос"},
    { value: "Кидони - Бориана - Каранос"},
    { value: "Кисамитский карнавал"},
    { value: "Комолифи"},
    { value: "Консерватория «Венизелио»"},
    { value: "Корфос"},
    { value: "Крепость Грамвусы"},
    { value: "Крепость Фирка"},
    { value: "Криос"},
    { value: "Ксилоскало в Самарье"},
    { value: "Кувшинки на пляжах Неа Кидониа, Хриси Акти вплоть до Агиа Марина (охраняемый вид)"},
    { value: "Кулес Нио Хорио"},
    { value: "Кулесы в Аскифосе"},
    { value: "Кутелос"},
    { value: "Лавракас"},
    { value: "Лагуфа"},
    { value: "Лакуди"},
    { value: "Лафонисийский кросс"},
    { value: "Лес Азилакас"},
    { value: "Лес Рупакиа (Орпакиа)"},
    { value: "Ликос"},
    { value: "Лисос"},
    { value: "Лутраки"},
    { value: "Лутро"},
    { value: "Марафи"},
    { value: "Мармара"},
    { value: "Махеридиа"},
    { value: "Маяк в порту Ханьи"},
    { value: "Маяк Гавдоса"},
    { value: "Маяк на мысе Дрепано"},
    { value: "Международный шахматный турнир в Палеохоре"},
    { value: "Мельницы Сирикари у входа в ущелье"},
    { value: "Мемориал Павшим 1941 г. на деревенской площади (Площадь Героев 1941 г.)"},
    { value: "Мемориал Павшим в Арони"},
    { value: "Мемориал павшим жителям Неа Хора"},
    { value: "Металический мост Тавронитис"},
    { value: "Мечеть Гьяли"},
    { value: "Милиа"},
    { value: "Минарет Ахмет Ага"},
    { value: "Минойская вилла в Нерокурос Ханьи"},
    { value: "Минойский комплекс зданий"},
    { value: "Минойское жилище"},
    { value: "Митата (пастушьи сараи) – Куми в Керамиа"},
    { value: "Митрополичья палата Ханьи"},
    { value: "Многофункциональный центр - Театр «Микис Теодоракис»"},
    { value: "Могилы Венизелосов"},
    { value: "Монастырь Богордицы Фимиани"},
    { value: "Монастырь Богородицы Златолестничной (Панагия Хрисоскалитисса)"},
    { value: "Монастырь Богородицы Одигитрии Гониа"},
    { value: "Монастырь Богородицы (Панагии) (Тилифос)"},
    { value: "Монастырь Живоносного Источника (Зоодохос Пиги)"},
    { value: "Монастырь Живоносного Источника (Хрисопиги)"},
    { value: "Монастырь Иоанна Предтечи в местечке Самоли"},
    { value: "Монастырь Пресвятой Богородицы  Госпожи Ангелов в Гувернето"},
    { value: "Монастырь Санта Мария деи Мираколли"},
    { value: "Монастырь Саракина (подворье Великой Лавры)"},
    { value: "Монастырь Св. Троицы (Подворье Монастыря Св. Екатерины на Синае)"},
    { value: "Монастырь Святого Иоанна в Гионасе"},
    { value: "Монастырь Святого Иоанна Милостивого (Агиос Иоаннис Элеимон)"},
    { value: "Монастырь Святого Павла"},
    { value: "Монастырь Святого Элевтерия"},
    { value: "Монастырь Святой Кириакии"},
    { value: "Монастырь Святой Кириакии - Пещерная часовня Семи Святых Чад"},
    { value: "Монастырь Святой Троицы Тзагароло"},
    { value: "Монастырь Святых Отцов"},
    { value: "Монастырь Честного Предтечи в Коракиес"},
    { value: "Мост «Греческая камара»"},
    { value: "Музей Акритов Европы"},
    { value: "Музей критских трав Православной Академии Крита в Колимвари"},
    { value: "Музей Национального Сопротивления в Ферисо"},
    { value: "Музей оливы в Вувес"},
    { value: "Музей оливы и масла – фольклорная коллекция Дермитзакиса"},
    { value: "Музей типографии"},
    { value: "Музей школьной жизни"},
    { value: "Муниципальная художественная галерея"},
    { value: "Муниципальный кинотеатр «Кипос»"},
    { value: "Муниципальный рынок"},
    { value: "Муниципальный сад"},
    { value: "Набережная «Кум Капи»"},
    { value: "Национальное стрельбище Ханьи"},
    { value: "Национальный аэропорт Ханьи «Даскалогианнис»"},
    { value: "Национальный исследовательский фонд «Элефтериос Венизелос»"},
    { value: "Немецкое военное кладбище"},
    { value: "Немецкое консульство в Критском государстве"},
    { value: "Ночь желаний"},
    { value: "Оборонительная башня (Анидри)"},
    { value: "Оборонительная башня (Влифиа)"},
    { value: "Оборонительная башня (Спаниакос)"},
    { value: "Оборонительные стены"},
    { value: "Озеро Агии (территория Natura)"},
    { value: "Озеро Криос"},
    { value: "Озеро Курна "},
    { value: "Окаменелости в часовне Св. Пантелеймона"},
    { value: "Окаменелости, найденные к востоку от часовни Св. Георгия в Кедрес"},
    { value: "Окаменелый скелет Сирении"},
    { value: "Окаменелый скелет сирины на севере поселения Котсиана"},
    { value: "Орфи Аммос"},
    { value: "Островок Марафи"},
    { value: "Островок Суда (Венецианский форт)"},
    { value: "Отпечатки стоп, вероятно принадлежавших гоминиду периода миоцена (6 млн. лет назад) на побережье Трахила Грамвусы"},
    { value: "Палиа гитониа"},
    { value: "Памятник десяти казнённым"},
    { value: "Памятник казнённым - Голгофа"},
    { value: "Памятник Критским революциям (бывшая мэрия Вамоса)"},
    { value: "Памятник Критскому сражению"},
    { value: "Памятник «Сражению на 42-ой улице»"},
    { value: "Памятник сражению при Панагии"},
    { value: "Памятник Холокосту"},
    { value: "Панагиа в Эпископи Агиа Кидониа"},
    { value: "Парк Агии Апостоли"},
    { value: "Парк дайвинга Оспрогиалос"},
    { value: "Парк Святого Герасима в Моди"},
    { value: "Парк Сохранения флоры и фауны Политехнического института Крита"},
    { value: "Пахиа Аммос"},
    { value: "Пахья Аммос"},
    { value: "Пелеканиотис"},
    { value: "Пещера Асфендос, местечко Скордалакиа"},
    { value: "Пещера Бога Софии (Агиа Софиа)"},
    { value: "Пещера Драколакки, или Драколаки"},
    { value: "Пещера Крионериды"},
    { value: "Пещера Ксотикоспилиос"},
    { value: "Пещера Кумароспилиос"},
    { value: "Пещера Полифема"},
    { value: "Пещера Святого Иоанна Отшельника (Агиос Иоаннис Эримитис) "},
    { value: "Пещера Тзани "},
    { value: "Пещера Тюленей (Фокоспилио)"},
    { value: "Пещера Элефантон"},
    { value: "Пиргос"},
    { value: "Плато Омалос"},
    { value: "Плотина Валсамиоти в Ватолакко"},
    { value: "Площадь 1866 г."},
    { value: "Площадь Тало"},
    { value: "Пляж Агиа Марина"},
    { value: "Пляж Афрата"},
    { value: "Пляж в Равдуха"},
    { value: "Пляж в старом порту"},
    { value: "Пляж Колимвари"},
    { value: "Пляж Малеме"},
    { value: "Пляж морской станции Крита"},
    { value: "Пляж Платаниас"},
    { value: "Пляж Платаниас - Лиманаки"},
    { value: "Пляж Потамос"},
    { value: "Пляж Тавронитис"},
    { value: "Подворье монастыря Св. Георгия в Хародье"},
    { value: "Подворье Пресвятой Богородицы в Перволитса"},
    { value: "Подземный фонтан для омовения Сплантзья"},
    { value: "Полирриниа"},
    { value: "Порт Гавдоса"},
    { value: "Порт и побережье Грамвусы"},
    { value: "Поселение Арадена, характерное историческое поселение, тропа ущелья Арадена, характерный памятник"},
    { value: "Праздник пирожков калитсуни в Кантаносе"},
    { value: "Праздник томатов"},
    { value: "Прасонисиа"},
    { value: "Публичная библиотека"},
    { value: "Равелин Приули"},
    { value: "Равелин Святой Екатерины"},
    { value: "Раннеминойская гробница в Неа Румата"},
    { value: "Раннехристианская базилика Алмириды"},
    { value: "Раннехристианская базилика в Агиа Румели"},
    { value: "Раннехристианская базилика Святого Никиты"},
    { value: "Раскопки «Вакалунаки»"},
    { value: "Раскопки «К югу от стены»"},
    { value: "Раскопки «Лионаки-Вламаки»"},
    { value: "Раскопки «Матиудаки»"},
    { value: "Раскопки «Пападопулоса»"},
    { value: "Резервуары Капаре"},
    { value: "Река Агиа Ирини"},
    { value: "Река Кедрес"},
    { value: "Река Тавронитис"},
    { value: "Река Фетифе"},
    { value: "Ризитика"},
    { value: "Рокка"},
    { value: "Русские казармы"},
    { value: "Самарья"},
    { value: "Саракинико"},
    { value: "Сасало"},
    { value: "Сводчатая гробница «Азогире» или «Азоире» в местечке Стилос"},
    { value: "Сводчатая гробница в Малеме"},
    { value: "Сводчатая гробница в Филаки"},
    { value: "Сейтан (Стефану) Порт"},
    { value: "Сельские дома с прессом для олив"},
    { value: "Сельский дом с виноградодавильней"},
    { value: "Сельское святилище под открытым небом"},
    { value: "Сииа"},
    { value: "Скалидис"},
    { value: "Собор Агион Асоматон"},
    { value: "Собор Архангела Михаила"},
    { value: "Собор Архангела Михаила (Кавалариана)"},
    { value: "Собор Архангела Михаила (Ротонда)"},
    { value: "Собор Архангела Михаила (Саракина)"},
    { value: "Собор Благовещения Богородицы (Митрополия)"},
    { value: "Собор Богоматери (Панагии) (Анисараки)"},
    { value: "Собор Богородицы Зервиотиссы (или Монастира)"},
    { value: "Собор Богородицы Миртидиотиссы"},
    { value: "Собор Богородицы на двух скалах (или Богородица в Хараки)"},
    { value: "Собор Богородицы (Панагии) Скафидиани"},
    { value: "Собор Богородицы (Панагии) (Склавопула)"},
    { value: "Собор Всех Святых"},
    { value: "Собор Живоносного Источника / Ай Кир Гианнис"},
    { value: "Собор Животворящего Креста"},
    { value: "Собор Ильи Пророка"},
    { value: "Соборный храм (Кафоликон) Монастыря Гониа "},
    { value: "Собор Панагии в Ферисо"},
    { value: "Собор Преображения Господня"},
    { value: "Собор Преображения Господня (Племениана)"},
    { value: "Собор Преображения Господня (Темениа)"},
    { value: "Собор преподобного Иоанна Ксеноса (Странника)"},
    { value: "Собор Преподобной Марии Египетской"},
    { value: "Собор Пресвятой Богородицы в Кадросе"},
    { value: "Собор Пророка Илии в Мелидони"},
    { value: "Собор Пророка Ильи"},
    { value: "Собор Св. Георгия Пьянящего (Ай Гиоргис Мефистис)"},
    { value: "Собор Св. Пантелеймона"},
    { value: "Собор Святого Афанасия"},
    { value: "Собор Святого Василия"},
    { value: "Собор Святого Георгия"},
    { value: "Собор Святого Георгия (Аликианос)"},
    { value: "Собор Святого Георгия (Кедрес)"},
    { value: "Собор Святого Георгия Кумпелиса"},
    { value: "Собор Святого Георгия (Лакки)"},
    { value: "Собор Святого Евтихия"},
    { value: "Собор Святого Иоанна"},
    { value: "Собор Святого Иоанна Богослова"},
    { value: "Собор Святого Иоанна Богослова и Святого Николая"},
    { value: "Собор Святого Николая"},
    { value: "Собор Святого Николая (Агиос Николаос)"},
    { value: "Собор Святого Николая в Мури"},
    { value: "Собор Святого Николая (Кириакоселиа)"},
    { value: "Собор Святого Николая (Маза)"},
    { value: "Собор Святого Павла"},
    { value: "Собор Святого Пантелеймона"},
    { value: "Собор Святого Спиридона"},
    { value: "Собор Святого Стефана"},
    { value: "Собор Святого Фотия и Непобедимого (Аникитоса)"},
    { value: "Собор Святого Харлампия"},
    { value: "Собор Святого Элевтерия"},
    { value: "Собор Святой Анны"},
    { value: "Собор Святой Варвары в Латзиана"},
    { value: "Собор Святой Екатерины"},
    { value: "Собор Святой Екатерины (Сплантзья)"},
    { value: "Собор Святой Ирины"},
    { value: "Собор Святой Кириакии"},
    { value: "Собор Святой Магдалины"},
    { value: "Собор Святых Бессребреников"},
    { value: "Собор Святых Отцов"},
    { value: "Собор Успения Богородицы"},
    { value: "Собор Успения Богородицы (Аликампос)"},
    { value: "Собор Успения Богородицы (Кефалас)"},
    { value: "Собор Христа Спасителя"},
    { value: "Собор Честного пояся"},
    { value: "Соборы Св. Нестора и Св. Димитрия в Катохори, Керамиа"},
    { value: "Солнечные часы в Фотакадо"},
    { value: "Союзническое кладбище"},
    { value: "Ставрос"},
    { value: "Сталос"},
    { value: "Старая просёлочная дорога Кантаноса – Сегмент Анавос-Кантанос"},
    { value: "Старволимни или Страволимни"},
    { value: "Старый Епископский дворец"},
    { value: "Стиванадика"},
    { value: "Стомио"},
    { value: "Сугиа"},
    { value: "Судебно-административный дворец"},
    { value: "Табакарья"},
    { value: "Табакарья (на ул. Вивилаки)"},
    { value: "Тарра"},
    { value: "Театр восточного рва"},
    { value: "Театр «Димитрис Влисидис»"},
    { value: "Терсанас"},
    { value: "Тигани - Лагуна на побережье Балос"},
    { value: "Тополиано"},
    { value: "Торговый порт в Суда"},
    { value: "Торговый центр"},
    { value: "Трипити"},
    { value: "Тропа Врипетро - Ферисо"},
    { value: "Тропа Даскалу Вриси"},
    { value: "Тропа Зурва-Тромарисса"},
    { value: "Туннель в Тополиа"},
    { value: "Убежище, Музей Второй Мировой Войны"},
    { value: "Угиа, панораманый вид Равдуха"},
    { value: "Усадьба Франгедианов"},
    { value: "Устье реки Влахида"},
    { value: "Устье реки Кладисос"},
    { value: "Устье реки Моронис"},
    { value: "Ущелье Агиа Ирини"},
    { value: "Ущелье Ай Гиоргис (Кикламино)"},
    { value: "Ущелье Анидри"},
    { value: "Ущелье Арадэна"},
    { value: "Ущелье Диктамо"},
    { value: "Ущелье Импрос"},
    { value: "Ущелье Камбос"},
    { value: "Ущелье Кантанос"},
    { value: "Ущелье Лиссос"},
    { value: "Ущелье Месавлиа - Далиана"},
    { value: "Ущелье Потамос"},
    { value: "Ущелье Рокка"},
    { value: "Ущелье Самарья"},
    { value: "Ущелье Саракина"},
    { value: "Ущелье Саракины, Мескла"},
    { value: "Ущелье Сирикари"},
    { value: "Ущелье Ферисо"},
    { value: "Фалассарна"},
    { value: "Фестиваль кисамитской культуры"},
    { value: "Филологическая ассоциация «Хрисостом»"},
    { value: "Финикас"},
    { value: "Фольклорный музей в Кефаласе"},
    { value: "Фольклорный музей в Палеа Румата"},
    { value: "Форт в Кириакоселиа"},
    { value: "Форт Итзедин"},
    { value: "Фортификационные сооружения"},
    { value: "Форт Кисамоса"},
    { value: "Форт Кулес"},
    { value: "Форт («Кулес») в Кастеллосе"},
    { value: "Форт Малафирос"},
    { value: "Форт Палеохора (Castel Selino)"},
    { value: "Форт порта в Лутро и кулес Лутро"},
    { value: "Форт Св. Феодоров"},
    { value: "Франгокастело"},
    { value: "Францисканский монастырь Сан Сальваторе"},
    { value: "Францисканский Монастырь Святого Франциска"},
    { value: "Французская школа"},
    { value: "Французское консульство в Критском государстве"},
    { value: "Хамам на ул. Зампелиу"},
    { value: "Хамам на ул. Катре"},
    { value: "Хамам на ул. Халидон"},
    { value: "Хионотрипа в Миле"},
    { value: "Холм «Святой Елиссей» (Агиос Элиссеос)"},
    { value: "Хриси Акти (Золотое Побережье)"},
    { value: "Хрисокалитса – Элафониси – Кедродасос – Криос"},
    { value: "Христианские катакомбы Стернес (Эвангелистриа)"},
    { value: "Центр Архитектуры Средиземноморья (Большой арсенал)"},
    { value: "Центр Экологического Образования (К.П.Е.)"},
    { value: "Церковь Рождества Пресвятой Богородицы"},
    { value: "Церковь Св. Роха"},
    { value: "Часы в Муниципальном саду"},
    { value: "Черепаха Каретта-Каретта и её гнёзда вдоль побережья Сталос"},
    { value: "Школа в Вутасе"},
    { value: "Школа в Кустогерако"},
    { value: "Школа в Склавопула"},
    { value: "Школа для девочек"},
    { value: "Школьные здания на ул. Кораи"},
    { value: "Экспозиция - Генштаб революции Ферисо (1905 г.)"},
    { value: "Элафониси"},
    { value: "Элирос"},
    { value: "Элос"},
    { value: "Ярмарка Великой пятницы – улица Скалиди" }

]