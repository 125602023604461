import ky from 'ky'
import { Cors} from './configuration'

export const axiosSearch = {

  async getSearch(searchString: string, lang: string) {

    // console.log(lang)

    const pois : any = await ky.get(`https://chania.mitos.cityguideplatform.com/api/pois?fetch_files=1&search_keyword=${searchString}&lang=${lang === 'gr' ? 'el' : lang }`, {
          headers: { 
          'X-Authorization': 'hfG7u8C4UNMw17E6Jyzq'
        }
    }).json()


    const routes : any = await ky.get(`https://chania.mitos.cityguideplatform.com/api/routes?lang=${lang === 'gr' ? 'el' : lang }&search_keyword=${searchString}`, {
      headers: { 
      'X-API-KEY': 'hfG7u8C4UNMw17E6Jyzq'
    }
    }).json()



    const { events } : any = await ky.get(`https://events.explorechania.gr/api/${lang === 'gr' ? 'el' : lang }/events/search?keywords=${searchString}`, {headers: Cors}).json()
    

    return  [pois.success, routes.success, events] 
  },


}




// async getSearch(searchString: string) {
   
//   const data = fetch('https://chania.mitos.cityguideplatform.com/api/pois?fetch_files=1&search_keyword=φυσιολατρικό&lang=el', {
//     headers: { 
//     'X-Authorization': 'hfG7u8C4UNMw17E6Jyzq'
//   }
//   });

//   return (await data).json();