export const AboutUsData = [{
'gr':{
  id: 1,
  title: 'Σχετικά με το έργο',
  text:`
<div>
  O παρόν δικτυακός τόπος, explorechania.gr, αναπτύχθηκε στο πλαίσιο του έργου 
  <strong>«Αξιοποίηση καινοτόμων εφαρμογών ΤΠΕ για την ανάδειξη του τουριστικού και πολιτιστικού πλούτου των Χανίων» του Δήμου Χανίων». </strong>
  Χρηματοδοτήθηκε από το ΕΠ «Κρήτη» 2014-2020 (MIS :5030576, ΣΑΕΠ 002/1: 2019ΕΠ00210023) από την Ευρωπαϊκή Ένωση - Ευρωπαϊκό Ταμείο Περιφερειακής Ανάπτυξης (ΕΤΠΑ) και από εθνικούς πόρους .
</div>
<div>
  Το έργο αυτό με δικαιούχο τον Δήμο Χανίων, βασίζεται στη διαδημοτική συνεργασία όλων των Δήμων της Περιφερειακή Ενότητας (Π.Ε.) Χανίων και της Εφορείας Αρχαιοτήτων Χανίων.
</div>
<div>
  Στοχεύει στην από κοινού ολοκληρωμένη προβολή και ανάδειξη του πολιτιστικού και τουριστικού αποθέματος των επτά (7) Δήμων της Π.Ε Χανίων (Αποκορώνου, Γαύδου, Καντάνου - Σελίνου, Κισσάμου, Πλατανιά, Σφακίων και Χανίων), μέσα από ένα σύνολο καινοτόμων και συνδυαστικών ψηφιακών δράσεων, που παρέχουν νέες δυνατότητες προβολής του πολιτιστικού και τουριστικού περιεχομένου, στους κατοίκους και επισκέπτες των Χανίων.
  </div>
<div>
  Δείτε περισσότερες πληροφορίες πλαίσιο για το έργο εδώ: 
  <a href="https://www.chania.gr/enimerosi/erga-espa/ris-app-TPE-chania.html" target="_blank" style="color: rgb(10, 85, 114);
  text-decoration: underline;">
  «Αξιοποίηση καινοτόμων εφαρμογών ΤΠΕ για την ανάδειξη του τουριστικού και πολιτιστικού πλούτου των Χανίων» του Δήμου Χανίων»
</a>
</div>
`
  },
  
'en':{
  id: 2,
  title: 'About us',
  text:`
<div>
  This website, explorechania.gr, was developed in the context of the project
  <strong>"Use of innovative ICT applications to highlight the touristic and cultural wealth of Chania" of the Municipality of Chania. </strong>
  It was financed by the OP "Crete" 2014-2020 (MIS:5030576, SAEP 002/1: 2019ΕΠ00210023) from the European Union - European Regional Development Fund (ERDF) and from national resources.
</div>
<div>
  This project, with the Municipality of Chania as beneficiary, is based on the inter-municipal cooperation of all the Municipalities of the Regional Unit (PE) of Chania and the Ephorate of Antiquities of Chania.
</div>
<div>
  It aims at the joint comprehensive promotion and promotion of the cultural and touristic stock of the seven (7) Municipalities of the Province of Chania (Apokoronou, Gavdos, Kantanou-Selinou, Kissamos, Platanias, Sfakion and Chania), through a set of innovative and combined digital actions, which provide new opportunities to promote the cultural and touristic content, to the residents and visitors of Chania.
  </div>
<div>
  See more background information about the project here:
  <a href="https://www.chania.gr/enimerosi/erga-espa/ris-app-TPE-chania.html" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline">
  "Use of innovative ICT applications to highlight the touristic and cultural wealth of Chania" by the Municipality of Chania</a>
</div>
`
},
'fr':{
  id: 3,
  title: 'À propos de nous', 
  text:`
<div>
  Ce site, explorechania.gr, a été développé dans le cadre du projet
  <strong>"Utilisation d'applications TIC innovantes pour mettre en valeur la richesse touristique et culturelle de La Canée" de la Municipalité de La Canée. </strong>
  Il a été financé par le PO "Crète" 2014-2020 (MIS:5030576, SAEP 002/1: 2019ΕΠ00210023) de l'Union Européenne - Fonds Européen de Développement Régional (FEDER) et de ressources nationales.
</div>
<div>
  Ce projet, avec la Municipalité de La Canée comme bénéficiaire, est basé sur la coopération inter-municipale de toutes les Municipalités de l'Unité Régionale (PE) de La Canée et de l'Ephorie des Antiquités de La Canée.
</div>
<div>
  Il vise à la promotion globale conjointe et à la promotion du stock culturel et touristique des sept (7) municipalités de la province de La Canée (Apokoronou, Gavdos, Kantanou-Selinou, Kissamos, Platanias, Sfakion et La Canée), à travers un ensemble de et des actions numériques combinées, qui offrent de nouvelles opportunités pour promouvoir le contenu culturel et touristique auprès des résidents et des visiteurs de La Canée.
  </div>
<div>
  Voir plus d'informations sur le projet ici:
  <a href="https://www.chania.gr/enimerosi/erga-espa/ris-app-TPE-chania.html" target="_blank" style="color : rgb(10, 85, 114); text-decoration: underline">
  "Utilisation d'applications TIC innovantes pour mettre en valeur la richesse touristique et culturelle de La Canée" par la municipalité de La Canée</a>
</div>
  `
  },

'de':{
  id: 4,
  title: 'Über uns', 
  text:`
  <div>
  Diese Website, explorechania.gr, wurde im Rahmen des Projekts entwickelt
  <strong>"Einsatz innovativer IKT-Anwendungen zur Hervorhebung des touristischen und kulturellen Reichtums von Chania" der Stadtverwaltung von Chania. </strong>
  Es wurde durch das OP „Kreta“ 2014-2020 (MIS:5030576, SAEP 002/1:2019ΕΠ00210023) aus der Europäischen Union – Europäischer Fonds für regionale Entwicklung (EFRE) und aus nationalen Mitteln finanziert.
</div>
<div>
  Dieses Projekt mit der Gemeinde Chania als Begünstigter basiert auf der interkommunalen Zusammenarbeit aller Gemeinden der Regionaleinheit (PE) von Chania und der Ephorie für Altertümer von Chania.
</div>
<div>
  Es zielt auf die gemeinsame umfassende Förderung und Förderung des kulturellen und touristischen Bestands der sieben (7) Gemeinden der Provinz Chania (Apokoronou, Gavdos, Kantanou-Selinou, Kissamos, Platanias, Sfakion und Chania) durch eine Reihe innovativer und kombinierte digitale Aktionen, die den Einwohnern und Besuchern von Chania neue Möglichkeiten bieten, den kulturellen und touristischen Inhalt zu fördern.
  </div>
<div>
  Weitere Hintergrundinformationen zum Projekt finden Sie hier:
  <a href="https://www.chania.gr/enimerosi/erga-espa/ris-app-TPE-chania.html" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline">
  „Nutzung innovativer IKT-Anwendungen zur Hervorhebung des touristischen und kulturellen Reichtums von Chania“ von der Gemeinde Chania</a>
</div>
  `
},

'ru': {
  id: 5,
  title: 'О нас',
  text: `
<div>
  Этот сайт, explorechania.gr, был разработан в рамках проекта
  <strong>«Использование инновационных приложений ИКТ для привлечения внимания к туристическому и культурному богатству Ханьи» муниципалитета Ханьи. </strong>
  Он финансировался ОП «Крит» 2014-2020 (MIS:5030576, SAEP 002/1: 2019ΕΠ00210023) из Европейского Союза – Европейского фонда регионального развития (ERDF) и из национальных ресурсов.
</div>
<div>
  Этот проект, бенефициаром которого является муниципалитет Ханьи, основан на межмуниципальном сотрудничестве всех муниципалитетов регионального подразделения (PE) Ханьи и Эфората древностей Ханьи.
</div>
<div>
  Он направлен на совместное всестороннее продвижение и продвижение культурного и туристического фонда семи (7) муниципалитетов провинции Ханья (Апокороноу, Гавдос, Кантану-Селину, Киссамос, Платаниас, Сфакион и Ханья) посредством ряда инновационных и комбинированные цифровые акции, которые предоставляют новые возможности для продвижения культурного и туристического контента жителям и гостям Ханьи.
  </div>
<div>
  Более подробную информацию о проекте смотрите здесь:
  <a href="https://www.chania.gr/enimerosi/erga-espa/ris-app-TPE-chania.html" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline">
  «Использование инновационных приложений ИКТ для привлечения внимания к туристическому и культурному богатству Ханьи» муниципалитета Ханьи.</a>
</div>
  `
  }
}
]
