export const PolicyData = [{
'gr':{
  id: 1,
  title: 'Πολιτική απορρήτου',
  text:` 
<div>
Η προστασία των δεδομένων προσωπικού χαρακτήρα (ΔΠΧ), αποτελεί βασικό μέλημα του Δήμου Χανίων. Στο πλαίσιο του Γενικού Κανονισμού Προστασίας των Δεδομένων (ΕΕ) 2016/679 (ΓΚΠΔ), που τέθηκε σε ισχύ από την 25/05/2018, όπως εκάστοτε ισχύει, με το παρόν κείμενο παρέχεται χρήσιμη ενημέρωση για την επεξεργασία των δεδομένων προσωπικού χαρακτήρα και τα δικαιώματα των υποκειμένων της επεξεργασίας, σύμφωνα με το άρθρο 13 του παραπάνω Κανονισμού.
</div>
<div>
Σύμφωνα με τον παραπάνω Κανονισμό, στο άρθρο 4 αναφέρεται ότι νοούνται ως:
</div>
<div>
• «δεδομένα προσωπικού χαρακτήρα»: κάθε πληροφορία που αφορά ταυτοποιημένο ή ταυτοποιήσιμο φυσικό πρόσωπο («υποκείμενο των δεδομένων»)· το ταυτοποιήσιμο φυσικό πρόσωπο είναι εκείνο του οποίου η ταυτότητα μπορεί να εξακριβωθεί, άμεσα ή έμμεσα, ιδίως μέσω αναφοράς σε αναγνωριστικό στοιχείο ταυτότητας, όπως όνομα, σε αριθμό ταυτότητας, σε δεδομένα θέσης, σε επιγραμμικό αναγνωριστικό ταυτότητας ή σε έναν ή περισσότερους παράγοντες που προσιδιάζουν στη σωματική, φυσιολογική, γενετική, ψυχολογική, οικονομική, πολιτιστική ή κοινωνική ταυτότητα του εν λόγω φυσικού προσώπου, ενώ
</div>
<div>
• «επεξεργασία»: κάθε πράξη ή σειρά πράξεων που πραγματοποιείται με ή χωρίς τη χρήση αυτοματοποιημένων μέσων, σε δεδομένα προσωπικού χαρακτήρα ή σε σύνολα δεδομένων προσωπικού χαρακτήρα, όπως η συλλογή, η καταχώριση, η οργάνωση, η διάρθρωση, η αποθήκευση, η προσαρμογή ή η μεταβολή, η ανάκτηση, η αναζήτηση πληροφοριών, η χρήση, η κοινολόγηση με διαβίβαση, η διάδοση ή κάθε άλλη μορφή διάθεσης, η συσχέτιση ή ο συνδυασμός, ο περιορισμός, η διαγραφή ή η καταστροφή
</div>
<div>
Η προστασία αφορά όλα τα δεδομένα προσωπικού χαρακτήρα, που έχουν περιέλθει ή θα περιέλθουν σε γνώση των Υπηρεσιών του Δήμου Χανίων, στο πλαίσιο της νόμιμης λειτουργίας του και της συνεργασίας του με πολίτες και φορείς του δημοσίου ή ιδιωτικού τομέα.
</div>
<div>
Ο Κανονισμός (ΕΕ) 2016/679, δεν καταργεί υποχρεώσεις που προκύπτουν από διατάξεις άλλων νόμων. Οι Υπηρεσίες του Δήμου Χανίων, συνεχίζουν να λειτουργούν και να παρέχουν υπηρεσίες προς του δημότες εντός του πλαισίου των νομικά τεκμηριωμένων αρμοδιοτήτων τους.
</div>
<div>
Ο Δήμος Χανίων προκειμένου να λειτουργεί σε συμμόρφωση με το ισχύον νομοθετικό πλαίσιο έχει προβεί σε σειρά ενεργειών που απαιτούνται, εφαρμόζοντας τα κατάλληλα τεχνικά και οργανωτικά μέτρα για τη νόμιμη τήρηση, την επεξεργασία και την ασφαλή φύλαξη των αρχείων δεδομένων προσωπικού χαρακτήρα, έτσι ώστε να διασφαλίζει και να προστατεύει με κάθε τρόπο την επεξεργασία των προσωπικών δεδομένων από απώλεια ή διαρροή, αλλοίωση, διαβίβαση ή την με οποιονδήποτε άλλο τρόπο αθέμιτη επεξεργασία τους.
</div>


<h4>Νομιμότητα επεξεργασίας</h4>
<div>
Ο Δήμος Χανίων ως υπεύθυνος επεξεργασίας, κατά την έννοια του άρθρου 4 παρ. 7 του Γενικού Κανονισμού Προστασίας Δεδομένων, συλλέγει δεδομένα προσωπικού χαρακτήρα για σκοπούς ενάσκησης αρμοδιοτήτων του. Η νομική βάση για την εν λόγω επεξεργασία είναι το άρθρο 6 παρ. 1 στοιχεία γ και ε του ΓΚΠΔ, επίσης για την επεξεργασία ειδικών κατηγοριών δεδομένων προσωπικού χαρακτήρα, είναι το άρθρο 9 παρ. 2 στοιχεία β, ζ και ι του παραπάνω κανονισμού.
</div>
<div>
Ως προς την εγκατάσταση cookies για την προσωποποιημένη περιήγηση στον διαδικτυακό τόπο μας, η νομική βάση είναι η συγκατάθεσή σας κατά το άρθρο 6 παρ. 1 (α) του ΓΚΠΔ.
</div>


<h4>Ποια δεδομένα συλλέγουμε – Αρχές επεξεργασίας</h4>
<div>
Τα δεδομένα προσωπικού χαρακτήρα που συλλέγονται από τις Υπηρεσίες του Δήμου Χανίων, περιορίζονται στα αναγκαία για τους σκοπούς για τους οποίους υποβάλλονται σε επεξεργασία και είναι τα τελείως απαραίτητα για τη συμμόρφωσή του σε κάθε έννομη υποχρέωσή του ή την εκπλήρωση των καθηκόντων του, που εκτελείται προς το δημόσιο συμφέρον ή την άσκηση της δημόσιας εξουσίας που του έχει ανατεθεί.
</div>
<div>
Η επεξεργασία προσωπικών δεδομένων (απλών και ειδικών κατηγοριών «ευαίσθητων») από το Δήμο Χανίων, διέπεται από τις αρχές που περιγράφονται με σαφήνεια στο άρθρο 5 του ΓΚΠΔ.
</div>
<div>
Οι Υπηρεσίες του Δήμου Χανίων επεξεργάζονται τα ΔΠΧ, σύμφωνα με την ισχύουσα νομοθεσία και με διαφάνεια. Στο πλαίσιο της διαφάνειας, επιδιώκεται η ενημέρωση του υποκειμένου να γίνεται με τρόπο συνοπτικό, κατανοητό, με σαφή και απλή διατύπωση («νομιμότητα, αντικειμενικότητα και διαφάνεια»).
</div>
<div>
Τα ΔΠΧ που παρέχονται στον Δήμο Χανίων, δεν υποβάλλονται σε περαιτέρω επεξεργασία κατά τρόπο ασύμβατο προς τους σκοπούς που συλλέγονται. Οι σκοποί αυτοί είναι καθορισμένοι, ρητοί και νόμιμοι, στο πλαίσιο λειτουργίας του και της παροχής υπηρεσιών προς τους πολίτες, («περιορισμός του σκοπού»).
</div>
<div>
Ο Δήμος Χανίων συγκεντρώνει μόνο τα ΔΠΧ που είναι κατάλληλα, συναφή και περιορίζονται στο αναγκαίο για τους σκοπούς για τους οποίους υποβάλλονται σε επεξεργασία («ελαχιστοποίηση των δεδομένων»).
</div>
<div>
Στόχος των Υπηρεσιών του Δήμου Χανίων είναι τα ΔΠΧ που συλλέγονται να είναι ακριβή και να επικαιροποιούνται, όταν είναι αναγκαίο, λαμβάνοντας όλα τα εύλογα μέτρα για την άμεση διόρθωση ή διαγραφή ανακριβών παίρνοντας υπόψη τους επιδιωκόμενους σκοπούς της επεξεργασίας («ακρίβεια»).
</div>
<div>
Τα ΔΠΧ διατηρούνται σε φυσικά ή ψηφιακά αρχεία για το διάστημα που απαιτείται για τους σκοπούς της επεξεργασίας. Το διάστημα αυτό καθορίζεται από την ισχύουσα νομοθεσία («περιορισμός της περιόδου αποθήκευσης»).
</div>
<div>
Στις αρμόδιες Υπηρεσίες του Δήμου παίρνονται τα κατάλληλα τεχνικά και οργανωτικά μέτρα, ώστε τα ΔΠΧ να υποβάλλονται σε επεξεργασία κατά τέτοιο τρόπο, που να εξασφαλίζεται η ενδεδειγμένη ασφάλεια των δεδομένων προσωπικού χαρακτήρα, όπως η προστασία τους από μη εξουσιοδοτημένη ή παράνομη επεξεργασία και τυχαία απώλεια, καταστροφή ή φθορά, («ακεραιότητα και εμπιστευτικότητα»).
</div>
<div>
Ο Δήμος Χανίων φέρει την ευθύνη της επεξεργασίας των ΔΠΧ και είναι σε θέση να αποδείξει τη συμμόρφωσή του με τις αρχές του GDPR, ενώπιον των εποπτικών αρχών και των δικαστηρίων («λογοδοσία»).
</div>
<div>
Επισημαίνουμε ότι o Δήμος Χανίων χρησιμοποιεί στην ιστοσελίδα του «cookies». Για λεπτομέρειες σχετικά με τα cookies, μπορείτε να ενημερωθείτε από τους Όρους Χρήσης στην ιστοσελίδα του (www.explorechania.gr).
</div>


<h4>Πού γνωστοποιούμε τα δεδομένα</h4>
<div>
Η επεξεργασία των δεδομένων προσωπικού χαρακτήρα διενεργείται αποκλειστικά και μόνο για τους σκοπούς για τους οποίους υποβάλλονται.
</div>
<div>
Ο Δήμος Χανίων, σε καμία περίπτωση δεν πωλεί, εκμισθώνει, ή με οποιονδήποτε άλλο τρόπο παραχωρεί ή διαβιβάζει σε τρίτους προσωπικά στοιχεία, που υποβάλλονται στις Υπηρεσίες του. Εξαίρεση αποτελεί η περίπτωση κατά την οποία η κοινοποίηση των στοιχείων επιβάλλεται από ρητή διάταξη νόμου και αποκλειστικά προς τις αρμόδιες αρχές.
</div>
<div>
Σε περίπτωση που μετά από εφαρμογή της ισχύουσας νομοθεσίας, περί σύναψης δημοσίων συμβάσεων, προκύψει ανάδοχος ο οποίος κατά την εκτέλεση της σύμβασης έχει πρόσβαση σε δεδομένα προσωπικού χαρακτήρα που επεξεργάζεται ο Δήμος Χανίων, τότε με ευθύνη των αρμοδίων Υπηρεσιών υπογράφεται σύμβαση εμπιστευτικότητας- εχεμύθειας ή οι όροι αυτής της σύμβασης ενσωματώνονται εντός της κύριας σύμβασης.
</div>
<div>
Στόχος της παραπάνω ενέργειας είναι να εξασφαλιστούν επαρκείς διαβεβαιώσεις για την εφαρμογή από τους αναδόχους κατάλληλων τεχνικών και οργανωτικών μέτρων, κατά τρόπο ώστε η επεξεργασία να πληροί τις απαιτήσεις του GDPR και να διασφαλίζεται η προστασία των δικαιωμάτων του υποκειμένου των δεδομένων.
</div>

<h4>Πώς προστατεύουμε τα δεδομένα</h4>
<div>
Η επεξεργασία των προσωπικών δεδομένων υπόκειται στους όρους της παρούσας πολιτικής προστασίας δεδομένων, καθώς και στις σχετικές διατάξεις της ελληνικής νομοθεσίας και του ενωσιακού δικαίου για την προστασία του ατόμου και την προστασία δεδομένων προσωπικού χαρακτήρα.
</div>
<div>
Ο Δήμος Χανίων λαμβάνει όλα τα εύλογα φυσικά, τεχνικά και οργανωτικά μέτρα ασφάλειας ώστε τα δεδομένα προσωπικού χαρακτήρα να υποβάλλονται σε επεξεργασία κατά τρόπο που εγγυάται την ενδεδειγμένη ασφάλεια των δεδομένων προσωπικού χαρακτήρα, μεταξύ άλλων την προστασία τους από μη εξουσιοδοτημένη ή παράνομη επεξεργασία και τυχαία απώλεια, παράνομη καταστροφή ή κοινολόγηση και φθορά.
</div>


<h4>Διάρκεια τήρησης των δεδομένων</h4>
<div>
Ο Δήμος Χανίων διατηρεί τα δεδομένα προσωπικού χαρακτήρα, μόνο για το διάστημα που απαιτείται για τους σκοπούς της επεξεργασίας τους, όπως ορίζει κατά περίπτωση η ισχύουσα νομοθεσία, στα πλαίσια της εκπλήρωσης των καθηκόντων του και της άσκησης της δημόσιας εξουσίας που του έχει ανατεθεί, επίσης για όσο χρονικό διάστημα είναι απαραίτητο για τη θεμελίωση, άσκηση ή υποστήριξη νομικών αξιώσεων.
</div>


<h4>Δικαιώματα των υποκειμένων των δεδομένων</h4>
<div>
Τα υποκείμενα των δεδομένων διατηρούν όλα τα δικαιώματα που απορρέουν από τις εφαρμοστέες ελληνικές και ενωσιακές διατάξεις, ιδίως τα δικαιώματα ενημέρωσης, πρόσβασης, διόρθωσης, συμπλήρωσης, διαγραφής, περιορισμού της επεξεργασίας των προσωπικών του δεδομένων, λαμβάνοντας υπόψη ότι ο GDPR δεν καταργεί υποχρεώσεις που προκύπτουν από διατάξεις άλλων νόμων.
</div>
<div>
Ειδικότερα, τηρουμένου του νομοθετικού πλαισίου προστασίας προσωπικών δεδομένων, όπως κάθε φορά ισχύει, δίνεται σε κάθε υποκείμενο των δεδομένων το δικαίωμα:
</div>
<div>
• Ενημέρωσης και πρόσβασης στα δεδομένα: Περισσότερη και σαφέστερη ενημέρωση για τα προσωπικά του δεδομένα που συλλέγονται και επεξεργάζονται από τις Υπηρεσίες του Δήμου και το δικαίωμα πρόσβασης σε αυτά.
</div>
<div>
• Διόρθωσης: Διόρθωση ή συμπλήρωση τυχόν ελλιπών ή ανακριβών δεδομένων που αφορούν κάθε υποκείμενο των δεδομένων, από τις αρμόδιες Υπηρεσίες του Δήμου.
</div>
<div>
• Περιορισμού της επεξεργασίας και εναντίωσης στην επεξεργασία: υπό συγκεκριμένες προϋποθέσεις, όταν προβλέπονται από τις ισχύουσες νομοθετικές διατάξεις.
</div>
<div>
• Στη λήθη: Διαγραφή των δεδομένων, υπό την προϋπόθεση ότι τα δεδομένα δεν τηρούνται για κάποιο συγκεκριμένο νόμιμο και δηλωμένο σκοπό, όπως ορίζει κατά περίπτωση η ισχύουσα νομοθεσία, με ευθύνη της εκάστοτε αρμόδιας Υπηρεσίας.
</div>
<div>
• Στη φορητότητα των δεδομένων: Μεταφορά των δεδομένων, σε μηχαναγνώσιμη μορφή, από έναν υπεύθυνο επεξεργασίας σε άλλον υπό συγκεκριμένες προϋποθέσεις.
</div>
<div>
Όταν η άσκηση των παραπάνω δικαιωμάτων εξαρτάται αποκλειστικά από ενέργειες των Υπηρεσιών του Δήμου Χανίων, αφού εξακριβωθεί η νομιμότητα του αιτήματος, η ανταπόκριση του Δήμου Χανίων στο αίτημα λαμβάνει χώρα εντός της προβλεπόμενης από τον Κανονισμό προθεσμίας του ενός μηνός. Σε κάθε περίπτωση παρέχεται ενημέρωση για τυχόν αιτιολογημένη ανάγκη παράτασης του διαστήματος αυτού.
</div>


<h4>Γνωστοποίηση Αλλαγών</h4>
<div>
Διατηρούμε το δικαίωμα αλλαγής των ανωτέρω όρων σύμφωνα με το εκάστοτε ισχύον νομικό πλαίσιο.
</div>
<div>
Σε περίπτωση που οι ανωτέρω όροι υποστούν οποιαδήποτε μεταβολή, θα παρέχεται ενημέρωση με ανακοινώσεις μέσω του ιστότοπου μας.
</div>


<h4>Εφαρμοστέο δίκαιο και Δικαιοδοσία</h4>
Για οποιαδήποτε διαφορά προκύψει μεταξύ χρήστη και Δήμου Χανίων εφαρμοστέο δίκαιο είναι το ελληνικό και αρμόδιο δικαστήριο επίλυσης της διαφοράς είναι τα καθ’ ύλην αρμόδια Δικαστήρια των Χανίων.

<h4>Σύνδεσμοι (Links)</h4>
<div>
Η ιστοσελίδα μας ενδέχεται να περιέχει συνδέσμους (links) προς άλλες ιστοσελίδες. Η παρούσα δήλωση περί προστασίας προσωπικών δεδομένων ισχύει μόνον όσον αφορά στην πρόσβαση του χρήστη στην παρούσα ιστοσελίδα αλλά και σε άλλες ιστοσελίδες που διαχειρίζεται αποκλειστικά ο Δήμος Χανίων. Σε καμία περίπτωση δεν ευθύνεται ο διαχειριστής της παρούσας δικτυακής πύλης για τους όρους προστασίας των προσωπικών δεδομένων άλλων δικτυακών τόπων, οι οποίοι βρίσκονται υπό την ευθύνη τρίτων φορέων (φυσικά ή νομικά πρόσωπα).
</div>


<h4>Τρόπος άσκησης των δικαιωμάτων σας</h4>
<div>
Ο Δήμος Χανίων σας γνωστοποιεί, ότι έχει ορίσει Υπεύθυνο Προστασίας Προσωπικών δεδομένων, στον οποίο μπορείτε να απευθύνεστε στην ηλεκτρονική διεύθυνση επικοινωνίας dpo@chania.gr.
</div>
<div>
Εάν έχετε ερωτήσεις ή συστάσεις σχετικά με την παρούσα Πολιτική, μπορείτε να τις απευθύνετε στην ανωτέρω διεύθυνση και στο ως άνω τηλέφωνο επικοινωνίας. Οι συνεχείς εξελίξεις στο Διαδίκτυο γενικότερα καθιστούν απαραίτητες τις κατά καιρούς προσαρμογές των κανόνων μας περί προστασίας δικτυακών δεδομένων. Ο Δήμος Χανίων επιφυλάσσεται για το δικαίωμά του να προβαίνει στις εκάστοτε τυχόν ενδεικνυόμενες μεταβολές των εν λόγω κανόνων ανά πάσα στιγμή.
</div>

<h4>Δικαίωμα προσφυγής στην Αρχή</h4>
<div>
Για ζητήματα που αφορούν την επεξεργασία προσωπικών σας δεδομένων έχετε το δικαίωμα να προσφύγετε στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα (Κηφισίας 1-3, 115 23, Αθήνα, τηλ. 2106475600). Για την αρμοδιότητα της Αρχής και τον τρόπο υποβολής καταγγελίας, μπορείτε να επισκεφθείτε την ιστοσελίδα της (www.dpa.gr / Τα δικαιώματά μου / Υποβολή καταγγελίας), όπου υπάρχουν αναλυτικές πληροφορίες.
</div>


<h4>Τελικές διατάξεις</h4>
<div>
Οι ανωτέρω όροι καθώς και οποιαδήποτε τροποποίηση τους, διέπονται και συμπληρώνονται από το ελληνικό δίκαιο, το δίκαιο της Ευρωπαϊκής Ένωσης και τις σχετικές διεθνείς συνθήκες. Οποιαδήποτε διάταξη των ανωτέρω όρων καταστεί αντίθετη προς το νόμο, παύει αυτοδικαίως να ισχύει και αφαιρείται από το παρόν, χωρίς σε καμία περίπτωση να θίγεται η ισχύς των λοιπών όρων.
</div>

`
},
'en':{
  id: 2,
  title: 'Privacy Policy',
  text:`
  <div>
  The protection of personal data (PID) is a key concern of the Municipality of Chania. In the context of the General Data Protection Regulation (EU) 2016/679 (GDPR), which entered into force on 25/05/2018, as applicable, this text provides useful information on the processing of personal data and the rights of the subjects of the processing, in accordance with article 13 of the above Regulation.
  </div>
  <div>
  According to the above Regulation, in article 4 it is stated that they are understood as:
  </div>
  <div>
  • "personal data": any information relating to an identified or identifiable natural person ("data subject"); an identifiable natural person is one whose identity can be ascertained, directly or indirectly, in particular by reference to an identifier; such as name, identity number, location data, online identifier or one or more factors that characterize the physical, physiological, genetic, psychological, economic, cultural or social identity of the natural person in question, while
  </div>
  <div>
  • "processing": any operation or series of operations carried out with or without the use of automated means, on personal data or sets of personal data, such as collection, registration, organization, structuring, storage, adaptation or the alteration, retrieval, retrieval of information, use, disclosure by transmission, dissemination or any other form of disposal, association or combination, restriction, deletion or destruction
  </div>
  <div>
  The protection concerns all personal data that have come or will come to the knowledge of the Services of the Municipality of Chania, in the context of its legal operation and its cooperation with citizens and public or private sector bodies.
  </div>
  <div>
  Regulation (EU) 2016/679 does not abolish obligations arising from provisions of other laws. The Services of the Municipality of Chania continue to operate and provide services to the citizens within the framework of their legally documented powers.
  </div>
  <div>
  The Municipality of Chania, in order to operate in compliance with the current legislative framework, has taken a series of necessary actions, applying the appropriate technical and organizational measures for the legal observance, processing and safe keeping of personal data files, so as to ensure and to protect in every way the processing of personal data from loss or leakage, alteration, transmission or in any other way their unlawful processing.
  </div>
  <h4>Lawfulness of processing</h4>
  <div>
  The Municipality of Chania as data controller, within the meaning of article 4 par. 7 of the General Data Protection Regulation, collects personal data for the purposes of exercising its powers. The legal basis for this processing is Article 6, paragraph 1, items c and e of the GDPR, also for the processing of special categories of personal data, it is Article 9, paragraph 2, items b, g and j of the above regulation.
  </div>
  <div>
  Regarding the installation of cookies for personalized browsing on our website, the legal basis is your consent according to Article 6 para. 1 (a) of the GDPR.
  </div>
  
  <h4>What data we collect – Principles of processing</h4>
  <div>
  The personal data collected by the Services of the Municipality of Chania are limited to what is necessary for the purposes for which they are processed and are absolutely necessary for its compliance with any legal obligation or the fulfillment of its duties, performed towards the public interest or the exercise of public authority delegated to him.
  </div>
  <div>
  The processing of personal data (simple and special categories of "sensitive") by the Municipality of Chania is governed by the principles clearly described in article 5 of the GDPR.
  </div>
  <div>
  The Services of the Municipality of Chania process VAT, in accordance with the applicable legislation and transparently. In the context of transparency, it is sought to inform the subject in a concise, comprehensible manner, with clear and simple wording ("legality, objectivity and transparency").
  </div>
  <div>
  Personal data provided to the Municipality of Chania are not further processed in a manner incompatible with the purposes for which they are collected. These purposes are defined, explicit and legal, in the context of its operation and the provision of services to citizens, ("limitation of purpose").
  </div>
  <div>
  The Municipality of Chania collects only the Personal Data that are appropriate, relevant and limited to what is necessary for the purposes for which they are processed ("data minimization").
  </div>
  <div>
  The aim of the Services of the Municipality of Chania is for the collected personal data to be accurate and updated, when necessary, taking all reasonable measures to immediately correct or delete inaccurate data taking into account the intended purposes of the processing ("accuracy").
  </div>
  <div>
  BPI is kept in physical or digital files for as long as necessary for the purposes of processing. This period is determined by the applicable legislation ("restriction of the storage period").
  </div>
  <div>
  In the competent Services of the Municipality, the appropriate technical and organizational measures are taken, so that the TINs are processed in such a way as to ensure the appropriate security of personal data, such as their protection from unauthorized or illegal processing and accidental loss, destruction or attrition, ("integrity and confidentiality").
  </div>
  <div>
  The Municipality of Chania is responsible for the processing of the GDPR and is able to prove its compliance with the principles of the GDPR, before the supervisory authorities and the courts ("accountability").
  </div>
  <div>
  We point out that the Municipality of Chania uses "cookies" on its website. For details about cookies, you can consult the Terms of Use on its website (www.explorechania.gr).
  </div>
 

  <h4>Where we disclose data</h4>
  <div>
  The processing of personal data is carried out exclusively and only for the purposes for which they are submitted.
  </div>
  <div>
  The Municipality of Chania under no circumstances sells, rents, or in any other way grants or transmits to third parties personal information submitted to its Services. An exception is the case in which the communication of the data is required by an express provision of the law and exclusively to the competent authorities.
  </div>
  <div>
  In the event that, after the application of the applicable legislation, on the conclusion of public contracts, a contractor appears who, during the execution of the contract, has access to personal data processed by the Municipality of Chania, then a confidentiality-confidentiality contract is signed under the responsibility of the competent Services or the terms of this contract are incorporated into the main contract.
  </div>
  <div>
  The aim of the above action is to ensure sufficient assurances for the implementation by the contractors of appropriate technical and organizational measures, in such a way that the processing meets the requirements of the GDPR and ensures the protection of the rights of the data subject.
  </div>
  
  <h4>How we protect data</h4>
  <div>
  The processing of personal data is subject to the terms of this data protection policy, as well as the relevant provisions of Greek legislation and EU law on the protection of the individual and the protection of personal data.
  </div>
  <div>
  The Municipality of Chania takes all reasonable physical, technical and organizational security measures so that personal data is processed in a way that guarantees the appropriate security of personal data, including its protection against unauthorized or illegal processing and accidental loss, unlawful destruction or disclosure and wear.
  </div>
  
  <h4>Data retention period</h4>
  <div>
  The Municipality of Chania retains the personal data only for the period required for the purposes of its processing, as defined in each case by the applicable legislation, in the context of the fulfillment of its duties and the exercise of the public authority assigned to it, also for as long as necessary to establish, exercise or support legal claims.
  </div>

  <h4>Rights of data subjects</h4>
  <div>
  Data subjects retain all rights deriving from the applicable Greek and EU provisions, in particular the rights to information, access, correction, completion, deletion, restriction of the processing of their personal data, bearing in mind that the GDPR does not abolish obligations arising from provisions of other laws.
  </div>
  <div>
  In particular, in compliance with the legislative framework for the protection of personal data, as applicable each time, each data subject is given the right:
  </div>
  <div>
  • Information and access to data: More and clearer information about his personal data collected and processed by the Municipality Services and the right to access them.
  </div>
  <div>
  • Correction: Correction or completion of any incomplete or inaccurate data concerning each data subject, by the competent Services of the Municipality.
  </div>
  <div>
  • Restriction of processing and objection to processing: under specific conditions, when provided for by the applicable legislative provisions.
  </div>
  <div>
  • Into oblivion: Deletion of the data, provided that the data is not kept for a specific legal and declared purpose, as defined in each case by the applicable legislation, under the responsibility of the relevant Service.
  </div>
  <div>
  • Data portability: Transfer of data, in machine-readable form, from one data controller to another under specific conditions.
  </div>
  <div>
  When the exercise of the above rights depends exclusively on the actions of the Services of the Municipality of Chania, after the legitimacy of the request has been verified, the response of the Municipality of Chania to the request takes place within the one-month deadline provided by the Regulation. In any case, information is provided for any justified need to extend this period.
  </div>

  <h4>Notification of Changes</h4>
  <div>
  We reserve the right to change the above terms in accordance with the current legal framework.
  </div>
  <div>
  In the event that the above conditions undergo any change, an update will be provided with announcements through our website.
  </div>

  <h4>Applicable Law and Jurisdiction</h4>
  For any dispute arising between the user and the Municipality of Chania, the applicable law is the Greek law and the competent court to resolve the dispute is the competent Courts of Chania.
  
  <h4>Links</h4>
  <div>
  Our website may contain links to other websites. This statement on personal data protection applies only to the user's access to this website but also to other websites managed exclusively by the Municipality of Chania. In no case is the administrator of this web portal responsible for the personal data protection conditions of other websites, which are under the responsibility of third parties (natural or legal persons).
  </div>
  
  
  <h4>How to exercise your rights</h4>
  <div>
  The Municipality of Chania informs you that it has appointed a Personal Data Protection Officer, who you can contact at the email address dpo@chania.gr.
  </div>
  <div>
  If you have questions or recommendations regarding this Policy, you can direct them to the above address and the above contact number. Continuous developments on the Internet in general make it necessary to adjust our online data protection rules from time to time. The Municipality of Chania reserves the right to make any appropriate changes to these rules at any time.
  </div>
  
  <h4>Right of appeal to the Authority</h4>
  <div>
  For issues concerning the processing of your personal data, you have the right to appeal to the Personal Data Protection Authority (Kifisias 1-3, 115 23, Athens, tel. 2106475600). For the Authority's competence and how to submit a complaint, you can visit its website (www.dpa.gr / My rights / Submit a complaint), where detailed information is available.
  </div>
  
  <h4>Final provisions</h4>
  <div>
  The above conditions, as well as any modification thereof, are governed and supplemented by Greek law, European Union law and the relevant international treaties. Any provision of the above terms that becomes contrary to the law, automatically ceases to be valid and is removed from this, without in any way affecting the validity of the other terms.
  </div>

  `
},
'fr':{
  id: 4,
  title: 'Politique de confidentialité',
  text:`
  <div>
   La protection des données personnelles (PID) est une préoccupation majeure de la municipalité de La Canée. Dans le cadre du règlement général sur la protection des données (UE) 2016/679 (RGPD), entré en vigueur le 25/05/2018, le cas échéant, ce texte fournit des informations utiles sur le traitement des données personnelles et les droits des sujets du traitement, conformément à l'article 13 du Règlement précité.
   </div>
   <div>
   Selon le règlement ci-dessus, à l'article 4, il est précisé qu'ils s'entendent comme :
   </div>
   <div>
   • « données personnelles » : toute information relative à une personne physique identifiée ou identifiable (« personne concernée ») ; une personne physique identifiable est une personne dont l'identité peut être établie, directement ou indirectement, notamment par référence à un identifiant ; tels que le nom, le numéro d'identité, les données de localisation, l'identifiant en ligne ou un ou plusieurs facteurs qui caractérisent l'identité physique, physiologique, génétique, psychologique, économique, culturelle ou sociale de la personne physique en question, tandis que
   </div>
   <div>
   • « traitement » : toute opération ou série d'opérations effectuées avec ou sans l'utilisation de moyens automatisés, sur des données personnelles ou des ensembles de données personnelles, telles que la collecte, l'enregistrement, l'organisation, la structuration, le stockage, l'adaptation ou la modification, la récupération, récupération d'informations, utilisation, divulgation par transmission, diffusion ou toute autre forme d'élimination, association ou combinaison, restriction, suppression ou destruction
   </div>
   <div>
   La protection concerne toutes les données personnelles qui sont venues ou viendront à la connaissance des Services de la Municipalité de La Canée, dans le cadre de son fonctionnement légal et de sa coopération avec les citoyens et les organismes du secteur public ou privé.
   </div>
   <div>
   Le règlement (UE) 2016/679 ne supprime pas les obligations découlant des dispositions d'autres lois. Les services de la municipalité de La Canée continuent de fonctionner et de fournir des services aux citoyens dans le cadre de leurs pouvoirs légalement documentés.
   </div>
   <div>
   La municipalité de La Canée, afin de fonctionner conformément au cadre législatif en vigueur, a pris une série d'actions nécessaires, en appliquant les mesures techniques et organisationnelles appropriées pour le respect légal, le traitement et la conservation des fichiers de données personnelles, afin d'assurer et de protéger de toutes les manières le traitement des données personnelles contre la perte ou la fuite, l'altération, la transmission ou de toute autre manière leur traitement illicite.
   </div>
   <h4>Légalité du traitement</h4>
   <div>
   La municipalité de La Canée en tant que responsable du traitement, au sens de l'article 4 par. 7 du Règlement général sur la protection des données, collecte des données à caractère personnel pour les besoins de l'exercice de ses attributions. La base juridique de ce traitement est l'article 6, paragraphe 1, points c et e du RGPD, également pour le traitement de catégories particulières de données à caractère personnel, il s'agit de l'article 9, paragraphe 2, points b, g et j du règlement ci-dessus .
   </div>
   <div>
   En ce qui concerne l'installation de cookies pour une navigation personnalisée sur notre site Internet, la base légale est votre consentement conformément à l'article 6 par. 1 (a) du RGPD.
   </div>
   <h4>Quelles sont les données que nous collectons – Principes de traitement</h4>
   <div>
   Les données personnelles collectées par les services de la municipalité de La Canée sont limitées à ce qui est nécessaire aux fins pour lesquelles elles sont traitées et sont absolument nécessaires au respect de toute obligation légale ou à l'accomplissement de ses fonctions, exécutées dans l'intérêt public ou l'exercice de la puissance publique qui lui est délégué.
   </div>
   <div>
   Le traitement des données personnelles (catégories simples et spéciales de "sensibles") par la Municipalité de La Canée est régi par les principes clairement décrits à l'article 5 du RGPD.
   </div>
   <div>
   Les services de la municipalité de La Canée traitent la TVA, conformément à la législation applicable et de manière transparente. Dans le cadre de la transparence, on cherche à informer le sujet de manière concise et compréhensible, avec des formulations claires et simples ("légalité, objectivité et transparence").
   </div>
   <div>
   Les données personnelles fournies à la municipalité de La Canée ne sont pas traitées ultérieurement d'une manière incompatible avec les finalités pour lesquelles elles sont collectées. Ces finalités sont définies, explicites et légales, dans le cadre de son fonctionnement et de la fourniture de services aux citoyens, (« limitation de finalité »).
   </div>
   <div>
   La Municipalité de La Canée ne collecte que les Données Personnelles appropriées, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées ("minimisation des données").
   </div>
   <div>
   L'objectif des services de la municipalité de La Canée est que les données personnelles collectées soient exactes et mises à jour, si nécessaire, en prenant toutes les mesures raisonnables pour corriger ou supprimer immédiatement les données inexactes en tenant compte des finalités du traitement ("exactitude") .
   </div>
   <div>
   BPI est conservé dans des fichiers physiques ou numériques aussi longtemps que nécessaire aux finalités du traitement. Cette durée est déterminée par la législation applicable ("limitation de la durée de conservation").
   </div>
   <div>
   Dans les services compétents de la municipalité, les mesures techniques et organisationnelles appropriées sont prises, afin que les NIF soient traités de manière à assurer la sécurité appropriée des données personnelles, telles que leur protection contre le traitement non autorisé ou illégal et la perte accidentelle, destruction ou attrition ("intégrité et confidentialité").
   </div>
   <div>
   La municipalité de La Canée est responsable du traitement du RGPD et est en mesure de prouver sa conformité aux principes du RGPD, devant les autorités de contrôle et les tribunaux ("responsabilité").
   </div>
   <div>
   Nous attirons votre attention sur le fait que la Municipalité de La Canée utilise des "cookies" sur son site Internet. Pour plus de détails sur les cookies, vous pouvez consulter les conditions d'utilisation sur son site Web (www.explorechania.gr).
   </div>

   <h4>Où nous divulguons des données</h4>
   <div>
   Le traitement des données personnelles est effectué exclusivement et uniquement aux fins pour lesquelles elles sont soumises.
   </div>
   <div>
   La Municipalité de La Canée ne vend, loue ou, de quelque manière que ce soit, accorde ou transmet à des tiers les informations personnelles soumises à ses Services. Une exception est le cas où la communication des données est requise par une disposition expresse de la loi et exclusivement aux autorités compétentes.
   </div>
   <div>
   Dans le cas où, après l'application de la législation applicable, lors de la conclusion de contrats publics, un entrepreneur apparaît qui, pendant l'exécution du contrat, a accès aux données personnelles traitées par la municipalité de La Canée, alors un contrat de confidentialité-confidentialité est signé sous la responsabilité des Services compétents ou les termes du présent contrat sont intégrés au contrat principal.
   </div>
   <div>
   L'action ci-dessus a pour but d'assurer des garanties suffisantes pour la mise en œuvre par les contractants de mesures techniques et organisationnelles appropriées, de manière à ce que le traitement réponde aux exigences du RGPD et assure la protection des droits de la personne concernée.
   </div>
  
   <h4>Comment nous protégeons les données</h4>
   <div>
   Le traitement des données personnelles est soumis aux termes de la présente politique de protection des données, ainsi qu'aux dispositions pertinentes de la législation grecque et du droit de l'UE sur la protection de l'individu et la protection des données personnelles.
   </div>
   <div>
   La municipalité de La Canée prend toutes les mesures de sécurité physiques, techniques et organisationnelles raisonnables afin que les données personnelles soient traitées de manière à garantir la sécurité appropriée des données personnelles, y compris leur protection contre le traitement non autorisé ou illégal et la perte accidentelle, la destruction ou la divulgation illégale et l'usure. .
   </div>
  
  
   <h4>Période de conservation des données</h4>
   <div>
   La Municipalité de La Canée ne conserve les données personnelles que pendant la durée nécessaire aux finalités de leur traitement, telle que définie dans chaque cas par la législation applicable, dans le cadre de l'accomplissement de ses missions et de l'exercice de l'autorité publique qui lui est attribuée, également aussi longtemps que nécessaire pour établir, exercer ou soutenir des réclamations légales.
   </div>



   <h4>Droits des personnes concernées</h4>
   <div>
   Les personnes concernées conservent tous les droits découlant des dispositions grecques et européennes applicables, en particulier les droits d'information, d'accès, de rectification, de complément, de suppression, de limitation du traitement de leurs données personnelles, étant entendu que le RGPD ne supprime pas les obligations découlant de dispositions d'autres lois.
   </div>
   <div>
   En particulier, dans le respect du cadre législatif de protection des données personnelles, tel qu'applicable à chaque fois, chaque personne concernée a le droit :
   </div>
   <div>
   • Information et accès aux données : Des informations plus nombreuses et plus claires sur ses données personnelles collectées et traitées par les Services de la Municipalité et le droit d'y accéder.
   </div>
   <div>
   • Correction : Correction ou complément de toute donnée incomplète ou inexacte concernant chaque personne concernée, par les Services compétents de la Commune.
   </div>
   <div>
   • Limitation du traitement et opposition au traitement : dans des conditions particulières, lorsque cela est prévu par les dispositions législatives applicables.
   </div>
   <div>
   • Aux oubliettes : Suppression des données, à condition que les données ne soient pas conservées dans un but légal et déclaré déterminé, tel que défini dans chaque cas par la législation applicable, sous la responsabilité du Service concerné.
   </div>
   <div>
   • Portabilité des données : transfert de données, sous une forme lisible par machine, d'un responsable du traitement à un autre dans des conditions spécifiques.
   </div>
   <div>
   Lorsque l'exercice des droits ci-dessus dépend exclusivement des actions des services de la municipalité de La Canée, après vérification de la légitimité de la demande, la réponse de la municipalité de La Canée à la demande a lieu dans le délai d'un mois prévu par le Règlement. En tout état de cause, une information est fournie pour tout besoin justifié de prolongation de ce délai.
   </div>


   <h4>Notification des modifications</h4>
   <div>
   Nous nous réservons le droit de modifier les conditions ci-dessus conformément au cadre légal en vigueur.
   </div>
   <div>
   Dans le cas où les conditions ci-dessus subiraient des modifications, une mise à jour sera fournie avec des annonces via notre site Web.
   </div>

   <h4>Droit applicable et juridiction</h4>
   Pour tout litige survenant entre l'utilisateur et la municipalité de La Canée, la loi applicable est la loi grecque et le tribunal compétent pour résoudre le litige est les tribunaux compétents de La Canée.
  
   <h4>Liens</h4>
   <div>
   Notre site Web peut contenir des liens vers d'autres sites Web. Cette déclaration sur la protection des données personnelles s'applique uniquement à l'accès de l'utilisateur à ce site Web, mais également à d'autres sites Web gérés exclusivement par la municipalité de La Canée. En aucun cas l'administrateur de ce portail web n'est responsable des conditions de protection des données personnelles des autres sites web, qui sont sous la responsabilité de tiers (personnes physiques ou morales).
   </div>
  
  
   <h4>Comment exercer vos droits</h4>
   <div>
   La municipalité de La Canée vous informe qu'elle a nommé un délégué à la protection des données personnelles, que vous pouvez contacter à l'adresse e-mail dpo@chania.gr.
   </div>
   <div>
   Si vous avez des questions ou des recommandations concernant cette politique, vous pouvez les adresser à l'adresse ci-dessus et au numéro de contact ci-dessus. Les développements continus sur Internet en général rendent nécessaire d'adapter de temps à autre nos règles de protection des données en ligne. La municipalité de La Canée se réserve le droit d'apporter à tout moment les modifications appropriées à ces règles.
   </div>
  
   <h4>Droit de recours auprès de l'Autorité</h4>
   <div>
   Pour les questions concernant le traitement de vos données personnelles, vous avez le droit de faire appel auprès de l'Autorité de protection des données personnelles (Kifisias 1-3, 115 23, Athènes, tél. 2106475600). Pour la compétence de l'Autorité et comment déposer une plainte, vous pouvez visiter son site Web (www.dpa.gr / Mes droits / Déposer une plainte), où des informations détaillées sont disponibles.
   </div>
  
  
   <h4>Dispositions finales</h4>
   <div>
   Les conditions ci-dessus, ainsi que toute modification de celles-ci, sont régies et complétées par le droit grec, le droit de l'Union européenne et les traités internationaux pertinents. Toute disposition des conditions ci-dessus qui deviendrait contraire à la loi, cesse automatiquement d'être valable et en est supprimée, sans affecter en rien la validité des autres conditions.
   </div>


  `
  },
'de':{
  id: 4,
  title: 'Datenschutz-Bestimmungen',
  text:`
  <div>
  Der Schutz personenbezogener Daten (PID) ist ein zentrales Anliegen der Gemeinde Chania. Im Zusammenhang mit der Datenschutz-Grundverordnung (EU) 2016/679 (DSGVO), die am 25.05.2018 in Kraft getreten ist, enthält dieser Text nützliche Informationen zur Verarbeitung personenbezogener Daten und den Rechten der Betroffenen der Verarbeitung gemäß Artikel 13 der oben genannten Verordnung.
  </div>
  <div>
  Gemäß der oben genannten Verordnung wird in Artikel 4 angegeben, dass sie verstanden werden als:
  </div>
  <div>
  • „personenbezogene Daten“: alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person („betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, deren Identität direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung, bestimmbar ist; wie Name, Identitätsnummer, Standortdaten, Online-Kennung oder ein oder mehrere Merkmale, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität der betreffenden natürlichen Person sind, während
  </div>
  <div>
  • „Verarbeitung“: jeder mit oder ohne Einsatz automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe an personenbezogenen Daten oder Sätzen personenbezogener Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder die Veränderung, das Auslesen, Abrufen von Informationen, Nutzung, Offenlegung durch Übermittlung, Verbreitung oder jede andere Form der Verfügung, Verknüpfung oder Kombination, Einschränkung, Löschung oder Vernichtung
  </div>
  <div>
  Der Schutz betrifft alle personenbezogenen Daten, die den Diensten der Gemeinde Chania im Rahmen ihrer rechtlichen Tätigkeit und ihrer Zusammenarbeit mit Bürgern und öffentlichen oder privaten Stellen bekannt wurden oder werden.
  </div>
  <div>
  Die Verordnung (EU) 2016/679 hebt keine Verpflichtungen auf, die sich aus Bestimmungen anderer Gesetze ergeben. Die Dienste der Gemeinde Chania werden weiterhin betrieben und erbringen Dienstleistungen für die Bürger im Rahmen ihrer gesetzlich dokumentierten Befugnisse.
  </div>
  <div>
  Die Gemeinde Chania hat, um in Übereinstimmung mit dem aktuellen Rechtsrahmen zu arbeiten, eine Reihe von notwendigen Maßnahmen ergriffen und die geeigneten technischen und organisatorischen Maßnahmen zur Einhaltung, Verarbeitung und sicheren Aufbewahrung personenbezogener Daten angewendet, um sicherzustellen und die Verarbeitung personenbezogener Daten in jeder Weise vor Verlust oder Verlust, Änderung, Übertragung oder auf andere Weise vor ihrer rechtswidrigen Verarbeitung zu schützen.
  </div>
  <h4>Rechtmäßigkeit der Verarbeitung</h4>
  <div>
  Die Gemeinde Chania als Datenverantwortlicher im Sinne von Artikel 4 Abs. 7 der Datenschutz-Grundverordnung erhebt personenbezogene Daten zum Zweck der Ausübung seiner Befugnisse. Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. c und e DSGVO, auch für die Verarbeitung besonderer Kategorien personenbezogener Daten Art. 9 Abs. 2 lit. b, g und j der vorstehenden Verordnung .
  </div>
  <div>
  Rechtsgrundlage für das Setzen von Cookies zum personalisierten Surfen auf unserer Website ist Ihre Einwilligung gem. Art. 6 Abs. 1 lit. 1 (a) der DSGVO.
  </div>
  <h4>Welche Daten wir sammeln – Grundsätze der Verarbeitung</h4>
  <div>
  Die von den Diensten der Gemeinde Chania erhobenen personenbezogenen Daten beschränken sich auf das, was für die Zwecke, für die sie verarbeitet werden, erforderlich ist, und sind für die Erfüllung einer gesetzlichen Verpflichtung oder die Erfüllung ihrer Aufgaben im öffentlichen Interesse oder die Ausübung der ihm übertragenen öffentlichen Gewalt.
  </div>
  <div>
  Die Verarbeitung personenbezogener Daten (einfache und besondere Kategorien von „sensiblen“) durch die Gemeinde Chania unterliegt den in Artikel 5 der DSGVO klar beschriebenen Grundsätzen.
  </div>
  <div>
  Die Dienste der Gemeinde Chania verarbeiten die Mehrwertsteuer gemäß den geltenden Gesetzen und transparent. Im Rahmen der Transparenz wird versucht, das Subjekt prägnant, verständlich, mit klaren und einfachen Formulierungen („Rechtmäßigkeit, Objektivität und Transparenz“) zu informieren.
  </div>
  <div>
  Personenbezogene Daten, die der Gemeinde Chania zur Verfügung gestellt werden, werden nicht in einer Weise weiterverarbeitet, die mit den Zwecken, für die sie erhoben wurden, unvereinbar ist. Diese Zwecke sind im Zusammenhang mit ihrem Betrieb und der Bereitstellung von Diensten für Bürger ("Zweckbeschränkung") definiert, ausdrücklich und legal.
  </div>
  <div>
  Die Gemeinde Chania erhebt nur die personenbezogenen Daten, die für die Zwecke, für die sie verarbeitet werden, angemessen, relevant und auf das Notwendige beschränkt sind („Datenminimierung“).
  </div>
  <div>
  Das Ziel der Dienste der Gemeinde Chania besteht darin, dass die gesammelten personenbezogenen Daten korrekt und bei Bedarf aktualisiert werden, indem alle angemessenen Maßnahmen ergriffen werden, um ungenaue Daten unter Berücksichtigung der beabsichtigten Zwecke der Verarbeitung („Genauigkeit“) unverzüglich zu korrigieren oder zu löschen. .
  </div>
  <div>
  BPI werden so lange in physischen oder digitalen Dateien aufbewahrt, wie es für die Zwecke der Verarbeitung erforderlich ist. Diese Dauer bestimmt sich nach den anwendbaren Rechtsvorschriften („Beschränkung der Speicherdauer“).
  </div>
  <div>
  In den zuständigen Diensten der Gemeinde werden die geeigneten technischen und organisatorischen Maßnahmen ergriffen, damit die TINs so verarbeitet werden, dass die angemessene Sicherheit personenbezogener Daten gewährleistet ist, z. B. deren Schutz vor unbefugter oder rechtswidriger Verarbeitung und versehentlichem Verlust, Zerstörung oder Verschleiß ("Integrität und Vertraulichkeit").
  </div>
  <div>
  Die Gemeinde Chania ist für die Verarbeitung der DSGVO verantwortlich und kann ihre Einhaltung der Grundsätze der DSGVO vor den Aufsichtsbehörden und Gerichten nachweisen („Rechenschaftspflicht“).
  </div>
  <div>
  Wir weisen darauf hin, dass die Gemeinde Chania auf ihrer Website „Cookies“ verwendet. Einzelheiten zu Cookies finden Sie in den Nutzungsbedingungen auf der Website (www.explorechania.gr).
  </div>

  <h4>Wo wir Daten offenlegen</h4>
  <div>
  Die Verarbeitung personenbezogener Daten erfolgt ausschließlich und nur für die Zwecke, für die sie übermittelt wurden.
  </div>
  <div>
  Die Gemeinde Chania verkauft, vermietet oder gewährt oder überträgt auf keinen Fall personenbezogene Daten, die an ihre Dienste übermittelt werden, an Dritte. Eine Ausnahme bildet der Fall, in dem die Übermittlung der Daten durch eine ausdrückliche gesetzliche Vorschrift und ausschließlich an die zuständigen Behörden erforderlich ist.
  </div>
  <div>
  Für den Fall, dass nach Anwendung der geltenden Gesetze beim Abschluss öffentlicher Verträge ein Auftragnehmer erscheint, der während der Vertragsausführung Zugang zu personenbezogenen Daten hat, die von der Gemeinde Chania verarbeitet werden, dann ein Vertraulichkeitsvertrag wird unter der Verantwortung der zuständigen Dienste unterzeichnet oder die Bedingungen dieses Vertrages werden in den Hauptvertrag aufgenommen.
  </div>
  <div>
  Ziel der oben genannten Maßnahmen ist es, ausreichende Garantien für die Umsetzung geeigneter technischer und organisatorischer Maßnahmen durch die Auftragnehmer zu gewährleisten, sodass die Verarbeitung den Anforderungen der DSGVO entspricht und den Schutz der Rechte der betroffenen Person gewährleistet.
  </div>
 
  <h4>Wie wir Daten schützen</h4>
  <div>
  Die Verarbeitung personenbezogener Daten unterliegt den Bestimmungen dieser Datenschutzrichtlinie sowie den einschlägigen Bestimmungen der griechischen Gesetzgebung und des EU-Rechts zum Schutz des Einzelnen und zum Schutz personenbezogener Daten.
  </div>
  <div>
  Die Gemeinde Chania ergreift alle angemessenen physischen, technischen und organisatorischen Sicherheitsmaßnahmen, damit personenbezogene Daten auf eine Weise verarbeitet werden, die die angemessene Sicherheit personenbezogener Daten gewährleistet, einschließlich ihres Schutzes vor unbefugter oder rechtswidriger Verarbeitung und versehentlichem Verlust, rechtswidriger Zerstörung oder Offenlegung und Abnutzung .
  </div>
 
 
  <h4>Dauer der Datenaufbewahrung</h4>
  <div>
  Die Gemeinde Chania speichert die personenbezogenen Daten nur für den Zeitraum, der für die Zwecke ihrer Verarbeitung erforderlich ist, wie dies jeweils durch die geltenden Rechtsvorschriften im Rahmen der Erfüllung ihrer Aufgaben und der Ausübung der ihr übertragenen öffentlichen Gewalt festgelegt ist, auch so lange, wie dies zur Geltendmachung, Ausübung oder Unterstützung von Rechtsansprüchen erforderlich ist.
  </div>



  <h4>Rechte der betroffenen Personen</h4>
  <div>
  Die betroffenen Personen behalten alle Rechte, die sich aus den geltenden griechischen und EU-Bestimmungen ergeben, insbesondere das Recht auf Auskunft, Auskunft, Berichtigung, Vervollständigung, Löschung, Einschränkung der Verarbeitung ihrer personenbezogenen Daten, wobei zu beachten ist, dass die DSGVO keine Verpflichtungen aufhebt, die sich daraus ergeben Bestimmungen anderer Gesetze.
  </div>
  <div>
  Insbesondere hat jede betroffene Person in Übereinstimmung mit dem jeweils geltenden Rechtsrahmen zum Schutz personenbezogener Daten das Recht:
  </div>
  <div>
  • Informationen und Zugang zu Daten: Mehr und klarere Informationen über seine personenbezogenen Daten, die von den Gemeindediensten erfasst und verarbeitet werden, und das Recht auf Zugang zu ihnen.
  </div>
  <div>
  • Berichtigung: Berichtigung oder Vervollständigung unvollständiger oder ungenauer Daten zu jeder betroffenen Person durch die zuständigen Dienststellen der Gemeinde.
  </div>
  <div>
  • Einschränkung der Verarbeitung und Widerspruch gegen die Verarbeitung: unter bestimmten Bedingungen, wenn dies in den geltenden Rechtsvorschriften vorgesehen ist.
  </div>
  <div>
  • In Vergessenheit: Löschung der Daten, sofern die Daten nicht für einen bestimmten gesetzlichen und erklärten Zweck aufbewahrt werden, wie in jedem Fall durch die anwendbaren Rechtsvorschriften definiert, unter der Verantwortung des jeweiligen Dienstes.
  </div>
  <div>
  • Datenübertragbarkeit: Übertragung von Daten in maschinenlesbarer Form von einem für die Datenverarbeitung Verantwortlichen zu einem anderen unter bestimmten Bedingungen.
  </div>
  <div>
  Wenn die Ausübung der oben genannten Rechte ausschließlich von den Maßnahmen der Dienste der Gemeinde Chania abhängt, erfolgt die Antwort der Gemeinde Chania auf die Anfrage nach Überprüfung der Rechtmäßigkeit der Anfrage innerhalb der vorgesehenen Frist von einem Monat durch die Verordnung. In jedem Fall wird über eine berechtigte Verlängerung dieser Frist informiert.
  </div>


  <h4>Benachrichtigung über Änderungen</h4>
  <div>
  Wir behalten uns das Recht vor, die vorstehenden Bedingungen unter Beachtung der aktuellen gesetzlichen Rahmenbedingungen zu ändern.
  </div>
  <div>
  Für den Fall, dass sich die oben genannten Bedingungen ändern, wird ein Update mit Ankündigungen über unsere Website bereitgestellt.
  </div>

  <h4>Anwendbares Recht und Gerichtsstand</h4>
  Für alle Streitigkeiten zwischen dem Benutzer und der Gemeinde Chania gilt das griechische Recht und das zuständige Gericht zur Beilegung des Streits sind die zuständigen Gerichte von Chania.
 
  <h4>Links</h4>
  <div>
  Unsere Website kann Links zu anderen Websites enthalten. Diese Erklärung zum Schutz personenbezogener Daten gilt nur für den Zugriff des Benutzers auf diese Website, aber auch auf andere Websites, die ausschließlich von der Gemeinde Chania verwaltet werden. In keinem Fall ist der Administrator dieses Webportals verantwortlich für die Datenschutzbedingungen anderer Websites, die der Verantwortung Dritter (natürliche oder juristische Personen) unterliegen.
  </div>
 
 
  <h4>Wie Sie Ihre Rechte ausüben</h4>
  <div>
  Die Gemeinde Chania teilt Ihnen mit, dass sie einen Datenschutzbeauftragten ernannt hat, den Sie unter der E-Mail-Adresse dpo@chania.gr kontaktieren können.
  </div>
  <div>
  Wenn Sie Fragen oder Empfehlungen zu dieser Richtlinie haben, können Sie diese an die oben genannte Adresse und die oben genannte Kontaktnummer richten. Die ständige Weiterentwicklung des Internets im Allgemeinen macht von Zeit zu Zeit Anpassungen unserer Online-Datenschutzbestimmungen erforderlich. Die Gemeinde Chania behält sich das Recht vor, jederzeit angemessene Änderungen an diesen Regeln vorzunehmen.
  </div>
 
  <h4>Beschwerderecht bei der Behörde</h4>
  <div>
  Bei Fragen zur Verarbeitung Ihrer personenbezogenen Daten haben Sie das Recht, sich an die Datenschutzbehörde (Kifisias 1-3, 115 23, Athen, Tel. 2106475600) zu wenden. Informationen zur Zuständigkeit der Behörde und zum Einreichen einer Beschwerde finden Sie auf ihrer Website (www.dpa.gr / Meine Rechte / Beschwerde einreichen), auf der detaillierte Informationen verfügbar sind.
  </div>
 
 
  <h4>Schlussbestimmungen</h4>
  <div>
  Die oben genannten Bedingungen sowie alle Änderungen davon unterliegen dem griechischen Recht, dem Recht der Europäischen Union und den einschlägigen internationalen Verträgen und werden ergänzt. Jede Bestimmung der vorstehenden Bedingungen, die dem Gesetz widerspricht, verliert automatisch ihre Gültigkeit und wird von diesen entfernt, ohne die Gültigkeit der anderen Bedingungen in irgendeiner Weise zu berühren.
  </div>

  `
  },
'ru':{
  id: 5,
  title: 'Политика конфиденциальности',
  text:`
  <div>
  Защита персональных данных (PID) является ключевой задачей муниципалитета Ханьи. В контексте Общего регламента по защите данных (ЕС) 2016/679 (GDPR), вступившего в силу 25.05.2018, этот текст содержит полезную информацию об обработке персональных данных и правах субъектов. обработки в соответствии со статьей 13 вышеуказанного Регламента.
  </div>
  <div>
  Согласно вышеуказанному Положению, в статье 4 указано, что под ними понимаются:
  </div>
  <div>
  • «персональные данные»: любая информация, относящаяся к идентифицированному или идентифицируемому физическому лицу («субъекту данных»); идентифицируемое физическое лицо — это лицо, личность которого может быть установлена ​​прямо или косвенно, в частности, по идентификатору; например, имя , идентификационный номер, данные о местоположении, онлайн-идентификатор или один или несколько факторов, характеризующих физическую, физиологическую, генетическую, психологическую, экономическую, культурную или социальную идентичность рассматриваемого физического лица, при этом
  </div>
  <div>
  • «обработка»: любая операция или ряд операций, выполняемых с использованием или без использования автоматизированных средств с персональными данными или наборами персональных данных, таких как сбор, регистрация, организация, структурирование, хранение, адаптация или изменение, извлечение, поиск информации, использование, разглашение путем передачи, распространения или любой другой формы распоряжения, ассоциации или сочетания, ограничения, удаления или уничтожения
  </div>
  <div>
  Защита касается всех персональных данных, которые стали или станут известны службам муниципалитета Ханьи в контексте их законной деятельности и сотрудничества с гражданами и органами государственного или частного сектора.
  </div>
  <div>
  Регламент (ЕС) 2016/679 не отменяет обязательств, вытекающих из положений других законов. Службы муниципалитета Ханьи продолжают работать и предоставлять услуги гражданам в рамках своих юридически оформленных полномочий.
  </div>
  <div>
  Муниципалитет Ханьи, чтобы действовать в соответствии с действующей законодательной базой, предпринял ряд необходимых действий, применив соответствующие технические и организационные меры для соблюдения закона, обработки и безопасного хранения файлов персональных данных, чтобы обеспечить и всячески защищать обработку персональных данных от потери или утечки, изменения, передачи или иным образом их неправомерной обработки.
  </div>
  <h4>Законность обработки</h4>
  <div>
  Муниципалитет Ханьи в качестве контролера данных по смыслу статьи 4 п. 7 Общего регламента по защите данных собирает персональные данные в целях осуществления своих полномочий. Правовой основой для такой обработки является статья 6, пункт 1, пункты c и e Общего регламента по защите данных, а также для обработки особых категорий персональных данных, это статья 9, пункт 2, пункты b, g и j вышеуказанного регламента. .
  </div>
  <div>
  Что касается установки файлов cookie для персонализированного просмотра нашего веб-сайта, правовым основанием является ваше согласие в соответствии со статьей 6, пункт 1 (a) GDPR.
  </div>
  
  
  <h4>Какие данные мы собираем – Принципы обработки</h4>
  <div>
  Персональные данные, собираемые Службами муниципалитета Ханьи, ограничены тем, что необходимо для целей, для которых они обрабатываются, и абсолютно необходимы для его соблюдения любого юридического обязательства или выполнения своих обязанностей, выполняемых в общественных интересах или осуществление divегированных ему государственных полномочий.
  </div>
  <div>
  Обработка персональных данных (простых и специальных категорий «конфиденциальных») муниципалитетом Ханьи регулируется принципами, четко описанными в статье 5 GDPR.
  </div>
  <div>
  Службы муниципалитета Ханьи обрабатывают НДС в соответствии с применимым законодательством и прозрачно. В контексте прозрачности стремятся информировать субъекта в сжатой, понятной форме, с четкими и простыми формулировками («законность, объективность и прозрачность»).
  </div>
  <div>
  Персональные данные, предоставленные муниципалитету Ханьи, не обрабатываются в дальнейшем способом, несовместимым с целями, для которых они собираются. Эти цели опреdivены, явны и законны в контексте его работы и предоставления услуг гражданам («ограничение цели»).
  </div>
  <div>
  Муниципалитет Ханьи собирает только те Персональные данные, которые являются соответствующими, актуальными и ограничиваются тем, что необходимо для целей, для которых они обрабатываются («минимизация данных»).
  </div>
  <div>
  Цель Услуг муниципалитета Ханьи состоит в том, чтобы собранные персональные данные были точными и при необходимости обновлялись, принимая все разумные меры для немедленного исправления или удаления неточных данных с учетом предполагаемых целей обработки («точность») .
  </div>
  <div>
  BPI хранится в физических или цифровых файлах столько времени, сколько необходимо для целей обработки. Этот срок опреdivяется действующим законодательством («ограничение срока хранения»).
  </div>
  <div>
  В компетентных службах муниципалитета принимаются соответствующие технические и организационные меры, чтобы ИНН обрабатывались таким образом, чтобы обеспечить надлежащую безопасность персональных данных, такую ​​как их защита от несанкционированной или незаконной обработки и случайной потери, уничтожение или истощение («целостность и конфиденциальность»).
  </div>
  <div>
  Муниципалитет Ханьи отвечает за обработку GDPR и может доказать его соответствие принципам GDPR перед надзорными органами и судами («подотчетность»).
  </div>
  <div>
  Обращаем ваше внимание на то, что муниципалитет Ханьи использует на своем веб-сайте файлы cookie. Для получения подробной информации о файлах cookie вы можете ознакомиться с Условиями использования на его веб-сайте (www.explorechania.gr).
  </div>


  <h4>Где мы раскрываем данные</h4>
  <div>
  Обработка персональных данных осуществляется исключительно и только для тех целей, для которых они предоставлены.
  </div>
  <div>
  Муниципалитет Ханьи ни при каких обстоятельствах не продает, не сдает в аренду или иным образом не предоставляет и не передает третьим лицам личную информацию, представленную в его Сервисах. Исключением является случай, когда передача данных требуется в соответствии с прямым положением закона и исключительно компетентным органам.
  </div>
  <div>
  В случае, если после применения применимого законодательства при заключении публичных договоров появляется подрядчик, который в ходе исполнения договора имеет доступ к персональным данным, обрабатываемым муниципалитетом Ханьи, то договор о конфиденциальности-конфиденциальности подписывается под ответственность компетентных служб или условия этого договора включены в основной договор.
  </div>
  <div>
  Целью вышеуказанного действия является обеспечение достаточных гарантий для реализации подрядчиками соответствующих технических и организационных мер таким образом, чтобы обработка соответствовала требованиям GDPR и обеспечивала защиту прав субъекта данных.
  </div>

  <h4>Как мы защищаем данные</h4>
  <div>
  Обработка персональных данных регулируется условиями настоящей политики защиты данных, а также соответствующими положениями греческого законодательства и законодательства ЕС о защите личности и защите персональных данных.
  </div>
  <div>
  Муниципалитет Ханьи принимает все разумные физические, технические и организационные меры безопасности, чтобы личные данные обрабатывались таким образом, чтобы гарантировать надлежащую безопасность личных данных, включая их защиту от несанкционированной или незаконной обработки и случайной потери, незаконного уничтожения или раскрытия и износа. .
  </div>

  <h4>Срок хранения данных</h4>
  <div>
  Муниципалитет Ханьи хранит персональные данные только в течение периода, необходимого для целей их обработки, как опреdivено в каждом случае применимым законодательством, в контексте выполнения своих обязанностей и осуществления возложенных на него государственных полномочий, также до тех пор, пока это необходимо для установления, осуществления или поддержки судебных исков.
  </div>

  <h4>Права субъектов данных</h4>
  <div>
  Субъекты данных сохраняют за собой все права, вытекающие из применимых положений Греции и ЕС, в частности права на информацию, доступ, исправление, дополнение, удаление, ограничение обработки их персональных данных, принимая во внимание, что GDPR не отменяет обязательств, вытекающих из положения других законов.
  </div>
  <div>
  В частности, в соответствии с законодательной базой о защите персональных данных, применимой каждый раз, каждому субъекту данных предоставляется право:
  </div>
  <div>
  • Информация и доступ к данным: Более подробная и четкая информация о его личных данных, собираемых и обрабатываемых Муниципальными службами, и право на доступ к ним.
  </div>
  <div>
  • Исправление: исправление или дополнение любых неполных или неточных данных, касающихся каждого субъекта данных, компетентными службами муниципалитета.
  </div>
  <div>
  • Ограничение обработки и возражение против обработки: при опреdivенных условиях, когда это предусмотрено применимыми законодательными положениями.
  </div>
  <div>
  • В забвение: удаление данных при условии, что данные не хранятся для конкретной законной и заявленной цели, опреdivенной в каждом конкретном случае применимым законодательством, под ответственность соответствующей службы.
  </div>
  <div>
  • Переносимость данных: передача данных в машиночитаемой форме от одного контроллера данных к другому при опреdivенных условиях.
  </div>
  <div>
  Когда осуществление вышеуказанных прав зависит исключительно от действий служб муниципалитета Ханьи, после проверки законности запроса ответ муниципалитета Ханьи на запрос осуществляется в течение предусмотренного месячного срока. По Положению. В любом случае предоставляется информация о любой обоснованной необходимости продления этого периода.
  </div>

  <h4>Уведомление об изменениях</h4>
  <div>
  Мы оставляем за собой право изменять вышеуказанные условия в соответствии с действующим законодательством.
  </div>
  <div>
  В случае, если вышеуказанные условия претерпят какие-либо изменения, обновление будет предоставлено с объявлениями через наш веб-сайт.
  </div>


  <h4>Применимое законодательство и юрисдикция</h4>
  Для любого спора, возникающего между пользователем и муниципалитетом Ханьи, применимым правом является греческий закон, а компетентным судом для разрешения спора являются компетентные суды Ханьи.
  
  <h4>Ссылки</h4>
  <div>
  Наш веб-сайт может содержать ссылки на другие веб-сайты. Это заявление о защите персональных данных относится только к доступу пользователя к этому веб-сайту, а также к другим веб-сайтам, управляемым исключительно муниципалитетом Ханьи. Ни в коем случае администратор этого веб-портала не несет ответственности за условия защиты персональных данных других веб-сайтов, которые находятся под ответственностью третьих лиц (физических или юридических лиц).
  </div>
  
  
  <h4>Как воспользоваться своими правами</h4>
  <div>
  Муниципалитет Ханьи сообщает вам, что он назначил сотрудника по защите персональных данных, с которым вы можете связаться по адресу электронной почты dpo@chania.gr.
  </div>
  <div>
  Если у вас есть вопросы или рекомендации относительно настоящей Политики, вы можете направить их по указанному выше адресу и указанному выше контактному номеру. Постоянное развитие Интернета в целом приводит к необходимости время от времени корректировать наши правила защиты данных в Интернете. Муниципалитет Ханьи оставляет за собой право вносить любые соответствующие изменения в эти правила в любое время.
  </div>
  
  <h4>Право на апелляцию в орган</h4>
  <div>
  По вопросам, связанным с обработкой ваших персональных данных, вы имеете право обратиться в Управление по защите персональных данных (Кифисиас 1-3, 115 23, Афины, тел. 2106475600). Чтобы узнать о компетенции Управления и о том, как подать жалобу, вы можете посетить его веб-сайт (www.dpa.gr/Мои права/Подать жалобу), где доступна подробная информация.
  </div>
  
  <h4>Заключительные положения</h4>
  <div>
  Вышеуказанные условия, а также любые их изменения регулируются и дополняются законодательством Греции, законодательством Европейского Союза и соответствующими международными договорами. Любое положение вышеуказанных условий, которое становится противоречащим закону, автоматически перестает быть действительным и удаляется из него, никоим образом не влияя на действительность остальных условий.
  </div>

 `
  }
}
]
