import React from "react"
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../components/Helmet'

// const Slider = lazy(() => import('./components/Slider'))

import Slider from './components/Slider'
import Search from './components/Search'
import Categories from './components/Categories'
import About from './components/About'
import Destinations from './components/Destinations'
import Events from './components/Events'

const Home = () => {

  const { t } = useTranslation()
  
  window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
  
  return (
    <>
      <HelmetComponent title={t('SEO Home title') || undefined} descrition={t('SEO Home description') || undefined} />

      <Slider />
      <Search />
      <Categories />
      <About />
      <Destinations /> 
      <Events />
    </>
  )
}

export default Home