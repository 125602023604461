import axios from 'axios'

const API = 'https://chania.mitos.cityguideplatform.com/api'
const X_API_KEY = 'hfG7u8C4UNMw17E6Jyzq'


export const axiosAreas = {

  async getAreas() {
    const { data } = await axios.get(`${API}/mainareas`, { headers: { 
      'X-API-KEY': X_API_KEY
    }})
    return data.success
  }

}