import React from 'react'
import { Row, Col, Button, Divider } from 'antd'
import classes from "./styles.module.css"
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { ArrowRightOutlined } from '@ant-design/icons'


interface Props{
  image: string
  Title: string
  FacebookLink?: string
  TwitterLink?: string
  InstagramLink?: string
  YoutubeLink?: string
  TikTokLink?:string
}

const ComponentBtn: React.FC<Props> = ({ image, Title, FacebookLink, TwitterLink, InstagramLink, YoutubeLink, TikTokLink }: Props) => {
  
  const { t } = useTranslation()

  return (
    <>
      <div className={classes.MunicipalityTopTitle}>
        <div className={classes.MunicipalityImage}><img src={image} alt={Title} style={{width: '70px',height: 'auto'}} /></div>
        <div className={classes.MunicipalityTitle}>{Title}</div>
      </div>

      <Divider />

      <Row justify="center">
        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className={classes.MunicipalitySocial}>Facebook</div>
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Button
            href={FacebookLink}
            target='_blank'
            size='large'
            className={classes.GoToBtn}>{t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
          </Button>
        </Col>
      </Row>

      {InstagramLink &&
      <>
        <Divider />
        <Row justify="center">
          <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <div className={classes.MunicipalitySocial}>Instagram</div>
          </Col>
          <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <Button
              href={InstagramLink}
              target='_blank'
              size='large'
              className={classes.GoToBtn}>{t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
            </Button>
          </Col>
        </Row>
      </>}
      
      {TwitterLink &&
      <>
        <Divider />
        <Row justify="center">
          <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <div className={classes.MunicipalitySocial}>Twitter</div>
          </Col>
          <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <Button
              href={TwitterLink}
              target='_blank'
              size='large'
              className={classes.GoToBtn}>{t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
            </Button>
          </Col>
        </Row>
      </>}

      {YoutubeLink &&
      <>
        <Divider />
        <Row justify="center">
          <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <div className={classes.MunicipalitySocial}>Youtube</div>
          </Col>
          <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <Button
              href={YoutubeLink}
              target='_blank'
              size='large'
              className={classes.GoToBtn}>{t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
            </Button>
          </Col>
        </Row>
        </>}
      
        {TikTokLink &&
        <>
          <Divider />
          <Row justify="center">
            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <div className={classes.MunicipalitySocial}>Tik Tok</div>
            </Col>
            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <Button
                href={TikTokLink}
                target='_blank'
                size='large'
                className={classes.GoToBtn}>{t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
              </Button>
            </Col>
          </Row>
        </>}
      
    </>
  )

}

export default ComponentBtn
