import React, {useState, useEffect} from "react"
import { Checkbox, Col, Row, Collapse } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useTranslation } from "react-i18next"
import classes from "../styles.module.css"


// Redux
import { useDispatch } from 'react-redux'
import { ExplorationPageNumberRedux } from '../../../redux/mapPage/category'
import { HistorySearchRedux } from '../../../redux/mapPage/search' 

import type { RootState } from '../../../redux/store'
import { useSelector } from 'react-redux'


const { Panel } = Collapse;


interface Props{
  checkedId: number;
  icon?: any;
  header?: string;
  key?: any;
  array: any;
  type: string;
  EnvironmentChecked?: CheckboxValueType[]
}

const HistoryCheckBoxComponent: React.FC<Props> = ({ key, icon, header, array, type }) => {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  
  const ReduxHistory: any[] = useSelector((state: RootState) => state.Searches.History)

  const [check, setCheck] = useState<boolean>(false)
  const [viewDeselect, setViewDeselect] = useState<boolean>(false)
  
  const onChange = (list: CheckboxValueType[]) => {

    dispatch(HistorySearchRedux(list))

    dispatch(ExplorationPageNumberRedux(1))
  }


  const deletAllChecked = () => dispatch(HistorySearchRedux(''))
  
  useEffect(() => {
    
    ReduxHistory.length == 0 ? setViewDeselect(false) : setViewDeselect(true)
 
  }, [ReduxHistory])
  
  const MyCheckBox = ({ array, type}: any) => {
  
    return (

    <Checkbox.Group
      style={{ width: '100%' }}
      onChange={onChange}
      defaultValue={ReduxHistory[0] }
    >
      <Row>
        {array.slice(0, 5).map((item: any) => {

          return (
            <Col span={24} key={item.id} className={classes.checkboxGroup}>
              <Checkbox value={type== 'events' ? item.name : item.id}>{item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()}</Checkbox>
            </Col>
          )
        })}

        <Collapse ghost expandIconPosition='start' onChange={()=> setCheck(!check)} defaultActiveKey={[check && type]}>

          <Panel header={t("Εμφάνιση όλων") + ' (' + (array.length - 5) + ')'} key={type} className={classes.collapse} >

            {array.slice(5, array.length).map((item: any) => {

              return (
                <Col span={24} key={item.id} className={classes.checkboxGroup}>
                  <Checkbox value={type == 'events' ? item.name : item.id}>{item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()}</Checkbox>
                </Col>
              )
            })}

          </Panel>
        </Collapse>
        {viewDeselect &&  
          <Col span={24} key={999} className={classes.checkboxGroup}>
            <div className={ classes.delDIV } onClick={deletAllChecked}>
              <span className={ classes.delX}>X</span>
              <span>{t('Αφαίρεση Επιλογών')}</span> 
            </div>
          </Col>
        }
      </Row>
    </Checkbox.Group>
    )
  }


  return (
    <>
      {header && <div className={classes.divider}>{icon}<span className={classes.dividerSpan2}>{header}</span></div>}

      <MyCheckBox array={array} type={type} />
    </>
  )
  
}

export default HistoryCheckBoxComponent
