import React from "react"
import { Checkbox, Col, Row, Collapse } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useTranslation } from "react-i18next"
import classes from "./styles.module.css"


// Redux
import { useDispatch } from 'react-redux'
import { History, Environment, Municipality, ExplorationPageNumberRedux }  from '../../redux/mapPage/category'
import type { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'



const { Panel } = Collapse;


interface Props{
  checkedId: number;
  icon?: any;
  header?: string;
  array: any;
  type: string;
  EnvironmentChecked?: CheckboxValueType[]
}

const CheckBoxComponent: React.FC<Props> = ({ checkedId, icon, header, array, type, EnvironmentChecked }) => {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  
  const ReduxHistory: any[] = useSelector((state: RootState) => state.Categories.History)
  const ReduxEnvironment: any[] = useSelector((state: RootState) => state.Categories.Environment)
  const ReduxMunicipalities: any[] = useSelector((state: RootState) => state.Categories.Municipality)
  
  const onChange = (list: CheckboxValueType[]) => {

    if (type === 'municipality') window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    type === 'history' ? dispatch(History(list)) : type === 'environment' ? dispatch(Environment(list)) : dispatch(Municipality(list))

    dispatch(ExplorationPageNumberRedux(1))
    
  }




  if (type === 'environment') {
    return (
      <>
        {header && <div className={classes.divider}>{icon}<span className={classes.dividerSpan2}>{header}</span></div>}

        <Checkbox.Group style={{ width: '100%' }} onChange={onChange} defaultValue={ ReduxEnvironment[0] && ReduxEnvironment[0] }>
          <Row>
            {array.slice(0, 6).map((item: any, index: any) => {

              return (
                <Col span={24} key={item.id} className={classes.checkboxGroup}>
                  <Checkbox value={item.id}>{item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()}</Checkbox>
                </Col>
              )
            })}

            <Collapse ghost expandIconPosition='start' >
              <Panel header={t("Εμφάνιση όλων") + ' (' + (array.length - 6) + ')'} key="1" className={classes.collapse} >

                {array.slice(6, array.length).map((item: any, index: any) => {

                  return (
                    <Col span={24} key={item.id} className={classes.checkboxGroup}>
                      <Checkbox value={item.id}>{item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()}</Checkbox>
                    </Col>
                  )
                })}

              </Panel>
            </Collapse>

          </Row>
        </Checkbox.Group>
    </>
    )
  }



  if (type === 'municipality') {
    return (
      <>
        {header && <div className={classes.divider}>{icon}<span className={classes.dividerSpan2}>{header}</span></div>}
        <Checkbox.Group style={{ width: '100%' }} onChange={onChange}  defaultValue={ReduxMunicipalities[0] && ReduxMunicipalities[0]}>
          <Row>
            <Col span={24} className={classes.checkboxGroup}>
              <Checkbox value='Αποκορώνου'>{t('Αποκορώνου')}</Checkbox>
            </Col>
            <Col span={24} className={classes.checkboxGroup}>
              <Checkbox value='Γαύδου'>{t('Γαύδου')}</Checkbox>
            </Col>
            <Col span={24} className={classes.checkboxGroup}>
              <Checkbox value="Καντάνου – Σελίνου">{t('Καντάνου - Σελίνου')}</Checkbox>
            </Col>
            <Col span={24} className={classes.checkboxGroup}>
              <Checkbox value='Κισσάμου'>{t('Κισσάμου')}</Checkbox>
            </Col>
            <Col span={24} className={classes.checkboxGroup}>
              <Checkbox value='Πλατανιά'>{t('Πλατανιά')}</Checkbox>
            </Col>
            <Col span={24} className={classes.checkboxGroup}>
              <Checkbox value='Σφακίων'>{t('Σφακίων')}</Checkbox>
            </Col>
            <Col span={24} className={classes.checkboxGroup}>
              <Checkbox value='Χανίων'>{t('Χανίων')}</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </>
    )
  }

  
  return (
    <>
      {header && <div className={classes.divider}>{icon}<span className={classes.dividerSpan2}>{header}</span></div>}

      <Checkbox.Group style={{ width: '100%' }} onChange={onChange} defaultValue={ReduxHistory[0] && ReduxHistory[0]}>
        <Row>

          {array.slice(0, 6).map((item: any, index: any) => {

            return (
              <Col span={24} key={item.id} className={classes.checkboxGroup}>
                <Checkbox value={item.id}>{item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()}</Checkbox>
              </Col>
            )
          })}

          <Collapse ghost expandIconPosition='start' >
            <Panel header={t("Εμφάνιση όλων") + ' (' + (array.length - 6) + ')'} key="1" className={classes.collapse} >

              {array.slice(6, array.length).map((item: any, index: any) => {

                return (
                  <Col span={24} key={item.id} className={classes.checkboxGroup}>
                    <Checkbox value={item.id}>{item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()}</Checkbox>
                  </Col>
                )
              })}

            </Panel>
          </Collapse>

        </Row>
      </Checkbox.Group>

      {/* <CheckboxGroup
        options={array.map((item: any) => ({ label: item.name, value: item.id }))}
        value={checkedList}
        onChange={onChange}
        className={classes.checkboxGroup}
      /> */}
    </>
  )
}

export default CheckBoxComponent