
export const ChangeMunicipality = (municipality: string) => {

  let Mun = municipality

  switch (municipality) {
    case 'Αποκορώνου': Mun = 'Αποκόρωνας'; break;
    case 'Γαύδου': Mun = 'Γαύδος'; break;
    case 'Καντάνου-Σελίνου': Mun = 'Σέλινο'; break;
    case 'Καντάνου – Σελίνου': Mun = 'Σέλινο'; break;
    case 'Κισσάμου': Mun = 'Κίσσαμος'; break;
    case 'Πλατανιά': Mun = 'Πλατανιάς'; break;
    case 'Σφακιών': Mun = 'Σφακιά'; break;
    case 'Σφακίων': Mun = 'Σφακιά'; break;
    case 'Χανίων': Mun = 'Χανιά'; break;
    default: 
  }

  return Mun
}

