import React from 'react'
import classes from "./Event.module.css"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'

import GlobusSvg from '../../img/svg/globus'
import EventsSvg from '../../img/svg/events'
import TheaterSvg from '../../img/svg/theater'
import MovieSvg from '../../img/svg/movie'
import DanceSvg from '../../img/svg/dance'
import ConcertSvg from '../../img/svg/concert'
import ConcertsSvg from '../../img/svg/concerts'
import LaboratoriesSvg from '../../img/svg/laboratories'
import ExhibitionSvg from '../../img/svg/exhibition'
import ConferencesSVG from '../../img/svg/conferences'


interface Props{
  id_item: string
  image: string
  title: string
  price: string
  time_periods: any[]
  place: string
  category_id:number
  category_type: string
}

const EventComponent: React.FC<Props> = ({...props}) => {

  const { t } = useTranslation()

  let StartTime = props.time_periods[0].starting_date
  let EndTime = props.time_periods[0].ending_date

  return (
    <Link to={`/event/${props.id_item}`} key={props.id_item} >
      <div className={classes.box}>
        <div className={classes.containerImg}>
          <img src={props.image} alt={props.title} className={ props.image === 'https://events.explorechania.gr/sites/default/files/default_images/exlore-chania-placeholder.png' ? classes.imgDefault : classes.img}  />
          {props.price == 'free' ? <div className={classes.type}>{t('Είσοδος Ελεύθερη')}</div> : <div>&nbsp;</div>}
        </div>

        <div className={classes.BoxTitle}>{props.title.length <= 52 ? props.title : props.title.slice(0, 52)+ ' ...'}</div>
        <div className={classes.BoxDates}>
          {props.time_periods && props.time_periods[0].starting_date !== '12/10/1983, 12:00' &&
            <>
              <div className={classes.BoxDesc}>{t('Έναρξη')}: { (StartTime.slice(-5) === '23:59' || StartTime.slice(-5) === '24:00' || StartTime.slice(-5) === '00:00') ? StartTime.slice(0, (StartTime.length, -7)) : StartTime }</div>
              <div className={classes.BoxDesc}>{t('Λήξη')}: {(EndTime.slice(-5) === '23:59' || EndTime.slice(-5) === '24:00' || EndTime.slice(-5) === '00:00' ) ? EndTime.slice(0, (EndTime.length, -7)) : EndTime}</div>
            </>
          }
        </div>
        <div className={classes.MyRow}>
          <div className={classes.MyRowColumn}>
            <p><GlobusSvg width={24} height={24} fill="#fff"/><span className={classes.text}> {t(props.place).length <= 14 ? t(props.place) : t(props.place).slice(0, 12)+ ' ...'}</span></p>
          </div>
          <div className={classes.MyRowColumn2}>
            <p>
              {
              props.category_id == 18 ? <MovieSvg width={24} height={24} fill="#fff" /> : 
              props.category_id == 19 ? <LaboratoriesSvg width={24} height={24} fill="#fff"/> :
              props.category_id == 23 ? <EventsSvg width={24} height={24} fill="#fff" /> :
              props.category_id == 25 ? <ConcertsSvg width={24} height={24} fill="#fff" /> :
              props.category_id == 26 ? <DanceSvg width={24} height={24} fill="#fff" /> :
              props.category_id == 27 ? <ExhibitionSvg width={24} height={24} fill="#fff"/> :
              props.category_id == 28 ? <TheaterSvg width={24} height={24} fill="#fff" /> :
              props.category_id == 29 ? <ConcertSvg width={24} height={24} fill="#fff" /> :
              <ConferencesSVG width={24} height={24} fill="#fff" />
            }
              <span className={classes.text}>{t(props.category_type)}</span></p>
          </div>
        </div>
      </div>
    </Link>       
  )
}

export default EventComponent

{/* 
  <Option value="Κινηματογράφος" className={'ant-option'}>Κινηματογράφος</Option>   &#127902;
  <Option value="Εργαστήριο" className={'ant-option'}>Εργαστήριο</Option>
  <Option value="Εκδηλώσεις" className={'ant-option'}>Εκδηλώσεις</Option>
  <Option value="Συναυλίες" className={'ant-option'}>Συναυλίες</Option> &#9835; 
  <Option value="Χορός" className={'ant-option'}>Χορός</Option> &#128131;
  <Option value="Έκθεση" className={'ant-option'}>Έκθεση</Option>
  <Option value="Θέατρο" className={'ant-option'}>Θέατρο</Option> &#127917;
  <Option value="Ρεσιτάλ" className={'ant-option'}>Ρεσιτάλ</Option>
  <Option value="Συνέδριο" className={'ant-option'}>Συνέδριο</Option> */}