import { useState } from "react"
import { useQuery } from '@tanstack/react-query'
import { TopDestinationsProps } from "../services/props"
import { axiosPoints } from "../services/topDestinations"


export const useTopDestinations = (lang: string) => {
  
  const [topDestinations, setTopDestinations] = useState<TopDestinationsProps[]>([]);

  const { isLoading, isFetching, error, refetch: refe } = useQuery(['topDestinations'], () => axiosPoints.getTopDestinations(lang), {

    onSuccess: (data) => {

      let arrayTopDestinations: TopDestinationsProps[] = []

      data.map((destination: any) => {

        arrayTopDestinations.push({

          id: destination.id,
          srcImage: destination.main_image,
          title: destination.name,
          description: destination.portal_main_info,
          subTitle: "",
          place: destination.extras._municipalityName.value,
          type: destination.category_path.length == 1 ? destination.category_path[0][1] :  destination.category_path[1][1]+ ', ' +destination.category_path[0][1]
        })
      }) 
 
      setTopDestinations(arrayTopDestinations)
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, topDestinations, refe }
}
