import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Row, Col, Button, message } from 'antd'
import { ArrowRightOutlined, PlusCircleOutlined } from '@ant-design/icons'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../components/Helmet'
import Spinner from '../../components/PageComponents/Spinner'
import TitleAndSubtitleAndBtn from '../../components/PageComponents/TitleAndSubtitleAndBtn'
import { useAuth } from "../../utils/auth"
import { useUserFavoritePoints, useUserFavoriteAddOrRemovePoint } from '../../hooks/useUser'
import ViewPointsComponent from '../Exploration/viewPoints'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import utils from '../../utils'
import CheckBoxGroup from '../../components/CheckBoxGroup'
import GlobusSvg from '../../img/svg/globus'
import {MunicipalitiesData} from '../../components/CheckBoxGroup/municipalities'
import i18n from '../../translation'



import ky from 'ky'
import {API, HeaderForUser} from '../../services/configuration'
import { useMutation, useQueryClient } from '@tanstack/react-query';



const MyFavorites: React.FC = () => {

  const auth = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const { isLoading, isFetching, Poises, refetch } = useUserFavoritePoints(auth.userData.id)
 
  const [poises, setPoises] = useState<any[]>([]);

  const [setPoisesMarkerExploration, PoisesMarkerExploration ] = useState<any[]>([]);


  const client = useQueryClient();


  const { mutate: create } =  useMutation((idPois: string) => {
    // mutationFn: (auth.userData.id, pointId, 0) => useUserFavoriteAddOrRemovePoint(auth.userData.id, pointId, 0);
    // useUserFavoriteAddOrRemovePoint(auth.userData.id, idPois, 0)
    setPoises(poises => poises.filter(todo => todo.id !== idPois));

    // mutationFn:  useUserFavoriteAddOrRemovePoint(auth.userData.id, idPois, 0)
    return ky.post(`${API}/editFavorites?user_id=${auth.userData.id}&poi_id=${idPois}&add=0`, { headers: HeaderForUser }).json();
  }, {

    onSuccess: () => {
      // client.invalidateQueries({ queryKey: ['userFavoritePoints', auth.userData.id] });
      // client.setQueriesData(['userFavoritePoints', auth.userData.id ], (Poises:any) => {
      //   // return [...(Poises || []), Poises];
      //   setPoises(Poises)
      // });
    }
  });


  const onClickAddRemoves = (idPois: string) => {
    create(idPois)
  };


  useEffect(() => {
    setPoises(Poises)
  }, [Poises])


  useEffect(() => {
    refetch()
  }, [i18n.language])


  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: t('Δεν βρέθηκαν αγαπημένα σημεία ενδιαφέροντος για τον δήμο αυτό'),
      duration: 2,
      style: {
        marginTop: '40vh'
      },
    });
  }


  const checkBoxLists = (list: CheckboxValueType[]) => {

    let result = utils.filterFavoritePoisesArray(Poises, list)
    
    if (result.length == 0) {
      warning()
      setPoises(Poises)
    }
    else  
      setPoises(result)
  }


  return (
    <>
      {contextHolder}
      <HelmetComponent title="Τα Αγαπημένα μου" descrition="Favourites page" />
      
      <TitleAndSubtitleAndBtn title={t('Λίστα Αγαπημένων')} subTitle={t('Τα αγαπημένα μου Σημεία ενδιαφέροντος')} />
      
      <div style={{ marginBottom: 50 }}>
        
        {isFetching && <div className={classes.allScreen}>
          <div className={classes.loader}></div>
        </div>}

        { poises.length == 0
            ?
            <>
              <Row justify="center">
                <div className={classes.FavouritesSection}>

                  <PlusCircleOutlined style={{ fontSize: 38 }} />
                  <div className={classes.pointTitle}>{t('Η λίστα σου είναι κενή')}</div>
                  <div className={classes.subTitleSection}> {t('Δεν έχεις προσθέσει Αγαπημένα Σημεία Ενδιαφέροντος')}</div>
                  <div className={classes.sectionTitle}>
                    {t('Πλοηγήσου στην σελίδα Εξερεύνηση ή Διαδρομές, επίλεξε “Προσθήκη στα Αγαπημένα” στα σημεία που σε ενδιαφέρουν και θα τα βρεις εδώ συγκεντρωμένα')}
                  </div>

                </div>
              </Row>
              <div className={classes.TabsContainer}>
                <Row justify="center">
                  <Col xs={24} sm={24} md={24} lg={24} >
                    <div className={classes.center}>
                      <Button
                        size='large'
                        className={classes.FavouritesBtn}
                        onClick={() => navigate('/explorations')}>
                        {t('Εξερεύνησε Σημεία Ενδιαφέροντος')}&nbsp;&nbsp;<ArrowRightOutlined />
                      </Button>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <div className={classes.center}>
                      <Button
                        size='large'
                        className={classes.FavouritesBtn}
                        onClick={() => navigate('/routes')}>
                        {t('Εξερεύνησε Διαδρομές')}&nbsp;&nbsp;<ArrowRightOutlined />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
            :
            <>
            <br /><br />
            <div className={classes.container2}>
              <Row justify="center">
                <Col xs={24} sm={24} md={12} lg={5}>
                  <div className={classes.checkBoxesContainer}>
                    <p className={classes.filters}>{t('Φίλτρα αναζήτησης')}</p>

                    <CheckBoxGroup
                      list={MunicipalitiesData}
                      checkBoxLists={(list: CheckboxValueType[]) => checkBoxLists(list)}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={19}>
                <ViewPointsComponent
                    lg={24}
                    xs={24}
                    paginationNumber={1}
                    array={poises}
                    total={poises.length}
                    setPoisesMarkerExploration={PoisesMarkerExploration}
                    isAuth={true}
                    isAddOrDelete={false}
                    onClickAddRemove={(idPois:string) => onClickAddRemoves(idPois)}
                  />
                </Col>
              </Row>
            </div>
          </>
        }
      </div>
    </>
  )
}

export default MyFavorites
