import React, { useState, useEffect }  from 'react'
import { Row, Col } from 'antd'
import classes from "./styles.module.css"
import { AboutUsData } from './AboutUsData'
import { AccessibilityStatementData } from './AccessibilityStatementData'
import { TermsData } from './TermsData'
import { PolicyData } from './PolicyData'


import { useLocation } from "react-router-dom"
import i18next from 'i18next'
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../components/Helmet'
  


const CommonPage: React.FC = () => {

  const [dataTitle, setDataTitle] = useState('')
  const [dataText, setDataText] = useState('')

  const location = useLocation()

  const { t } = useTranslation()

  let lang:any = ''

  useEffect(() => {

    lang = 'en'

    switch (location.pathname) {

      
      case '/page/accessibility_statement':

        // setDataTitle(AccessibilityStatementData[0][i18next.language == 'en' ? 'en' : i18next.language == 'gr' ? 'gr' : 'bg'].title);

        setDataTitle(AccessibilityStatementData[0][
          i18next.language == 'en' ? 'en'
          : i18next.language == 'gr' ? 'gr'
          : i18next.language == 'fr' ? 'fr'
          : i18next.language == 'de' ? 'de'
          : i18next.language == 'ru' ? 'ru'
          : 'en'
        
        ].title);

        setDataText(AccessibilityStatementData[0][
          i18next.language == 'en' ? 'en'
          : i18next.language == 'gr' ? 'gr'
          : i18next.language == 'fr' ? 'fr'
          : i18next.language == 'de' ? 'de'
          : i18next.language == 'ru' ? 'ru'
        : 'en'].text);
        break;

      
      case '/page/about_us':
        setDataTitle(AboutUsData[0][
          i18next.language == 'en' ? 'en'
          : i18next.language == 'gr' ? 'gr'
          : i18next.language == 'fr' ? 'fr'
          : i18next.language == 'de' ? 'de'
          : i18next.language == 'ru' ? 'ru'
        : 'en'].title);

        setDataText(AboutUsData[0][
          i18next.language == 'en' ? 'en'
          : i18next.language == 'gr' ? 'gr'
          : i18next.language == 'fr' ? 'fr'
          : i18next.language == 'de' ? 'de'
          : i18next.language == 'ru' ? 'ru'
        : 'en'].text);

        break;
      
      
      
      case '/page/terms':
        setDataTitle(TermsData[0][
          i18next.language == 'en' ? 'en'
          : i18next.language == 'gr' ? 'gr'
          : i18next.language == 'fr' ? 'fr'
          : i18next.language == 'de' ? 'de'
          : i18next.language == 'ru' ? 'ru'
        : 'en'].title);
        setDataText(TermsData[0][
          i18next.language == 'en' ? 'en'
          : i18next.language == 'gr' ? 'gr'
          : i18next.language == 'fr' ? 'fr'
          : i18next.language == 'de' ? 'de'
          : i18next.language == 'ru' ? 'ru'
        : 'en'].text);
        break;
      
      
      
      case '/page/policy':
        setDataTitle(PolicyData[0][
          i18next.language == 'en' ? 'en'
          : i18next.language == 'gr' ? 'gr'
          : i18next.language == 'fr' ? 'fr'
          : i18next.language == 'de' ? 'de'
          : i18next.language == 'ru' ? 'ru'
                    : 'en'].title);
        
        setDataText(PolicyData[0][
          i18next.language == 'en' ? 'en'
          : i18next.language == 'gr' ? 'gr'
          : i18next.language == 'fr' ? 'fr'
          : i18next.language == 'de' ? 'de'
          : i18next.language == 'ru' ? 'ru'
        : 'en'].text);
        break;

    }

  }, [i18next.language, location.pathname])


  return (
    <>
      <HelmetComponent title={dataTitle} descrition={dataTitle} />
      <div className={classes.containerBig}>
        <div className={classes.container}>
          <Row justify="center">
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>

              <div className={classes.title} dangerouslySetInnerHTML={{ __html: dataTitle }} ></div>
              <div className={classes.text} dangerouslySetInnerHTML={{ __html: dataText }} ></div>

            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default CommonPage
