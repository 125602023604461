
import React from "react"
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
import Icon from '@ant-design/icons'

    
const UsefulSvgFill = () => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="https://www.w3.org/2000/svg" x="0px" y="0px" width="20px" height="20px" 
            viewBox="0 0 22 22" >
            <g>
                <path fill='#275674' d="M10.1,9.2v2.3v3.7V16c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-2.3V10V9.2c0-0.5-0.4-0.9-0.9-0.9
                    S10.1,8.7,10.1,9.2L10.1,9.2z"/>
                <circle fill='#275674' cx="11" cy="6" r="0.9" />
                <g>
                    <path fill='#275674' d="M11,22C4.9,22,0,17.1,0,11S4.9,0,11,0c6.1,0,11,4.9,11,11S17.1,22,11,22z M11,2C6,2,2,6,2,11c0,5,4.1,9,9,9
                        c5,0,9-4.1,9-9C20,6,16,2,11,2z"/>
                </g>
            </g>
        </svg>

    )
}

const UsefulSvg = (props: Partial<CustomIconComponentProps>) => (<Icon component={UsefulSvgFill} {...props} />);

export {
    UsefulSvg
} 