import React from "react"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"

interface Props{
  url?: string
  title?: string
  descrition?: string
  keywords?: string
  image?:string
}

const HelmetComponent: React.FC<Props> = ({
  url = 'https://explorechania.gr',
  title = 'Title page',
  descrition = 'Descrition page',
  keywords = 'keywords',
  image = `${process.env.REACT_APP_DOMAIN}/favicon.ico`

}: Props) => {

  const { t } = useTranslation()

    return(
      <Helmet>

        <title>{title}</title>
        <link rel="canonical" href={`${url}`} />
        <meta name="description" content={descrition || undefined} />
        <meta name="keywords" content={keywords || undefined} />
        
        {/* <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href={image} />
        <link rel="apple-touch-icon" sizes="180x180" href={image}/>
        <link rel="icon" type="image/png" sizes="32x32" href={image}/>
        <link rel="icon" type="image/png" sizes="16x16" href={image} />
        {/* <meta name="twitter:site" content={`${process.env.REACT_APP_DOMAIN}`} />
        <meta name="twitter:creator" content="dotsoft.gr" />
        <meta name="twitter:title" content={t(title) || undefined} />
        <meta name="twitter:description" content={descrition || undefined} />
        <meta name="twitter:image" content={image} />
        <meta property="og:title" content={title || undefined} />
        <meta property="og:description" content={t(descrition) || undefined} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={`${process.env.REACT_APP_DOMAIN}`} />
        <meta property="og:site_name" content={`${process.env.REACT_APP_SITE_NAME}`} />
        <meta property="og:locale" content="el_GR" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content={`${process.env.REACT_APP_FACEBOOK}`} /> */}
      </Helmet>
  )
}

export default HelmetComponent