import React from "react"
import { Checkbox, Col, Row } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'

import { useTranslation } from "react-i18next"
import classes from "./styles.module.css"

import {MunicipalitiesData} from './municipalitiesForHome'
import GlobusSvg from '../../img/svg/globus'


// Redux
import { useDispatch } from 'react-redux'
import { MunicipalityRedux, EventsPageNumberRedux }  from '../../redux/mapPage/event'



interface ListData {
  id: string
  value: string
  item: string
}

interface Props{
  checkedId?: any[]
}

const CheckBoxMunicipalityComponent: React.FC<Props> = ({ ...props } ) => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const onChange = (list: CheckboxValueType[]) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    dispatch(MunicipalityRedux(list))
  }
  
  return (
    <>
      {/* <div className={classes.divider}><GlobusSvg width={30} height={30} fill="#275674" />&nbsp;&nbsp;{t("Δήμος")}</div> */}
      <div className={classes.dividerMunicipality}><br/><GlobusSvg width={30} height={30} fill="#275674" /><span className={classes.dividerSpanMunicipality}>{t("Δήμος")}</span></div>
      <Checkbox.Group style={{ width: '100%' }} onChange={onChange} defaultValue={props.checkedId && props.checkedId[0]}>
        <Row>
          {MunicipalitiesData.map((data: ListData) => {
            return (
              <Col span={24} key={data.id} className={classes.checkboxGroup}>
                <Checkbox value={ data.value }>{t( data.item )}</Checkbox>
              </Col>
            )
          })}
        </Row>
      </Checkbox.Group>
    </>
  )
}

export default CheckBoxMunicipalityComponent