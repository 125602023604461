import React, {useState, useEffect} from "react"
import { Row, Col, Button, Input, AutoComplete  } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../components/Helmet'
import Spinner from '../../components/PageComponents/Spinner'
import TitleAndSubtitle from '../../components/PageComponents/TitleAndSubtitle'
import { SearchOutlined } from '@ant-design/icons'
import { SearchPoisTitles } from '../Home/SearchPoisTitles'

import CheckBoxRouteExplorationEventComponent from '../../components/CheckBoxGroup/CheckBoxRouteExplorationEvent'
import CheckBoxMunicipalityComponent from '../../components/CheckBoxGroup/CheckBoxMunicipalitySearch'

import ViewPointsComponent from './viewPoints'

import utils from '../../utils'

// Redux
import type { RootState } from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import { KeySearchRedux } from '../../redux/mapPage/search'
import { useSearch } from '../../hooks/useSearch'


const Search: React.FC = () => {

  const { t, i18n } = useTranslation()

  const [stringTemp, setStringTemp] = useState<string>("")
  const [searchString, setSearchString] = useState<string>('')
  const dispatch = useDispatch()


  const [poises, setPoises] = useState<any[]>([]);
  const [notFind, setNotFind] = useState<boolean>(false);
  const [setPoisesMarkerExploration, PoisesMarkerExploration] = useState<any[]>([]);


  const ReduxKey: string = useSelector((state: RootState) => state.Searches.Key)
  const ReduxRoutes: any[] = useSelector((state: RootState) => state.Searches.Routes)
  const ReduxEnvironments: any[] = useSelector((state: RootState) => state.Searches.Environment)   
  const ReduxHistory: any[] = useSelector((state: RootState) => state.Searches.History)
  const ReduxEvents: any[] = useSelector((state: RootState) => state.Searches.Events)
  const ReduxMunicipalities: any[] = useSelector((state: RootState) => state.Searches.Municipality)
  const ReduxPageNumberSearch: number = useSelector((state: RootState) => state.Searches.SearchPageNumberRedux)
  const ReduxPageSizeSearch: number = useSelector((state: RootState) => state.Searches.SearchPageSizeRedux)


  const { isLoading, isFetching, SearchResultData, checkIfArrayEmpty, refetch } = useSearch(ReduxKey, i18n.language)


  // console.log('SearchResultData', SearchResultData)




  useEffect(() => {
    if ( ReduxKey !== '' ) {
      refetch() 
    }
  }, [ReduxKey, i18n.language])


  useEffect(() => {

    setPoises(SearchResultData)
    
    let result = utils.filterSearchArray(SearchResultData, ReduxRoutes, ReduxEnvironments, ReduxHistory, ReduxEvents, ReduxMunicipalities)
    // console.log(SearchResultData)
    // console.log('result', result)
    // result.length > 0 ? setPoises(result) : setPoises([])
    if (result.length > 0) {
      setPoises(result)
      setNotFind(false)
    }
    else {
      setPoises([])
      setNotFind(true)
    }

    // console.log('SearchResultData :', SearchResultData)
    // console.log('SearchResultData :', SearchResultData)
    // console.log('ReduxSearch :', ReduxSearch)
    // console.log('ReduxMunicipalities :', ReduxMunicipalities)
    // console.log('ReduxMunicipalities :', ReduxMunicipalities)
    // }
   
    // window.scrollTo({ top: 200, left: 0, behavior: 'smooth' });

  },[ ReduxKey, SearchResultData, ReduxRoutes, ReduxEnvironments, ReduxHistory, ReduxEvents, ReduxMunicipalities ])



  useEffect(() => { setNotFind(false) },[])

  const handleKeypress = (e : React.KeyboardEvent<HTMLInputElement>) => {
  
    if (e.key === 'Enter') {
      setSearchString(stringTemp)
      dispatch(KeySearchRedux(stringTemp))
    }
  };


  if (isFetching) {
    {window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
    return (
      <div className={classes.container}>
        <div className={classes.centered}>
          <Spinner />
         
        </div>
      </div>
      )
  }


  const onSelect = (data: string) => { setStringTemp(data) }
  const onChange = (data: string) => { setStringTemp(data) }
  
  console.log('poises :', poises)

  return (
    <>
      <HelmetComponent title='Αναζήτηση' descrition='Αναζήτηση' />
     
      <TitleAndSubtitle title={t('Αναζήτηση')} subTitle={t('')}/>

      <div className={classes.divSearchContainer}>
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={{span: 20}}>
            
            <div className={classes.divIntoSearchContainer}>

              <div className={classes.searchInput}>
                <AutoComplete
                  className={classes.input}
                  options={stringTemp.length >=2 ? SearchPoisTitles : []}
                  onSelect={onSelect}
                  onChange={onChange}
                  filterOption={(inputValue, option) =>
                  
                    inputValue.length >= 2 &&
                    
                    option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 }
                  
                > 
                  <Input
                    size="large"
                    style={{ height: '50px' }}
                    className={classes.inputMain}
                    // placeholder={searchString}
                    placeholder={t('Πληκτρολογήστε τους όρους αναζήτησης εδώ...') as string || ''}
                    // value={searchString}
                    onChange={(e) => setStringTemp(e.target.value)}
                    onKeyPress={handleKeypress}
                    prefix={<SearchOutlined />} />
                  

                </AutoComplete>
              </div>
           
              <span >
                <Button
                  type="primary" 
                  size='large'
                  className={classes.btnSearch}
                  onClick={() => [setSearchString(stringTemp), dispatch(KeySearchRedux(stringTemp))]}><span className={classes.searchBtn}>{t('Αναζήτηση')}</span>
                  {/* onClick={()=> [setSearchString('ser'), dispatch(KeySearchRedux('ser'))]}><span className={classes.searchBtn}>{t('Αναζήτηση')}</span> */}
                </Button>
              </span>
        
            </div>
          </Col>
        </Row>
      </div>

      <div className={classes.MainContainer}>
        <Row justify="center">
        <Col xs={24} sm={24} md={5} lg={5}>
          <div className={classes.checkBoxesContainer}>
              <p className={classes.filters}>{t('Φίλτρα αναζήτησης')}</p>  
              <CheckBoxRouteExplorationEventComponent checkedId={ReduxRoutes} />
              {/* <CheckBoxMunicipalityComponent checkedId={ReduxMunicipalities} /> */}
          </div>
        </Col>

        <Col xs={24} sm={24} md={19} lg={19}>
            <Row justify="center">
              
              <Col xs={24} sm={24} md={24} lg={24}>
              
                {poises.length > 0 ?
                  <>
                  <div className={classes.searchDiv}> {t('Αναζήτηση για')}: { ReduxKey }</div>
                    <ViewPointsComponent
                      lg={24}
                      xs={24}
                      array={poises}
                      total={poises.length}
                      isAddOrDelete={true}
                      setPoisesMarkerExploration={PoisesMarkerExploration}
                      onClickAddRemove={() => ''}
                      paginationNumber={ReduxPageNumberSearch}
                      paginationSize = {ReduxPageSizeSearch}
                    
                    />
                  </> 
                  :
                  (checkIfArrayEmpty && SearchResultData.length==0) ?

                    <div className={classes.dontHave}>
                      { t('Δεν βρέθηκαν αποτελέσματα')}
                    </div>
                    :
                    notFind ? 
                    <div className={classes.dontHave}>
                     { t('Δεν βρέθηκαν αποτελέσματα')}
                      </div>
                      : ''
                }  

            </Col>
          </Row>
        </Col>

        </Row>
      </div>
    </>
  )
}


export default Search
