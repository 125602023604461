import React from "react"
import { Checkbox, Col, Row } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useTranslation } from "react-i18next"
import classes from "../styles.module.css"


// Redux
import { useDispatch } from 'react-redux'
import { ExplorationPageNumberRedux } from '../../../redux/mapPage/category'
import { RoutesSearchRedux } from '../../../redux/mapPage/search' 

import type { RootState } from '../../../redux/store'
import { useSelector } from 'react-redux'



interface Props{
  checkedId: number;
  icon?: any;
  header?: string;
  key?: any;
  array: any;
  type: string;
  EnvironmentChecked?: CheckboxValueType[]
}

const RoutesCheckBoxComponent: React.FC<Props> = ({ key, icon, header, array, type }) => {

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const ReduxRoutes: any[] = useSelector((state: RootState) => state.Searches.Routes)

  const onChange = (list: CheckboxValueType[]) => {

    dispatch(RoutesSearchRedux(list))

    dispatch(ExplorationPageNumberRedux(1))
  }


  const MyCheckBox = ({ arrays }: any) => {
  
    return (
      <Checkbox.Group
        style={{ width: '100%' }}
        onChange={onChange}
        defaultValue={ ReduxRoutes[0] }
      >
        <Row>
          {arrays.map((item: any) => {
            return (
              <Col span={24} key={item.id} className={classes.checkboxGroup}>
                <Checkbox value={item.value}>{t(item.item)}</Checkbox>
              </Col>
            )
          })}
        </Row>
      </Checkbox.Group>
    )
  }

  return (
    <>
      {header && <div className={classes.divider}>{icon}<span className={classes.dividerSpan3}>{header}</span></div>}
      <MyCheckBox arrays={array} />
    </>
  )
  

}

export default RoutesCheckBoxComponent
