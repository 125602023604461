
import React from "react"
import Props from './svgProps'

const GoToPoiSvg = ({width, height, fill}:Props) => {

    return (
    <svg
        width={width}
        height={height}
        viewBox="2 -4 22 25"
        fill="none" xmlns="https://www.w3.org/2000/svg">
            <path d="M2.41345 9.74448C1.81811 9.51296 1.52043 9.39719 1.43353 9.23039C1.35819 9.08579 1.35809 8.91354 1.43326 8.76886C1.51997 8.60195 1.8175 8.48584 2.41258 8.25361L19.3003 1.66327C19.8375 1.45364 20.1061 1.34883 20.2777 1.40616C20.4268 1.45596 20.5437 1.57292 20.5935 1.72197C20.6509 1.8936 20.5461 2.16219 20.3364 2.69937L13.7461 19.5871C13.5139 20.1822 13.3977 20.4797 13.2308 20.5664C13.0862 20.6416 12.9139 20.6415 12.7693 20.5662C12.6025 20.4793 12.4867 20.1816 12.2552 19.5862L9.6271 12.8282C9.58011 12.7074 9.55661 12.647 9.52031 12.5961C9.48815 12.551 9.44871 12.5115 9.40361 12.4794C9.35273 12.4431 9.29231 12.4196 9.17146 12.3726L2.41345 9.74448Z" stroke="#275674" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
    )
}

export default GoToPoiSvg
