import ky from 'ky'
import {API, Header} from './configuration'

export const axiosPoisJson = {

  async getPoiJson(lang: string) {
    const data: any = await ky.get(`${API}/pois?fetch_files=1&lang=${lang}`, {headers: Header}).json()
    return data.success;
  },


}