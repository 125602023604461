import React,{ useState, useEffect } from 'react'
import { Row, Col, Carousel} from 'antd'
import classes from "./Slider.module.css"
import { SampleNextArrow, SamplePrevArrow } from '../../../components/ArrowsForCarousel/Slider'
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';


interface Props{
  images: string[]
}

const Slider3DComponent: React.FC<Props> = ({images}:Props) => {

  const settings = {
    dots: false,
    arrows:true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1056,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 5,
          infinite: true,
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isMobile = width <= 768;

  return ( 

    <div>

      <div className={classes.container}>
        <Row justify="center">
          <Col span={24}>
            <div className={classes.sliderContainer}>
              <Carousel autoplay {...settings}>
                {images && images.map((image: any, index: any) => {
                  return (

                    <div key={index}>
                      <div className={classes.helper}>

                        <ReactPhotoSphereViewer
                          src={image.url}
                          height={isMobile ? '230px' : '600px'}
                          width={"100%"}
                          container={''}
                        />

                      </div>
                    </div>
                  )
                })}
              </Carousel>
            </div>
          </Col>
        </Row>
      </div>

    </div>
  )
}

export default Slider3DComponent
