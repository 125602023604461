
import React from "react"
import { Col, Row, Button } from 'antd'
import { useTranslation } from "react-i18next"
import classes from "./styles.module.css"
import { ArrowRightOutlined } from '@ant-design/icons'

import { useNavigate } from "react-router-dom"
import { useTopDestinations } from '../../../hooks/useTopDestinations'

import DestinationComponent from '../../../components/Box/DestinationComponent'
import i18n from '../../../translation'

const ThreePoises: React.FC = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { topDestinations } = useTopDestinations(i18n.language)



  const onClickAddOrRemove = (text: string, idPois: string, numberAddDel: number) => {

  }




  return (
    <>
      <div className={classes.title}>
        {t('Κορυφαίες επιλογές προορισμών')}
      </div>
      <div className={classes.subTitle}>
        {t('Διαλέξαμε για σένα τα καλύτερα μέρη για να επισκευτείς')}
      </div>

      <Row justify="center" className={classes.DestinationContainer}>
        {
          topDestinations.slice(0, 3).map((point: any) => {

              let textShort = `${point.description.substring(0, 50)} ...`

              return (
                <Col xs={24} sm={24} md={12} lg={8} key={point.id}>
                  <DestinationComponent
                    id={point.id}
                    image={point.srcImage === '' ? '../img/defaultTopDestinationImage.png' : point.srcImage}
                    title={point.title}
                    text={textShort}
                    place={point.place}
                    type={point.type}
                    onClickAddOrRemovePois={(text, idPois, AddOrDelete) => onClickAddOrRemove(text, idPois, AddOrDelete)}
                  />
                </Col>
              )
          })
        }
      </Row>

      <div style={{ marginBottom: 60 }}>
        <Row justify="center">
          <div className={classes.sectionButtonCenter}>
            <Col span={11} xs={24} sm={24} md={24} lg={24}>
              <Button size='large' className={classes.HomeBtn} onClick={() => navigate(`/explorations`, { replace: true })}>
                {t('Εξερεύνησε περισσότερα')}&nbsp;&nbsp;<ArrowRightOutlined />
              </Button>
            </Col>
          </div>
        </Row>
      </div>
    </>
  )
}

export default ThreePoises