import React from 'react'
import { Popconfirm, Popover } from 'antd'
import classes from "./Destination.module.css"
import { useTranslation } from "react-i18next"
import { Link, useNavigate} from 'react-router-dom'
import { HeartOutlined, ArrowRightOutlined, ExclamationOutlined } from '@ant-design/icons'
import { useAuth } from "../../utils/auth"

import GlobusSvg from '../../img/svg/globus'
import MapSvg from '../../img/svg/map'

import { ChangeMunicipality } from './ChangeMunicipality'
interface Props{
  id: string
  image: string
  title: string
  text: string
  place: string
  type: string

  isAuth?: boolean
  isAddOrDelete?: boolean
  onClickAddOrRemovePois: (text: string, idPois: string, AddOrDelete: number) => void,
}


const DestinationComponent: React.FC<Props> = ({...props}) => {

  const { t } = useTranslation()
  const auth = useAuth()
  const navigate = useNavigate()
  const Municipality = ChangeMunicipality(props.place)


  let str: any = []
  let content: any = null
  
  if (props.type.indexOf(',') > -1) {
    
    str = props.type.split(', ')
    content = (
      <div style={{ color: '#275674', fontWeight: 500 }}>
        <p >{str[0]}</p>
        <p>{str[1]}</p>
      </div>
    );
  }

  return (
    <div className={classes.box}>
      <div className={classes.containerImg}>
      <Link to={`../exploration/${props.id}`}>
        <img src={props.image} alt={props.title} className={classes.img} />
      </Link>
        {(auth.userData.id == '' || auth.userData.id == undefined)
          ?
          <Popconfirm
            title={<div style={{ width: '200px', textAlign: 'left', fontSize: '14px', color: '#05324c', lineHeight: '1.2' }}>
                    {t('Για να προσθέσεις σημείο ενδιαφέροντος στα Αγαπημένα σου, κάνε σύνδεση ή εγγραφή')}
                  </div>}
            //  description="Πρέπει να είστε συνδεδεμένοι για να προσθέσετε σημεία ενδιαφέροντος στη λίστα με τα Αγαπημένα σας."
            onConfirm={() => navigate('/login', { replace: false })}
            icon={<ExclamationOutlined style={{ color: '#275674', fontWeight: '600' }} />}
            okText={t('Σύνδεση')}
            showCancel={false}
          >
            <div className={classes.addToRoute}>
              <HeartOutlined />&nbsp;&nbsp; {auth ? t('Προσθήκη στα Αγαπημένα') : t('Αφαίρεση από τη λίστα')}
            </div>
          </Popconfirm>
          :
          <div
            className={classes.addToRoute}
            onClick={() => auth.userData.id !== '' && props.onClickAddOrRemovePois(t('Σημείο Ενδιαφέροντος προστέθηκε με επιτυχία'), props.id, 1)}
          ><HeartOutlined />&nbsp;&nbsp; {auth ? t('Προσθήκη στα Αγαπημένα') : t('Αφαίρεση από τη λίστα')}</div>
        }

      </div>
      <Link to={`../exploration/${props.id}`}>
        <div className={classes.BoxTitle}>{props.title}</div>
      </Link>
      <div className={classes.BoxDesc}>{props.text}</div>
      <div className={classes.MyRow}>
        <div className={classes.MyRowColumn}>
          <p><GlobusSvg width={22} height={22} fill="#275674" />
            <span className={classes.text}>
            { props.id === '1_48' ? t('FaraggiSamarias') : props.id === '1_290' ? t('SelinoKissamos') : t(Municipality) }
             
            </span>
          </p>
        </div>
        <div className={classes.MyRowColumn2}>
          {/* <p><PushpinOutlined /> {point.type.charAt(0).toUpperCase() + point.type.slice(1).toLowerCase()}</p> */}
          {/* <p><MapSvg width={22} height={22} fill="#275674" /><span className={classes.text}>{props.type}</span></p> */}
          <p><MapSvg width={22} height={22} fill="#275674" />
            <span className={classes.text}>
              {str.length >= 1
                  ?
                  <Popover content={content} title="" trigger="hover">
                    <span>{str[0] + ' (+1)'}</span>
                  </Popover>
              : props.type
              }  
            </span>
          </p>
        </div>
      </div>
      <Link to={`../exploration/${props.id}`}>
        <div className={classes.LookMore}>
          {t('Περισσότερα')}&nbsp;&nbsp;<ArrowRightOutlined style={{ fontWeight: 'bold' }} />
        </div>
      </Link>
    </div>      
  )
}

export default DestinationComponent