import { createSlice, PayloadAction } from '@reduxjs/toolkit'


interface EventsArray{
  value: number;
}
interface DatesArray{
  value: number;
}
interface MunicipalityArray{
  value: number;
}

interface EventState {
  EventsRedux: EventsArray[];
  DatesRedux: DatesArray[];
  MunicipalityRedux: MunicipalityArray[];
  EventsPageNumberRedux: 0;
  EventsPageSizeRedux: 9,
  EventsDateStartRedux: any[],
  EventsDateEndRedux: any[],
}

const initialState: EventState = {
  EventsRedux:  [],
  DatesRedux: [],
  MunicipalityRedux: [],
  EventsPageNumberRedux: 0,
  EventsPageSizeRedux: 9,
  EventsDateStartRedux: [],
  EventsDateEndRedux: []
};

export const EventSlice = createSlice({
  name: 'Events',
  initialState,
  reducers: {

    EventsPageNumberRedux: (state, action) => { state.EventsPageNumberRedux=action.payload },
    EventsPageSizeRedux: (state, action) => { state.EventsPageSizeRedux=action.payload },



    EventsDateStartRedux: (state, action) => {
      state.EventsDateStartRedux = [];
      state.EventsDateStartRedux.push(action.payload)
    },
    EventsDateEndRedux: (state, action) => {
      state.EventsDateEndRedux = [];
      state.EventsDateEndRedux.push(action.payload)
    },



    EventsRedux: (state, action) => {
      // state.EventsRedux.splice(0, state.EventsRedux.length);
      // state.EventsRedux.push(action.payload)
      state.EventsRedux = [];
      action.payload == '' ? state.EventsRedux = [] : state.EventsRedux.push(action.payload)
    },
    DatesRedux: (state, action) => {
      // state.DatesRedux.splice(0, state.DatesRedux.length);
      // state.DatesRedux.push(action.payload)
      state.DatesRedux = [];
      action.payload == '' ? state.DatesRedux = [] : state.DatesRedux.push(action.payload)
    },
    MunicipalityRedux: (state, action) => {
      // state.MunicipalityRedux.splice(0, state.MunicipalityRedux.length);
      // if(action.payload !== '')
      //   state.MunicipalityRedux.push(action.payload)
      state.MunicipalityRedux = [];
      action.payload == '' ? state.MunicipalityRedux = [] : state.MunicipalityRedux.push(action.payload)
      
    }
  },
})

export const { EventsPageNumberRedux, EventsPageSizeRedux, EventsRedux, DatesRedux, MunicipalityRedux, EventsDateStartRedux, EventsDateEndRedux } = EventSlice.actions
export default EventSlice.reducer 