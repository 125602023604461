
import React from "react"
import Props from './svgProps'
    
const HistorySvg = ({width, height, fill}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="https://www.w3.org/2000/svg"
        viewBox="0 -40 712.54 579">

        <path fill={fill} d="M210.05,288.47H83.1a9.5,9.5,0,0,1-9.5-9.5,73,73,0,0,1,146,0A9.5,9.5,0,0,1,210.05,288.47Zm-116.61-19H199.72a54,54,0,0,0-106.28,0Z" /><path style={{ fill: '#052f40' }} d="M550.91,207.46H174a9.5,9.5,0,0,1-9.5-9.5c0-109.16,88.8-198,198-198s198,88.8,198,198A9.5,9.5,0,0,1,550.91,207.46Zm-367.17-19H541.16C536.21,94.18,457.94,19,362.44,19S188.69,94.18,183.74,188.46Z" /><path style={{ fill: '#052f40' }} d="M356.27,288.47h-127a9.5,9.5,0,0,1-9.5-9.5,73,73,0,0,1,146,0A9.5,9.5,0,0,1,356.27,288.47Zm-116.62-19H345.94a54,54,0,0,0-106.29,0Z" /><path style={{ fill: '#052f40' }} d="M504.47,288.47h-127A9.5,9.5,0,0,1,368,279a73,73,0,0,1,146,0A9.5,9.5,0,0,1,504.47,288.47Zm-116.62-19H494.13a54,54,0,0,0-106.28,0Z" /><path style={{ fill: '#052f40' }} d="M646.74,288.47h-127a9.5,9.5,0,0,1-9.5-9.5,73,73,0,0,1,146,0A9.5,9.5,0,0,1,646.74,288.47Zm-116.63-19H636.4a54,54,0,0,0-106.29,0Z" /><path style={{ fill: '#052f40' }} d="M684,309.9V550.5H28.5V309.9H684M603,528.68h57.3a19,19,0,0,0,19-19V379.27A18.84,18.84,0,0,0,679,376a47.56,47.56,0,0,0-95,3.3V509.68a19,19,0,0,0,19,19m-178.82,0h57.29a19,19,0,0,0,19-19V379.27a19,19,0,0,0-.27-3.22,47.56,47.56,0,0,0-95,3.22V509.68a19,19,0,0,0,19,19m-178.82,0h57.29a19,19,0,0,0,19-19V379.27a18.69,18.69,0,0,0-.3-3.37,47.55,47.55,0,0,0-95,3.37V509.68a19,19,0,0,0,19,19m-174.88,0h57.31a19,19,0,0,0,19-19V379.27a18.84,18.84,0,0,0-.29-3.3,47.57,47.57,0,0,0-95,3.3V509.68a19,19,0,0,0,19,19M703,290.9H9.5V569.5H703V290.9ZM603,509.68V379.27a28.57,28.57,0,1,1,57.13,0h.17V509.68Zm-178.82,0V379.27a28.57,28.57,0,1,1,57.13,0h.16V509.68Zm-178.82,0V379.27a28.56,28.56,0,1,1,57.11,0h.18V509.68Zm-174.88,0V379.27a28.57,28.57,0,1,1,57.13,0h.18V509.68Z" />
      </svg>
    )
}

export default HistorySvg 
