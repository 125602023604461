import React, {useState, useEffect} from 'react'
import { Row, Col, Button, Carousel, message } from 'antd'
import classes from "./css/Destinations.module.css"
import { useTranslation } from "react-i18next"
import { ArrowRightOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { useTopDestinations } from '../../../hooks/useTopDestinations'
import { settingsCarouselCategories } from '../../../components/ArrowsForCarousel/Categories'
import DestinationComponent from '../../../components/Box/DestinationComponent'
import { useUserFavoriteAddOrRemovePoint } from '../../../hooks/useUser'
import { useAuth } from "../../../utils/auth"
import i18n from '../../../translation'


const Destinations: React.FC = () => {

  const { topDestinations,  refe } = useTopDestinations(i18n.language)

  const { t } = useTranslation()
  const [messageApi, contextHolder] = message.useMessage()
  const auth = useAuth()
  const [pointId, setPointId] = useState<string>('')
  const [addDel, setAddDel] = useState<number>(0)

  const { refetch } = useUserFavoriteAddOrRemovePoint(auth.userData.id, pointId, addDel)

  const onClickAddOrRemove = (text: string, idPois: string, numberAddDel: number) => {

    setPointId(idPois)
    setAddDel(numberAddDel)
    messageApi.open({
      type: 'success',
      content: text,
      style: {
        marginTop: '20vh'
      },
    })
  }

  useEffect(() => {
    if (pointId !== '')
        refetch()
  }, [pointId])


  useEffect(() => {
    refe()
  }, [i18n.language])


  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isMobile = width <= 768;

  return (
    <>
      {contextHolder}

      <div className={classes.containerBg}>
        <div className={classes.container}>
          <Row justify="center">
            <Col span={20}>
              <div className={classes.title}>
                {t('Κορυφαίες επιλογές προορισμών')}
              </div>
            </Col>
          </Row>

          <Row justify="center">
            <Col xs={{ span: 22 }} lg={{ span: 20 }}>
              <div className={classes.subTitle}>
                {t('Διαλέξαμε για σένα τα καλύτερα μέρη για να επισκευτείς')}
              </div>
            </Col>
          </Row>

          {isMobile ?

            <Row justify="center" className={classes.DestinationContainer}>
              <Col xs={{ span: 22 }} md={{ span: 24}}  lg={{ span: 24 }}>
                <Carousel {...settingsCarouselCategories}>
                  {
                    topDestinations.map((point: any, index: any) => {
                      let textShort = `${point.description.substring(0, 50)} ...`
                      if (index <= 5)
                        return (
                          <span key={point.id}>
                            <DestinationComponent
                              id={point.id}
                              image={point.srcImage === '' ? '../img/defaultTopDestinationImage.png' : point.srcImage}
                              title={point.title}
                              text={textShort}
                              place={point.place}
                              type={point.type}

                              onClickAddOrRemovePois={(text, idPois, AddOrDelete) => onClickAddOrRemove(text, idPois, AddOrDelete)}
                            />
                          </span>
                        )
                    })
                  }
                </Carousel>
              </Col>
            </Row>
            :
            <Row justify="center" className={classes.DestinationContainer}>
              {
                topDestinations.map((point: any, index: any) => {
                  let textShort = `${point.description.substring(0, 50)} ...`
                  if (index <= 5)
                    return (
                      <Col xs={24} sm={24} md={12} lg={8} key={point.id}>
                        <DestinationComponent
                          id={point.id}
                          image={point.srcImage === '' ? '../img/defaultTopDestinationImage.png' : point.srcImage}
                          title={point.title}
                          text={textShort}
                          place={point.place}
                          type={point.type}

                          onClickAddOrRemovePois={(text, idPois, AddOrDelete) => onClickAddOrRemove(text, idPois, AddOrDelete)}
                        />
                      </Col>
                    )
                })
              }
            </Row>
          }

          <Row justify="center">
            <Col xs={{ span: 22 }} lg={{ span: 16 }}>
              <div className={classes.allDestinations}>
                <Link to={'../explorations'}>
                  <Button
                    type="primary"
                    className={classes.MoreBtn}
                  >
                    {t('Εξερεύνησε περισσότερα')}&nbsp;&nbsp;<ArrowRightOutlined style={{ fontWeight: 'bold' }} />
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>

        </div>
      </div>
    </>
  )
}

export default Destinations