import React from 'react'
import { Row, Col } from 'antd'
import classes from "./css/About.module.css"
import { useTranslation } from "react-i18next"


const About: React.FC = () => {

  const { t } = useTranslation()

  return (
    <>
      <div className={classes.container}>
        {/* <Row justify="center">
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <div className={classes.title}>
              <hr className={classes.hr} />
              {t('Τι είναι το ExploreChania.gr')}
            </div>
          </Col>
        </Row> */}

        
        <Row justify="center">
          <Col xs={24} sm={24} md={6} lg={6}>
            <div className={classes.center} >
              <hr className={classes.hr} />
            </div>
          </Col>
          <Col xs={24} sm={24} md={16} lg={16}>
            <div className={classes.title}>
              {t('Τι είναι το ExploreChania.gr')}
            </div>
          </Col>
          <Col lg={4}> </Col>
        </Row>




        <Row justify="center">
          <Col xs={24} sm={24} md={4} lg={{ span:4 }}>
            
          </Col>
          <Col xs={{ span: 22 }} lg={{ span: 12 }} >
            <div className={classes.subTitle}>
              {t('AboutText') }
            </div>
          </Col>

          <Col xs={{ span: 22 }} lg={{ span: 8 }} >
            {/* <div className={classes.subTitleText}>
              {t('Υπό την αιγίδα')}
            </div> */}
            <div className={classes.subTitleImage}>
              <img className={classes.image} alt="Chania image 1" src="./img/aigida.png" />
            </div>
          </Col>
        </Row>

      </div>
    </>
  )
}

export default About
