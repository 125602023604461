export const MunicipalitiesData = [
  // { id: '1', value: "Αποκόρωνας", item: "Αποκορώνου" },
  // { id: '2', value: "Γαύδος",     item: "Γαύδου" },
  // { id: '3', value: "Σέλινο", item: "Καντάνου - Σελίνου" },
  // { id: '4', value: "Κίσσαμος",   item: "Κισσάμου" },
  // { id: '5', value: "Πλατανιάς",   item: "Πλατανιά" },
  // { id: '6', value: "Σφακιά",    item: "Σφακίων" },
  // { id: '7', value: "Χανιά", item: "Χανίων" }
  
  { id: '1', value: "Δήμος Αποκορώνου", item: "Αποκορώνου"},
  { id: '2', value: "Δήμος Γαύδου",     item: "Γαύδου" },
  { id: '3', value: "Δήμος Καντάνου - Σελίνου", item: "Καντάνου - Σελίνου", },
  { id: '4', value: "Δήμος Κισσάμου",   item: "Κισσάμου" },
  { id: '5', value: "Δήμος Πλατανιά",   item: "Πλατανιά" },
  { id: '6', value: "Δήμος Σφακίων",    item: "Σφακίων" },
  { id: '7', value: "Δήμος Χανίων",     item: "Χανίων" }
]


export const MunicipalitiesSearchBoxData = [
  { id: '1', value: "Αποκόρωνας", item: "Αποκορώνου",name: "Αποκορώνου"},
  { id: '2', value: "Γαύδος",     item: "Γαύδου",   name: "Γαύδου" },
  { id: '3', value: "Σέλινο",     item: "Καντάνου - Σελίνου", name: "Καντάνου - Σελίνου" },
  { id: '4', value: "Κίσσαμος",   item: "Κισσάμου", name: "Κισσάμου" },
  { id: '5', value: "Πλατανιάς",  item: "Πλατανιά", name: "Πλατανιά" },
  { id: '6', value: "Σφακιά",     item: "Σφακίων",  name: "Σφακίων" },
  { id: '7', value: "Χανιά",      item: "Χανίων",   name: "Χανίων" }
]