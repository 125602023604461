
import React, {useState, useEffect} from "react"
import { Row, Col, Button, Spin, Image } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import { useEvent } from '../../../hooks/useEvent'
import { useParams, useNavigate } from 'react-router-dom'

import GlobusSvg from '../../../img/svg/globus'
import ClockSvg from '../../../img/svg/clock'
import MapSvg from '../../../img/svg/map'
import RouteSvg from '../../../img/svg/route'
import EuroSvg from '../../../img/svg/euro'
import EuroEventSvg from '../../../img/svg/euroEvent'


import HelmetComponent from '../../../components/Helmet'
import LiefletComponent from './Lieflet'
import SliderComponent from './Slider'
import ThreeEventsComponent from './ThreeEvents'
import ThreePoisesComponent from './ThreePoises'


import i18n from '../../../translation'
import { ChangeMunicipality } from '../../../components/Box/ChangeMunicipality'


const EventView: React.FC = () => {

  const { id } = useParams()
  const { isLoading, isFetching, refetch, EventData } = useEvent(id || '')

  const navigate = useNavigate();

  const { t } = useTranslation()

  const [event, setEvent] = useState<any>({})
  const [showMap, setShowMap] = useState<boolean>(false)

  const showInMapClicked = () => {
    window.open(`https://www.google.com/maps/dir//${event.venues[0].coordinate_x},+${event.venues[0].coordinate_y}/@${event.venues[0].coordinate_x},${event.venues[0].coordinate_y},13z/data=!4m6!4m5!1m0!1m3!2m2!1d22.3801386!2d38.3825321`)
  };

  useEffect(() => {
    if(EventData)
      setEvent(EventData)
  }, [EventData])

  useEffect(() => {
    refetch()
  }, [i18n.language])

  if (isFetching || Object.keys(event).length < 0) {
    return (
      <Row justify="center" >
        <Col span={12} offset={6}>
          <div className={classes.loadingSpinner}> <Spin size="large" /> {t('Παρακαλώ περιμένετε')} ... </div>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <HelmetComponent
        title={event.title}
        descrition={event.title+', ' + ChangeMunicipality(t(event.municipality))}
        keywords='έκθεση'
        image={event.image}
      />

      <div className={classes.containerBg}>
        <div className={classes.container}>
          <Row justify="center">
            <Col span={20}>
              <div className={classes.title}>
                {event.title}
              </div>
              <div className={classes.subTitle}>
                {event.category}
              </div>
             
              {event?.price && event?.price === 'free' ?  
                <div className={classes.subTitle}><span className={classes.type}>{t('Είσοδος Ελεύθερη')}</span><br /><br /></div> : ''
              }
              
            </Col>
          </Row>
        </div>
        <Row justify="center">
          <Col span={20}>
            <Button size='large' className={classes.BackBtn} onClick={() => navigate(-1)}>{t('Επιστροφή')}</Button>
          </Col>
        </Row>

      </div>
      

      {event.image
        && event.image.length !== 0
        && event.image.substring(event.image.length - 29, (event.image.length)) !== 'exlore-chania-placeholder.png'
        && <Row justify="center">  
            <div className={classes.helperS}>
              <Image src={event.image} className={classes.eventImg} alt="Image from Event" />
            </div>
          </Row> 
      }


      {showMap && event.venues[0].coordinate_x !== null &&
          
        <div className={classes.container}>

          <Row justify="center">
            <Col xs={24} sm={24} md={{ span: 24 }} lg={{ span: 24 }}>
              <div className={[classes.btn, classes.btnPadding].join(" ")}>
                <Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff" />} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp;  {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button>
              </div>
            </Col>
            <Col xs={24} sm={24} md={{ span: 24 }} lg={{ span: 24 }}>
              <LiefletComponent
                venues={event.venues}
                image={event.image}
                title={event.title}

                start={event.time_periods[0].starting_date}
                end={event.time_periods[0].ending_date}
                category_id={event.category_id}
                municipality={event.municipality}
                municipality2={event.municipality2}
                latitude={event.venues[0].coordinate_x}
                longitude={event.venues[0].coordinate_y}
                category={event.category}
              />
            </Col>
          </Row>
        </div>
      } 
      
      <div className={classes.container}>
        <div className={classes.checkBoxesContainerPoint}>
          <Row justify="center">
            <Col xs={24} sm={24} md={24} lg={24} >
              {!showMap && event?.venues && event?.venues.length && event.venues[0].coordinate_x !== null ?
                <div className={classes.btn}><Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff" />} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp;  {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button></div>
              : ''}
            </Col>
          </Row>
        </div>
      </div>
      
      <Row justify="center">
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14 }} lg={{ span: 14 }} >
          <div className={classes.description}>
           
            <div className={classes.eventTitle}>{t('Περιγραφή')}</div>

            <div className={classes.eventDescription} dangerouslySetInnerHTML={{ __html: event.description }} />

            

            {event.gallery && event.gallery.length !== 0 &&
              <>
              <div className={classes.eventPhoto}>{t('Πολυμεσικό Υλικό Εκδήλωσης')}</div>
                  <SliderComponent images={event.gallery} />
              </>
            }
            


            <div className={classes.eventInformation}>{t('Πληροφορίες')}</div>

            <div className={classes.MyRowColumnDescription}>

              <p>{<GlobusSvg width={24} height={24} fill="#275674" />} <span>{event.municipality}</span> </p>
      
              {event.time_periodsTwo && event?.time_periodsTwo.length !== 0 &&
                <p><ClockSvg width={24} height={24} fill="#275674" />
                  <span>
                    {(event.time_periodsTwo[0].starting_date.slice(-5) === '23:59' || event.time_periodsTwo[0].starting_date.slice(-5) === '24:00' || event.time_periodsTwo[0].starting_date.slice(-5) === '00:00' ) ? event.time_periodsTwo[0].starting_date.slice(0, (event.time_periodsTwo[0].starting_date.length, -7)) : event.time_periodsTwo[0].starting_date }
                    &nbsp; - &nbsp;
                    {event.time_periodsTwo && (event.time_periodsTwo[0].ending_date.slice(-5) === '23:59' || event.time_periodsTwo[0].ending_date.slice(-5) === '24:00' || event.time_periodsTwo[0].ending_date.slice(-5) === '00:00' ) ? event.time_periodsTwo[0].ending_date.slice(0, (event.time_periodsTwo[0].ending_date.length, -7)) : event.time_periodsTwo[0].ending_date}
                  </span>
                </p>
              }

              {event.venues && event?.venues.length !== 0 && event?.venues[0].coordinate_x !== null &&
                <p><MapSvg width={24} height={24} fill="#275674" />
                  <span className={classes.url} onClick={showInMapClicked}>{event.venues[0].title}</span>
                </p>
              }

              {event.urls && event?.urls.length !== 0 &&
                <p className={classes.pUrl}><RouteSvg width={24} height={24} fill="#275674" />
                  <span className={classes.url} onClick={() => window.open(event.urls[0].url , '_blank')}>{event.urls[0].url}</span>
                </p>
              }

              

             {/* <p>{<EuroSvg width={24} height={24} fill="#275674" />} <span className={classes.price}>{event?.price && event?.price.length == 0 ? t('Είσοδος Ελεύθερη') : event?.price && event?.price[0]?.price + ' (' + event?.price[0]?.description + ')'}</span></p> */}

             {event?.price && event?.price == 'free' ? <p><EuroSvg width={24} height={24} fill="#275674" /><span className={classes.price}>{t('Είσοδος Ελεύθερη')} </span></p> :
              
                event.price == 'out' ?
                  event.urls && event?.urls.length !== 0 ?
                    <p><EuroEventSvg width={24} height={24} fill="#275674" />&nbsp;<span style={{ top: '0px', left: '10px', position: 'relative' }} className={classes.url} onClick={() => window.open(event.urls[0].url, '_blank')}>{t('Περισσότερες πληροφορίες εδώ')}</span></p>
                  :''
 
                :
                event?.price && event?.price.length !== 0 &&
                <>
                  <p className={classes.pUrl2}><EuroEventSvg width={24} height={24 } fill="#275674" />
                 
                      {event.price.slice(0,2).map((item: any, index: number) => {
 
                        return (
                          <span key={index}>
                      
                            {t(item.description) + ': '}{item.price}&euro;{ index == 0 && event.price.length !== 1 && ', '} 
                      
                          </span>
                        )
                      })
                      }
                    {event.urls && event?.urls.length !== 0 &&
                       <> { '. '} <span className={classes.url} onClick={() => window.open(event.urls[0].url, '_blank')}>{t('Περισσότερες πληροφορίες εδώ')}</span></>
                    }
                </p>
              </>
              }

            </div>
         </div>
        </Col>
      </Row> 

      <Row justify="center">
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 18 }} >
          <ThreeEventsComponent />
        </Col>
      </Row>

      <div style={{ backgroundColor: '#f3f8fd' }}>
        <Row justify="center">
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 18 }} >
            <ThreePoisesComponent />
          </Col>
        </Row>
      </div>

    </>
  )
}

export default EventView
