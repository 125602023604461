import React from "react"
import { Row, Col, Button } from 'antd'
import classes from './styles.module.css'
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'


const ReturnAndHomeBtn: React.FC = () => {

  const { t } = useTranslation()

  const navigate = useNavigate()

  return (
    <div className={classes.btnSection}>
      <Row justify="center">
        <Col xs={24} sm={24} md={11} lg={11}>
          <Button
            size='large'
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate(-1)}
            className={classes.ReturnBtn}>&nbsp;&nbsp;{t('Επιστροφή')}
          </Button>
        </Col>
        <div className={classes.space}></div>
        <Col xs={24} sm={24} md={11} lg={11}>
          <Link to="/">
            <Button
              size='large'
              className={classes.HomeBtn}
            >
              {t('Αρχική Σελίδα')}&nbsp;&nbsp;<ArrowRightOutlined />
            </Button>
          </Link>
        </Col>
      </Row>
    </div>
  )
}

export default ReturnAndHomeBtn
