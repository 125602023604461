
import React, {useState, useEffect} from "react"
import { Col, Row, Pagination } from 'antd'
import { useTranslation } from "react-i18next"
import classes from "./styles.module.css"
import EventComponent from '../../components/Box/EventComponent'


// Redux
import { useDispatch } from 'react-redux'
import { EventsPageNumberRedux, EventsPageSizeRedux }  from '../../redux/mapPage/event'

interface Props{
  paginationNumber: number
  paginationSize: number
  array: any;
  total: number
  setEventsMarkerExploration: any
}

const ViewEventsComponent: React.FC<Props> = ({ paginationNumber, paginationSize, array, total, setEventsMarkerExploration }:Props ) => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [Events, setEvents] = useState<any[]>(array);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(9);

  useEffect(() => {

    setEvents(array)
    setEventsMarkerExploration(currentPage * pageSize > array.length ? array.slice((currentPage - 1) * pageSize) : array.slice((currentPage - 1) * pageSize, currentPage * pageSize))

  }, [array])
  

  useEffect(() => {

    if (paginationNumber !== 0 || paginationSize != 9)
      setEvents(paginationNumber * paginationSize > array.length ? array.slice((paginationNumber - 1) * paginationSize) : array.slice((paginationNumber - 1) * paginationSize, paginationNumber * paginationSize))
      
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      setEventsMarkerExploration(paginationNumber * paginationSize > array.length ? array.slice((paginationNumber - 1) * paginationSize) : array.slice((paginationNumber - 1) * paginationSize, paginationNumber * paginationSize)) 
  
  }, [paginationNumber, paginationSize])


  const onChange = (page: number, pageSize: number) => {

    dispatch(EventsPageNumberRedux(page))
    dispatch(EventsPageSizeRedux(pageSize))
    setCurrentPage(page)
    setPageSize(pageSize)
    setEvents(page * pageSize > array.length ? array.slice((page - 1) * pageSize) : array.slice((page - 1) * pageSize, page * pageSize))
    window.scrollTo({ top: 300, left: 0, behavior: 'smooth' })
    setEventsMarkerExploration(page * pageSize > array.length ? array.slice((page - 1) * pageSize) : array.slice((page - 1) * pageSize, page * pageSize))
  }

  return (
    <>
      <Row justify="center">
        {Events.slice(0, paginationSize).map((event: any) => {

          return (
            <Col xs={24} sm={24} md={12} lg={12} xl={8} key={event.id}>
              <EventComponent 
                id_item={event.id_item}
                image={event.image}
                title={event.title}
                price={event.price}
                time_periods={event.time_periods}
                place={event.place}
                category_id={event.category_id}
                category_type={event.category_type}
              />
            </Col>
          )
        })}
      </Row>
      
      <Row justify="center">
        <Col span={23}>
          <Pagination
            onChange={onChange}
            className={classes.pagination}
            total={total}
            showSizeChanger={true}
            showQuickJumper={false}
            showTotal={(total, range) => t('Αποτελέσματα') + ' ' + `${range[0]} - ${range[1]}` + ' ' + t('από') + ' ' + `${total}`}
            defaultPageSize={paginationSize}
            pageSizeOptions={[9, 21, 60, 100]}
            current={paginationNumber !== 0 ? paginationNumber : 1  }
          />
        </Col>
      </Row>

    </>
  )
}

export default ViewEventsComponent