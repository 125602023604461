
import React from "react"
import Props from './svgProps'
    
const TheaterSvg = ({width, height, fill}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="https://www.w3.org/2000/svg"
        viewBox="0 0 19.96 20">
        
        <path fill={fill} d="M16.45,9.63a1.33,1.33,0,0,0-1.52.88,11.07,11.07,0,0,1,1.48.5,5.18,5.18,0,0,1,1.23.66s-.18-1.86-1.19-2Z" />
        <path fill={fill} d="M10.73,10.31a15,15,0,0,1-3.24.44,17,17,0,0,1-3.55-.44l-.27.22a3.68,3.68,0,0,0,7.34,0Z" />
        <path fill={fill} d="M10.33,6.85a5,5,0,0,1,1.78,0s-.58-1.48-1.85-1.48a2.16,2.16,0,0,0-1.84,2,8.86,8.86,0,0,1,1.91-.51Z" />
        <path fill={fill} d="M6.26,7.36c.07-.53-.68-2-1.84-2S2.57,6.83,2.57,6.83a5,5,0,0,1,1.78,0,8.86,8.86,0,0,1,1.91.51Z" />
        <path fill={fill} d="M15.41,5.26c-.35-.08-.72-.14-1.08-.19a18.06,18.06,0,0,0-.23-2.14C13.8,1.24,10.38.46,7.34.46S.87,1.24.58,2.93A19.06,19.06,0,0,0,.31,6.08c0,6.52,3.09,11.63,7,11.63A4.7,4.7,0,0,0,9.84,17a4.22,4.22,0,0,0,2.63,2,4,4,0,0,0,.77.08,5.38,5.38,0,0,0,3.87-2A13.25,13.25,0,0,0,20,11a15.37,15.37,0,0,0,.32-2.52c.06-1.44-2.52-2.65-4.94-3.17Zm-14,.83a19.25,19.25,0,0,1,.24-3c.1-.56,2.08-1.55,5.67-1.55s5.57,1,5.66,1.55h0a17.22,17.22,0,0,1,.25,3c0,5.79-2.65,10.5-5.91,10.5S1.43,11.88,1.43,6.09Zm17.81,2.3a15.36,15.36,0,0,1-.3,2.33,12,12,0,0,1-2.66,5.52c-1.16,1.27-2.43,1.84-3.58,1.59a2.71,2.71,0,0,1-1.31-.72,7.32,7.32,0,0,0,1.52-2l.56.1a8.71,8.71,0,0,1,2,.8l.33-.15a2.87,2.87,0,0,0-.34-2,2.42,2.42,0,0,0-1.59-1.25h0a18.88,18.88,0,0,0,.6-6.16,2.46,2.46,0,0,1,0-.26c.23,0,.47.08.72.14,2.66.57,4.09,1.65,4.07,2Z" />

      </svg>
    )
}

export default TheaterSvg 
