
import React, {useState, useEffect } from "react"
import Spinner from '../../components/PageComponents/Spinner'
import { Button, Row, Col } from 'antd'
import classes from './styles.module.css'
import { PoweroffOutlined } from '@ant-design/icons';
import { useCreatePoisJson } from '../../hooks/useCreatePoisJson'

const JsonPage: React.FC = () => {

  const [lang, setLanguage] = useState<string>('')
  const [loadings, setLoadings] = useState<boolean[]>([])

  const { isLoading, isFetching, arrayPois, refetch } = useCreatePoisJson(lang)



  const enterLoading = (index: number) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };


  console.log(arrayPois)


  useEffect(() => {

    if(lang.length !== 0){
      refetch()
    }

  }, [lang])



  const ExportUserInfo = (arrayPois: any) => {
    
    let str = "export const PoisesData" + lang.charAt(0).toUpperCase() + lang.slice(1) + " = "
    
    const fileData = JSON.stringify(arrayPois, null, 2);
    let blob = new Blob([fileData], { type: "application/json" });
    blob = new Blob([str , blob], {type: "application/json"});
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `${lang}.json`;
    link.href = url;
    link.click();

    // var reader = new FileReader();
    // reader.onload = function () {
    //   alert(reader.result);
    // }
    // reader.readAsText(blob);
  }


  if ( isFetching ) {
    return (<Spinner />)
  }
  

  return (
    <>
      <div className={classes.container}>
        <Row justify="center">
          <Col xs={{ span: 24 }} lg={{ span: 19 }}>

            <Button type="primary" className={classes.padding} icon={<PoweroffOutlined />} loading={loadings[2]} onClick={() => [setLanguage('el'), enterLoading(2)]}>Gr</Button>
            <Button type="primary" className={classes.padding} icon={<PoweroffOutlined />} loading={loadings[2]} onClick={() => [setLanguage('en'), enterLoading(2)]}>En</Button>
            <Button type="primary" className={classes.padding} icon={<PoweroffOutlined />} loading={loadings[2]} onClick={() => [setLanguage('fr'), enterLoading(2)]}>Fr</Button>
            <Button type="primary" className={classes.padding} icon={<PoweroffOutlined />} loading={loadings[2]} onClick={() => [setLanguage('de'), enterLoading(2)]}>De</Button>
            <Button type="primary" className={classes.padding} icon={<PoweroffOutlined />} loading={loadings[2]} onClick={() => [setLanguage('ru'), enterLoading(2)]}>Ru</Button>

            <Button type="default" className={classes.paddingDownload} onClick={ () => ExportUserInfo(arrayPois) }>Download</Button>

          </Col>
        </Row>
      </div>
     
    </>
  )
}

export default JsonPage
