import React, {useState} from "react"
import { Col, Row } from 'antd'
import classes from "../styles.module.css"
// import SvgPointer from "./images/svg_pointers"

// LEAFLET
import { Icon } from 'leaflet'
import { ZoomControl, ScaleControl, MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import point from "../EventView/point.svg"


import GlobusSvg from '../../../img/svg/globus'
import EventsSvg from '../../../img/svg/events'
import TheaterSvg from '../../../img/svg/theater'
import MovieSvg from '../../../img/svg/movie'
import DanceSvg from '../../../img/svg/dance'
import ConcertSvg from '../../../img/svg/concert'
import ConcertsSvg from '../../../img/svg/concerts'
import LaboratoriesSvg from '../../../img/svg/laboratories'
import ExhibitionSvg from '../../../img/svg/exhibition'
import ConferencesSVG from '../../../img/svg/conferences'
import { useTranslation } from "react-i18next"


let SVG_Point = new Icon({
  iconUrl: point,
  popupAnchor: [0, -20],
  iconSize: [36, 36]
})

interface Props{
  image: string
  title: string
  municipality: string
  municipality2: string
  latitude: string
  longitude: string
  venues: any,
  start: string
  end: string
  category: string
  category_id: number

}

const LiefletComponent: React.FC<Props> = ({ venues, image, title, municipality, municipality2, latitude, longitude, start, end, category, category_id }: Props) => {
  
  const { t } = useTranslation()

  const MarkersPois = () => {

    return <>
      {
        venues.map((event: any) => {
   
          return (
            <Marker
              key={1}
              position={[event.coordinate_x, event.coordinate_y]}
              icon={SVG_Point}
            >

              <Popup className="Event-popup">
                <div className={classes.MarkerGpsInfoWindow}>
                  <img src={image !== '' ? image : '../img/defaultPoisImage.jpg'} alt={title} />
                  <div className={classes.PointCenter}>
                    <div className={classes.MarkerPointCenterLeft}>
                      {title.length <= 52 ? title : title.slice(0, 52)+ ' ...'}
                    </div>
                  </div>

                  <div className={classes.BoxDates}>
                    {start !== '12/10/1983, 12:00' &&
                      <>
                        <div>{t('Έναρξη')}: {start}</div>
                        <div>{t('Λήξη')}: {end}</div>
                      </>
                    }
                  </div>

                  <div className={classes.MarkerPointCenterBottom}>
                    <div className={classes.MarkerPointCenterButtomLeft}>
                       <GlobusSvg width={26} height={24} fill="#fff"/><span className={classes.text}>{municipality2}</span>
                    </div>
                    <div className={classes.MarkerPointCenterButtomRight}>
                      {category_id == 18 ? <MovieSvg width={26} height={26} fill="#fff"/> :
                        category_id == 19 ? <LaboratoriesSvg width={26} height={26} fill="#fff"/> :
                        category_id == 23 ? <EventsSvg width={26} height={26} fill="#fff"/> :
                        category_id == 25 ? <ConcertsSvg width={26} height={26} fill="#fff"/> :
                        category_id == 26 ? <DanceSvg width={26} height={26} fill="#fff"/> :
                        category_id == 27 ? <ExhibitionSvg width={26} height={26} fill="#fff"/> :
                        category_id == 28 ? <TheaterSvg width={26} height={26} fill="#fff"/> :
                        category_id == 29 ? <ConcertSvg width={26} height={26} fill="#fff"/> : <ConferencesSVG width={26} height={26} fill="#fff"/>}                    
                      <span className={classes.text}>{category}</span>
                    </div>
                  </div>
                </div>
              </Popup>
            </Marker>
          )
        })
      }
    </>
  }


  return (
    <>
      <Row justify="center">
        <Col span={23}>

          <MapContainer
            key={JSON.stringify([36.09132683539974, 28.08729093999769])} // To center every time
            center={[parseFloat(latitude), parseFloat(longitude)]}
            zoom={14}
            tap={false}
            style={{ height: '573px', width: '100%', zIndex: '0' }}
            zoomControl={false}
            scrollWheelZoom={false}
          >
            <ZoomControl position='bottomright' />
            <ScaleControl position="bottomright" metric imperial={false} />
            <MarkersPois />
            <TileLayer url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />

          </MapContainer>

        </Col>
      </Row>
    </>
  )
}

export default LiefletComponent