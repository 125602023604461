import ky from 'ky'
import axios from 'axios'
import {API, Header, HeaderForUser} from './configuration'
import i18n from '../../src/translation'

export const user = {

  // async loginUser(email: string, password: string) {
  //   const data: any = await ky.post(`${API}/en/login?email=${email}&password=${password}`, { headers: HeaderForUser }).json()
  //   return data
  // },

  async loginUser(email: string, password: string) {

    // let emailq = 'sergios@dotsoft.gr'
    // let passwordq = '12345678'
    // console.log(emailq, passwordq)
    // console.log(email, password)

    const { data } = await axios.post(`${API}/en/login?email=${email}&password=${password}`,{
      timeout: 14000
    }, { headers: Header })
    return data
  },
  
  // async registerUser(username: string, email: string, password: string) {
  //   const data: any = await ky.post(`${API}/en/register?name=${username}&email=${email}&password=${password}`, { headers: HeaderForUser }).json()
  //   return data.data
  // },

  async registerUser(username: string, email: string, password: string) {
    const data = await axios.post(`${API}/en/register?name=${username}&email=${email}&password=${password}`,{
      timeout: 14000
    }, { headers: Header })
    return data.data
  },




  async userFavoriteAddOrRemovePoint(id: string, idPoint: string, removeOrAdd: number ) {
    const data: any = await ky.post(`${API}/editFavorites?user_id=${id}&poi_id=${idPoint}&add=${removeOrAdd}`, { headers: HeaderForUser }).json()
    return data.success
  },


  async userFavoritePoints(idUser: string) {
    const data:any = await ky.get(`${API}/favorites?user_id=${idUser}&lang=${i18n.language == 'gr' ? 'el' : i18n.language }`, { headers: HeaderForUser }).json()
    return data.success
  },

  // async userFavoritePoints(idUser: string) {
  //   const data = await axios.get(`${API}/favorites?user_id=${idUser}&lang=${i18n.language == 'gr' ? 'el' : i18n.language }`, {
  //     timeout: 14000
  //   }, { headers: HeaderForUser })
  //   return data.success
  // },

}