import { useQuery } from '@tanstack/react-query'
import { axiosWeather } from "../services/weather"
import { useState} from "react"
import {Weather} from '../services/props'

// export const useWeather = () => {
  
//   const [weatherData, setWeatherData] = useState<Weather[]>([]);

//   let deg: string = ''
//   let dg: number = 0
//   let arrayWeather: any[] = []

//   const { isLoading, error } = useQuery(['weather'], () => axiosWeather.getWeather(), {
   
//     onSuccess: (data) => {

//       dg = data.wind.deg

//       if (dg > 11.25 && dg <= 56.25) {
//         deg = 'Β';
//       } else if (dg > 56.25 && dg <= 101.25) {
//         deg = 'ΒΑ';
//       } else if (dg > 101.25 && dg <= 146.25) {
//         deg = 'Α';
//       } else if (dg > 146.25 && dg <= 191.25) {
//         deg = 'ΝΑ';
//       } else if (dg > 191.25 && dg <= 236.25) {
//         deg = 'Ν';
//       } else if (dg > 236.25 && dg <= 281.25) {
//         deg = 'ΝΔ';
//       } else if (dg > 281.25 && dg <= 326.25) {
//         deg = 'Δ';
//       } else {
//         deg = 'ΒΔ';
//       }

//       arrayWeather.push({
//         description: data.weather[0].description,
//         image: `https://openweathermap.org/img/w/${data.weather[0].icon}.png`,
//         degree_min: Math.round(data.main.temp_min),
//         degree_max: Math.round(data.main.temp_max),
//         degree_feels: data.main.feels_like,
//         humidity: data.main.humidity,
//         wind_speed: data.wind.speed,
//         wind_deg: deg
//       })

//       setWeatherData(arrayWeather)
      
//     },
//     onError: (error:any) => {
//       alert(error.message)
//     }
//   })

//   return { isLoading, error, weatherData }
  
// }


export const useWeather = () => {
  
  const [weatherData, setWeatherData] = useState<Weather[]>([]); 

  let arrayWeather: any[] = []

  const { isLoading, error } = useQuery(['weather'], () => axiosWeather.getWeather(), {
   
    onSuccess: (data) => {

      arrayWeather.push({
        degree_min: Math.round(data.daily[0].temp.min),
        degree_max: Math.round(data.daily[0].temp.max)
      })

      setWeatherData(arrayWeather)
      
    },
    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, error, weatherData }
  
}



export const useHours = () => {
  
  const [hours, setHours] = useState(''); 

  const { isLoading, error } = useQuery(['hours'], () => axiosWeather.getHours(), {
   
    refetchInterval: 30000, // call every 30 sec

    onSuccess: (data) => {

      setHours(data.datetime.split('T')[1].split('.')[0].slice(0, -3))
      // console.log(data)
    },
    onError: (error:any) => {
      // alert(error.message)
    }
  })

  return { isLoading, error, hours }
  
}