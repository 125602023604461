import React from "react"
import { Row, Col, Spin } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"

interface Props{
  text?: string
}

const Spinner: React.FC<Props> = ({ text = 'Παρακαλώ περιμένετε' }: Props) => {

  const {t} = useTranslation()

  return (
    <Row justify="center" >
      <Col span={12} offset={6}>
        <div className={classes.loading}> <Spin size="large" /> { t(text) } ...</div>
      </Col>
    </Row>
  )
}

export default Spinner
