import { createSlice } from '@reduxjs/toolkit'

interface RoutesArray{
  value: number;
}

interface MunicipalityArray{
  value: string;
}

interface RouteState {
  Routes: RoutesArray[]
  Municipality: MunicipalityArray[]
}

const initialState: RouteState = {
  Routes: [],
  Municipality: []
};

export const RouteSlice = createSlice({
  name: 'Routes',
  initialState,
  reducers: {
    Routes: (state, action) => {
      state.Routes.splice(0, state.Routes.length);
      state.Routes.push(action.payload)
    },

    Municipality: (state, action) => {
      // state.Municipality.splice(0, state.Municipality.length);
      // if(action.payload !== '')
      //   state.Municipality.push(action.payload) 
      state.Municipality = [];
      action.payload == '' ? state.Municipality = [] : state.Municipality.push(action.payload)
    }
  },
})

export const { Routes, Municipality } = RouteSlice.actions
export default RouteSlice.reducer 