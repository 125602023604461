import React, { useState, useRef } from 'react'
import { Col, Row, Button, Rate, Popover } from 'antd'
import classes from "./Pois.module.css"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../utils/auth"
import { HeartOutlined, PlusCircleOutlined, CloseCircleOutlined, ExclamationOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import LoginOrRegisterModal from './LoginOrRegisterModal'
import GlobusSvg from '../../img/svg/globus'
import MapSvg from '../../img/svg/map'

import { ChangeMunicipality } from './ChangeMunicipality'
interface Props{
  id: string
  image: string
  title: string
  rate: number
  text: string
  municipality: string
  category: string
  isAuth?: boolean
  isAddOrDelete?: boolean
  onClickAddOrRemovePois: (text: string, idPois: string, AddOrDelete: number) => void,
  onClickAddRemovePois: (idPois : string) => void,
}

const PoisComponent: React.FC<Props> = ({id, image, title, rate, text, municipality, category, isAuth, isAddOrDelete, onClickAddOrRemovePois, onClickAddRemovePois }:Props) => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const auth = useAuth()
  const Municipality = ChangeMunicipality(municipality)

  const [size, setSize] = useState<any>({});
  const imgRef = useRef<any>(null)
  
  let str: any = []
  let content: any = null
  
  if (category.indexOf(',') > -1) {
    
    str = category.split(', ')
    content = (
      <div style={{ color: '#275674', fontWeight: 600 }}>
        <p >{str[0]}</p>
        <p>{str[1]}</p>
      </div>
    );

  }
      
  
  return (
  <>
    <div className={classes.PointContainer}>
      <Row>

        <Col xs={24} sm={24} md={5} lg={6}>
          <Link to={`/exploration/${id}`} >
              <div>
                <img
                  ref={imgRef}
                  onLoad={ (e: any ) => {
                          setSize({
                            height: e.target.naturalHeight,
                            width: e.target.naturalWidth
                          })
                  }}
                  src={image !== '' ? image : '../img/defaultPoisImage.jpg'}
                  className={ size.height > size.width ? classes.PointImgPortrait : classes.PointImg}
                  alt={title} />
              </div>
          </Link>
        </Col>

        <Col xs={24} sm={24} md={14} lg={13}>

          <div className={classes.PointCenter}>
            
            <div className={classes.PointCenterLeft} onClick={() => navigate(`/exploration/${id}`, { replace: false  })}>
              {title}
            </div>
              
            <div className={classes.PointCenterRight}>
              <Rate disabled allowHalf value={rate} />
            </div>
          </div>

          <div className={classes.PointCenter}>
            <p className={classes.PointCenterCenterP}>{text}...</p>
          </div>

          <div className={classes.PointCenterBottom}>
            <div className={classes.PointCenterButtomLeft}>
                <div><GlobusSvg width={25} height={25} fill="#275674" />
                  <span className={classes.PointTextBtm}>
                    { id === '1_48' ? t('FaraggiSamarias') : id === '1_290' ? t('SelinoKissamos') : t(Municipality) }
                  </span>
                </div>
            </div>
            <div className={classes.PointCenterButtomRight}>
              
                <div><MapSvg width={25} height={25} fill="#275674" />
                  <span className={classes.PointTextBtm}>
                    {str.length >= 1
                      ?
                      <Popover content={content} title="" trigger="hover">
                        <span>{str[0] + ' (+1)'}</span>
                      </Popover>
                  : category.charAt(0).toUpperCase() + category.slice(1).toLowerCase()
                  }  
                  </span>
                </div>
              {/* <div><MapSvg width={25} height={25} fill="#275674" /><span className={classes.PointTextBtm}>{category.charAt(0).toUpperCase() + category.slice(1).toLowerCase()}</span></div>
             */}
              </div>
          </div>
        </Col>

        <Col xs={24} sm={24} md={5} lg={5}>
            <div>
              <LoginOrRegisterModal
                id={id}
                isAuth={isAuth}
                isAddOrDelete={isAddOrDelete}
                onClickAddOrRemovePois={onClickAddOrRemovePois}
                onClickAddRemovePois={onClickAddRemovePois}
              
              />
            {/* {(auth.userData.id == '' || auth.userData.id == undefined)
              ?
              <Popconfirm
                title=
                {<div style={{ width: '200px', textAlign: 'left', fontSize: '14px', color: '#05324c', lineHeight: '1.2' }}>
                  {t('Για να προσθέσεις σημείο ενδιαφέροντος στα Αγαπημένα σου, κάνε σύνδεση ή εγγραφή')}
                </div>}
                onConfirm={() => navigate('/login')}
                icon={<ExclamationOutlined style={{ color: '#275674', fontWeight: '600' }} />}
                okText={t('Σύνδεση')}
                showCancel={false}
              >
                 <div className={!isAuth ? classes.PointFavoriteBtn2 : classes.PointRemoveFavoriteBtn} >
                    {!isAuth ? < HeartOutlined /> : <CloseCircleOutlined />}&nbsp;
                    {!isAuth ? t('Προσθήκη στα Αγαπημένα') : t('Αφαίρεση απο αγαπημένα')}
                  </div>
              </Popconfirm>
                
              :
                
              <Popconfirm
                title= {<div style={{ width: '200px', textAlign: 'left', fontSize: '14px', color: '#055604', lineHeight: '1.2' }}> {t('Το Σημείο Ενδιαφέροντος προστέθηκε στα Αγαπημένα σου!')} </div>}
                icon={< HeartOutlined style={{color: '#055604' }} />}
                showCancel={false}      
              > */}
                {/* <Button
                  size='large'
                  icon={!isAuth ? < HeartOutlined /> : <CloseCircleOutlined />}
                  className={!isAuth ? classes.PointFavoriteBtn : classes.PointRemoveFavoriteBtn}
                  onClick={() => auth.userData.id !== '' && [onClickAddRemovePois(id), isAddOrDelete ? onClickAddOrRemovePois(t('Σημείο Ενδιαφέροντος προστέθηκε με επιτυχία'), id, 1) : onClickAddOrRemovePois(t('Σημείο Ενδιαφέροντος διαγράφηκε επιτυχώς'), id, 0)]}>
                  {!isAuth ? t('Προσθήκη στα Αγαπημένα') : t('Αφαίρεση')}
                </Button> */}
                  
                {/* <div
                  className={!isAuth ? classes.PointFavoriteBtn2 : classes.PointFavoriteBtnRemove}
                  // style={{color: 'red'}}
                  onClick={() => auth.userData.id !== '' && [onClickAddRemovePois(id), isAddOrDelete ? onClickAddOrRemovePois(t('Σημείο Ενδιαφέροντος προστέθηκε με επιτυχία'), id, 1) : onClickAddOrRemovePois(t('Σημείο Ενδιαφέροντος διαγράφηκε επιτυχώς'), id, 0)]}
                >
                  {!isAuth ? < HeartOutlined /> : <CloseCircleOutlined />}&nbsp;
                  {!isAuth ? t('Προσθήκη στα Αγαπημένα') : t('Αφαίρεση απο αγαπημένα')}
                </div>
              </Popconfirm>
            }*/}
            <Button
              size='large'
              icon={< PlusCircleOutlined />}
              className={classes.PointMoreBtn}
              onClick={() => navigate(`/exploration/${id}`)}>
              {t('Περισσότερα')}
            </Button> 
          </div>
        </Col>

      </Row>
      </div>
    </>
  )
}

export default PoisComponent
