import React, {useEffect, useState, useRef} from "react"
import { Col, Row, Button, Tooltip } from 'antd'
import classes from "./styles.module.css"
import PlaySvg from '../../../img/svg/play'
import StopSvg from '../../../img/svg/stop'
import { useTranslation } from "react-i18next"

interface Props{
  title: string
  mp3: any
}

const PlayComponent: React.FC<Props> = ({ title, mp3 }: Props) => {
  
  const { t } = useTranslation()

  let audioGr: any = useRef();
  let audioEn:any = useRef();
  
  const [svgPlayGrClick, setSvgPlayGrClick] = useState<boolean>(true)
  const [svgPlayEnClick, setSvgPlayEnClick] = useState<boolean>(true)

  // const [titleGr, setTitleGr] = useState<string>('')
  // const [titleEn, setTitleEn] = useState<string>('')

  useEffect(() => {
    if (mp3) {
      audioGr.current = new Audio(mp3[0].caption.substring(1, 4) === 'GRE' ? mp3[0].url : mp3[1].url)
      audioEn.current = new Audio(mp3[0].caption.substring(1, 4) === 'ENG' ? mp3[0].url : mp3[1].url)
      // setTitleGr(mp3[1].caption.substring(1, 4) == 'GRE' ? mp3[1].caption.substring(5) : mp3[0].caption.substring(5))
      // setTitleEn(mp3[0].caption.substring(1, 4) == 'ENG' ? mp3[0].caption.substring(5) : mp3[1].caption.substring(5))
    }
  }, [mp3])


  useEffect(() => {
    return () => {
      audioGr.current.pause()
      audioEn.current.pause()
    }
  }, [])



  const playAudioGr = () => {

    if (!svgPlayGrClick) {
      audioGr.current.pause()
      audioEn.current.pause()
      setSvgPlayGrClick(true);
      setSvgPlayEnClick(true);
    }
    else {
      
      audioGr.current.play()
      audioEn.current.pause()
      setSvgPlayGrClick(false);
      setSvgPlayEnClick(true);
    }
  }

  const playAudioEn = () => {

    if (!svgPlayEnClick) {
      audioGr.current.pause()
      audioEn.current.pause()
      setSvgPlayGrClick(true);
      setSvgPlayEnClick(true);
    }
    else {
      audioGr.current.pause()
      audioEn.current.play()
      setSvgPlayGrClick(true);
      setSvgPlayEnClick(false);
    }
  }


  return (
    <>
      <br/>
      <Row justify="center">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 14 }} lg={{ span: 14 }} >
          <div className={classes.eventTitle}>{t('Ακουστική Περιγραφή')}</div>
          
          <Tooltip placement="top" color='#1a4a68' title={ t('Κάντε κλικ για αναπαραγωγή')} >
            <Button
              icon={svgPlayGrClick ? <PlaySvg width={37} height={37} fill="#fff" /> : <StopSvg width={37} height={37} fill="#000" />}
              onClick={() => playAudioGr()}
              className={classes.btnPlay}>
              
              <span className={classes.btnPlayTitle}> {title} </span>
              <span className={classes.btnPlayFlag}><img src="/img/useful/gr_flag.png" /></span>
              <span className={classes.btnPlayText}>{t('Ελληνικά')}</span>
            </Button>
          </Tooltip>
        
          {/* <div className={classes.btn22}>
            <span className={classes.btnTit}>{mp3 && mp3[0].caption}</span>
            {audioGr1 && 
              <audio controls>
                <source src={audioGr1} type="audio/ogg"/>
                <source src={audioGr1} type="audio/mpeg"/> 
              </audio>
            } 
              <span className={classes.btnFlag}><img src="/img/useful/gr.png" /></span>
               <span className={classes.btnFlag2}>Ελληνικά</span>
            </div>*/}

          <br /> 

          <Tooltip placement="top" color='#1a4a68' title={t('Κάντε κλικ για αναπαραγωγή')} >
            <Button
              icon={svgPlayEnClick ? <PlaySvg width={37} height={37} fill="#fff" /> : <StopSvg width={37} height={37} fill="#000" />}
              onClick={() => playAudioEn()}
              className={classes.btnPlay}>
              
              <span className={classes.btnPlayTitle}>{title}</span>
              <span className={classes.btnPlayFlag}><img src="/img/useful/gb_flag.png" /></span>
              <span className={classes.btnPlayText}>{t('English')}</span>  
            </Button>
          </Tooltip>

            {/* <div className={classes.btn22}>
              <span className={classes.btnTit}>{mp3 && mp3[1].caption}</span>
              {audioEn1 && 
                <audio controls>
                  <source src={audioEn1} type="audio/ogg"/>
                  <source src={audioEn1} type="audio/mpeg"/> 
                </audio>
              } 
                <span className={classes.btnFlag}><img src="/img/useful/gb.png" /></span>
                <span className={classes.btnFlag2}>English</span>
            </div> */}
          <br />
        </Col>
      </Row>
    </>
  )
}

export default PlayComponent