import { createSlice } from '@reduxjs/toolkit'

interface Key{
  value: string;
}

interface RoutesArray{
  value: number;
}


interface EnvironmentArray{
  value: number;
}

interface HistoryArray{
  value: number;
}


interface EventsArray{
  value: number;
}

interface MunicipalityArray{
  value: string;
}

interface RoutesState {
  Key: ''
  Routes: RoutesArray[]
  Environment: EnvironmentArray[]
  History: HistoryArray[]
  Events: EventsArray[]
  Municipality: MunicipalityArray[]
  EnvironmentColl: string
  HistoryColl: boolean
  EventColl: boolean
  SearchPageNumberRedux: 0;
  SearchPageSizeRedux: 9,
}

const initialState: RoutesState = {
  Key: '',
  Routes: [],
  Environment: [],
  History: [],
  Events: [],
  Municipality: [],
  EnvironmentColl: '',
  HistoryColl: false,
  EventColl: false,
  SearchPageNumberRedux: 0,
  SearchPageSizeRedux: 9,
};

export const SearchSlice = createSlice({
  name: 'Search',
  initialState,
  reducers: {

    KeySearchRedux: (state, action) => {
      state.Key = '';
      action.payload == '' ? state.Key = '' : state.Key = action.payload
    },


    RoutesSearchRedux: (state, action) => {
      state.Routes = [];
      action.payload == '' ? state.Routes = [] : state.Routes.push(action.payload)
    },
   
    EnvironmentSearchRedux: (state, action) => {
      state.Environment = [];
      action.payload == '' ? state.Environment = [] : state.Environment.push(action.payload)
    },

    HistorySearchRedux: (state, action) => {
      state.History = [];
      action.payload == '' ? state.History = [] : state.History.push(action.payload)
    },
    

    EventsSearchRedux: (state, action) => {
      state.Events = [];
      action.payload == '' ? state.Events = [] : state.Events.push(action.payload)
    },


    MunicipalitySearchRedux: (state, action) => {
      state.Municipality = [];
      action.payload == '' ? state.Municipality = [] : state.Municipality.push(action.payload)
    },
    

    MunicipalityRedux: (state, action) => {
      state.Municipality = [];
      action.payload == '' ? state.Municipality = [] : state.Municipality.push(action.payload)
    },





    EnvironmentCollapse:(state, action) => {
      state.EnvironmentColl = action.payload
    },

    HistoryCollapse:(state, action) => {
      state.HistoryColl = action.payload
    },

    EventCollapse:(state, action) => {
      state.EventColl = action.payload
    },

    SearchPageNumberRedux: (state, action) => { state.SearchPageNumberRedux=action.payload },
    SearchPageSizeRedux: (state, action) => { state.SearchPageSizeRedux=action.payload },
  },
})

export const {
  KeySearchRedux,
  RoutesSearchRedux,
  EnvironmentSearchRedux,
  HistorySearchRedux,
  EventsSearchRedux,
  MunicipalityRedux,
  MunicipalitySearchRedux,
  EnvironmentCollapse,
  HistoryCollapse,
  EventCollapse,
  SearchPageNumberRedux,
  SearchPageSizeRedux

} = SearchSlice.actions

export default SearchSlice.reducer 