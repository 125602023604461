import React, {useState, useRef} from 'react'
import { Col, Row, Button, Rate, Popconfirm } from 'antd'
import classes from "./SearchItem.module.css"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../utils/auth"
import { HeartOutlined, PlusCircleOutlined, CloseCircleOutlined, ExclamationOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'


import GlobusSvg from '../../img/svg/globus'
import RouteSvg from '../../img/svg/route'
import EventsSvg from '../../img/svg/events'
import TheaterSvg from '../../img/svg/theater'
import MovieSvg from '../../img/svg/movie'
import DanceSvg from '../../img/svg/dance'
import ConcertSvg from '../../img/svg/concert'
import ConcertsSvg from '../../img/svg/concerts'
import LaboratoriesSvg from '../../img/svg/laboratories'
import ExhibitionSvg from '../../img/svg/exhibition'
import ConferencesSVG from '../../img/svg/conferences'


interface Props{
  id: string
  type: string
  image: string
  title: string
  category_id: number
  text: string
  municipality: string
  categoryName: string
  timePeriods: any[]
  isAuth?: boolean
  isAddOrDelete?: boolean
  onClickAddOrRemovePois: (text: string, idPois: string, AddOrDelete: number) => void,
  onClickAddRemovePois: (idPois : string) => void,
}

const SearchItemComponent: React.FC<Props> = ({id, type, image, title, category_id, text, municipality, categoryName, timePeriods, isAuth, isAddOrDelete, onClickAddOrRemovePois, onClickAddRemovePois }:Props) => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const auth = useAuth()


  const [size, setSize] = useState<any>({});
  const imgRef = useRef<any>(null)



  return (
  <>
    <div className={classes.PointContainer}>
      <Row>

        <Col xs={24} sm={24} md={5} lg={6}>
          
              {type == "Event" ?
                <Link to={`/event/${id}`} > 
                  <div className={classes.divEventImage}>
                    <img src={image !== '' ? image : '../img/defaultPoisImage.jpg'} className={classes.PointImgEvent} alt={title} />
                  </div>
                </Link> 
              :
              type == "Poi" ?
                <Link to={`/exploration/${id}`} >   
                  <div>
                    <img
                      ref={imgRef}
                      onLoad={ (e: any ) => {
                              setSize({
                                height: e.target.naturalHeight,
                                width: e.target.naturalWidth
                              })
                      }}
                      src={image !== '' ? image : '../img/defaultPoisImage.jpg'}
                      className={size.height > size.width ? classes.PointImgPortrait : classes.PointImg}

                      alt={title}
                    />
                  </div>
                </Link>
              :
              <Link to={`/route/${id}`} >   
                <div>
                  <img src={image !== '' ? image : '../img/defaultPoisImage.jpg'} className={classes.PointImg} alt={title} />
                </div>
              </Link>
            }  
          
        </Col>

        <Col xs={24} sm={24} md={14} lg={13}>

          <div className={classes.PointCenter}>
            
            <div className={classes.PointCenterLeft} onClick={() => navigate(type == "Event" ? `/event/${id}` : type == "Poi" ? `/exploration/${id}` : `/route/${id}` , { replace: false  })}>
              {title}
            </div>
              
            {/* <div className={classes.PointCenterRight}>
              <Rate disabled allowHalf value={rate} />
            </div> */}
          </div>

          <div className={classes.PointCenter}>
              {type == "Poi" ?
                
              <p className={classes.PointCenterCenterP}>{text}...</p>
              : 
              type == "Route" ?
              <div className={classes.PointCenterCenterP} dangerouslySetInnerHTML={{ __html: text }} ></div>
              :

              timePeriods && timePeriods[0].starting_date !== '12/10/1983, 12:00' ?
                    
              timePeriods.map( (time: any, index:number) => {
                  return (
                      <div key={index} className={classes.PointCenterCenterP} >
                        <span className={classes.startPeriod}>{t('Έναρξη')}: {time.starting_date}</span>
                        <span className={classes.endPeriod}>{t('Λήξη')}: {time.ending_date}</span>
                      </div>
                )
              })      

              :
              <div className={classes.PointCenterCenterP}></div>    

              // timePeriods && timePeriods[0].starting_date !== '12/10/1983, 12:00' ?
              
              // <div className={classes.PointCenterCenterP}>
              //   <span className={classes.startPeriod}>{t('Έναρξη')}: {timePeriods[0].starting_date}</span>
              //   <span className={classes.endPeriod}>{t('Λήξη')}: {timePeriods[0].ending_date}</span>
              // </div>
              // :
              // <div className={classes.PointCenterCenterP}></div>   
            }
          </div>
     
          <div className={classes.PointCenterBottom}>
            <div className={classes.PointCenterButtomLeft}>
                <span style={{ minWidth: '180px', width: 'auto', display: 'inline-block' }}><GlobusSvg width={25} height={25} fill="#275674" /><span className={classes.PointTextBtm}>
                  {/* {t(`${municipality}`)} */}
                  { id === '1_48' ? t('FaraggiSamarias') : id === '1_290' ? t('SelinoKissamos') : t(municipality) }
                </span></span>
                {type == "Poi" ?
                  null
                  :
                  type == "Route" ?
                  <>       
                    <span className={classes.TypeIcon} ><RouteSvg width={24} height={24} fill="#275674" /></span><span className={classes.TypeText} style={{ marginLeft: '0px', marginTop: '-10px', top: '-10px', padding: '10px 10px 7px 8px' }}>{t(`${ categoryName }`)}</span>
                  </>
                  :
                  <> 
                  <span className={classes.TypeIconEvent} > 
                    {
                      category_id == 18 ? <MovieSvg width={28} height={28} fill="#275674" /> : 
                      category_id == 19 ? <LaboratoriesSvg width={28} height={28} fill="#275674"/> :
                      category_id == 23 ? <EventsSvg width={28} height={28} fill="#275674" /> :
                      category_id == 25 ? <ConcertsSvg width={28} height={28} fill="#275674" /> :
                      category_id == 26 ? <DanceSvg width={28} height={28} fill="#275674" /> :
                      category_id == 27 ? <ExhibitionSvg width={28} height={28} fill="#275674"/> :
                      category_id == 28 ? <TheaterSvg width={28} height={28} fill="#275674" /> :
                      category_id == 29 ? <ConcertSvg width={28} height={28} fill="#275674" /> :
                      <ConferencesSVG width={28} height={28} fill="#275674" />
                    }
                    </span> 
                    
                    <span className={classes.TypeTextEvent} style={{ marginLeft: '0px', marginTop: '-10px', top: '-10px', padding: '10px 10px 7px 8px' }}>{t(`${categoryName}`)}</span>
                  </>
                }
              </div>
          </div>


        </Col>

        <Col xs={24} sm={24} md={5} lg={5}>
          <div>
           
            {type !== "Event" ?  

              (auth.userData.id == '' || auth.userData.id == undefined)
              ?
              <Popconfirm
                title=
                {<div style={{ width: '200px', textAlign: 'left', fontSize: '14px', color: '#05324c', lineHeight: '1.2' }}>
                  {t('Για να προσθέσεις σημείο ενδιαφέροντος στα Αγαπημένα σου, κάνε σύνδεση ή εγγραφή')}
                </div>}
                onConfirm={() => navigate('/login')}
                icon={<ExclamationOutlined style={{ color: '#275674', fontWeight: '600' }} />}
                okText={t('Σύνδεση')}
                showCancel={false}
              >
                 <div className={!isAuth ? classes.PointFavoriteBtn2 : classes.PointRemoveFavoriteBtn} >
                    {!isAuth ? < HeartOutlined /> : <CloseCircleOutlined />}&nbsp;
                    {!isAuth ? t('Προσθήκη στα Αγαπημένα') : t('Αφαίρεση απο αγαπημένα')}
                  </div>
              </Popconfirm>
                
              :
                
              <Popconfirm
                title= {<div style={{ width: '200px', textAlign: 'left', fontSize: '14px', color: '#055604', lineHeight: '1.2' }}> {t('Το Σημείο Ενδιαφέροντος προστέθηκε στα Αγαπημένα σου!')} </div>}
                icon={< HeartOutlined style={{color: '#055604' }} />}
                showCancel={false}      
              >                 
                <div
                  className={!isAuth ? classes.PointFavoriteBtn2 : classes.PointFavoriteBtnRemove}
                  // style={{color: 'red'}}
                  onClick={() => auth.userData.id !== '' && [onClickAddRemovePois(id), isAddOrDelete ? onClickAddOrRemovePois(t('Σημείο Ενδιαφέροντος προστέθηκε με επιτυχία'), id, 1) : onClickAddOrRemovePois(t('Σημείο Ενδιαφέροντος διαγράφηκε επιτυχώς'), id, 0)]}
                >
                  {!isAuth ? < HeartOutlined /> : <CloseCircleOutlined />}&nbsp;
                  {!isAuth ? t('Προσθήκη στα Αγαπημένα') : t('Αφαίρεση απο αγαπημένα')}
                </div>
              </Popconfirm>
                :
                null
              
            }
              
            <Button
              size='large'
              icon={< PlusCircleOutlined />}
              className={ type == "Event" ? classes.PointMoreBtnEvent : classes.PointMoreBtn }
              onClick={() => navigate(type == "Event" ? `/event/${id}` : type == "Poi" ? `/exploration/${id}` : `/route/${id}` , { replace: false  })}>
              {t('Περισσότερα')}
            </Button>
              
          </div>
        </Col>

      </Row>
      </div>
    </>
  )
}

export default SearchItemComponent
