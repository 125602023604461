
import React,{ useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import classes from "./Slider.module.css"
import ReactPlayer from 'react-player'

interface Props{
  url: string
}

const SliderVideoComponent: React.FC<Props> = ({url}:Props) => {


  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isMobile = width <= 768;



  return ( 

    <div>

      <div className={classes.container}>
        <Row justify="center">
          <Col span={24}>
            <div className={classes.helper}>

              <ReactPlayer
                width='100%'
                height={isMobile ? '230px' : '600px'}
                url={url}
              
              />
            </div>
          </Col>
        </Row>
      </div>

    </div>
  )
}

export default SliderVideoComponent
