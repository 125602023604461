export const EventsData = [
{
    id: "1",
    item: "Συναυλίες",
    name: "Συναυλίες"
},
{
    id: "2",
    item: "Θέατρο",
    name: "Θέατρο",
},
{
    id: "3",
    item: "Έκθεση",
    name: "Έκθεση",
},
{
    id: "4",
    item: "Κινηματογράφος",
    name: "Κινηματογράφος",
},
{
    id: "5",
    item: "Χορός",
    name: "Χορός",
},
{
    id: "6",
    item: "Εκδηλώσεις",
    name: "Εκδηλώσεις",
},
{
    id: "7",
    item: "Ρεσιτάλ",
    name: "Ρεσιτάλ",
},
{
    id: "8",
    item: "Συνέδριο",
    name: "Συνέδριο",
}
]