
import React from "react"
import Props from './svgProps'
    
const ConcertSvg = ({width, height, fill}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="https://www.w3.org/2000/svg"
        viewBox="0 0 19.96 20">
        <path fill={fill} d="M7.4,5.84H7a2.88,2.88,0,0,0,.84-1.08A2,2,0,0,0,9,2.9V2.62A1.62,1.62,0,0,0,7.69,1,1.29,1.29,0,0,0,6.44,0h-2A2.46,2.46,0,0,0,2.67.78a2.5,2.5,0,0,0-.74,1.78v.7h.32v.32A2.93,2.93,0,0,0,3.33,5.84H2.9A2.9,2.9,0,0,0,0,8.73V12a1.93,1.93,0,0,0,1.93,1.93V20H8.37V13.88A1.93,1.93,0,0,0,10.3,12V8.73A2.9,2.9,0,0,0,7.4,5.84ZM4.83,14.53v3.54H2.58V16.75a1.75,1.75,0,0,0,1.28-1.08l.46-1.24a2.87,2.87,0,0,0,.19-1v-.18H5.79v.18a2.87,2.87,0,0,0,.19,1l.46,1.24a1.75,1.75,0,0,0,1.29,1.08v1.32H5.47V14.53Zm1-1.93H4.51v-5l.64-.43.64.43ZM4.13,6.51l.44.29-.44.3A.18.18,0,0,1,3.86,7v-.3A.18.18,0,0,1,4,6.48a.17.17,0,0,1,.1,0Zm2,.59-.44-.3.44-.29a.18.18,0,0,1,.27.14V7a.18.18,0,0,1-.27.15ZM2.58,2.56a1.87,1.87,0,0,1,.56-1.34A1.78,1.78,0,0,1,4.42.69h2a.64.64,0,0,1,.64.64v.32H7.4a1,1,0,0,1,1,1V2.9a1.39,1.39,0,0,1-.64,1.17V3.26A1.29,1.29,0,0,0,6.44,2H6.3l-.09.1a1.88,1.88,0,0,1-1.33.55H2.58Zm.32,1V3.26h2a2.48,2.48,0,0,0,1.67-.63.64.64,0,0,1,.53.63V4.73A2.24,2.24,0,0,1,2.9,3.58ZM.64,8.73A2.26,2.26,0,0,1,2.9,6.48h.34a1,1,0,0,0,0,.17V7a.83.83,0,0,0,.64.8v5.67a2.39,2.39,0,0,1-.14.79l-.46,1.23a1.12,1.12,0,0,1-.68.65v-8H1.93V12H.64ZM.82,12.6H1.93v.64A1.3,1.3,0,0,1,.82,12.6Zm1.76,6.76v-.65H4.83v.65Zm2.89,0v-.65H7.73v.65Zm2.9-6.12V12.6H9.48A1.3,1.3,0,0,1,8.37,13.24ZM9.66,12H8.37V8.09H7.73v8A1.11,1.11,0,0,1,7,15.44l-.46-1.23a2.39,2.39,0,0,1-.14-.79V7.75A.82.82,0,0,0,7.08,7v-.3a1,1,0,0,0,0-.17H7.4A2.26,2.26,0,0,1,9.66,8.73Z" />
        <path fill={fill} d="M20,12V5.51H16.49a1.47,1.47,0,0,0-1,.43,1.47,1.47,0,0,0-1-.43H10.94V12h4.19v7.41H12.88V20H18v-.64H15.77V12ZM16.49,6.16h2.82v5.15H15.77V6.57a.84.84,0,0,1,.72-.41Zm-4.9,0h2.82a.84.84,0,0,1,.72.41v4.74H11.59Z" />
        <path fill={fill} d="M15.45,3.91a1,1,0,0,0,1-1V1L18.35.73v1A1.13,1.13,0,0,0,18,1.65a1,1,0,1,0,0,1.93,1,1,0,0,0,1-1V0L15.77.4V2A1.13,1.13,0,0,0,15.45,2a1,1,0,0,0,0,1.94Zm2.58-1a.32.32,0,1,1,.32-.32A.32.32,0,0,1,18,2.94Zm-2.58-.32a.32.32,0,1,1-.32.32A.32.32,0,0,1,15.45,2.62Z" />
        <path fill={fill} d="M14.38,7.51,13.2,6.93V8.79a1.13,1.13,0,0,0-.32-.06,1,1,0,1,0,1,1V8l.25.12a.72.72,0,0,1,.4.64h.64a1.37,1.37,0,0,0-.75-1.22ZM12.88,10a.32.32,0,1,1,.32-.32A.32.32,0,0,1,12.88,10Z" />
      </svg>
    )
}

export default ConcertSvg  