
import React from "react"
import Props from './svgProps'
    
const ConcertsSvg = ({width, height, fill}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="https://www.w3.org/2000/svg"
        viewBox="0 0 19.96 20">
        
        <path fill={fill} d="M15.28,18.24a3.29,3.29,0,0,0,.8-.08,3.57,3.57,0,0,0,3-3.22V0L5.85,2.1V14.31l-.3-.15a3.93,3.93,0,0,0-1.79-.42,4,4,0,0,0-.76.07A3.85,3.85,0,0,0,.52,15.42a2.89,2.89,0,0,0-.47,2.19,3,3,0,0,0,1.52,2,3.82,3.82,0,0,0,1.85.47,3.87,3.87,0,0,0,.8-.08,3.58,3.58,0,0,0,3-3.22V7.26L17.68,5.62v6.83l-.29-.15A4,4,0,0,0,14.84,12a3.85,3.85,0,0,0-2.48,1.61,2.89,2.89,0,0,0-.47,2.19A3.31,3.31,0,0,0,15.28,18.24Zm-9.72-.55a2.43,2.43,0,0,1-1.61,1,2.56,2.56,0,0,1-.55,0,2.35,2.35,0,0,1-1.16-.29,1.58,1.58,0,0,1-.84-1.09,1.52,1.52,0,0,1,.26-1.17,2.49,2.49,0,0,1,1.61-1,2.09,2.09,0,0,1,.54,0,1.91,1.91,0,0,1,2,1.37A1.56,1.56,0,0,1,5.56,17.69ZM7.23,5.9V3.3L17.68,1.66v2.6Zm6.27,8.45a2.5,2.5,0,0,1,1.61-1,2.6,2.6,0,0,1,.54,0,2.44,2.44,0,0,1,1.17.28,1.55,1.55,0,0,1,.84,1.1,1.52,1.52,0,0,1-.26,1.16,2.53,2.53,0,0,1-1.61,1,2.46,2.46,0,0,1-.53.06,2,2,0,0,1-2-1.38A1.54,1.54,0,0,1,13.5,14.35Z" />
      
      </svg>
    )
}

export default ConcertsSvg  